import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Checkbox, ListItem, ListItemText } from '@mui/material';
import { connect } from 'react-redux';
import { styles } from '../styles';
import InfoPopover from '../popup/InfoPopover';
import ChecklistIndicator from './ChecklistIndicator';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import functions from '../../functions/functions';

class ChecklistItemSelectable extends React.Component {
  state = { checked: false };
  componentDidMount() {
    if (this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }
  handleClick = (e) => {
    this.setState({ checked: !this.state.checked });
    this.props.onClick(e);
  };
  render() {
    const { classes } = this.props;
    let issue = this.props.issue;
    let labelId = this.props.labelId;
    return (
      (<ListItem key={`${issue.issue.replace(/\s/g, '')}_item`} role={undefined} dense className={classes.checklistItemSelectable} style={{ cursor: 'pointer' }}>
        <InfoPopover resize placement='left' title={issue.subtitle} usePrioritizeTabs id={issue.issue} type='prioritize' colorValue={functions.numberToColor(this.props.state)} />
        <ChecklistIndicator color={functions.numberToColor(this.props.state)} tooltip={functions.numberToPrioritizeTooltip(this.props.state)}></ChecklistIndicator>
        <Checkbox
          onClick={this.handleClick}
          className={classes.checkbox}
          color='secondary'
          icon={<CheckBoxOutlineBlankIcon className={classes.checkboxIconResize} />}
          checkedIcon={<CheckBoxIcon className={classes.checkboxIconResize} />}
          checked={this.state.checked}
        ></Checkbox>
        <ListItemText
          onClick={this.handleClick}
          id={labelId}
          primary={`${issue.subtitle}`}
          className={this.state.checked ? classes.checklistItemActive : null}
          classes={this.props.format ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }}
        />
      </ListItem>)
    );
  }
}

ChecklistItemSelectable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(ChecklistItemSelectable));
