import React from 'react';
//import investigationDetails from '../../../arrays/optimize/investigationDetails';
//import InvestigateDetails from '../../../components/helpers/InvestigateDetails';
//import recommendationDetails from '../../../arrays/optimize/recommendationDetails';
import validateClientPermission from '../../filters/functions/validateClientPermission';
import TextDisplay from '../../filters/TextDisplay';
//import Grid from '@mui/material/Grid';
import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
  Collapse,
  ListItem,
  ListItemText,
  Checkbox,
  MenuItem,
  Popper,
  Paper,
  Divider,
  ClickAwayListener,
  CircularProgress,
  Link,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import CustomDialog from '../../popup/CustomDialog';
import DialogContentText from '@mui/material/DialogContentText';
import TableWidget from '../../widgets/TableWidget';
import DropdownMenu from '../../menus/DropdownMenu';
import InfoPopover from '../../popup/InfoPopover';
//import { MailOutline } from '@mui/icons-material';
//import MenuIcon from '@mui/icons-material/Menu';
//import FormStepperWidget from '../../widgets/FormStepperWidget';
import WidgetTitle from '../../headers/WidgetTitle';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import CustomSelect from '../../filters/CustomSelect';
import {
  fetchDeviceImprovements,
  fetchDeviceByTest,
  fetchDeviceIssues,
  fetchDevices,
  fetchActions,
  fetchInvestigations,
  fetchIssueDetails,
  approveImprovement,
  deleteImprovement,
  fetchDeviceSpecs,
  fetchDiagnostics,
  setTestStatus,
  clearDeviceIssuesImprovements,
  //addImprovement,
} from '../../../actions/index';
import api from '../../../apis/api';
import { CheckCircle, Undo, Edit, Delete, ThumbUpAlt, ThumbDownAlt, ExpandMore, MoreVert, Search, Settings, Speed } from '@mui/icons-material';
import PrintHeader from '../../headers/PrintHeader';
import ChecklistIndicator from '../../filters/ChecklistIndicator';
import ValveChartWidget from '../../widgets/ValveChartWidget';
import functions from '../../../functions/functions';
import AccordionWidget from '../../widgets/AccordionWidgetOptimize';
import DiagnosticsWidgets from './DiagnosticsWidgets';
import PerformanceWidgets from './PerformanceWidgets';
import AlignmentWidgets from './AlignmentWidgets';
import OptimizeContent from '../config/content/OptimizeContent';

let requestedInvestigations = [];
let requestedActions = [];
let requestedIssues = [];
let loadedTest = -1;

class ImprovementsWidgets extends React.Component {
  //const [open, setOpen] = React.useState(false);

  constructor(props) {
    super(props);
    this.state = {
      status: '',
      statusMessage: '',
      open: false,
      formBool: false,
      improvement: '',
      comment: '',
      level: 1,
      improvementType: '',
      test: null,
      testDate: null,
      stateDate: null,
      editId: null,
      menus: {},
      approvalOpen: false,
      approvalName: '',
      approvalEmail: '',
      approvalId: null,
      approvalType: '',
      deleteId: null,
      deleteOpen: false,
      statusMenu: false,
      statusMenuAnchor: null,
      performanceCheck: false,
      diagnosticsCheck: false,
      alignmentCheck: false,
      diagnosticsToggle: false,
      performanceToggle: false,
      alignmentToggle: false,
      type: 'Technician',
      copyText: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeLevel = this.handleInputChangeLevel.bind(this);
    this.handleInputChangeImprovementType = this.handleInputChangeImprovementType.bind(this);
    this.handleInputChangeDate = this.handleInputChangeDate.bind(this);
    this.handleApproval = this.handleApproval.bind(this);
    this.openApproval = this.openApproval.bind(this);
    this.closeApproval = this.closeApproval.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.openDelete = this.openDelete.bind(this);
    this.closeDelete = this.closeDelete.bind(this);
    this.processStatus = this.processStatus.bind(this);
    //let issueLoad = false;
    this.valveLevelData = [
      {
        issue: 4,
        status: 'Detected',
        investigationList: '1,3',
        recommendationList: '1,3,13',
      },
      {
        issue: 5,
        status: 'FakeStatus',
        investigationList: '1,3',
        recommendationList: '1,3,13',
      },
    ];
    this.issue = {
      4: {
        name: 'Data Validity',
        description: 'Tim is great',
        priority: 0,
        charts: '1,2,6',
        valveInfo: 'SignalSource',
        profileSecondary: 'SeatContact, FullOpen',
      },
      5: {
        name: 'Data Test',
        description: 'Tim is super great',
        priority: 2,
        charts: '1',
        valveInfo: '',
        profileSecondary: '',
      },
    };
  }
  recommendationsCurrent = {};

  convertChartTypeNum(num) {
    switch (num) {
      case '1':
        return { attribute: 'Travel', idType: 'Profile' };
      case '2':
        return { attribute: 'Air', idType: 'Profile' };
      case '3':
        return { attribute: 'Actuator', idType: 'Profile' };
      case '4':
        return { attribute: 'Friction', idType: 'Profile' };
      case '5':
        return { attribute: 'I/P', idType: 'Profile' };
      case '6':
        return { attribute: 'Seat', idType: 'Profile' };
      case '7':
        return { attribute: 'Resolution', idType: 'Resolution' };
      case '8':
        return { attribute: 'Sensitivity', idType: 'Sensitivity' };
      case '9':
        return { attribute: 'Stroke', idType: 'Stroke' };
      case '10':
        return { attribute: 'Alignment', idType: 'Alignment' };
      default:
        return { attribute: 'Error', idType: 'Error' };
    }
  }
  processSupplementalType(infoType) {
    let device = this.props.devices[this.props.activeDevice];
    let testId = !device ? null : device.activeProfileId;
    let specs = !this.props.tests || !this.props.tests.diagnosticsSpecs ? null : this.props.tests.diagnosticsSpecs[testId];
    if (!this.props.tests || !this.props.tests.diagnosticsSpecs) {
      return { title: 'Loading', value: 'N/A' };
    }
    switch (infoType) {
      case 'seatContact':
        return { title: 'Seat Contact', value: specs.seatContact };
      case 'fullOpen':
        return { title: 'Full Open', value: specs.fullOpen };
      case 'signalSource':
        return { title: 'Signal Source', value: device.signalSource };
      default:
        return 'Info';
    }
  }
  renderIssueSupplemental(chartSupplemental) {
    return (
      <Grid container>
        {chartSupplemental.map((infoType) => {
          let data = this.processSupplementalType(infoType);
          return <TextDisplay key={`supplemental_${infoType}`} id={null} label={data.title} text={!data.value && data.value !== 0 && data.value !== '' ? 'Error' : data.value} type='tile' />;
        })}
      </Grid>
    );
  }
  renderIssueCharts(chartDetails) {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <Grid container>
        {chartDetails.map((chartTypeId) => {
          let chartType = this.convertChartTypeNum(chartTypeId.toString());
          return (
            <Grid key={`chartType_key_${chartTypeId}`} item xs={12} sm={printStyles ? 12 : 6} md={printStyles ? 12 : 4}>
              <ValveChartWidget
                className={classes.widgetSpacing}
                xs={12}
                type='valve'
                valveColumns={1}
                progress={false}
                testId={!this.props.devices || !this.props.devices[this.props.activeDevice] ? null : this.props.devices[this.props.activeDevice][`active${chartType.idType}Id`]}
                attribute={chartType.attribute}
                loadingValidation={'single'}
                embed
                chartType={chartType}
              ></ValveChartWidget>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  getProbabilityColor(probability) {
    const { classes } = this.props;
    switch (probability) {
      case 'Very Likely':
        return classes.greenText;
      case 'Likely':
        return classes.greenText;
      case 'Possible':
        return classes.yellowText;
      case 'Unlikely':
        return classes.redText;
      default:
        return classes.grayText;
    }
  }
  handleRecommendationClick(e, issue, id) {
    this.recommendationsCurrent[issue][id].checked = !this.recommendationsCurrent[issue][id].checked;
    this.setState({ state: this.state });
  }
  renderRecommendations(issueId, recommendations) {
    const { classes } = this.props;
    //console.log(recommendations);
    let recommendationDetails = this.props.recommendations.actions;
    //console.log(recommendationDetails);
    return (
      <React.Fragment>
        {recommendations.map((recommendation) => {
          let rowActionId = recommendation;
          if (!recommendationDetails[recommendation]) {
            return <Typography key={`recommendation_row_id${rowActionId}`}>Loading...</Typography>;
          }
          if (typeof this.recommendationsCurrent[issueId] === 'undefined') {
            this.recommendationsCurrent[issueId] = {};
          }
          if (typeof this.recommendationsCurrent[issueId][rowActionId] === 'undefined') {
            this.recommendationsCurrent[issueId][rowActionId] = {
              checked: false,
              level: recommendationDetails[recommendation].actionType,
            };
          }

          return (
            <React.Fragment key={`${recommendation}_recommendation_item`}>
              <ListItem
                role={undefined}
                dense
                //className={this.preGenActionItemsCurrent[rowActionId].checked ? classes.checklistItemActive : classes.checklistItemSelectable}
                style={{ cursor: 'pointer', paddingLeft: 0 }}
              >
                <Checkbox
                  checked={this.recommendationsCurrent[issueId][rowActionId].checked}
                  onClick={(e) => {
                    this.handleRecommendationClick(e, issueId, rowActionId);
                  }}
                  className={classes.checkbox}
                  style={{ marginRight: 8 }}
                  color='secondary'
                ></Checkbox>
                <ListItemText
                  onClick={(e) => {
                    this.handleRecommendationClick(e, issueId, rowActionId);
                  }}
                  primary={
                    <Typography variant='subtitle2'>
                      <Grid container>
                        <Grid item style={{ marginRight: 40 }}>
                          {!recommendationDetails[recommendation].action ? 'Recommendation not found!' : recommendationDetails[recommendation].action}
                        </Grid>
                        <Grid item>
                          Likelihood -{' '}
                          <span className={this.getProbabilityColor(recommendationDetails[recommendation].probability)}>
                            {!recommendationDetails[recommendation].probability ? 'Likelihood not found!' : recommendationDetails[recommendation].probability}
                          </span>
                        </Grid>
                      </Grid>
                    </Typography>
                  }
                  classes={this.props.format ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }}
                />
              </ListItem>
              <Collapse in={this.recommendationsCurrent[issueId][rowActionId].checked} style={{ marginTop: 8 }}>
                <div style={{ marginBottom: 24 }}>
                  <Typography
                    variant='body2'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginBottom: 16,
                    }}
                    className={classes.body}
                  >
                    {!recommendationDetails[recommendation].comment ? 'Recommendation description not found!' : recommendationDetails[recommendation].comment}
                  </Typography>
                  <div
                    style={{
                      marginLeft: 5,
                      marginBottom: 16,
                    }}
                  >
                    <CustomSelect
                      single
                      id={null}
                      label={`Repair Level`}
                      onChangeFunction={(event, value, type) => {
                        this.recommendationsCurrent[issueId][rowActionId].level = value;
                        this.setState({ state: this.state });
                      }}
                      values={this.recommendationsCurrent[issueId][rowActionId].level}
                      options={['Field Adjustment', 'Parts Replacement', 'Body Repair', 'Note']}
                      helperText={null}
                    />
                  </div>
                </div>
              </Collapse>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
  handleInfoClick = (event) => {
    event.preventDefault();
  };
  handleInvestigateClick = (event) => {
    event.preventDefault();
  };
  handleMenuClick = (event) => {
    event.preventDefault();
    this.setState({
      statusMenu: !this.state.statusMenu,
      statusMenuAnchor: event.currentTarget,
    });
  };
  renderIssue(issue) {
    const { classes } = this.props;
    const issueRecommendations = issue.recommendation_list_active;
    const issueRecommendationsInactive = issue.recommendation_list_inactive;
    const issueInvestigations = issue.investigation_list_active;
    const issueInvestigationsInactive = issue.investigation_list_inactive;
    const combinedRecommendations = issueRecommendations.concat(issueRecommendationsInactive);

    //only ask for things we do not have yet
    let missingRecommendations = [];
    for (let i = 0; i < combinedRecommendations.length; i++) {
      if (!this.props.recommendations.actions[combinedRecommendations[i]] && !requestedActions.includes(combinedRecommendations[i])) {
        requestedActions.push(combinedRecommendations[i]);
        missingRecommendations.push(combinedRecommendations[i]);
      }
    }
    const combinedInvestigations = issueInvestigations.concat(issueInvestigationsInactive);
    let missingInvestigations = [];
    for (let i = 0; i < combinedInvestigations.length; i++) {
      if (!this.props.recommendations.investigations[combinedInvestigations[i]] && !requestedInvestigations.includes(combinedInvestigations[i])) {
        requestedInvestigations.push(combinedInvestigations[i]);
        missingInvestigations.push(combinedInvestigations[i]);
      }
    }

    if (!this.props.recommendations.issues[issue.issue] && !requestedIssues.includes(issue.issue)) {
      requestedIssues.push(issue.issue);
      this.props.fetchIssueDetails([issue.issue]);
    }
    if (missingInvestigations.length !== 0) {
      this.props.fetchInvestigations(missingInvestigations);
    }
    if (missingRecommendations.length !== 0) {
      this.props.fetchActions(missingRecommendations);
    }
    const issueDetails = !this.props.recommendations.issues[issue.issue] ? {} : this.props.recommendations.issues[issue.issue];
    const issueCharts = !issueDetails.chart_tuple ? [] : issueDetails.chart_tuple;
    const chartSupplemental = !issueDetails.profilesecondary_tuple && !issueDetails.valveinfo_tuple ? [] : issueDetails.profilesecondary_tuple.concat(issueDetails.valveinfo_tuple);
    const device = this.props.devices[this.props.activeDevice];
    const handleStatusClose = () => {
      this.setState({ statusMenu: false });
    };

    return (
      <Accordion TransitionProps={{ unmountOnExit: true }} square={false} key={`issue_key_${issue.issue}`}>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMore className={classes.iconGray} />} aria-controls='test' id='test'>
          <Grid container justifyContent='space-between'>
            <Grid item style={{ paddingTop: 3, minWidth: 300 }}>
              <Grid container>
                {/*<Grid item onClick={this.handleInfoClick}>
                  <InfoPopover resize title='Test' id='Test' />
    </Grid>*/}
                <Grid item>
                  <div style={{ marginTop: 6 }}>
                    <ChecklistIndicator color={functions.numberToColor(issueDetails.priority)}></ChecklistIndicator>
                  </div>
                </Grid>
                <Grid item>
                  {!issueDetails.issue_name ? (
                    <Typography variant='subtitle2' className={classes.issueName}>
                      <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                      Loading...
                    </Typography>
                  ) : (
                    <Typography variant='subtitle1' className={classes.issueName}>
                      {issueDetails.issue_name}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/*<Grid item style={{ paddingTop: 3 }}>
              <Typography variant='subtitle1'>Very Important</Typography>
    </Grid>*/}
            <Grid item className={classes.issueItem}>
              <Typography variant='subtitle1'>
                {issueRecommendations.length} Recommendation
                {issueRecommendations.length === 1 ? null : 's'}
              </Typography>
            </Grid>
            <Grid item className={classes.issueItem}>
              <Typography variant='subtitle1'>Status: {!issue.status ? 'Unassigned' : issue.status}</Typography>
            </Grid>
            <Grid item className={classes.printHide}>
              <Grid container style={{ minWidth: 60 }} justifyContent='space-between'>
                <Grid item></Grid>
                {issueInvestigations < 1 ? null : (
                  <span onClick={this.handleInvestigateClick}>
                    <InfoPopover
                      center
                      tooltip='Investigate this Issue'
                      title={`Investigate - ${!device || !device.name ? 'Device Name Not Found' : device.name}`}
                      titleIcon='Investigate'
                      resize
                      type='investigate'
                      useInvestigateTabs
                      icon={<Search></Search>}
                      issue={issue}
                      investigations={issueInvestigations}
                    />
                  </span>
                )}
                <Tooltip arrow placement='top' title='Update Progress' enterDelay={300}>
                  <IconButton color='inherit' aria-label='more' onClick={this.handleMenuClick} size='large'>
                    <MoreVert />
                  </IconButton>
                </Tooltip>
                <Popper open={!this.state.statusMenu ? false : this.state.statusMenu} anchorEl={this.state.statusMenuAnchor} onClose={handleStatusClose} placement='bottom-start'>
                  <ClickAwayListener onClickAway={handleStatusClose}>
                    <Paper>
                      <Typography
                        variant='caption'
                        style={{
                          padding: '10px 14px 6px 14px',
                          display: 'block',
                        }}
                      >
                        Select to update progress
                      </Typography>
                      <MenuItem>Detected</MenuItem>
                      <MenuItem>Acknowledged</MenuItem>
                      <MenuItem>Rejected</MenuItem>
                      <MenuItem>In Progress</MenuItem>
                      <MenuItem>Resolved</MenuItem>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5 }}>
              Description
            </Typography>
            <Typography variant='body1' className={classes.body}>
              {issueDetails.description}
            </Typography>
            {this.renderIssueCharts(issueCharts)}
            {this.renderIssueSupplemental(chartSupplemental)}
          </div>
          {issueInvestigations < 1 ? null : (
            <div className={classes.helperSection}>
              <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5 }}>
                Investigation
              </Typography>
              <Typography style={{ marginBottom: 10 }}>Unsure of next steps? Click below to investigate the issue and view examples.</Typography>
              <InfoPopover
                center
                button
                tooltip='Investigate this Issue'
                title={`Investigate - ${!device || !device.name ? 'Device Name Not Found' : device.name}`}
                titleIcon='Investigate'
                resize
                type='investigate'
                useInvestigateTabs
                icon={<Search className={classes.buttonIcon}></Search>}
                issue={issue}
                investigations={issueInvestigations}
              />
            </div>
          )}
          <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5 }}>
            Recommendations
          </Typography>
          {this.renderRecommendations(issue.issue, issueRecommendations)}
          <Typography>Don't see the improvement path you need? Create a custom improvement path below.</Typography>
          <Divider className={`${classes.dialogDivider} ${classes.accordionDialogDivider}`}></Divider>
          <Grid container justifyContent='space-between'>
            <Grid item></Grid>
            <Grid item>
              <Button
                type='text'
                onClick={(e) => {
                  this.handleRecommendationSubmit(issue.issue, this.recommendationsCurrent[issue.issue]);
                }}
                color='secondary'
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
  handleRecommendationSubmit(issueId, recommendations) {
    console.log(issueId, recommendations);
  }
  processStatus(data) {
    if (data.notApproved === 1) {
      return {
        status: 'Not Approved',
        contact: data.notApprovedBy,
        user: data.notApprovedByUser,
        date: data.notApprovedByDate,
        email: data.notApprovedByEmail,
      };
    } else if (data.completed === 1) {
      return {
        status: 'Completed',
        contact: data.completedBy,
        user: data.completedByUser,
        date: data.completedByDate,
        email: data.completedByEmail,
      };
    } else if (data.approved === 1) {
      return {
        status: 'Approved',
        contact: data.approvedBy,
        user: data.approvedByUser,
        date: data.approvedByDate,
        email: data.approvedByEmail,
      };
    } else {
      return {
        status: 'Pending',
        contact: '-',
        user: data.user,
        date: data.date,
      };
    }
  }
  processState(state) {
    switch (parseInt(state)) {
      case 1:
        return ' - As Left';
      case 3:
        return ' - As Found';
      default:
        return '';
    }
  }
  processTestType(state) {
    switch (parseInt(state)) {
      case 1:
        return 'As Left';
      case 3:
        return 'As Found';
      default:
        return 'Standard';
    }
  }
  processDate(date) {
    return (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) + '/' + (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) + '/' + date.getFullYear();
  }
  processTimestampArray(arr, stateArr) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      const timestamp = new Date(parseInt(arr[i]) * 1000).toLocaleString();

      result.push(`${timestamp}${this.processState(stateArr[i])}`);
    }
    return result;
  }
  processActiveTest = (date) => {
    const timestampArr =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory ? this.props.devices[this.props.activeDevice].datetimeHistory : [];
    const stateArr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].stateHistory ? this.props.devices[this.props.activeDevice].stateHistory : [];
    //let indexNum = -1;
    let newActiveTestId = -1;
    for (let i = 0; i < timestampArr.length; i++) {
      const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
      //result.push(timestamp);

      if (timestamp === date) {
        //indexNum = i;
        newActiveTestId = this.props.devices[this.props.activeDevice].testIdHistory[i];
        //console.log(this.props.activeDevice, newActiveTestId);
        return newActiveTestId;
      }
    }
    return newActiveTestId;
  };
  selectActiveTest = async (date) => {
    const timestampArr =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory ? this.props.devices[this.props.activeDevice].datetimeHistory : [];
    const stateArr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].stateHistory ? this.props.devices[this.props.activeDevice].stateHistory : [];
    //let indexNum = -1;
    let newActiveTestId = -1;
    for (let i = 0; i < timestampArr.length; i++) {
      const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
      //result.push(timestamp);

      if (timestamp === date) {
        //indexNum = i;
        newActiveTestId = this.props.devices[this.props.activeDevice].testIdHistory[i];
        await this.props.fetchDeviceByTest(this.props.activeDevice, newActiveTestId);
        this.props.fetchDeviceImprovements(this.props.activeDevice, !this.props.alignment ? 'Technician' : 'Control');
        //console.log(this.props.activeDevice, newActiveTestId);
        return newActiveTestId;
      }
    }
    return newActiveTestId;
  };
  selectActiveTestValve = (date) => {
    const timestampArr =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory ? this.props.devices[this.props.activeDevice].datetimeHistory : [];
    const stateArr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].stateHistory ? this.props.devices[this.props.activeDevice].stateHistory : [];
    //let indexNum = -1;
    let newActiveTestValveId = -1;
    for (let i = 0; i < timestampArr.length; i++) {
      const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
      //result.push(timestamp);

      if (timestamp === date) {
        //indexNum = i;
        newActiveTestValveId = this.props.devices[this.props.activeDevice].valveTestIdHistory[i];
        //this.props.fetchDeviceByTest(this.props.activeDevice, newActiveTestId);
        //console.log(this.props.activeDevice, newActiveTestId);
        return newActiveTestValveId;
      }
    }
    return newActiveTestValveId;
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleInputChangeLevel(event) {
    //console.log(event);
    //console.log(event.target);
    let value = -2;
    switch (event) {
      case 'Note':
        value = 0;
        break;
      case 'Field Adjustment':
        value = 1;
        break;
      case 'Parts Replacement':
        value = 2;
        break;
      case 'Body Repair':
        value = 3;
        break;
      default:
        value = -1;
    }
    const name = 'level';

    this.setState({
      [name]: value,
      levelName: event,
    });
    return value;
    /* const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;
    console.log(event);
    console.log(event.target);
    this.setState({
      [name]: value,
    });*/
  }
  handleInputChangeImprovementType(event) {
    let value = event;
    let name = 'improvementType';
    this.setState({
      [name]: value,
    });
  }
  handleInputChangeDate(event) {
    //console.log(event);
    //console.log(this.selectActiveTest(event));
    //const target = event.target;
    //const value = target.name === 'isGoing' ? target.checked : target.value;
    const value = event;
    const name = 'test';

    this.setState({
      [name]: value,
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true, status: '', statusMessage: '' });
  };

  handleClose = () => {
    this.setState({
      open: false,
      formBool: false,
      testDate: null,
      stateDate: null,
    });
  };
  handleSubmit = async () => {
    let test = this.state.test;
    if (test === null) {
      test =
        !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime
          ? null
          : `${new Date(this.props.devices[this.props.activeDevice].dateTime * 1000).toLocaleString()}${this.processState(this.props.devices[this.props.activeDevice].state)}`;
      this.setState({
        test,
      });
    }
    const selectedTest = this.processActiveTest(test);
    const selectedTestValve = this.selectActiveTestValve(test);
    //console.log(selectedTestValve);
    //console.log(selectedTest);
    //console.log(this.state);
    //this.props.addImprovement(this.state, this.selectActiveTest(test),this.props.devices[this.props.activeDevice] );
    //console.log(this.props.activeDevice);
    //console.log(this.props.devices[this.props.activeDevice]);
    const url = this.state.formBool
      ? `/${this.props.currentModule}/devices/${this.props.activeDevice}/improvements/update/${this.state.editId}`
      : `/${this.props.currentModule}/devices/${this.props.activeDevice}/improvements`;
    //console.log(url);
    //console.log(this.state);
    //console.log(test);
    //console.log(selectedTest);
    //console.log(

    //);
    //console.log(response);
    let currentState = this.state;
    if (isNaN(currentState.level)) {
      currentState.level = this.handleInputChangeLevel(currentState.level);
    }
    try {
      if (validateClientPermission(this.props.clientPermission)) {
        /*const response = */ await api.post(url, [currentState, selectedTest, selectedTestValve]);
        console.log(!this.props.alignment ? 'Technician' : 'Control');
        this.props.fetchDeviceImprovements(this.props.activeDevice, !this.props.alignment ? 'Technician' : 'Control');
      } else {
        //add local state addition here
        //TODO redux persistance
        // https://stackoverflow.com/questions/52161128/react-redux-state-is-lost-at-page-refresh
        // https://github.com/rt2zz/redux-persist
        console.log([currentState, selectedTest, selectedTestValve]);
        console.log(this.props.devices[this.props.activeDevice].improvements);
      }

      this.setState({
        status: '',
        statusMessage: '',
        open: false,
        formBool: false,
        improvement: '',
        comment: '',
        level: 1,
        improvementType: '',
        test: null,
        testDate: null,
        stateDate: null,
        editId: null,
        menus: {},
        approvalOpen: false,
        approvalName: '',
        approvalEmail: '',
        approvalId: null,
        approvalType: '',
        deleteId: null,
        deleteOpen: false,
        type: 'Technician',
      });
    } catch (e) {
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
        //loading: false,
      });
    }
  };
  componentDidMount() {
    if (!this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].improvements) {
      this.props.fetchDevices(this.props.activeDevice);
    }
    if (!this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].improvements) {
      this.props.fetchDeviceImprovements(this.props.activeDevice, !this.props.alignment ? 'Technician' : 'Control');
      //this.props.fetchLoopEvents(this.props.activeLoop);
    }
  }
  componentDidUpdate() {
    let device = this.props.devices[this.props.activeDevice];
    let testId = !device ? null : device.activeProfileId;
    if (!!device) {
      if (
        (!!device && !!this.props.devices[this.props.activeDevice].activeTestId && !this.props.devices[this.props.activeDevice].issues && !this.issueLoad) ||
        (loadedTest !== this.props.devices[this.props.activeDevice].activeTestId && !!this.props.devices[this.props.activeDevice].activeTestId)
      ) {
        this.issueLoad = true;
        loadedTest = this.props.devices[this.props.activeDevice].activeTestId;
        this.props.fetchDeviceIssues(this.props.activeDevice, this.props.devices[this.props.activeDevice].activeTestId, !this.props.alignment ? 'Technician' : 'Control');
        //this.props.fetchIssueDetails([1, 2, 3]);
        // this.props.fetchActions([1, 2, 3]);
        //this.props.fetchInvestigations([1, 2, 3]);
        //this.props.fetchLoopEvents(this.props.activeLoop);
      }
      if (
        (!this.props.tests_status ||
          !this.props.tests_status['diagnostics'] ||
          !(this.props.tests_status['diagnostics'][testId] === 'loading' || this.props.tests_status['diagnostics'][testId] === 'loaded')) &&
        !!testId
      ) {
        this.props.fetchDiagnostics(testId);
        this.props.fetchDeviceSpecs(this.props.activeDevice);
        this.props.setTestStatus('diagnostics', testId, 'loading');
      }
    }
  }
  addImprovement() {
    console.log('t');
  }
  processTroubleshootingLevel(level) {
    switch (level) {
      case 0:
        return 'Note';
      case 1:
        return 'Field Adjustment';
      case 2:
        return 'Parts Replacement';
      case 3:
        return 'Body Repair';
      default:
        return 'Unknown';
    }
  }
  async handleApproval(action, rowId, type) {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    let validEmail = true;
    if (!regex.test(this.state.approvalEmail.replace(/\s/g, '')) && this.state.approvalEmail !== '') {
      validEmail = false;
    }
    if (validEmail) {
      try {
        const id = !rowId ? this.state.approvalId : rowId;
        const url = `/${this.props.currentModule}/devices/${this.props.activeDevice}/improvements/approve`; /*this.props.fetchDeviceImprovements(
      this.props.activeDevice
    );
        
    this.setState({ open: false });*/

        const finalType = !type ? this.state.approvalType : type;
        //console.log(type, finalType);

        await api.post(url, {
          rowId: id,
          action,
          name: this.state.approvalName,
          email: this.state.approvalEmail,
          type: finalType,
        }); //console.log(response);
        this.props.approveImprovement(id, action, this.state.approvalName, this.state.approvalEmail, finalType);
        this.setState({
          approvalId: null,
          approvalOpen: false,
          approvalName: '',
          approvalEmail: '',
          approvalType: '',
          status: '',
          statusMessage: '',
        });
      } catch (e) {
        console.log(e);
        this.setState({
          status: 'Failure',
          statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
          //loading: false,
        });
      }
    } else {
      this.setState({
        status: 'Failure',
        statusMessage: `Invalid Email Format`,
      });
    }
    /*const response = */
  }

  approvalButton(id, approval) {
    //console.log(id, approval);
    //console.log(approval === null);
    const { classes } = this.props;
    const action = approval === null;
    return (
      <Button
        id={id}
        color={approval === null ? `primary` : `secondary`}
        variant='outlined'
        onClick={() => {
          this.handleApproval(id, action);
        }}
      >
        {approval === null ? (
          <React.Fragment>
            <CheckCircle className={classes.buttonIcon}></CheckCircle>
            <React.Fragment>Approve</React.Fragment>
          </React.Fragment>
        ) : (
          <React.Fragment>Unapprove</React.Fragment>
        )}
      </Button>
    );
  }

  processEdit(id) {
    let data =
      !this.props.devices[this.props.activeDevice] ||
      !this.props.devices[this.props.activeDevice].improvements ||
      !this.props.devices[this.props.activeDevice].improvements[!this.props.alignment ? 'Technician' : 'Control']
        ? []
        : this.props.devices[this.props.activeDevice].improvements[!this.props.alignment ? 'Technician' : 'Control'];
    let improvement = data.filter((e) => e.id === id)[0];
    const device = this.props.devices[this.props.activeDevice];
    const testIndex = device.testIdHistory.indexOf(improvement.testID.toString());
    //console.log(improvement);
    this.setState({
      open: true,
      status: '',
      statusMessage: '',
      formBool: true,
      editId: id,
      improvement: improvement.improvement,
      comment: improvement.comment,
      level: improvement.level2,
      improvementType: improvement.improvementType,
      testDate: device.datetimeHistory[testIndex],
      stateDate: device.stateHistory[testIndex],
    });
  }
  processDelete(id) {
    let data = !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].improvements ? [] : this.props.devices[this.props.activeDevice].improvements;
    let improvement = data.filter((e) => e.id === id)[0];
    const device = this.props.devices[this.props.activeDevice];

    const testIndex = device.testIdHistory.indexOf(improvement.testId.toString());
    //console.log(improvement);
    this.setState({
      open: true,
      formBool: true,
      editId: id,
      improvement: improvement.improvement,
      comment: improvement.comment,
      level: improvement.level2,
      improvementType: improvement.improvementType,
      testDate: device.datetimeHistory[testIndex],
      stateDate: device.stateHistory[testIndex],
    });
  }
  editButton(id, user, userId) {
    //console.log(id, approval);
    //console.log(approval === null);
    const { classes } = this.props;
    //const action = approval === null;
    const button =
      userId === user ? (
        <Button
          id={id}
          color={userId === user ? `primary` : `secondary`}
          className={classes.button}
          variant='outlined'
          onClick={(e) => {
            //console.log(e);
            //console.log(id);

            //console.log('edit');
            this.processEdit(id);
            //this.handleApproval(id, action);
          }}
        >
          <Edit className={classes.buttonIcon}></Edit>
        </Button>
      ) : null;
    return button;
  }
  openApproval() {
    this.setState({ approvalOpen: true, status: '', statusMessage: '' });
  }
  closeApproval() {
    this.setState({
      approvalOpen: false,
      approvalName: '',
      approvalEmail: '',
      approvalId: null,
      approvalType: '',
    });
  }
  approvalDialog() {
    const { classes } = this.props;
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    return (
      <CustomDialog
        center
        open={this.state.approvalOpen}
        onClose={this.closeApproval}
        title='Contact'
        content={
          <React.Fragment>
            <DialogContentText style={{ marginTop: 20, marginBottom: 20 }}>Please enter the name of the contact person.</DialogContentText>
            <form>
              <TextField
                autoFocus
                id='approvalName'
                label='Name'
                name='approvalName'
                fullWidth
                //multiline
                //rows={4}
                //defaultValue=''
                //variant="outlined"
                value={this.state.approvalName}
                onChange={this.handleInputChange}
                className={classes.textField}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
              <TextField
                autoFocus
                id='approvalEmail'
                label='Email'
                name='approvalEmail'
                fullWidth
                type='email'
                //multiline
                //rows={4}
                //defaultValue=''
                //variant="outlined"
                value={this.state.approvalEmail}
                onChange={this.handleInputChange}
                className={classes.textField}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </form>
            {this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginRight: 20 }}>{`${this.state.statusMessage}`}</Typography> : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.closeApproval} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.handleApproval(true);
              }}
              color='primary'
            >
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  openDelete() {
    this.setState({ deleteOpen: true, status: '', statusMessage: '' });
  }
  closeDelete() {
    this.setState({
      deleteOpen: false,
      deleteId: null,
    });
  }
  async handleDelete(action, rowId) {
    try {
      const id = !rowId ? this.state.deleteId : rowId;
      const url = `/${this.props.currentModule}/devices/${this.props.devices[this.props.activeDevice]}/improvements/delete`; /*this.props.fetchDeviceImprovements(
      this.props.activeDevice
    );
    this.setState({ open: false });*/

      await api.post(url, {
        rowId: id,
      }); //console.log(response);
      this.props.deleteImprovement(id);
      this.setState({
        deleteId: null,
        deleteOpen: false,
      });
      this.props.fetchDeviceImprovements(this.props.activeDevice, 'Technician');
    } catch (e) {
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
        //loading: false,
      });
    }
    /*const response = */
  }
  deleteDialog() {
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    //const { classes } = this.props;
    return (
      <CustomDialog
        center
        open={this.state.deleteOpen}
        onClose={this.closeDelete}
        title='Delete Improvement'
        content={
          <React.Fragment>
            <DialogContentText style={{ marginTop: 20 }}>Are you sure you want to delete this improvement?</DialogContentText>
            {this.state.status !== '' ? (
              <Typography variant='subtitle1' color={color} style={{ marginRight: 20 }}>
                {`${this.state.statusMessage}`}
              </Typography>
            ) : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.closeDelete} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.handleDelete(true);
              }}
              color='primary'
            >
              Delete
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }

  addImprovementForm() {
    const { classes } = this.props;
    const bool = this.state.formBool;
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    const testOptions =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.activeDevice].datetimeHistory, this.props.devices[this.props.activeDevice].stateHistory)
        : [];
    return (
      <CustomDialog
        center
        open={this.state.open}
        onClose={this.handleClose}
        title={`${bool ? `Edit` : `Add`} Improvement Path`}
        content={
          <React.Fragment>
            <DialogContentText style={{ paddingTop: 20 }}>Please describe the improvement you wish to add.</DialogContentText>
            <form>
              <TextField
                autoFocus
                id='improvement'
                label='Improvement'
                name='improvement'
                fullWidth
                //multiline
                //rows={4}
                //defaultValue=''
                //variant="outlined"
                value={this.state.improvement}
                onChange={this.handleInputChange}
                className={classes.textField}
                style={{ minWidth: '100%', marginTop: 10, marginBottom: 15 }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
              <br />
              <CustomSelect
                single
                id='test'
                label='Source Test'
                type='test'
                name='test'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.handleInputChangeDate(value);
                }}
                //type='unit'
                values={
                  !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime
                    ? null
                    : !this.state.test
                    ? `${new Date(this.props.devices[this.props.activeDevice].dateTime * 1000).toLocaleString()}${this.processState(this.props.devices[this.props.activeDevice].state)}`
                    : this.state.test
                }
                options={testOptions}
                //value={null}
                helperText={null}
              />
              <CustomSelect
                single
                id='level'
                label='Level of Repair'
                type='level'
                name='level'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.handleInputChangeLevel(value);
                }}
                //type='unit'
                values={!this.state.levelName ? 'Note' : this.state.levelName}
                options={['Note', 'Field Adjustment', 'Parts Replacement', 'Body Repair']}
                //value={null}
                helperText={null}
              />
              <CustomSelect
                single
                id='improvementType'
                label='Improvement Type'
                type='improvementType'
                name='improvementType'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.handleInputChangeImprovementType(value);
                }}
                //type='unit'
                values={!this.state.improvementType ? 'None' : this.state.improvementType}
                options={['None', 'Calibration and Tuning', 'I/P', 'Positioner', 'Air Supply', 'Actuator', 'Body and Packing', 'Note']}
                //value={null}
                helperText={null}
              />

              <TextField
                id='comment'
                label='Comment'
                name='comment'
                multiline
                fullWidth
                rows={4}
                variant='outlined'
                value={this.state.comment}
                onChange={this.handleInputChange}
                style={{ marginTop: 15 }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </form>
            {this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginRight: 20 }}>{`${this.state.statusMessage}`}</Typography> : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.handleClose} color='secondary'>
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color='secondary'>
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  handlePerformanceCheck = () => {
    this.setState({
      performanceCheck: !this.state.performanceCheck,
    });
  };
  handleDiagnosticsCheck = () => {
    this.setState({
      diagnosticsCheck: !this.state.diagnosticsCheck,
    });
  };
  handleAlignmentCheck = () => {
    this.setState({
      alignmentCheck: !this.state.alignmentCheck,
    });
  };
  renderPerformanceWidgets() {
    const { classes } = this.props;
    if (this.state.performanceToggle) {
      return (
        <div className={`${classes.pageBreak} ${classes.avoidBreak}`}>
          <PerformanceWidgets diagnosticSpecs moreSpecs toggledWidget></PerformanceWidgets>
        </div>
      );
    }
  }
  renderDiagnosticsWidgets() {
    const { classes } = this.props;
    if (this.state.diagnosticsToggle) {
      return (
        <div className={`${classes.pageBreak} ${classes.avoidBreak}`}>
          <DiagnosticsWidgets toggledWidget diagnosticSpecs></DiagnosticsWidgets>
        </div>
      );
    }
  }
  renderAlignmentWidgets() {
    const { classes } = this.props;
    if (this.state.alignmentToggle) {
      return (
        <div className={`${classes.pageBreak} ${classes.avoidBreak}`}>
          <AlignmentWidgets diagnosticSpecs moreSpecs toggledWidget></AlignmentWidgets>
        </div>
      );
    }
  }
  togglePdfDialog = () => {
    this.setState({
      pdfDialog: !this.state.pdfDialog,
    });
  };
  renderPdfDialog() {
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.pdfDialog}
        onClose={this.togglePdfDialog}
        title='View Raw PDF'
        titleIcon='Folder'
        content={
          <div style={{ marginTop: 16 }}>
            <Link>View Valve Test 1</Link>
            <Link>Save as</Link>
          </div>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.togglePdfDialog} color='primary'>
              Cancel
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  render() {
    const { classes } = this.props;
    //console.log(this.props);
    //console.log(this.props.devices[this.props.activeDevice]);
    const device = this.props.devices[this.props.activeDevice];
    const row = !device
      ? {}
      : {
          id: device.deviceId,
          name: device.name,
          serial: device.serial,
          site: device.site,
          application: device.application,
          description: device.description,
          bodySize: device.bodySize,
          bodyType: device.bodyType,
          importance: device.importance,
          resApp: !device.appRes ? null : device.appRes,
          senApp: !device.appSen ? null : device.appSen,
          strokeApp: !device.appStroke ? null : device.appStroke,
          effectiveTargetDate: new Date().getTime() / 1000,
          speedImportance: device.speedImportance,
          accuracyImportance: device.accuracyImportance,
          performanceSatisfaction: device.performanceSatisfaction,
          currentSerial: device.serial,
          deviceId: device.deviceId,
        };
    if (!!device && loadedTest !== this.props.devices[this.props.activeDevice].activeTestId) {
      this.issueLoad = true;
      loadedTest = this.props.devices[this.props.activeDevice].activeTestId;
      this.props.fetchDeviceIssues(this.props.activeDevice, this.props.devices[this.props.activeDevice].activeTestId, !this.props.alignment ? 'Technician' : 'Control');
    }
    const headerArr = [
      //{ id: 'from', label: 'From' },
      //{ id: 'date2', label: 'Date Submitted' },
      //{ id: 'user', label: 'User' },
      //{ id: 'improvement', label: 'Proposed Improvement Path' },
      { id: 'improvementFormatted', label: 'Proposed Improvement Path' },
      { id: 'origin_issue', label: 'Issue' },
      //{ id: 'state2', label: 'Test Type' },
      //{ id: 'level2', label: 'Repair Level' },
      //{ id: 'improvementType', label: 'Improvement Type' },
      { id: 'comment', label: 'Comment' },
      //{ id: 'statusUser', label: 'By' },
      { id: 'statusDate', label: 'Date' },
      //{ id: 'status', label: 'Status' },
      { id: 'statusFormatted', label: 'Status' },
      { id: 'contactFormatted', label: 'Contact' },
      //{ id: 'approved', label: 'Approval' },
      // { id: 'edit', label: 'Edit' },
      { id: 'menu', label: 'Update Progress' },
    ];
    let data =
      !this.props.devices[this.props.activeDevice] ||
      !this.props.devices[this.props.activeDevice].improvements ||
      !this.props.devices[this.props.activeDevice].improvements[!this.props.alignment ? 'Technician' : 'Control']
        ? []
        : this.props.devices[this.props.activeDevice].improvements[!this.props.alignment ? 'Technician' : 'Control'];
    data = data.filter((improvement) => improvement.testID === this.props.devices[this.props.activeDevice].activeTestId);
    for (let i = 0; i < data.length; i++) {
      data[i].state2 = this.processTestType(data[i].state);
      //if (!!data[i].level) {
      data[i].level2 = this.processTroubleshootingLevel(data[i].level);
      let status = this.processStatus(data[i]);
      //console.log(status);
      data[i].statusFormatted = (
        <Typography
          variant='subtitle2'
          className={status.status === 'Not Approved' ? classes.redText : status.status === 'Approved' ? classes.blueText : status.status === 'Completed' ? classes.greenText : null}
        >
          {status.status}
        </Typography>
      );
      data[i].status = status.status;
      data[i].contact = status.contact;
      //console.log(data);
      data[i].contactFormatted = (
        <React.Fragment>
          <Typography variant='subtitle2'>{status.contact}</Typography>
          {!status.email ? null : <Typography variant='subtitle2'>{status.email}</Typography>}
          {/*!!status.user && status.email !== '' && status.contact !== '-' ? (
            <React.Fragment>
              <InfoPopover
                tooltip='Copy Email'
                title='Copy Email'
                resize
                onClick={() => {
                  //console.log('t');
                  navigator.clipboard.writeText(status.email);
                  this.setState({ copy: true, copyText: status.email });
                }}
                style={{ marginBottom: -15 }}
                icon={<MailOutline />}
                content={
                  <Typography style={{ marginRight: 40 }} variant='subtitle2' className={classes.printHide}>
                    '{this.state.copyText}' Copied to Clipboard
                  </Typography>
                }
              />
            </React.Fragment>
          ) : null*/}
        </React.Fragment>
      );
      data[i].contact = status.contact;
      data[i].improvementFormatted = (
        <React.Fragment>
          <Typography variant='subtitle2'>{data[i].improvement}</Typography>
          <Typography variant='subtitle2' color='secondary' style={{ marginTop: 10 }}>
            Repair Level
          </Typography>
          <Typography variant='subtitle2'>{data[i].level2}</Typography>
        </React.Fragment>
      );
      data[i].statusUser = status.user;
      data[i].statusEmail = status.email;
      data[i].statusDate = status.date === '-' ? '-' : this.processDate(new Date(status.date * 1000));
      if (parseInt(data[i].date) > 1000000000) {
        data[i].date2 = this.processDate(new Date(data[i].date * 1000));
        data[i].approvedDate2 = data[i].approvedDate === null ? '' : this.processDate(new Date(data[i].approvedDate * 1000));
      }
      //Because approved itself is changed to a button we use approvedBy to monitor state
      //data[i].approved = this.approvalButton(data[i].id, data[i].approvedBy);
      //data[i].edit = this.editButton(data[i].id, this.props.userId, data[i].user);
      let menuOptions = [];
      const approved = data[i].approved === 1;
      const notApproved = data[i].notApproved === 1;
      const completed = data[i].completed === 1;
      const base = !approved && !notApproved && !completed;
      if ((base || approved) && !completed && !notApproved) {
        if (data[i].approved === 0 || data[i].approved === null) {
          //Approve if unapproved, unapprove if approved
          menuOptions.push({
            id: data[i].id,
            title: 'Approve',
            icon: ThumbUpAlt,
            onClick: () => {
              this.setState({
                approvalId: data[i].id,
                approvalType: `approved`,
              });
              this.openApproval();
            },
          });
        } else {
          menuOptions.push({
            id: data[i].id,
            title: 'Undo Approval',
            icon: Undo,
            onClick: (id) => {
              //console.log('Time to Unapprove! ' + data[i].id);
              //this.setState({ approvalType: 'approve' });
              this.handleApproval(false, data[i].id, `approved`);
            },
          });
        }
      }
      if ((base || approved || notApproved) && !completed) {
        if (data[i].notApproved === 0 || data[i].notApproved === null) {
          menuOptions.push({
            id: data[i].id,
            title: 'Not Approved',
            icon: ThumbDownAlt,
            onClick: () => {
              //console.log('Time to cancel ' + data[i].id);
              this.setState({
                approvalId: data[i].id,
                approvalType: `notApproved`,
              });
              this.openApproval();
              //this.setState({ approvalId: data[i].id });
              //this.openApproval();
            },
          });
        } else {
          menuOptions.push({
            id: data[i].id,
            title: 'Undo Not Approved',
            icon: Undo,
            onClick: (id) => {
              //console.log('Time to Unapprove! ' + data[i].id);
              //this.setState({ approvalType: 'approve' });
              this.handleApproval(false, data[i].id, `notApproved`);
            },
          });
        }
      }
      if (!notApproved) {
        if (data[i].completed === 0 || data[i].completed === null) {
          menuOptions.push({
            id: data[i].id,
            title: 'Complete',
            icon: CheckCircle,
            onClick: () => {
              //console.log('Time to complete ' + data[i].id);
              this.setState({
                approvalId: data[i].id,
                approvalType: `completed`,
              });
              this.openApproval();
              //this.setState({ approvalId: data[i].id });
              //this.openApproval();
            },
          });
        } else {
          menuOptions.push({
            id: data[i].id,
            title: 'Undo Completed',
            icon: Undo,
            onClick: (id) => {
              //console.log('Time to Unapprove! ' + data[i].id);
              //this.setState({ approvalType: 'approve' });
              this.handleApproval(false, data[i].id, `completed`);
            },
          });
        }
      }
      /*menuOptions.push({
        id: data[i].id,
        title: 'More Info',
        icon: Info,
        onClick: () => {
          console.log('More Info ' + data[i].id);
          console.log(data[i].user);
          console.log(data[i].date);
          //this.setState({ approvalId: data[i].id });
          //this.openApproval();
        },
      });*/

      if (this.props.userId === data[i].user) {
        //permissioning for edit capability, logged in user is the same as the writer. // TODO needs same check on backend
        menuOptions.push({
          id: data[i].id,
          title: 'Edit',
          icon: Edit,
          onClick: (e) => {
            this.processEdit(data[i].id);
          },
        });
        menuOptions.push({
          id: data[i].id,
          title: 'Delete',
          icon: Delete,
          onClick: (e) => {
            this.setState({ deleteId: data[i].id });
            this.openDelete();
            //this.processDelete(data[i].id);
          },
        });
      }
      data[i].menu = <DropdownMenu tooltip='Update Progress' helperText='Select to update progress' options={menuOptions} />;

      //}
    }
    const handleClick = (event) => {
      this.setState({
        selectMenu: !this.state.selectMenu,
        selectMenuAnchor: event.currentTarget,
      });
    };
    const handleClose = () => {
      this.setState({ selectMenu: false });
    };
    const handleSubmit = () => {
      this.setState({ selectMenu: false, performanceToggle: this.state.performanceCheck, diagnosticsToggle: this.state.diagnosticsCheck, alignmentToggle: this.state.alignmentCheck });
    };
    const testOptions =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.activeDevice].datetimeHistory, this.props.devices[this.props.activeDevice].stateHistory)
        : ['Loading'];
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        <Grid container justifyContent='space-between' style={!!this.props.toggledWidget ? null : { marginBottom: -42 }} className={classes.printHide}>
          <Grid item></Grid>
          <Grid item>
            <Grid container spacing={1}>
              {!this.props.toggledWidget ? (
                <React.Fragment>
                  {!printStyles ? (
                    <Grid item>
                      <Tooltip arrow placement='top' title='Create Performance Certificate in a New Tab' aria-label='Create Performance Certificate in a New Tab'>
                        <Link href={`/optimize/${this.props.activeDevice}/Certification`} target='_blank'>
                          <IconButton className={classes.textColor} aria-label='more' style={{ marginLeft: -10 }} size='large'>
                            <Speed />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </Grid>
                  ) : null}
                  {!printStyles ? (
                    <Grid item>
                      <InfoPopover
                        center
                        tooltip='Configure'
                        title='Configure'
                        titleIcon='Settings'
                        resize
                        style={{ marginBottom: -15 }}
                        icon={<Settings />}
                        content={<OptimizeContent row={row} clickedRow={this.props.activeDevice} rowOpen={true} />}
                      />
                    </Grid>
                  ) : null}
                  <Grid item>
                    <Tooltip arrow placement='top' title='Show More' aria-label='Show More'>
                      <IconButton color='inherit' aria-label='more' onClick={handleClick} style={{ marginLeft: -10 }} size='large'>
                        <MoreVert />
                      </IconButton>
                    </Tooltip>
                    <Popper open={!this.state.selectMenu ? false : this.state.selectMenu} onClose={handleClose} anchorEl={this.state.selectMenuAnchor} placement='bottom-start'>
                      <ClickAwayListener onClickAway={handleClose}>
                        <Paper>
                          <Typography
                            variant='caption'
                            style={{
                              padding: '10px 12px 6px 14px',
                              display: 'block',
                            }}
                          >
                            Select to show more info
                          </Typography>
                          <MenuItem onClick={this.handlePerformanceCheck}>
                            <Checkbox className={classes.menuCheckbox} checked={this.state.performanceCheck} color='secondary' />
                            Performance
                          </MenuItem>
                          <MenuItem onClick={this.handleDiagnosticsCheck}>
                            <Checkbox className={classes.menuCheckbox} checked={this.state.diagnosticsCheck} color='secondary' />
                            Diagnostics
                          </MenuItem>
                          <MenuItem onClick={this.handleAlignmentCheck}>
                            <Checkbox className={classes.menuCheckbox} checked={this.state.alignmentCheck} color='secondary' />
                            Alignment
                          </MenuItem>
                          <Grid container justifyContent='space-between'>
                            <Grid item></Grid>
                            <Grid item>
                              <Button variant='outlined' disableElevation size='small' color='primary' aria-label='submit' onClick={handleSubmit} style={{ margin: 10, display: 'block' }}>
                                Submit
                              </Button>
                            </Grid>
                          </Grid>
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {!!this.props.toggledWidget && !this.props.alignment ? <PrintHeader></PrintHeader> : null}
        <WidgetTitle title={`${!this.props.alignment ? 'Technician ' : 'Alignment '}Issues and Recommendations - ${!device || !device.name ? 'Device Name Not Found' : device.name}`} />
        <Grid container justifyContent='space-between'>
          {!!this.props.toggledWidget || !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime ? null : (
            <Grid item>
              <CustomSelect
                single
                id={null}
                label='Test'
                type='Test'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.selectActiveTest(value);
                }}
                //type='unit'
                values={
                  !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime
                    ? 'Loading'
                    : `${new Date(this.props.devices[this.props.activeDevice].dateTime * 1000).toLocaleString()}${this.processState(this.props.devices[this.props.activeDevice].state)}`
                }
                options={testOptions}
                //value={null}
                helperText={null}
                width={260}
              />
            </Grid>
          )}
          <Grid item>
            <Grid container direction='column' justifyContent='space-between' style={{ height: '100%' }}>
              <Grid item></Grid>
              <Grid item>
                <Typography variant='body2' style={{ marginBottom: 4 }} className={classes.printHide}>
                  Click any row to learn more about issues and view recommendations
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AccordionWidget alignment={this.props.alignment} />
        {!this.props.alignment ? (
          <div className={classes.avoidBreak}>
            <WidgetTitle title={`Improvement Paths - ${!device || !device.name ? 'Device Name Not Found' : device.name}`} />
            <Grid container spacing={1}>
              <TableWidget xs={12} data={data} header={!this.props.devices[this.props.activeDevice] ? [] : headerArr} type='improvement' defaultRows={25} />
            </Grid>
            {!!this.props.hideButton && this.props.hideButton === true ? null : (
              <Button onClick={this.handleClickOpen} className={`${classes.buttonFilter} ${classes.printHide}`} color='primary' variant='outlined' style={{ marginTop: 8 }}>
                Add Improvement Path
              </Button>
            )}
          </div>
        ) : null}
        {this.renderPdfDialog()}
        {this.addImprovementForm()}
        {this.approvalDialog()}
        {this.deleteDialog()}
        {this.renderPerformanceWidgets()}
        {this.renderDiagnosticsWidgets()}
        {this.renderAlignmentWidgets()}
        {!this.props.toggledWidget ? (
          <Grid className={classes.printHide}>
            <Button variant='outlined' size='medium' color='primary' aria-label='more' onClick={handleClick} style={{ marginTop: 4 }}>
              <MoreVert className={classes.buttonIcon}></MoreVert>Show More
            </Button>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    devices: state.devices.devices,
    currentModule: state.currentModule.currentModule,
    userId: state.auth.userId,
    clientPermission: state.auth.clientPermission,
    recommendations: state.recommendations,
    tests: state.tests.tests,
    tests_status: state.tests.status,
  };
};

export default connect(mapStateToProps, {
  fetchDeviceImprovements,
  fetchDeviceIssues,
  fetchActions,
  fetchInvestigations,
  fetchIssueDetails,
  fetchDevices,
  approveImprovement,
  fetchDeviceSpecs,
  fetchDiagnostics,
  setTestStatus,
  deleteImprovement,
  clearDeviceIssuesImprovements,
  fetchDeviceByTest,
  //addImprovement /*, fetchDeviceEvents */,
})(withStyles(styles)(ImprovementsWidgets));
