import React from 'react';
import { Button, Checkbox, Grid, TextField, IconButton, MenuItem, Tooltip, Typography, Paper, Popper, ClickAwayListener, Link } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import OptimizeSpecs from '../../widgets/OptimizeSpecs';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import ValveChartWidget from '../../widgets/ValveChartWidget';
import WidgetTitle from '../../headers/WidgetTitle';
import CustomSelect from '../../filters/CustomSelect';
import Alert from '@mui/material/Alert';
import { connect } from 'react-redux';
import functions from '../../../functions/functions';
import TableWidget from '../../widgets/TableWidget.js';
import {
  fetchDevices,
  fetchDevicesSummary,
  fetchDeviceByTest,
  fetchDeviceSpecs,
  fetchTest,
  setTestStatus,
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  optimizeSetCompareDevice,
  setFormat,
  setDevicesSummaryStatus,
  clearDeviceIssuesImprovements,
} from '../../../actions/index';
import PerformanceWidgets from './PerformanceWidgets';
import DiagnosticsWidgets from './DiagnosticsWidgets';
import ImprovementsWidgets from './ImprovementsWidgets';
import InfoPopover from '../../popup/InfoPopover.js';
import { Merge, Update, Settings, MoreVert, Speed, NotInterested } from '@mui/icons-material';
import OptimizeContent from '../config/content/OptimizeContent';
import OptimizeTestMerge from '../config/content/OptimizeTestMerge';
import OptimizeTestState from '../config/content/OptimizeTestState';
import PrintHeader from '../../headers/PrintHeader';
//import StepTimeWidget from '../../widgets/StepTimeWidget';
import CustomDialog from '../../popup/CustomDialog';
import AlignmentSummary from '../../widgets/AlignmentSummary.js';
import api from '../../../apis/api.js';

let stepPerformanceAttempts = 0;
let stepPerformanceLoad = false;
class AlignmentWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.compareTest = this.compareTest.bind(this);
  }
  state = {
    performanceCheck: false,
    improvementsCheck: false,
    diagnosticsCheck: false,
    performanceToggle: false,
    diagnosticsToggle: false,
    improvementsToggle: false,
    message: '',
    mergeFormOpen: false,
    selectMenu: false,
    selectMenuAnchor: null,
    //stepPerformanceLoad: false,
  };
  isUndefined(value) {
    return typeof value === 'undefined';
  }
  componentDidMount() {
    if (!this.props.optimize.compareFlag) {
      this.props.optimizeSetCompareDevice(this.props.activeDevice); //defaults comparison to the current device
    }
    if (
      this.isUndefined(this.props.devices[this.props.activeDevice]) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].resolutionApp) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].sensitivityApp) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].strokeApp) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].spring) //taking place of specbool check //|| // TODO FIX SPECBOOL CHECK
      //!this.props.devices[this.props.activeDevice].specBool
    ) {
      this.props.fetchDevices(this.props.activeDevice);
      if (this.props.optimize.compareDevice !== this.props.activeDevice && this.props.optimize.compareDevice !== '') {
        this.props.fetchDevices(this.props.optimize.compareDevice);
      }
      if (this.props.deviceSummary === false) {
        this.props.setDevicesSummaryStatus(true);
        this.props.fetchDevicesSummary();
      }

      this.props.fetchDeviceSpecs(this.props.activeDevice);
      //this.props.optimizeSetCompareDevice(this.props.activeDevice);
      //this.props.fetchLoopEvents(this.props.activeLoop);
    }
  }
  componentDidUpdate = () => {
    //console.log(stepPerformanceLoad, stepPerformanceAttempts);
    const device = this.props.devices[this.props.activeDevice];
    if (!!device && !!device.activeTestId && !stepPerformanceLoad && stepPerformanceAttempts < 2) {
      stepPerformanceLoad = true;
      stepPerformanceAttempts++;
      this.getStepPerformanceData(device.activeTestId);
    }
  };
  getStepPerformanceData = async (testId) => {
    try {
      //this.setState({ stepPerformanceLoad: true });
      stepPerformanceLoad = true;
      let results = await api.get(`/optimize/tests/${testId}/stepPerformance/`);
      this.setState({ stepPerformanceData: results.data });
    } catch (e) {
      stepPerformanceLoad = false;
    }
  };
  handlePerformanceCheck = () => {
    this.setState({
      performanceCheck: !this.state.performanceCheck,
    });
  };
  handleDiagnosticsCheck = () => {
    this.setState({
      diagnosticsCheck: !this.state.diagnosticsCheck,
    });
  };
  handleImprovementsCheck = () => {
    this.setState({
      improvementsCheck: !this.state.improvementsCheck,
    });
  };
  setList() {}
  processState(state) {
    switch (parseInt(state)) {
      case 1:
        return ' - As Left';
      case 3:
        return ' - As Found';
      default:
        return '';
    }
  }
  processTimestampArray(arr, stateArr) {
    let result = [];
    if (!!arr) {
      for (let i = 0; i < arr.length; i++) {
        const timestamp = new Date(parseInt(arr[i]) * 1000).toLocaleString();

        result.push(`${timestamp}${this.processState(stateArr[i])}`);
      }
    }
    return result;
  }

  compareTest(date) {
    this.setState({ message: '' });
    if (date === 'No Comparison') {
      this.props.optimizeSetCompareFlag(false);
      this.props.optimizeSetCompareTest('');
    } else {
      const timestampArr = !!this.props.devices[this.props.optimize.compareDevice] ? this.props.devices[this.props.optimize.compareDevice].datetimeHistory : [];
      const stateArr =
        !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].stateHistory
          ? this.props.devices[this.props.optimize.compareDevice].stateHistory
          : [];
      let newCompareTestId = -1;
      for (let i = 0; i < timestampArr.length; i++) {
        const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
        if (timestamp === date) {
          newCompareTestId = this.props.devices[this.props.optimize.compareDevice].testIdHistory[i];
          if (parseInt(newCompareTestId) !== this.props.devices[this.props.activeDevice].activeTestId || this.props.optimize.compareDevice.deviceId !== this.props.activeDevice) {
            this.props.optimizeSetCompareFlag(true);
            this.props.optimizeSetCompareTest(newCompareTestId);
            this.props.fetchDevices(newCompareTestId);
          } else {
            this.props.optimizeSetCompareFlag(false);
            this.setState({ message: 'No Alignment Data to Compare!' });
          }
        } else {
        }
      }
    }
  }

  selectActiveTest = (date) => {
    const timestampArr = !!this.props.devices[this.props.activeDevice] ? this.props.devices[this.props.activeDevice].datetimeHistory : [];
    const stateArr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].stateHistory ? this.props.devices[this.props.activeDevice].stateHistory : [];
    let newActiveTestId = -1;
    for (let i = 0; i < timestampArr.length; i++) {
      const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
      if (timestamp === date) {
        newActiveTestId = this.props.devices[this.props.activeDevice].testIdHistory[i];
        // this.props.clearDeviceIssuesImprovements(this.props.activeDevice);  //superflous added to fetchDeviceByTest
        this.props.fetchDeviceByTest(this.props.activeDevice, newActiveTestId);
      }
    }
  };

  renderWidgets() {
    const { classes } = this.props;
    const device = this.props.devices[this.props.activeDevice];
    /*const testOptions =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.activeDevice].datetimeHistory, this.props.devices[this.props.activeDevice].stateHistory)
        : ['Loading'];
    const testOptionsCompare =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.optimize.compareDevice].datetimeHistory, this.props.devices[this.props.optimize.compareDevice].stateHistory)
        : ['No Tests Found'];
    const testOptionsCompareIndex =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? this.props.devices[this.props.optimize.compareDevice].testIdHistory.indexOf(this.props.optimize.compareTest) + 1
        : 0;*/
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    if (this.props.devices[this.props.activeDevice]) {
      return (
        <React.Fragment>
          {/*<Grid container>
            <Grid item>
              {!!this.props.toggledWidget || !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime ? null : (
                <CustomSelect
                  single
                  id={null}
                  label='Test'
                  type='Test'
                  disabled={this.state.loading}
                  onChangeFunction={(event, value) => {
                    this.selectActiveTest(value);
                  }}
                  values={
                    !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime
                      ? 'Loading'
                      : `${new Date(this.props.devices[this.props.activeDevice].dateTime * 1000).toLocaleString()}${this.processState(this.props.devices[this.props.activeDevice].state)}`
                  }
                  options={testOptions}
                  helperText={null}
                  width={260}
                />
              )}
            </Grid>
            {!!this.props.toggledWidget ||
            !this.props.devices[this.props.activeDevice] ||
            !this.props.devices[this.props.activeDevice].datetimeHistory ||
            this.props.devices[this.props.activeDevice].datetimeHistory.length < 0 ? null : (
              <React.Fragment>
                <Grid item>
                  <Autocomplete
                    className={classes.formSelect}
                    id='combo-box-demo'
                    options={Object.keys(this.props.devices).map((key) => {
                      return {
                        name: this.props.devices[key].name,
                        deviceId: this.props.devices[key].deviceId,
                      };
                    })}
                    value={
                      !this.props.optimize.compareDevice || !this.props.devices[this.props.optimize.compareDevice]
                        ? null
                        : {
                            name: this.props.devices[this.props.optimize.compareDevice].name,
                            deviceId: this.props.devices[this.props.optimize.compareDevice].deviceId,
                          }
                    }
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.name === value.name && option.deviceId === value.deviceId;
                    }}
                    disableClearable={true}
                    onChange={(e, value) => {
                      this.props.optimizeSetCompareFlag(false);
                      this.props.optimizeSetCompareTest('');
                      this.props.optimizeSetCompareDevice(value.deviceId);
                      this.props.fetchDevices(value.deviceId);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Comparison Device'
                        variant='standard'
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <CustomSelect
                    single
                    id={null}
                    label='Compare to'
                    type='Compare'
                    disabled={this.state.loading}
                    onChangeFunction={(event, value) => {
                      this.compareTest(value);
                    }}
                    values={['No Comparison'].concat(testOptionsCompare)[testOptionsCompareIndex]}
                    options={['No Comparison'].concat(testOptionsCompare)}
                    helperText={null}
                    width={260}
                  />
                </Grid>
                {this.state.message !== '' ? (
                  <Grid item>
                    <Alert severity='warning'>{this.state.message}</Alert>
                  </Grid>
                ) : null}
              </React.Fragment>
            )}
          </Grid>*/}
          <Grid container className={classes.containerSpacingFix}>
            {!device.activeResolutionId ? (
              <Grid item xs={12} className={classes.widgetSpacing}>
                <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
                  <Typography variant='subtitle2'>No Test Found for Resolution</Typography>
                </Paper>
              </Grid>
            ) : (
              <ValveChartWidget
                alignment
                initialWidth={12}
                type='valve'
                valveColumns={1}
                progress={1}
                testId={this.props.devices[this.props.activeDevice].activeResolutionId}
                attribute={'Resolution'}
              />
            )}
            {!device.activeSensitivityId ? (
              <Grid item xs={12} sm={6} className={classes.widgetSpacing}>
                <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
                  <Typography variant='subtitle2'>No Test Found for Sensitivity</Typography>
                </Paper>
              </Grid>
            ) : (
              <ValveChartWidget
                alignment
                initialWidth={6}
                type='valve'
                valveColumns={1}
                progress={1}
                testId={this.props.devices[this.props.activeDevice].activeSensitivityId}
                attribute={'Sensitivity'}
              />
            )}
            {!device.activeStrokeId ? (
              <Grid item xs={12} sm={6} className={classes.widgetSpacing}>
                <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
                  <Typography variant='subtitle2'>No Test Found for Stroke</Typography>
                </Paper>
              </Grid>
            ) : (
              <ValveChartWidget alignment initialWidth={6} type='valve' valveColumns={1} progress={1} testId={this.props.devices[this.props.activeDevice].activeStrokeId} attribute={'Stroke'} />
            )}
          </Grid>
          {/*<StepTimeWidget initialWidth={12} testId={this.props.devices[this.props.activeDevice].activeTestId} attribute={'Alignment'}></StepTimeWidget>*/}
        </React.Fragment>
      );
    }
  }
  renderPerformanceWidgets() {
    const { classes } = this.props;
    if (this.state.performanceToggle) {
      return (
        <div className={`${classes.pageBreak} ${classes.avoidBreak}`}>
          <PerformanceWidgets diagnosticSpecs moreSpecs toggledWidget></PerformanceWidgets>
        </div>
      );
    }
  }
  renderDiagnosticsWidgets() {
    const { classes } = this.props;
    if (this.state.diagnosticsToggle) {
      return (
        <div className={`${classes.pageBreak} ${classes.avoidBreak}`}>
          <DiagnosticsWidgets toggledWidget diagnosticSpecs></DiagnosticsWidgets>
        </div>
      );
    }
  }
  renderImprovementsWidgets() {
    const { classes } = this.props;
    if (this.state.improvementsToggle) {
      return (
        <div className={classes.pageBreak}>
          <ImprovementsWidgets toggledWidget hideInfo></ImprovementsWidgets>
        </div>
      );
    }
  }
  closePdfDialog = () => {
    this.setState({
      pdfDialog: !this.state.pdfDialog,
    });
  };
  renderPdfDialog() {
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.pdfDialog}
        onClose={this.togglePdfDialog}
        title='View Raw PDF'
        titleIcon='Folder'
        content={
          <div style={{ marginTop: 16 }}>
            <Link>View Valve Test 1</Link>
            <Link>Save as</Link>
          </div>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.togglePdfDialog} color='primary'>
              Cancel
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  renderStepTimeTable(device) {
    if (!device) return 'Loading...';
    let headerArr = [
      { id: 'resSignal', label: 'Resolution Signal' }, // % Move
      { id: 'resStepTime', label: 'Resolution Step Time' }, //t-63
      { id: 'resStepChange', label: 'Resolution Change from Prev. Step' },
      { id: 'senSignal', label: 'Sensitivity Signal' }, // % Move
      { id: 'senStepTime', label: 'Sensitivity Step Time' }, //t-63
      { id: 'senStepChange', label: 'Sensitivity Change from Prev. Step' },
    ];
    const { classes } = this.props;
    let data = [];
    let dataPositive = [];
    let dataNegative = [];
    let failureIcon = (
      <Tooltip arrow placement='top' title='This step failed to complete' enterDelay={300}>
        <NotInterested className={classes.grayTextLight} fontSize='small' style={{ marginBottom: -3 }} />
      </Tooltip>
    );
    if (!!this.state.stepPerformanceData && this.state.stepPerformanceData.length !== 0) {
      let stepDataRes = this.state.stepPerformanceData.filter((step) => step.test_type === 'resolution');

      let stepDataSen = this.state.stepPerformanceData.filter((step) => step.test_type === 'sensitivity');

      for (let i = 0; i < stepDataRes.length; i++) {
        let changeCheck = i > 0 && i + 1 !== stepDataRes.length;
        let orderCheck = stepDataRes[i].step_sizes > 0;

        //handle the swap of signs
        let resDelta = failureIcon;
        let senDelta = failureIcon;
        if (changeCheck) {
          if (!!stepDataRes[i])
            resDelta = orderCheck
              ? Math.round((stepDataRes[i].t_63_time_sec - stepDataRes[i + 1].t_63_time_sec) * 10) / 10
              : Math.round((stepDataRes[i].t_63_time_sec - stepDataRes[i - 1].t_63_time_sec) * 10) / 10;

          //handle the swap of signs
          if (!!stepDataSen[i])
            senDelta = orderCheck
              ? Math.round((stepDataSen[i].t_63_time_sec - stepDataSen[i + 1].t_63_time_sec) * 10) / 10
              : Math.round((stepDataSen[i].t_63_time_sec - stepDataSen[i - 1].t_63_time_sec) * 10) / 10;
        }
        let resColor = resDelta > 0 ? classes.redText : resDelta < 0 ? classes.greenText : null;
        let senColor = senDelta > 0 ? classes.redText : senDelta < 0 ? classes.greenText : null;
        let rowObj = {
          resSignalRaw: !stepDataRes[i] ? 'N/A' : stepDataRes[i].step_sizes,
          resSignal: !stepDataRes[i] ? 'N/A' : Math.round(stepDataRes[i].step_sizes * 100) / 100 + '%',
          resStepTime: !stepDataRes[i] || !stepDataRes[i].t_63_time_sec ? failureIcon : Math.round(stepDataRes[i].t_63_time_sec * 10) / 10 + 's', //null -> couldn't make the movement.
          resStepChange:
            !stepDataRes[i] || !stepDataRes[i].t_63_time_sec ? (
              failureIcon
            ) : (
              <Typography variant='subtitle2' className={resColor}>
                {resDelta}
                {`${!changeCheck ? '' : 's'}`}
              </Typography>
            ), //null -> couldn't make the movement. invert delta to show 'slowing down'?
          senSignalRaw: !stepDataSen[i] ? 'N/A' : stepDataSen[i].step_sizes,
          senSignal: !stepDataSen[i] ? 'N/A' : Math.round(stepDataSen[i].step_sizes * 100) / 100 + '%',
          senStepTime: !stepDataSen[i] || !stepDataSen[i].t_63_time_sec ? failureIcon : Math.round(stepDataSen[i].t_63_time_sec * 10) / 10 + 's', //null -> couldn't make the movement.
          senStepChange:
            !stepDataSen[i] || !stepDataSen[i].t_63_time_sec ? (
              failureIcon
            ) : (
              <Typography variant='subtitle2' className={senColor}>
                {senDelta}
                {`${!changeCheck ? '' : 's'}`}
              </Typography>
            ), //null -> couldn't make the movement. invert delta to show 'slowing down'?
        };
        if (orderCheck) {
          dataPositive.push(rowObj);
        } else {
          dataNegative.push(rowObj);
        }
      }
      dataPositive.sort((a, b) => {
        if (!b.resSignalRaw || !a.resSignalRaw) {
          return b.senSignalRaw - a.senSignalRaw;
        } else {
          return b.resSignalRaw - a.resSignalRaw;
        }
      });

      data = dataNegative.concat(dataPositive);
    }

    /*{
      "0": {
        "device_id": 7446,
        "valve_id": 38800,
        "test_id": 388001633176060,
        "test_type": "resolution",
        "step_sizes": -5,
        "accuracy": 1.3133330345153809,
        "app_val": 2.5,
        "overshoot": 0,
        "t_63_time_perc": 0.04500000178813934,
        "t_63_time_sec": 0.04500000178813934,
        "settle_time_perc": 0.09000000357627869,
        "settle_time_sec": 1.7999999523162842
      }
    }*/
    /*let data = [
      { resSignal: '.25%', resStepTime: '8s', resStepChange: '-', senSignal: '.25%', senStepTime: '8s', senStepChange: '-' },
      { resSignal: '.50%', resStepTime: '4s', resStepChange: '4s', senSignal: '.50%', senStepTime: '4s', senStepChange: '4s' },
      { resSignal: '1%', resStepTime: '2s', resStepChange: '2s', senSignal: '1%', senStepTime: '2s', senStepChange: '2s' },
      { resSignal: '2.5%', resStepTime: '1s', resStepChange: '1s', senSignal: '2.5%', senStepTime: '1s', senStepChange: '1s' },
      { resSignal: '5%', resStepTime: '.5s', resStepChange: '.5s', senSignal: '5%', senStepTime: '.5s', senStepChange: '.5s' },
    ];*/
    return (
      <Grid container className={classes.avoidBreak}>
        <Grid item xs={12}>
          <TableWidget header={headerArr} data={data} defaultRows={25} loading={this.state.loading} rowsPerPageOptions={[]} marginTop={4}></TableWidget>
        </Grid>
      </Grid>
    );
    //console.log(device);

    //return device.deviceId;
  }

  render() {
    const { classes } = this.props;
    const device = this.props.devices[this.props.activeDevice];

    const compareDevice = this.props.devices[this.props.optimize.compareDevice];
    /* Table Widget goes here with new step time data (pull from API) 
              resolution signal (% move), resolution step time (t-63), resolution step time (change from prev.), all of the above with sensitivity
            */

    const row = !device
      ? {}
      : {
          id: device.deviceId,
          name: device.name,
          serial: device.serial,
          site: device.site,
          application: device.application,
          description: device.description,
          bodySize: device.bodySize,
          bodyType: device.bodyType,
          importance: device.importance,
          resApp: !device.appRes ? null : device.appRes,
          senApp: !device.appSen ? null : device.appSen,
          strokeApp: !device.appStroke ? null : device.appStroke,
          effectiveTargetDate: new Date().getTime() / 1000,
          speedImportance: device.speedImportance,
          accuracyImportance: device.accuracyImportance,
          performanceSatisfaction: device.performanceSatisfaction,
          currentSerial: device.serial,
          deviceId: device.deviceId,
        };
    const handleClick = (event) => {
      this.setState({
        selectMenu: !this.state.selectMenu,
        selectMenuAnchor: event.currentTarget,
      });
    };
    const handleClose = () => {
      this.setState({ selectMenu: false });
    };
    const handleSubmit = () => {
      this.setState({ selectMenu: false, performanceToggle: this.state.performanceCheck, improvementsToggle: this.state.improvementsCheck, diagnosticsToggle: this.state.diagnosticsCheck });
    };
    const testOptions =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.activeDevice].datetimeHistory, this.props.devices[this.props.activeDevice].stateHistory)
        : ['Loading'];
    //console.log(compareDevice);
    //Prevent comparison to self
    let adjustedDatetimeHistory = !compareDevice || !compareDevice.datetimeHistory ? [] : compareDevice.datetimeHistory.slice();
    let adjustedStateHistory = !compareDevice || !compareDevice.datetimeHistory ? [] : compareDevice.stateHistory.slice();
    //console.log(adjustedDatetimeHistory);
    //console.log(compareDevice);
    const index = !compareDevice || !compareDevice.datetimeHistory ? -1 : compareDevice.datetimeHistory.indexOf(device.dateTime.toString());
    //console.log(!device ? 'loading' : device.dateTime);
    //console.log(index);
    if (index !== -1) {
      adjustedDatetimeHistory.splice(index, 1); //removed the current datatime so as to not allow "comparison to self"

      adjustedStateHistory.splice(index, 1); //removed the current datatime so as to not allow "comparison to self"
    }
    const testOptionsCompare =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? this.processTimestampArray(adjustedDatetimeHistory, adjustedStateHistory)
        : ['No Tests Found'];
    const testOptionsCompareIndex =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? adjustedDatetimeHistory.indexOf(this.props.optimize.compareTest) + 1
        : 0;
    //console.log(testOptionsCompare[testOptionsCompareIndex]);
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        <Grid>
          <Grid container justifyContent='space-between' className={classes.printHide} style={{ marginBottom: this.props.activeTab === 'Certification' ? null : -42 }}>
            <Grid item></Grid>
            <Grid item>
              <Grid container spacing={1}>
                {!this.props.toggledWidget &&
                !!this.props.devices &&
                !!this.props.devices[this.props.activeDevice] &&
                !!this.props.devices[this.props.activeDevice].testIdHistory &&
                this.props.devices[this.props.activeDevice].testIdHistory.length > 1 ? (
                  <Grid item style={{ marginBottom: -15 }}>
                    <InfoPopover tooltip='Merge Tests' title='Merge Tests' titleIcon='Merge' icon={<Merge />} content={<OptimizeTestMerge />} maxWidth='sm' />
                  </Grid>
                ) : null}
                <React.Fragment>
                  {!this.props.toggledWidget ? (
                    <React.Fragment>
                      {!printStyles ? (
                        <Grid item>
                          <Tooltip arrow placement='top' title='Create Performance Certificate in a New Tab' aria-label='Create Performance Certificate in a New Tab'>
                            <Link href={`/optimize/${this.props.activeDevice}/Certification`} target='_blank'>
                              <IconButton className={classes.textColor} aria-label='more' style={{ marginLeft: -10 }} size='large'>
                                <Speed />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        </Grid>
                      ) : null}
                      {!printStyles ? (
                        <Grid item style={{ marginBottom: -15 }}>
                          <InfoPopover center tooltip='Set As Found/As Left' title='Set As Found/As Left' titleIcon='Update' large icon={<Update />} content={<OptimizeTestState />} maxWidth='sm' />
                        </Grid>
                      ) : null}
                      {!printStyles ? (
                        <Grid item>
                          <InfoPopover
                            center
                            tooltip='Configure'
                            title='Configure'
                            titleIcon='Settings'
                            resize
                            style={{ marginBottom: -15 }}
                            icon={<Settings />}
                            content={<OptimizeContent row={row} clickedRow={this.props.activeDevice} rowOpen={true} />}
                          />
                        </Grid>
                      ) : null}
                      <Grid item>
                        <Tooltip arrow placement='top' title='Show More' aria-label='Show More'>
                          <IconButton color='inherit' aria-label='more' onClick={handleClick} style={{ marginLeft: -10 }} size='large'>
                            <MoreVert />
                          </IconButton>
                        </Tooltip>
                        <Popper open={this.state.selectMenu} onClose={handleClose} anchorEl={this.state.selectMenuAnchor} placement='bottom-start'>
                          <ClickAwayListener onClickAway={handleClose}>
                            <Paper>
                              <Typography
                                variant='caption'
                                style={{
                                  padding: '10px 12px 6px 14px',
                                  display: 'block',
                                }}
                              >
                                Select to show more info
                              </Typography>
                              <MenuItem onClick={this.handlePerformanceCheck}>
                                <Checkbox className={classes.menuCheckbox} checked={this.state.performanceCheck} color='secondary' />
                                Performance
                              </MenuItem>
                              <MenuItem onClick={this.handleImprovementsCheck}>
                                <Checkbox className={classes.menuCheckbox} checked={this.state.improvementsCheck} color='secondary' />
                                Improvement Paths
                              </MenuItem>
                              <MenuItem onClick={this.handleDiagnosticsCheck}>
                                <Checkbox className={classes.menuCheckbox} checked={this.state.diagnosticsCheck} color='secondary' />
                                Diagnostics
                              </MenuItem>
                              <Grid container justifyContent='space-between'>
                                <Grid item></Grid>
                                <Grid item>
                                  <Button variant='outlined' disableElevation size='small' color='primary' aria-label='submit' onClick={handleSubmit} style={{ margin: 10, display: 'block' }}>
                                    Submit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Paper>
                          </ClickAwayListener>
                        </Popper>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              </Grid>
            </Grid>
          </Grid>
          <div>
            {!!this.props.toggledWidget ? <PrintHeader></PrintHeader> : null}
            <WidgetTitle title={`Alignment - ${!device || !device.name ? 'Device Name Not Found' : device.name}`} />
            {!!this.props.toggledWidget ? null : (
              <OptimizeSpecs
                device={this.props.devices[this.props.activeDevice]}
                compareDevice={this.props.devices[this.props.optimize.compareDevice]}
                compareTo={['No Comparison'].concat(testOptionsCompare)[testOptionsCompareIndex]}
                useTitle
                title='Device Details'
                moreSpecs
                diagnosticsSpecs={this.state.diagnosticsToggle}
              />
            )}
            <AlignmentSummary />
            <ImprovementsWidgets toggledWidget hideButtons alignment></ImprovementsWidgets>
            <WidgetTitle title={`Performance - ${!device || !device.name ? 'Device Name Not Found' : device.name}`} />
            {!!this.props.toggledWidget ? null : (
              <Grid container className={classes.testSelect}>
                <Grid item>
                  {!this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime ? null : (
                    <CustomSelect
                      single
                      id={null}
                      label='Test'
                      type='Test'
                      disabled={this.state.loading}
                      onChangeFunction={(event, value) => {
                        this.selectActiveTest(value);
                      }}
                      values={
                        !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime
                          ? 'Loading'
                          : `${new Date(this.props.devices[this.props.activeDevice].dateTime * 1000).toLocaleString()}${this.processState(this.props.devices[this.props.activeDevice].state)}`
                      }
                      options={testOptions}
                      helperText={null}
                      width={260}
                    />
                  )}
                </Grid>
                {!this.props.devices[this.props.activeDevice] ||
                !this.props.devices[this.props.activeDevice].datetimeHistory ||
                this.props.devices[this.props.activeDevice].datetimeHistory.length < 0 ? null : (
                  <React.Fragment>
                    <Grid item className={!this.props.optimize.compareFlag ? classes.printHide : null}>
                      <Autocomplete
                        className={classes.formSelect}
                        id='combo-box-demo'
                        options={Object.keys(this.props.devices).map((key) => {
                          return {
                            name: this.props.devices[key].name,
                            deviceId: this.props.devices[key].deviceId,
                          };
                        })}
                        value={
                          !this.props.optimize.compareDevice || !this.props.devices[this.props.optimize.compareDevice]
                            ? null
                            : {
                                name: this.props.devices[this.props.optimize.compareDevice].name,
                                deviceId: this.props.devices[this.props.optimize.compareDevice].deviceId,
                              }
                        }
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => {
                          return option.name === value.name && option.deviceId === value.deviceId;
                        }}
                        disableClearable={true}
                        onChange={(e, value) => {
                          this.props.optimizeSetCompareFlag(false);
                          this.props.optimizeSetCompareTest('');
                          this.props.optimizeSetCompareDevice(value.deviceId);
                          this.props.fetchDevices(value.deviceId);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Comparison Device'
                            /*InputLabelProps={{
                          className: classes.printHide,
                        }}*/
                            variant='standard'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item className={!this.props.optimize.compareFlag ? classes.printHide : null}>
                      <CustomSelect
                        single
                        id={null}
                        label='Compare to'
                        type='Compare'
                        disabled={this.state.loading || testOptionsCompare.length < 1}
                        onChangeFunction={(event, value) => {
                          this.compareTest(value);
                        }}
                        values={!testOptionsCompare || !testOptionsCompare[testOptionsCompareIndex] || !this.props.optimize.compareFlag ? 'No Comparison' : testOptionsCompare[testOptionsCompareIndex]}
                        options={['No Comparison'].concat(testOptionsCompare)}
                        helperText={null}
                        width={260}
                      />
                    </Grid>
                    {this.state.message !== '' ? (
                      <Grid item>
                        <Alert severity='warning'>{this.state.message}</Alert>
                      </Grid>
                    ) : null}
                  </React.Fragment>
                )}
              </Grid>
            )}
            <div style={{ marginTop: 8 }}>{this.renderWidgets()}</div>
            {/* Table Widget goes here with new step time data (pull from API) 
              resolution signal (% move), resolution step time (t-63), resolution step time (change from prev.), all of the above with sensitivity
            */}
            {this.renderStepTimeTable(device)}
          </div>
        </Grid>
        {this.renderPdfDialog()}
        {this.renderPerformanceWidgets()}
        {this.renderImprovementsWidgets()}
        {this.renderDiagnosticsWidgets()}
        {!this.props.toggledWidget ? (
          <Grid className={classes.printHide}>
            <Button variant='outlined' size='medium' color='primary' aria-label='more' onClick={handleClick} style={{ marginTop: 4 }}>
              <MoreVert className={classes.buttonIcon}></MoreVert>Show More
            </Button>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    devices: state.devices.devices,
    optimize: state.optimize,
    format: state.format.format,
    tests_status: state.tests.status,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, {
  fetchDevices,
  fetchDevicesSummary,
  fetchDeviceByTest,
  fetchDeviceSpecs,
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  optimizeSetCompareDevice,
  setFormat,
  fetchTest,
  setTestStatus,
  setDevicesSummaryStatus,
  clearDeviceIssuesImprovements,
  //fetchDeviceCompareTests /*, fetchDeviceEvents */,
})(withStyles(styles)(AlignmentWidgets));
