import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { connect } from 'react-redux';
import { setFormat, setColorMode } from '../../actions';
import { withRouter } from 'react-router-dom';
import { Brightness4, Brightness7, Print } from '@mui/icons-material';

class AppBarButtons extends React.Component {
  clickPrint() {
    let win = window.open(`${window.location.href}/print`, '_blank');
    win.focus();
  }
  render() {
    const { classes } = this.props;
    const path = this.props.location.pathname.slice(1);
    let disablePrint = path.length < 1;
    return (
      <React.Fragment>
        <span className={classes.printButton} style={disablePrint ? { display: 'none' } : null}>
          <Tooltip arrow placement='top' className={classes.printHide} title='Print' aria-label='Print'>
            <IconButton color='inherit' onClick={() => this.clickPrint()} size='large'>
              <Print></Print>
            </IconButton>
          </Tooltip>
        </span>
        <Tooltip arrow placement='top' className={classes.printHide} title='Toggle Dark Mode' aria-label='Toggle Dark Mode'>
          <IconButton color='inherit' onClick={() => this.props.setColorMode(this.props.colorMode === 'light' ? 'dark' : 'light')} size='large'>
            {this.props.colorMode === 'dark' ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    print: state.print.print,
    format: state.format.format,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, { setFormat, setColorMode })(withStyles(styles, { withTheme: true })(withRouter(AppBarButtons)));
