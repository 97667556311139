//import _ from 'lodash';
import { CLEAR_STATE, FETCH_ACTIONS, FETCH_INVESTIGATIONS, FETCH_ISSUES_DETAILS } from '../actions/types';

const INITIAL_STATE = { actions: {}, investigations: {}, issues: {} };
const recommendationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return {
        ...state,
        actions: {},
        investigations: {},
        issues: {},
      };
    }

    case FETCH_INVESTIGATIONS:
      let investigations = Object.assign({}, state.investigations);
      for (let i = 0; i < action.payload.length; i++) {
        let investigation = action.payload[i];
        if (!state.investigations[investigation.id]) {
          //test doesn't exist yet, add
          //console.log('add');
          investigations[investigation.id] = investigation;
        } else {
          //console.log('merge');
          //test exists, merge data
          /* const mergedinvestigation = Object.assign(
            state.investigations[investigation.Id],
            investigation
          );*/
          investigations[investigation.Id] = investigation;
        }
      }
      return {
        ...state,
        investigations,
      };
    case FETCH_ACTIONS:
      let actions = Object.assign({}, state.actions);
      for (let i = 0; i < action.payload.length; i++) {
        let actionObj = action.payload[i];
        if (!state.actions[actionObj.id]) {
          //test doesn't exist yet, add
          //console.log('add');
          actions[actionObj.id] = actionObj;
        } else {
          //console.log('merge');
          //test exists, merge data
          /*const mergedaction = Object.assign(
            state.actions[actionObj.Id],
            actionObj
          );*/
          actions[actionObj.Id] = actionObj;
        }
      }
      return {
        ...state,
        actions,
      };
    case FETCH_ISSUES_DETAILS:
      let issues = Object.assign({}, state.issues);
      for (let i = 0; i < action.payload.length; i++) {
        let issue = action.payload[i];
        if (!state.issues[issue.id]) {
          //test doesn't exist yet, add
          //console.log('add');
          issues[issue.id] = issue;
        } else {
          //console.log('merge');
          //test exists, merge data
          //const mergedissue = Object.assign(state.issues[issue.Id], issue);
          issues[issue.Id] = issue;
        }
      }
      return {
        ...state,
        issues,
      };
    default:
      return state;
  }
};

export default recommendationsReducer;
