import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Tooltip, Grid, Typography, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { ExpandMore } from '@mui/icons-material';
import CustomSelect from '../filters/CustomSelect';
import FieldContent from '../widgetGroups/config/content/FieldContent';

class AccordionWidgetFieldReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  clickSelect(e) {
    e.stopPropagation();
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.avoidBreak}>
        <Accordion>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={
              <Tooltip arrow placement='top' title='Expand/Collapse' enterDelay={300}>
                <ExpandMore className={classes.iconGray} />
              </Tooltip>
            }
            aria-controls='test'
            id='test'
            onClick={() => {
              this.setState({
                status: '',
                statusMessage: '',
                //loading: false,
              });
            }}
          >
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item onClick={(e) => this.clickSelect(e)}>
                <CustomSelect label='Field Report' Value='Latest Field Report' variant='outlined' inline></CustomSelect>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                  Device Name:
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                  Device Type:
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                  <SvgIcon className={classes.legendIcon}>
                    <circle cx='12' cy='12' r='12' />
                  </SvgIcon>
                  <span style={{ marginTop: 2 }}>In Service</span>
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails} style={{ marginBottom: 10 }}>
            <FieldContent />
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

AccordionWidgetFieldReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccordionWidgetFieldReport);
