import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Tooltip } from '@mui/material';
import { styles } from '../styles';

class ChecklistIndicator extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Tooltip arrow placement='top' title={!this.props.tooltip ? '' : this.props.tooltip} enterDelay={300}>
        <div
          className={`${classes.checkbox} ${
            this.props.color === 'red'
              ? classes.checkboxRed
              : this.props.color === 'yellow'
              ? classes.checkboxYellow
              : this.props.color === 'orange'
              ? classes.checkboxYellow
              : this.props.color === 'green'
              ? classes.checkboxGreen
              : this.props.color === 'black'
              ? classes.checkboxBlack
              : this.props.color === 'purple'
              ? classes.checkboxPurple
              : this.props.color === 'gray'
              ? classes.checkboxGrayDark
              : classes.checkboxGray
          } ${this.props.bodyCheckbox ? classes.bodyCheckbox : null} `}
        ></div>
      </Tooltip>
    );
  }
}

ChecklistIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChecklistIndicator);
