import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import { ExpandMore } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import WidgetTitle from '../../headers/WidgetTitle';
import { Accordion, AccordionDetails, AccordionSummary, Typography, CircularProgress, Grid, Tooltip, IconButton } from '@mui/material';
import roadmapDetails from '../../../arrays/prioritize/roadmapDetails';
import functions from '../../../functions/functions';

class Roadmaps extends React.Component {
  render() {
    const { classes } = this.props;
    const roadmapArray = Object.keys(roadmapDetails);
    return (
      (<React.Fragment>
        <WidgetTitle title='Prioritize Roadmaps' />
        <Typography variant='body1' style={{ marginTop: 10, marginBottom: 20 }}>
          Roadmaps are a way for you to more easily address common issues.
        </Typography>
        <Typography variant='body2' style={{ marginBottom: 4 }} className={classes.printHide}>
          Click any row to learn more about each roadmap
        </Typography>
        {!roadmapArray
          ? `Roadmaps Not Found!`
          : roadmapArray.map((roadmapKey) => {
              const sectionArray = Object.keys(roadmapDetails[roadmapKey].roadmap);
              return (
                (<Accordion TransitionProps={{ unmountOnExit: true }} square={false} key={`${roadmapKey.replace(/\s/g, '')}_item`}>
                  <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMore className={classes.iconGray} />}>
                    {!roadmapDetails[roadmapKey].title ? (
                      <Typography variant='subtitle2' style={{ paddingTop: 4 }}>
                        <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                        Loading...
                      </Typography>
                    ) : (
                      <Grid container style={{ minWidth: 60 }} justifyContent='space-between'>
                        <Grid item>
                          <Typography variant='subtitle1'>{roadmapDetails[roadmapKey].title}</Typography>
                        </Grid>
                        <Grid
                          item
                          onClick={() => {
                            functions.setCookie('presetName', roadmapDetails[roadmapKey].title);
                            functions.setCookie('presetValue', roadmapKey);
                            this.props.setActiveNav(1);
                          }}
                        >
                          <Tooltip arrow placement='top' title='Link to Roadmap Preset' enterDelay={300}>
                            <IconButton color='inherit' aria-label='link' onClick={(e) => this.handleMenuClick} size='large' component={Link} to={'/prioritize'}>
                              <LinkIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    {!roadmapDetails[roadmapKey] ? (
                      'Roadmap not found!'
                    ) : (
                      <React.Fragment>
                        <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5 }}>
                          Keys to Success
                        </Typography>
                        <Typography variant='body1' className={classes.body}>
                          {roadmapDetails[roadmapKey].description}
                        </Typography>
                        {!sectionArray
                          ? null
                          : sectionArray.map((sectionKey) => {
                              const section = roadmapDetails[roadmapKey].roadmap[sectionKey];
                              const stepArray = Object.keys(section.steps);
                              return (
                                (<React.Fragment key={`${sectionKey.replace(/\s/g, '')}_item`}>
                                  <Typography variant='subtitle1' color='secondary' style={{ marginTop: 20, marginBottom: 5 }}>
                                    {!section.name ? 'Section Name Not Found' : section.name}
                                  </Typography>
                                  {!sectionArray
                                    ? `Step Not Found!`
                                    : stepArray.map((stepKey) => {
                                        const step = section.steps[stepKey];
                                        return (
                                          (<Typography
                                                                                              variant='body1'
                                                                                              className={classes.body}
                                                                                              style={
                                                                                                stepKey.includes('indent')
                                                                                                  ? {
                                                                                                      paddingLeft: 25,
                                                                                                      fontSize: 15,
                                                                                                    }
                                                                                                  : { paddingTop: 5 }
                                                                                              }
                                                                                              key={`${stepKey.replace(/\s/g, '')}_item`}
                                                                                            >
                                            {stepKey.includes('indent') ? '- ' : '• '}
                                            {step}
                                          </Typography>)
                                        );
                                      })}
                                </React.Fragment>)
                              );
                            })}
                      </React.Fragment>
                    )}
                  </AccordionDetails>
                </Accordion>)
              );
            })}
      </React.Fragment>)
    );
  }
}

export default withStyles(styles, { withTheme: true })(Roadmaps);
