import * as Sentry from '@sentry/react';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from '../reducers';

//Let's us use redux devTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/*const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);*/
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk), sentryReduxEnhancer)
);

export default store;
