import React from 'react';
import { connect } from 'react-redux';
import { styles } from '../../styles';
import { fetchLoop, fetchLoopDevices, fetchLoopEvents, fetchLoopVariable, setTestStatus, fetchLoopDiagnostics } from '../../../actions/index';
import MultiStockChartWrapper from '../MultiStockChartWrapper';
import withStyles from '@mui/styles/withStyles';
import { CircularProgress, Typography } from '@mui/material';

class PrioritizeMultiStockChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAnalysisRange: null,
      maxAnalysisRange: null,
    };
  }
  componentDidMount() {
    const variableList = !this.props.manualVariables ? this.props.activeVariables : this.props.manualVariables;
    const analysisRange = !this.props.manualRange ? this.props.analysisRange : this.props.manualRange;
    for (let i = 0; i < variableList.length; i++) {
      const variableName = variableList[i].toLowerCase().replace(' ', '_');
      if (
        !!this.props.activeLoop &&
        (!this.props.test_status[variableName] ||
          (variableName === 'concern_score' && analysisRange !== this.state.currentAnalysisRange) ||
          !this.props.test_status[variableName][this.props.activeLoop] ||
          !(this.props.test_status[variableName][this.props.activeLoop] === 'loading' || this.props.test_status[variableName][this.props.activeLoop] === 'loaded'))
      ) {
        this.props.fetchLoopVariable(
          this.props.activeLoop,
          variableName,
          variableName === 'concern_score'
            ? {
                analysisrange: analysisRange,
                minExtreme: this.props.minExtreme,
              }
            : //null
              {
                analysisrange: analysisRange,
                minExtreme: this.props.minExtreme,
              },
          this.props.dataset
        );
        this.props.setTestStatus(variableName, this.props.activeLoop, 'loading');
      }
    }
    if (analysisRange !== this.state.currentAnalysisRange) {
      this.setState({ currentAnalysisRange: analysisRange });
    }
    if (analysisRange > this.state.maxAnalysisRange) {
      this.setState({ maxAnalysisRange: analysisRange });
    }
  }
  componentDidUpdate() {
    const variableList = !this.props.manualVariables ? this.props.activeVariables : this.props.manualVariables;
    const analysisRange = !this.props.manualRange ? this.props.analysisRange : this.props.manualRange;
    for (let i = 0; i < variableList.length; i++) {
      const variableName = variableList[i].toLowerCase().replace(' ', '_');
      if (
        !!this.props.activeLoop &&
        (!this.props.test_status[variableName] ||
          /*variableName === 'concern_score' &&*/ analysisRange /*!==*/ > this.state.maxAnalysisRange ||
          !this.props.test_status[variableName][this.props.activeLoop] ||
          !(this.props.test_status[variableName][this.props.activeLoop] === 'loading' || this.props.test_status[variableName][this.props.activeLoop] === 'loaded'))
      ) {
        this.props.fetchLoopVariable(
          this.props.activeLoop,
          variableName,
          variableName === 'concern_score'
            ? {
                analysisrange: analysisRange,
                minExtreme: this.props.minExtreme,
              }
            : //null
              {
                analysisrange: analysisRange,
                minExtreme: this.props.minExtreme,
              },
          this.props.dataset
        );
        /*this.props.setTestStatus(
          variableName,
          this.props.activeLoop,
          'loading'
        );*/
      }
    }
    if (analysisRange !== this.state.currentAnalysisRange) {
      this.setState({ currentAnalysisRange: analysisRange });
    }
    if (analysisRange > this.state.maxAnalysisRange) {
      this.setState({ maxAnalysisRange: analysisRange });
    }
  }

  issueToType = (issue) => {
    //console.log(issue);
    switch (issue) {
      case 'Validity':
      case 'Stuck Reading':
      case 'Data Resolution':
      case 'Saturation':
      case 'Redundant Error':
      case 'Loop Error':
      case 'Data Validity Warning':
        return 'Inputs and Outputs';
      case 'Control Range':
      case 'Tend Change':
      case 'Slope Shift':
      case 'Stability':
      case 'Volatility':
      case 'Controllability':
        return 'Controllability';
      case 'Oscillation Amplitude':
      case 'Oscillations':
      case 'Inconsistency':
        return 'Traits';
      default:
        return 'Unknown';
    }
  };

  typeToColor = (type) => {
    //console.log(type);
    switch (type) {
      case 'Inputs and Outputs':
        return 'rgba(108, 191, 116, 0.2)';
      case 'Controllability':
        return 'rgba(213,  121, 173, 0.2)';
      case 'Traits':
        return 'rgba(111, 139, 206, 0.2)';
      default:
        return 'rgba(136, 112, 172, 0.2)';
    }
  };

  checkLoadingStatus = () => {
    let fullyLoaded = true;
    //console.log(this.props.test_status);
    const variableList = !this.props.manualVariables ? this.props.activeVariables : this.props.manualVariables;
    for (let i = 0; i < variableList.length; i++) {
      const variableName = variableList[i].toLowerCase().replace(' ', '_');
      if (
        !fullyLoaded ||
        !this.props.test_status[variableName] ||
        !this.props.test_status[variableName][this.props.activeLoop] ||
        (this.props.test_status[variableName][this.props.activeLoop] !== 'loaded' && this.props.test_status[variableName][this.props.activeLoop] !== 'no data')
      ) {
        fullyLoaded = false;
      }
    }
    return fullyLoaded;
  };

  processIssues = (loop) => {
    let plotBands = [];
    const issueList = loop.issues;
    //console.log(loop.issues);
    //console.log(this.props.events[issueList[0]]);
    for (let i = 0; i < issueList.length; i++) {
      const issue = this.props.events[issueList[i]];
      if (!!issue && this.props.issueFilter.includes(issue.eventName)) {
        //console.log(issue.incidentTimes);
        //console.log(issue);
        let tempStart = 0;
        let tempEnd = 0;
        for (let j = 0; j < issue.incidentTimes.length; j++) {
          const type = this.issueToType(issue.eventName);
          if (j === 0) {
            tempStart = issue.incidentTimes[j][0];
            tempEnd = issue.incidentTimes[j][1];
          } else {
            if (issue.incidentTimes[j][0] > tempEnd) {
              plotBands.push({
                color: this.typeToColor(type),
                id: `${issue.id}_${j}`,
                from: tempStart * 1000, //issue.incidentTimes[j][0] * 1000,
                to: tempEnd * 1000, //issue.incidentTimes[j][1] * 1000,
                type: issue.eventName,
                name: issue.eventName,
                /*zIndex: 1,
                  events: {
                    mouseover: function (e) {
                      const chart = this.axis.chart;
                      chart.myPlotband = [this.options.from, this.options.to];
                    },
                    mouseout: function (e) {
                      console.log('mouseout');
                      console.log(e);
                      const chart = this.axis.chart;
                      chart.myPlotband = null;
                    },
                  },*/
                /*label: {
                    text: issue.eventName,
                    align: 'right',
                    x: -10,
                  },*/
              });
              tempStart = issue.incidentTimes[j][0];
              tempEnd = issue.incidentTimes[j][1];
            } else if (issue.incidentTimes[j][1] > tempEnd) {
              tempEnd = issue.incidentTimes[j][1];
            }
          }
          if (j === issue.incidentTimes.length - 1) {
            plotBands.push({
              color: this.typeToColor(type),
              id: `${issue.id}_${j}`,
              from: tempStart * 1000, //issue.incidentTimes[j][0] * 1000,
              to: tempEnd * 1000, //issue.incidentTimes[j][1] * 1000,
              type: issue.eventName,
              name: issue.eventName,
              /*zIndex: 1,
                events: {
                  mouseover: function (e) {
                    const chart = this.axis.chart;
                    chart.myPlotband = [this.options.from, this.options.to];
                  },
                  mouseout: function (e) {
                    console.log('mouseout');
                    console.log(e);
                    const chart = this.axis.chart;
                    chart.myPlotband = null;
                  },
                },*/
              /*label: {
                  text: issue.eventName,
                  align: 'right',
                  x: -10,
                },*/
            });
          }
        }
      }
    }
    //console.log(plotBands);
    return plotBands;
  };

  processShutdowns = (loop, device_ids) => {
    let plotBands = [];
    var operator_table = {
      High: function (a, b) {
        return a > b;
      },
      Low: function (a, b) {
        return a < b;
      },
      // ...
    };
    const shutdownList = !loop || !loop.shutdowns ? [] : loop.shutdowns;
    for (let i = 0; i < shutdownList.length; i++) {
      const shutdown = this.props.shutdowns[shutdownList[i]];
      //console.log(issue);
      if (!!shutdown) {
        if (shutdown.type === 'time') {
          plotBands.push({
            color: 'rgba(200, 200, 200, 0.2)',
            //id: `${issue.id}_${j}`,
            from: shutdown.start * 1000, //issue.incidentTimes[j][0] * 1000,
            to: shutdown.end * 1000, //issue.incidentTimes[j][1] * 1000,
            type: `Shutdown Time`,
            device: loop.devices[loop.device_ids.indexOf(shutdown.device_id.toString())],
            name: `Shutdown Time - (${loop.devices[loop.device_ids.indexOf(shutdown.device_id.toString())]})`,
          });
        }
        //TODO LIMIT SHUTDOWNS RESTRICTED TO PRIMARY VALUE
        if (shutdown.type === 'Limit') {
          let limit = parseFloat(shutdown.value);
          for (let i = 0; i < device_ids.length; i++) {
            if (shutdown.device_id === parseInt(device_ids[i])) {
              //console.log(device_ids[i]);
              let match = false;
              let plotBandStart = 0;
              let testData = !this.props.tests.primary || !this.props.tests.primary[device_ids[i]] ? [] : this.props.tests.primary[device_ids[i]];
              for (let j = 0; j < testData.length; j++) {
                if (testData[j][1] !== null) {
                  if (operator_table[shutdown.limit](testData[j][1], limit)) {
                    if (match) {
                    } else {
                      plotBandStart = testData[j][0];
                      match = true;
                    }
                    //console.log('t');
                  } else {
                    if (match) {
                      plotBands.push({
                        color: 'rgba(150, 150, 150, 0.2)',
                        //id: `${issue.id}_${j}`,
                        from: plotBandStart, //issue.incidentTimes[j][0] * 1000,
                        to: testData[j][0], //issue.incidentTimes[j][1] * 1000,
                        type: `Shutdown Limit`,
                        device: loop.devices[loop.device_ids.indexOf(shutdown.device_id.toString())],
                        name: `Shutdown Limit - ${shutdown.limit} (${loop.devices[loop.device_ids.indexOf(device_ids[i])]})`,
                      });
                      match = false;
                    } else {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return plotBands;
  };
  render() {
    const loop = !this.props.loops[this.props.activeLoop] ? null : this.props.loops[this.props.activeLoop];
    const device_ids = !this.props.loops || !this.props.loops[this.props.activeLoop] || !this.props.loops[this.props.activeLoop].device_ids ? [] : this.props.loops[this.props.activeLoop].device_ids;
    let fullyLoaded = this.checkLoadingStatus();
    //console.log(fullyLoaded);
    const { classes } = this.props;
    let varList = [];
    let chartList = {};
    let plotBands = [];
    let selectedPlotBands = [];
    //!REPLACE WITH FUNCTION WHEN DONE WITH PRELIM WORK
    for (let i = 0; i < this.props.chartSelections.length; i++) {
      selectedPlotBands.push({
        color: 'rgba(20, 100, 240, 0.2)',
        //id: `${issue.id}_${j}`,
        from: !this.props.chartSelections[i][0] ? 0 : this.props.chartSelections[i][0], //issue.incidentTimes[j][0] * 1000,
        to: !this.props.chartSelections[i][1] ? 0 : this.props.chartSelections[i][1], //issue.incidentTimes[j][1] * 1000,
        type: `TEST`,
        device: `We don't need no stinkin' device`,
        name: `TEST`,
      });
    }
    const variableList = !this.props.manualVariables ? this.props.activeVariables : this.props.manualVariables;
    const analysisRange = !this.props.manualRange ? this.props.analysisRange : this.props.manualRange;

    if (this.props.issueFilter.length > 0 && !!loop) {
      plotBands = this.processIssues(loop);
    }
    let shutdownBands = [];
    if (this.props.shutdownFlag && !!loop) {
      shutdownBands = this.processShutdowns(loop, device_ids);
    }
    //! REMOVE SECOND CONCAT WHEN TESTING COMPLETE
    let finalBands = plotBands.concat(shutdownBands).concat(selectedPlotBands);

    // console.log(this.props.loops[this.props.activeLoop]);
    for (let i = 0; i < variableList.length; i++) {
      const variableName = variableList[i].toLowerCase().replace(' ', '_');
      /*if (
        !this.props.test_status[variableName] ||
        !this.props.test_status[variableName][this.props.activeLoop] ||
        !(
          this.props.test_status[variableName][this.props.activeLoop] ===
            'loading' ||
          this.props.test_status[variableName][this.props.activeLoop] ===
            'loaded'
        )
      ) {
        this.props.fetchLoopVariable(this.props.activeLoop, variableName);
        this.props.setTestStatus(
          variableName,
          this.props.activeLoop,
          'loading'
        );
      }*/
      //

      for (let j = 0; j < device_ids.length; j++) {
        const device_id = device_ids[j];
        const label = !this.props.devices || !this.props.devices[device_id] ? null : variableList[i] === 'Primary' ? this.props.devices[device_id].variableLabel : 'Score';
        const suffix = !label || label === 'Score' ? '' : ` (${label})`;
        let adjustedVariableName = variableList[i] === 'Primary' && !!this.props.devices[device_id] ? this.props.devices[device_id].deviceType : variableList[i];
        adjustedVariableName = variableList[i] === 'Concern Score' && !!this.props.analysisRange ? 'Concern Score (' + analysisRange + ' days)' : `${adjustedVariableName}${suffix}`;
        if (varList.indexOf(adjustedVariableName) === -1) {
          chartList[adjustedVariableName] = [];

          varList.push(adjustedVariableName);
        }
        const activeVariableData = !this.props.tests[variableName] || !this.props.tests[variableName][device_id] ? [] : this.props.tests[variableName][device_id];

        const newSeries = {
          name: !this.props.devices || !this.props.devices[device_id] ? '' : this.props.devices[device_id].name,
          title: adjustedVariableName,
          nameType: variableList[i],
          label: label,
          device: !this.props.devices || !this.props.devices[device_id] ? '' : this.props.devices[device_id].name,
          data: activeVariableData,
          //yAxis: adjustedVariableName,
        };
        //console.log(newSeries);
        chartList[adjustedVariableName].push(newSeries);
      }
    }
    //!NEED TO REFACTOR SO THAT THE CHARTS ONLY UPDATE OR CHANGE WHAT THEY NEED TO FROM THE UNDERLYING DATA OVER BLOWING EVERYTHING UP EVERY TIME
    //console.log(this.props.minExtreme, this.props.maxExtreme);
    return (
      <React.Fragment>
        {!fullyLoaded ? (
          <Typography variant='subtitle2' style={{ paddingLeft: 20, paddingTop: 20 }}>
            <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
            Loading Data...
          </Typography>
        ) : (
          <MultiStockChartWrapper
            embed={this.props.embed}
            chartList={chartList}
            plotBands={finalBands}
            className={classes.avoidBreak}
            analysisRange={analysisRange}
            minExtreme={this.props.minExtreme}
            maxExtreme={this.props.maxExtreme}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeLoop: state.prioritize.activeLoop,
    activeVariables: state.prioritize.activeVariables,
    events: state.events,
    shutdowns: state.shutdowns,
    loops: state.loops.loops,
    devices: state.devices.devices,
    tests: state.tests.tests,
    test_status: state.tests.status,
    analysisRange: state.sort.filter.AnalysisRange,
    dataset: state.sort.filter.Dataset,
    chartSelections: state.chartSelections.selections,
  };
};

export default connect(mapStateToProps, {
  fetchLoop,
  fetchLoopEvents,
  fetchLoopDevices,
  fetchLoopVariable,
  setTestStatus,
  fetchLoopDiagnostics,
})(withStyles(styles)(PrioritizeMultiStockChart));
