import React from 'react';
import api from '../../apis/api';
import withStyles from '@mui/styles/withStyles';
import { CircularProgress, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { styles } from '../styles';

class CheckApi extends React.Component {
  state = { statusText: '', color: null, loading: true };
  componentDidMount() {
    api
      .get('')
      .then((response) => {
        this.setState({
          statusText: 'Connected',
          color: 'green',
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          statusText: 'Disconnected',
          color: 'red',
          loading: false,
        });
        //console.log(error);
      });
  }
  render() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <Typography variant='subtitle2'>
          API : <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
          Loading...
        </Typography>
      );
    } else {
      return (
        <Typography variant='subtitle2'>
          API : <span className={this.state.color === 'red' ? classes.redText : classes.greenText}>{this.state.statusText}</span>
        </Typography>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(CheckApi));
