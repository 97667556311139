import React from 'react';
import { Typography, Grid, List } from '@mui/material';
import diagnosticsGraphDetails from '../../arrays/optimize/diagnosticsGraphDetails';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from '../styles';
import imagesArray from '../../arrays/optimize/imagesArray';

const useStyles = makeStyles(styles);

function DiagnosticChartDetails(props) {
  const classes = useStyles();
  const id = props.id;
  if (!diagnosticsGraphDetails[id]) {
    return <Typography style={{ marginRight: 40 }}>Loading...</Typography>;
  }
  let content = (
    <Grid container spacing={0} justifyContent='space-between'>
      <Grid item>
        {props.activeTab === 'intro' ? (
          <React.Fragment>
            <Typography variant='subtitle1'>Description</Typography>
            <List>
              {!diagnosticsGraphDetails[id].descriptions
                ? `Descriptions Not Found!`
                : Object.keys(diagnosticsGraphDetails[id].descriptions).map((description) => {
                    const labelId = `${description.replace(/\s/g, '')}`;
                    return (
                      (<Typography
                                                                          key={`${description.replace(/\s/g, '')}_item`}
                                                                          variant='body1'
                                                                          color={labelId.includes('Subtitle') ? 'secondary' : null}
                                                                          style={labelId.includes('Title') ? { paddingTop: 20, fontWeight: 500 } : labelId.includes('Subtitle') ? { paddingTop: 10 } : null}
                                                                          id={labelId}
                                                                          className={classes.body}
                                                                        >
                        {diagnosticsGraphDetails[id].descriptions[description]}
                      </Typography>)
                    );
                  })}
            </List>
          </React.Fragment>
        ) : null}
        {props.activeTab === 'investigate' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Step by Step Investigation
            </Typography>
            <List>
              {!diagnosticsGraphDetails[id].investigate
                ? `Investigation name not found!`
                : Object.keys(diagnosticsGraphDetails[id].investigate).map((recommendation, index) => {
                    const labelId = `checkbox-list-label-${recommendation.replace(/\s/g, '')}`;
                    var recommendationLabel = recommendation.slice(5, 6);
                    if (labelId.includes('Indent')) {
                      return (
                        (<Typography key={`${recommendation.replace(/\s/g, '')}_item`} role={undefined} className={classes.bodyList} style={{ paddingLeft: 10, marginTop: -10 }} id={labelId}>
                          {diagnosticsGraphDetails[id].investigate[recommendation]}
                        </Typography>)
                      );
                    } else if (labelId.includes('Title')) {
                      return (
                        <React.Fragment>
                          <Typography variant='subtitle2'>Step {recommendationLabel}</Typography>
                          <Typography variant='subtitle2' color='secondary'>
                            {diagnosticsGraphDetails[id].investigate[recommendation]}
                          </Typography>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        (<Typography key={`${recommendation.replace(/\s/g, '')}_item`} role={undefined} className={classes.bodyList} id={labelId}>
                          {diagnosticsGraphDetails[id].investigate[recommendation]}
                        </Typography>)
                      );
                    }
                  })}
            </List>
          </div>
        ) : null}
        {props.activeTab === 'issues' ? (
          <React.Fragment>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Related Issues
            </Typography>
            <Typography variant='body2'>This is a description of possible issues related to this graph</Typography>
            <List>
              {!diagnosticsGraphDetails[id].issues
                ? `Checklist Not Found!`
                : Object.keys(diagnosticsGraphDetails[id].issues).map((issue) => {
                    const labelId = `${issue.replace(/\s/g, '')}`;
                    return (
                      (<Typography
                                                                          key={`${issue.replace(/\s/g, '')}_item`}
                                                                          variant='body1'
                                                                          color={labelId.includes('Subtitle') ? 'secondary' : null}
                                                                          style={
                                                                            labelId.includes('Title')
                                                                              ? { paddingTop: 30, fontWeight: 500 }
                                                                              : labelId.includes('Subtitle')
                                                                              ? { paddingTop: 10, fontWeight: 400 }
                                                                              : labelId.includes('Indent')
                                                                              ? { paddingLeft: 0 }
                                                                              : { paddingLeft: 0 }
                                                                          }
                                                                          id={labelId}
                                                                        >
                        {diagnosticsGraphDetails[id].issues[issue]}
                      </Typography>)
                    );
                  })}
            </List>
          </React.Fragment>
        ) : null}
        {props.activeTab === 'examples' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Examples
            </Typography>
            <List>
              {!diagnosticsGraphDetails[id].examples
                ? null
                : Object.keys(diagnosticsGraphDetails[id].examples).map((example, index) => {
                    return (
                      <Grid key={example} container style={{ marginTop: 10, marginBottom: 30 }}>
                        <Grid item xs={12}>
                          <Typography variant='subtitle2' className={classes.body} color='secondary'>
                            <span style={{ fontWeight: 500 }}>Example {index + 1}</span> - {diagnosticsGraphDetails[id].examples[example].title}
                          </Typography>
                        </Grid>
                        <Typography variant='body1' className={classes.body}>
                          {diagnosticsGraphDetails[id].examples[example].caption}
                        </Typography>
                        <Grid container>
                          <img
                            key={`img-${diagnosticsGraphDetails[id].examples[example].src}`}
                            src={imagesArray[diagnosticsGraphDetails[id].examples[example].src]}
                            alt={diagnosticsGraphDetails[id].examples[example].caption}
                            className={classes.helperScreenshot}
                            style={{
                              maxWidth: !!props.resize ? 1000 : diagnosticsGraphDetails[id].examples[example].size === 'small' ? 600 : 1000,
                            }}
                          />
                        </Grid>
                        <Grid
                          container
                          justifyContent='space-between'
                          style={{
                            maxWidth: !!props.resize ? 1000 : diagnosticsGraphDetails[id].examples[example].size === 'small' ? 600 : 1000,
                          }}
                        >
                          <Grid item></Grid>
                          <Grid item>
                            <Typography variant='caption' style={{ fontStyle: 'italic' }}>
                              Example {index + 1} image
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
            </List>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
  return content;
}

export default DiagnosticChartDetails;
