import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Divider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { Button } from '@mui/material';

class Confirm extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby='form-dialog-title' PaperProps={{ classes: { root: classes.centerDialog } }}>
        <DialogTitle id='draggable-dialog-title' className={classes.dialogTitle}>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography variant='subtitle1' style={{ marginTop: 3 }}>
                    {!this.props.title ? 'Title Not Found' : this.props.title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 20 }}>{this.props.content}</DialogContent>
        <Divider className={classes.dialogDivider}></Divider>
        <DialogActions>
          <Button onClick={this.props.handleClose} color='secondary'>
            Cancel
          </Button>
          <Button onClick={this.props.handleConfirm} color='secondary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
    //}
  }
}

export default withStyles(styles)(Confirm);
