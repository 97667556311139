//import _ from 'lodash';
import {
  //FETCH_SITE,
  CLEAR_STATE,
  FETCH_SITES /*,
  CREATE_SITE,
  DELETE_SITE,
  EDIT_SITE*/,
} from '../actions/types';

const siteReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return {
        ...state,
        list: [],
      };
    }
    //case FETCH_SITE:
    //  return { ...state, [action.payload.siteId]: action.payload };
    case FETCH_SITES:
      let sites = [];
      for (let i = 0; i < action.payload.length; i++) {
        sites.push(action.payload[i].site);
      }
      return { ...state, list: sites };
    /*case EDIT_SITE:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_SITE:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_SITE:
      return _.omit(state, action.payload);*/
    default:
      return state;
  }
};

export default siteReducer;
