//import _ from 'lodash';
import {
  CLEAR_STATE,
  FETCH_CLIENTS /*,
  CREATE_LOCATION,
  DELETE_LOCATION,
  EDIT_LOCATION*/,
} from '../actions/types';

const clientReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return {
        ...state,
        list: [],
      };
    }
    case FETCH_CLIENTS:
      let clients = [];
      for (let i = 0; i < action.payload.length; i++) {
        clients.push(action.payload[i].client);
      }
      return { ...state, list: clients };
    /*case EDIT_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_LOCATION:
      return _.omit(state, action.payload);*/
    default:
      return state;
  }
};

export default clientReducer;
