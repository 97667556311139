import React from 'react';
import { Typography, Grid, Button, Tooltip } from '@mui/material';
import Link from '@mui/material/Link';
import withStyles from '@mui/styles/withStyles';
import CustomSelect from '../filters/CustomSelect';
import CheckApi from './CheckApi';
import { connect } from 'react-redux';
import {
  //signIn,
  signOut,
  verifyAuth,
  clearState,
} from '../../actions';
import { fetchLocations, fetchLocation, fetchRealms, setRealm } from '../../actions/index';
import { styles } from '../styles';
import CustomDialog from '../popup/CustomDialog';
import Releases from '../popup/content/Releases';

class UserPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: '', notesOpen: false };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.realms || this.props.realms.length === 0) {
      //console.log(1);
      this.props.fetchRealms();
    }
  }
  logoutHelper = () => {
    this.props.signOut();
    //this.props.clearState();
  };

  profileHelper = () => {};
  toggleNotes = () => {
    this.setState({
      notesOpen: !this.state.notesOpen,
    });
  };
  render() {
    //console.log(this.props.current_realm);
    //console.log(this.props.realms);
    const { classes } = this.props;
    let version = '2.1.2';
    if (!this.props.isSignedIn) {
      return (
        <div className={classes.userPanel}>
          <CheckApi />
          <Typography variant='subtitle2' style={{ marginTop: 5, marginBottom: -3 }}>
            <i>Not Signed In</i>
          </Typography>
        </div>
      );
    } else {
      let current_realm = this.props.realms.find((o) => parseInt(o.id) === parseInt(this.props.current_realm));
      return (
        (<div className={classes.userPanel}>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <CheckApi />
            </Grid>
            {!this.props.auth.optimize || !this.props.auth.prioritize ? null : (
              <Grid item style={{ margin: '-4px 0px -4px -6px' }}>
                <Tooltip arrow placement='top' title={`Release Notes`} enterDelay={300}>
                  <Button type='text' color='secondary' size='small' onClick={this.toggleNotes}>
                    Version {version}
                  </Button>
                </Tooltip>
                <CustomDialog
                  center
                  maxWidth='md'
                  open={this.state.notesOpen}
                  onClose={() => {
                    this.setState({ notesOpen: false });
                  }}
                  title={`Release Notes`}
                  content={<Releases />}
                />
              </Grid>
            )}
          </Grid>
          <Typography variant='subtitle2' style={{ marginTop: 5, marginBottom: -3 }}>
            Welcome,
          </Typography>
          <Typography variant='subtitle1'>{this.props.full_name}</Typography>
          {!current_realm || !current_realm.name ? null : (
            /*<SingleSelect
                label={`Realm`}
                selectedOption={current_realm.name}
                options={this.props.realms.map((realm) => realm.name)}
                handleChange={(e) => {
                  let new_realm = this.props.realms.find((o) => o.name === e);
                  this.props.setRealm(new_realm.id);
                }}
                narrow
              ></SingleSelect>*/
            (<CustomSelect
              single
              id={null}
              label={`Portal`}
              onChangeFunction={(event, value, type) => {
                let new_realm = this.props.realms.find((o) => o.name === value);
                this.props.setRealm(new_realm.id);
              }}
              values={current_realm.name}
              options={this.props.realms.map((realm) => realm.name)}
              helperText={null}
            />)
          )}
          <Typography>
            <Link
              //component='button'
              variant='body2'
              href='/Profile'
              style={{ marginTop: 5 }}
            >
              Profile
            </Link>
          </Typography>
          <Link component='button' variant='body2' href='/Login' onClick={this.logoutHelper} style={{ marginTop: 5 }}>
            Sign Out
          </Link>
        </div>)
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    full_name: state.auth.full_name,
    locations: state.locations,
    errors: state.errors,
    realms: state.auth.realms,
    current_realm: state.auth.current_realm,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  signOut,
  verifyAuth,
  fetchLocations,
  fetchLocation,
  clearState,
  fetchRealms,
  setRealm,
})(withStyles(styles)(UserPanel));
