import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Grid, Paper } from '@mui/material';
import { styles } from '../styles';
import TableExpandable from '../charts/TableExpandable';
import functions from '../../functions/functions';

class TableWidgetExpandable extends React.Component {
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <Grid item xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg}>
        <Paper elevation={3} className={printStyles ? classes.printPaper : classes.paper} style={{ padding: 0 }}>
          <TableExpandable
            selectable={this.props.selectable}
            onSelectAllClick={this.props.onSelectAllClick}
            selections={this.props.selections}
            selectionActions={this.props.selectionActions}
            rows={this.props.rows}
            orderBy={this.props.orderBy}
            data={this.props.data}
            header={this.props.header}
            type={this.props.type}
            handleClick={this.props.handleClick}
            renderChildRowContent={this.props.renderChildRowContent}
            rowOpen={this.props.rowOpen}
            loading={this.props.loading}
          />
        </Paper>
      </Grid>
    );
  }
}

TableWidgetExpandable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableWidgetExpandable);
