import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import { styles } from '../styles';

class TextInput extends React.Component {
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes } = this.props;

    return (
      <TextField
        className={
          this.props.large ? classes.textFieldLarge : classes.textField
        }
        id={this.props.id}
        label={this.props.label}
        placeholder={this.props.placeholder}
        helperText={this.props.helperText}
        defaultValue={this.props.defaultValue}
      />
    );
  }
}

TextInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TextInput);
