import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { ArrowBack } from '@mui/icons-material';
import { styles } from '../styles';

class Back extends React.Component {
  handleBack = () => {
    this.props.history.goBack();
  };
  render() {
    const { classes } = this.props;
    return (
      <Tooltip arrow placement='top' title='Go Back' aria-label='Go Back'>
        <IconButton
          color='inherit'
          className={classes.back}
          onClick={() => {
            this.handleBack();
          }}
          aria-label='Go Back'
          size='large'
        >
          <ArrowBack>alarm</ArrowBack>
        </IconButton>
      </Tooltip>
    );
  }
}

export default withRouter(withStyles(styles)(Back));
