import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
//import makeStyles from '@mui/styles/makeStyles';
//import { styles } from '../styles';
import { connect } from 'react-redux';
import DeviceContent from '../widgetGroups/config/content/DeviceContent';
import LoopContent from '../widgetGroups/config/content/LoopContent';
import functions from '../../functions/functions';
import { fetchDeviceTypes } from '../../actions/index';
//const useStyles = makeStyles(styles);

function PrioritizeConfigDetails(props) {
  //const classes = useStyles();
  const loop = props.loop;
  /*if (!configDetails[id]) {
    return <Typography style={{ marginRight: 40 }}>Loading...</Typography>;
  }*/
  useEffect(() => {
    if (!props.devicetypes || props.devicetypes.length === 0) {
      props.fetchDeviceTypes();
    }
  });

  const getDeviceTypeList = (loop) => {
    let obj = {};
    let str = '';
    for (let i = 0; i < loop.deviceTypes.length; i++) {
      if (!(loop.deviceTypes[i] in obj)) {
        obj[loop.deviceTypes[i]] = 1;
      } else {
        obj[loop.deviceTypes[i]]++;
      }
    }
    let objKeys = Object.keys(obj).sort();
    for (let i = 0; i < objKeys.length; i++) {
      str += `${objKeys[i]} (${obj[objKeys[i]]})`;
      if (i + 1 !== objKeys.length) {
        str += ', ';
      }
    }
    //return loop.deviceTypesDistinct;
    return str;
  };
  let content = (
    <Grid container spacing={0} justifyContent='space-between'>
      <Grid item xs={12}>
        {props.activeTab === 'loop' ? (
          <LoopContent
            row={{
              id: loop.processId,
              devices: getDeviceTypeList(loop),
              site: loop.site,
              location: loop.location,
              application: loop.applicationType,
              loopName: loop.processName,
              importance: functions.importanceToString(loop.importance_max),
            }}
          />
        ) : null}
        {props.activeTab === 'device'
          ? loop.device_ids.map((device_id, index) => {
              let deviceName = loop.devices[index];
              return <DeviceContent key={`device_content_key_${device_id}`} padding row={{ id: device_id }} deviceName={deviceName} />;
            })
          : null}
      </Grid>
    </Grid>
  );
  return content;
}

const mapStateToProps = (state) => {
  return {
    devices: state.devices.devices,
    devicetypes: state.devicetypes.list,
  };
};

export default connect(mapStateToProps, { fetchDeviceTypes })(PrioritizeConfigDetails);
