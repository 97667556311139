import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Paper, MenuItem, Checkbox, Typography, Tooltip, IconButton, Popper, ClickAwayListener, Switch, List, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import PrioritizeTile from '../../tiles/PrioritizeTile';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import Presets from '../../../arrays/prioritize/presets';
import InfoPopover from '../../popup/InfoPopover.js';
import WidgetTitle from '../../headers/WidgetTitle';
import { connect } from 'react-redux';
import { FormControlLabel } from '@mui/material';
import functions from '../../../functions/functions';
import {
  fetchLoop,
  fetchLoopDevices,
  fetchLoopEvents,
  fetchLoopVariable,
  setTestStatus,
  fetchLoopDiagnostics,
  prioritizeClearActiveVariables,
  updateAnalysisRange,
  updatePreset,
  fetchIssueTypes,
  clearChartSelections,
} from '../../../actions/index';
import CustomSelect from '../../filters/CustomSelect';
//import AcknowledgeContent from '../../widgetGroups/config/content/AcknowledgeContentPrioritize';
import { Settings, MoreVert, Visibility, VisibilityOff, Delete, AddLocation } from '@mui/icons-material';
import PrioritizeMultiStockChart from '../../charts/wrappers/PrioritizeMultiStockChart';
import PrioritizeIssuesWidget from '../../widgets/PrioritizeIssuesWidget';
import IssuesWidgets from './IssuesWidgets';
import ActionWidgets from './ActionWidgets';
import CustomDialog from '../../popup/CustomDialog';
//import { values } from 'lodash';
import DatePicker from '../../filters/DatePicker';
import ChecklistItemSelectable from '../../filters/ChecklistItemSelectable';
import PrintHeader from '../../headers/PrintHeader';
import AddEvent from '../config/content/AddEvent';
import AddShutdown from '../config/content/AddShutdown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import api from '../../../apis/api.js';

let issues = {};

class EventMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issuesCheck: [],
      eventMenu: false,
      eventCount: 0,
    };
  }
  componentDidMount() {
    issues = {};
    this.setState({ issuesCheck: [] });
  }
  render() {
    const { classes } = this.props;
    const handleClickEvent = (event) => {
      this.setState({
        eventMenu: !this.state.eventMenu,
        eventMenuAnchor: event.currentTarget,
      });
    };
    const handleClose = (event) => {
      this.setState({
        eventMenu: false,
        datasetDialog: false,
      });
      if (!!this.props.onClose) {
        this.props.onClose(event);
      }
    };
    const handleEvents = (eventType) => {
      //console.log(e.target.value);
      const eventName = eventType;
      if (!issues[eventName]) {
        let newEvent = issues;
        newEvent[eventName] = true;
        //console.log(newEvent);
        issues = newEvent;
        this.setState({ issuesCheck: newEvent });
        //this.setState({events:this.state.events[eventName]
      } else {
        let newEvent = issues;
        newEvent[eventName] = !newEvent[eventName];
        //console.log(newEvent);
        issues = newEvent;
        this.issuesCheck = newEvent;
        this.setState({ issuesCheck: newEvent });
      }
      //this.setState();
    };
    const eventList = !this.props.eventList ? [] : this.props.eventList;
    return (
      <React.Fragment>
        <Button variant='outlined' color='primary' aria-label='more' onClick={handleClickEvent}>
          <MoreVert className={classes.buttonIcon}></MoreVert>Show Issues
        </Button>
        <Popper open={this.state.eventMenu} onClose={handleClose} anchorEl={this.state.eventMenuAnchor} placement='bottom-start'>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper>
              {eventList.length === 0 ? (
                <Typography
                  variant='caption'
                  style={{
                    padding: '10px 14px 6px 14px',
                    display: 'block',
                  }}
                >
                  No events found for this loop
                </Typography>
              ) : (
                <Typography
                  variant='caption'
                  style={{
                    padding: '10px 14px 6px 14px',
                    display: 'block',
                  }}
                >
                  Select events to view
                </Typography>
              )}
              {eventList.map((eventType) => {
                return (
                  /*<MenuItem
                        // data-name={eventType}
                        key={`${eventType}_event_menu_item`}
                        /* onClick={(e) => {
                          const eventName = e.currentTarget.dataset.name;
                          if (!this.state.issues[eventName]) {
                            let newEvent = this.issues;
                            newEvent[eventName] = true;
                            //console.log(newEvent);
                            this.issues = newEvent;
                            this.setState({ issuesCheck: newEvent });
                            //this.setState({events:this.state.events[eventName]
                          } else {
                            let newEvent = this.issues;
                            newEvent[eventName] = !newEvent[eventName];
                            // console.log(newEvent);
                            this.issues = newEvent;
                            //this.issuesCheck = newEvent;
                            this.setState({ issuesCheck: newEvent });
                          }
                        }}
                      >*/
                  <FormControlLabel
                    key={`${eventType}_event_menu_item`}
                    className={classes.selectList}
                    control={
                      <Checkbox
                        checked={!this.state.issuesCheck[eventType] ? false : this.state.issuesCheck[eventType]}
                        color='secondary'
                        onClick={(e) => {
                          handleEvents(eventType);
                        }}
                        style={{ marginTop: -2 }}
                      />
                    }
                    label={eventType}
                  />
                  /*</MenuItem>*/
                );
              })}

              <Grid container justifyContent='space-between'>
                <Grid item></Grid>
                <Grid item>
                  <Button disableElevation color='secondary' aria-label='submit' onClick={handleClose} style={{ margin: 10, display: 'block' }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </React.Fragment>
    );
  }
}

class WorkbenchWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issues: [],
      issuesCheck: [],
      shutdowns: true,
      events: false,
      issuesChecked: false,
      issuesToggle: false,
      actionChecked: false,
      actionsToggle: false,
      selectMenu: false,
      eventMenu: false,
      eventManager: false,
      eventAdd: false,
      shutdownManager: false,
      shutdownAdd: false,
      shutdownDialogOpen: false,
      shutdownSwitch: true,
      datasetDialog: false,
    };
    this.enableShutdowns = this.enableShutdowns.bind(this);
    this.enableEvents = this.enableEvents.bind(this);
    this.toggleEventAdd = this.toggleEventAdd.bind(this);
    this.toggleEventManager = this.toggleEventManager.bind(this);
    this.toggleShutdownAdd = this.toggleShutdownAdd.bind(this);
    this.toggleShutdownManager = this.toggleShutdownManager.bind(this);
  }
  async componentDidMount() {
    this.props.prioritizeClearActiveVariables();
    //console.log(this.props.test_status);
    //TODO PULL OUT DIAGNOSTICS FOR RENDER ISSUES
    let preset = {
      name: functions.getCookie('presetName'),
      value: functions.getCookie('presetValue'),
    }; // = `preset=${value}`;
    if (preset.name !== '' && preset.value !== '') {
      this.setState({ changes: true });
      await this.props.updatePreset(preset);
      let analysisRangePreset = !Presets[preset.name] ? null : Presets[preset.name].filter.AnalysisRange;
      if (!analysisRangePreset) {
        this.props.fetchLoopDiagnostics(this.props.activeLoop, analysisRangePreset, this.props.dataset);
        this.props.setTestStatus('diagnostics', this.props.activeLoop, 'loading');
      } else {
        this.props.fetchLoopDiagnostics(this.props.activeLoop, null, this.props.dataset);
        this.props.setTestStatus('diagnostics', this.props.activeLoop, 'loading');
      }
      if (!this.props.issuetypes || !this.props.issuetypes.list) {
        console.log('t');
        this.props.fetchIssueTypes();
      }

      //this.multiSelectOnClose();
    } else {
      if (
        !!this.props.activeLoop &&
        (!this.props.test_status ||
          !this.props.test_status['diagnostics'] ||
          !this.props.test_status['diagnostics'][this.props.activeLoop] ||
          !(this.props.test_status['diagnostics'][this.props.activeLoop] === 'loading' || this.props.test_status['diagnostics'][this.props.activeLoop] === 'loaded'))
      ) {
        //console.log(this.props.activeLoop);
        this.props.fetchLoopDiagnostics(this.props.activeLoop, this.props.analysisRange, this.props.dataset);
        this.props.setTestStatus('diagnostics', this.props.activeLoop, 'loading');
      }
      /*for (let i = 0; i < this.props.activeVariables.length; i++) {
      const variableName = this.props.activeVariables[i]
        .toLowerCase()
        .replace(' ', '_');

      if (
        !!this.props.activeLoop &&
        (!this.props.test_status[variableName] ||
          !this.props.test_status[variableName][this.props.activeLoop] ||
          !(
            this.props.test_status[variableName][this.props.activeLoop] ===
              'loading' ||
            this.props.test_status[variableName][this.props.activeLoop] ===
              'loaded'
          ))
      ) {
        this.props.fetchLoopVariable(this.props.activeLoop, variableName);
        this.props.setTestStatus(
          variableName,
          this.props.activeLoop,
          'loading'
        );
      }
    }*/
    }
  }
  componentDidUpdate() {
    if (
      !!this.props.activeLoop &&
      !!this.props.loops &&
      (!this.props.test_status ||
        !this.props.test_status['diagnostics'] ||
        !this.props.test_status['diagnostics'][this.props.activeLoop] ||
        !(
          this.props.test_status['diagnostics'][this.props.activeLoop] === 'loading' ||
          (this.props.test_status['diagnostics'][this.props.activeLoop] === 'loaded' && typeof this.props.loops[this.props.activeLoop].diagnostics !== 'undefined')
        ))
    ) {
      this.props.fetchLoopDiagnostics(this.props.activeLoop, this.props.analysisRange, this.props.dataset);
      this.props.setTestStatus('diagnostics', this.props.activeLoop, 'loading');
    }
    /*for (let i = 0; i < this.props.activeVariables.length; i++) {
      const variableName = this.props.activeVariables[i]
        .toLowerCase()
        .replace(' ', '_');
      if (
        !this.props.test_status[variableName] ||
        !this.props.test_status[variableName][this.props.activeLoop] ||
        !(
          this.props.test_status[variableName][this.props.activeLoop] ===
            'loading' ||
          this.props.test_status[variableName][this.props.activeLoop] ===
            'loaded'
        )
      ) {
        this.props.fetchLoopVariable(this.props.activeLoop, variableName);
        this.props.setTestStatus(
          variableName,
          this.props.activeLoop,
          'loading'
        );
      }
    }*/
  }
  /*enableIssues() {
    this.setState({ issues: !this.state.issues });
  }*/
  enableShutdowns() {
    this.setState({ shutdowns: !this.state.shutdowns });
  }
  enableEvents() {
    this.setState({ events: !this.state.events });
  }
  toggleEventAdd() {
    this.setState({ eventAdd: !this.state.eventAdd, eventCount: this.props.chartSelections.length });
  }
  toggleEventManager() {
    this.setState({ eventManager: !this.state.eventManager });
  }
  toggleShutdownAdd() {
    this.setState({ shutdownAdd: !this.state.shutdownAdd });
  }
  toggleShutdownManager() {
    this.setState({ shutdownManager: !this.state.shutdownManager });
  }
  renderWidgets() {
    if (this.props.loops[this.props.activeLoop]) {
      return <React.Fragment></React.Fragment>;
    }
  }
  handleIssuesCheck = () => {
    this.setState({
      issuesChecked: !this.state.issuesChecked,
    });
  };
  handleActionCheck = () => {
    this.setState({
      actionChecked: !this.state.actionChecked,
    });
  };
  renderIssuesWidgets() {
    const { classes } = this.props;
    if (this.state.issuesToggle) {
      return (
        <div className={classes.pageBreak}>
          <IssuesWidgets toggledWidget></IssuesWidgets>
        </div>
      );
    }
  }
  renderActionWidgets() {
    const { classes } = this.props;
    if (this.state.actionsToggle) {
      return (
        <div className={classes.pageBreak}>
          <ActionWidgets toggledWidget></ActionWidgets>
        </div>
      );
    }
  }
  renderIssues(issuesArray) {
    const { classes } = this.props;
    const diagnostics =
      !this.props.loops || !this.props.loops[this.props.activeLoop] || !this.props.loops[this.props.activeLoop].diagnostics ? {} : this.props.loops[this.props.activeLoop].diagnostics;
    let issues = issuesArray.map((issueSet) => {
      return (
        <div key={issueSet.title} style={{ marginRight: 20 }}>
          <Typography variant='subtitle2' className={classes.issueTitle}>
            {issueSet.title}
          </Typography>
          <List style={{ lineHeight: '1 !important' }}>
            {issueSet.issues.map((issue) => {
              console.log(issue);
              const labelId = `checkbox-list-label-${issue.varTitle.replace(/\s/g, '')}`;
              let stateVal = -1;
              if (diagnostics[issue.issue] === null) {
                stateVal = -2;
              } else if (diagnostics[issue.issue] <= -10) {
                stateVal = -3;
              } else if (diagnostics[issue.issue] < 3) {
                stateVal = 0;
              } else if (diagnostics[issue.issue] < 6) {
                stateVal = 1;
              } else if (diagnostics[issue.issue] <= 10) {
                stateVal = 2;
              }
              return (
                <ChecklistItemSelectable
                  key={`key_${issue.varTitle.replace(/\s/g, '')}`}
                  onClick={(e) => {
                    const varName = issue.varTitle;
                    this.update(varName);
                  }}
                  issue={issue}
                  labelId={labelId}
                  state={stateVal}
                ></ChecklistItemSelectable>
              );
            })}
          </List>
        </div>
      );
    });
    return issues;
  }
  renderShutdownAdd() {
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.shutdownAdd}
        onClose={this.handleClose}
        title='Add Shutdown'
        titleIcon='AddLocation'
        content={<AddShutdown></AddShutdown>}
        actions={
          <React.Fragment>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button color='primary'>Submit</Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  renderShutdownManager() {
    const { classes } = this.props;
    const loop = this.props.loops[this.props.activeLoop];
    let shutdownsArray = !loop || !loop.shutdowns ? [] : loop.shutdowns;
    //console.log(shutdownsArray);
    let finalContent = shutdownsArray.map((shutdownId, index) => {
      let shutdownName = `Shutdown ${index} (TBD)`;
      let startDate = functions.dateToString(0 * 1000, 'date');
      let endDate = functions.dateToString(0 * 1000, 'date');
      return (
        <Accordion TransitionProps={{ unmountOnExit: true }} key={`EventManagementAccordian_${index}`} elevation={3} style={{ marginTop: 16 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Typography>{shutdownName}</Typography>
              </Grid>
              <Grid item>
                <Typography>Start: </Typography>
              </Grid>
              <Grid item style={{ marginRight: 16 }}>
                <Typography>End: </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <AddShutdown shutdownName={shutdownName} startDate={startDate} endDate={endDate} index={index}></AddShutdown>
            <Grid container justifyContent='space-between' style={{ marginTop: 16 }}>
              <Grid item></Grid>
              <Grid item>
                <Button variant='text' disableElevation aria-label='delete event' style={{ margin: '8px 8px 8px 0px' }}>
                  <Delete className={classes.buttonIcon}></Delete>
                  Delete Shutdown
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.shutdownManager}
        onClose={this.handleClose}
        title='Manage Shutdowns'
        titleIcon='OfflineBolt'
        content={finalContent}
        actions={
          <React.Fragment>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button color='primary'>Submit</Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  renderEventAdd() {
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.eventAdd}
        onClose={this.handleClose}
        title='Add Event'
        titleIcon='AddLocation'
        content={<AddEvent handleClose={this.handleClose} handleEventSubmit={this.handleEventSubmit} eventCount={this.state.eventCount} />}
        actions={null}

        /*actions={
          <React.Fragment>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={this.handleEventSubmit} color='primary'>
              Submit
            </Button>
          </React.Fragment>
        }*/
      ></CustomDialog>
    );
  }
  //!FIX THE MISMATCH ON INCIDENT TIMES AND EVENTIDS development2000366Volatility development2000366Volatility
  renderEventManager() {
    const { classes } = this.props;
    const loop = this.props.loops[this.props.activeLoop];
    let issueArray = !loop || !loop.issues ? [] : loop.issues; /*Object.keys(this.props.events).
    filter((key) => key.includes(this.props.activeLoop)).
    reduce((cur, key) => { return Object.assign(cur, { [key]: obj[key] })}, {});*/
    //console.log(this.props.events);
    //console.log(loop);
    let finalContent = issueArray.map((issueId, index) => {
      let issue = this.props.events[`${issueId}`];
      //console.log(issue);
      let eventArray = !this.props.events[issueId] || !this.props.events[issueId].event_id ? [] : issue.event_id;
      //console.log(issue);

      return eventArray.map((eventItem, index) => {
        /*if (index !== 0) {
          return null;
        }*/
        //console.log(issue.incidentTimes[index]);
        //!TEMPORARY PROTECTION FOR ABOVE BUG
        let event = issue.incidentTimes[index];
        if (!event) {
          return null;
        }
        let eventName = `Event ${index} (TBD)`;
        let eventIssue = issue.eventName;
        let startDate = functions.dateToString(issue.incidentTimes[index][0] * 1000, 'date');
        let endDate = functions.dateToString(issue.incidentTimes[index][1] * 1000, 'date');
        let deviceName = issue.incidentDeviceNames[index][0];
        return (
          <Accordion TransitionProps={{ unmountOnExit: true }} key={`EventManagementAccordian_${index}`} elevation={3} style={{ marginTop: 16 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Typography>{eventName}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{eventIssue}</Typography>
                </Grid>
                <Grid item>
                  <Typography>Start: {startDate}</Typography>
                </Grid>
                <Grid item style={{ marginRight: 16 }}>
                  <Typography>End: {endDate}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <AddEvent
                eventName={eventName}
                devices={{ deviceName: deviceName, devices: loop.devices, device_ids: loop.device_ids }}
                deviceName={deviceName}
                eventIssue={{ issue: eventIssue, list: this.props.issuetypes.list }}
                eventCount={this.state.eventCount}
                eventType={'test'}
                startDate={startDate}
                endDate={endDate}
                index={index}
                handleClose={this.handleClose}
                handleEventSubmit={this.handleEventSubmit}
              ></AddEvent>
              <Grid container justifyContent='space-between' style={{ marginTop: 16 }}>
                <Grid item></Grid>
                <Grid item>
                  <Button
                    variant='text'
                    disableElevation
                    onClick={() => {
                      console.log('clicked');
                    }}
                    aria-label='delete event'
                    style={{ margin: '8px 8px 8px 0px' }}
                  >
                    <Delete className={classes.buttonIcon}></Delete>
                    Delete Event
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      });
    });
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.eventManager}
        onClose={this.handleClose}
        title='Manage Events'
        titleIcon='DateRange'
        content={finalContent}
        actions={
          <React.Fragment>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={this.handleEventSubmit} color='primary'>
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  renderDatasetDialog() {
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.datasetDialog}
        onClose={this.handleClose}
        title='Change Dataset'
        titleIcon='Folder'
        content={
          <div style={{ marginTop: 16 }}>
            <CustomSelect
              single
              id={null}
              label='Dataset'
              type='dataset'
              disabled={this.state.loading}
              onChangeFunction={this.updateDataset}
              //values={!this.props.sort.sort ? 'Date (Recent)' : this.props.sort.sort}
              //options={['Date (Recent)', 'Resolution (Highest)', 'Sensitivity (Highest)', 'Stroke (Highest)', 'Importance']}
              helperText={null}
              width={190}
            />
          </div>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.toggleDatasetDialog} color='primary'>
              Cancel
            </Button>
            <Button onClick={this.toggleDatasetDialog} color='primary'>
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  renderShutdownDialog() {
    //const { classes } = this.props;
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.shutdownDialogOpen}
        onClose={this.handleClose}
        title='Edit Shutdowns'
        titleIcon='Edit'
        content={
          <React.Fragment>
            <Grid container style={{ marginTop: 12, marginLeft: -8, marginBottom: 12 }}>
              <Grid item>
                <Switch
                  defaultChecked={this.state.shutdownSwitch}
                  onChange={(e) =>
                    this.setState({
                      shutdownSwitch: !this.state.shutdownSwitch,
                    })
                  }
                  color='secondary'
                />
              </Grid>
              <Grid item>
                <Typography variant='varTitle1' style={{ paddingTop: 6 }}>
                  {!this.state.shutdownSwitch ? 'Remove' : 'Add'} Shutdown
                </Typography>
              </Grid>
            </Grid>
            <DatePicker
              label='Start Date'
              /*disableFuture
              maxDate={new Date(this.props.sort.filter.Range.endDate * 1000)}         
              disabled={this.state.loading}
              value={new Date(this.props.sort.filter.Range.startDate * 1000)}
              date={new Date(this.props.sort.filter.Range.startDate * 1000)}
              dateFunction={(startDate) => {
                this.updateDateRange(startDate, 'start');
              }}*/
            ></DatePicker>
            {/*} <TimePicker value={value} onChange={setValue} renderInput={(params) => <TextField {...params} />} />*/}
            <DatePicker
              label='End Date'
              /*minDate={new Date(this.props.sort.filter.Range.startDate * 1000)}             
              value={new Date(this.props.sort.filter.Range.endDate * 1000)}
              disabled={this.state.loading}
              dateFunction={(endDate) => {
                this.updateDateRange(endDate, 'end');
              }}*/
            ></DatePicker>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={this.handleShutdownSubmit} color='primary'>
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  handleEventDialogOpen = () => {
    this.setState({ eventDialogOpen: true });
  };
  handleShutdownDialogOpen = () => {
    this.setState({ shutdownDialogOpen: true });
  };
  handleClose = () => {
    this.setState({
      eventAdd: false,
      eventManager: false,
      shutdownAdd: false,
      shutdownManager: false,
    });
  };
  handleEventSubmit = async (events) => {
    //console.log('hi');
    try {
      const url = `prioritize/events/add`;
      const data = events;
      let response = await api.post(url, data);
      if (response.status === 200) {
        return false;
        //this.handleClose();
        //return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
    //console.log(events);
    //console.log(this.state);
    //this.handleClose();
  };
  toggleDatasetDialog = () => {
    this.setState({
      datasetDialog: !this.state.datasetDialog,
    });
  };
  render() {
    const eventList = [];
    const eventKeys = Object.keys(this.props.events);
    const loop = this.props.loops[this.props.activeLoop];
    for (let i = 0; i < eventKeys.length; i++) {
      let eventName = this.props.events[eventKeys[i]].eventName;
      if (!eventList.includes(eventName)) {
        eventList.push(eventName);
      }
    }
    const { classes } = this.props;
    const handleCloseEvent = () => {
      this.setState({ issues: issues });
    };

    const filterIssues = (issues) => {
      return Object.keys(issues).filter(function (k) {
        return issues[k];
      });
    };
    const handleClick = (event) => {
      this.setState({
        selectMenu: !this.state.selectMenu,
        selectMenuAnchor: event.currentTarget,
      });
    };
    const handleClose = () => {
      this.setState({ selectMenu: false });
    };
    const handleSubmit = () => {
      this.setState({ selectMenu: false, actionsToggle: this.state.actionChecked, issuesToggle: this.state.issuesChecked });
    };
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        {this.renderShutdownAdd()}
        {this.renderShutdownManager()}
        {this.renderEventAdd()}
        {this.renderEventManager()}
        {this.renderDatasetDialog()}
        {!!this.props.toggledWidget ? <PrintHeader></PrintHeader> : null}
        <Grid container justifyContent='space-between' style={{ marginBottom: -42 }} className={classes.printHide}>
          <Grid item></Grid>
          <Grid item>
            <Grid container spacing={1}>
              {/* TODO Prio_1.0
              <Grid item>
                <Tooltip arrow placement='top' title='Change Dataset' aria-label='Change Dataset'>
                  <IconButton color='inherit' aria-label='more' onClick={this.toggleDatasetDialog} size='large'>
                    <Folder />
                  </IconButton>
                </Tooltip>
              </Grid>*/}
              {!printStyles ? (
                <Grid item>
                  <InfoPopover
                    center
                    tooltip='Configure'
                    title='Configure'
                    titleIcon='Settings'
                    resize
                    style={{ marginBottom: -15 }}
                    icon={<Settings />}
                    type='prioritizeConfig'
                    usePrioritizeConfigTabs
                    loop={loop}
                    devices={['1', '2', '3']}
                  />
                </Grid>
              ) : null}
              <Grid item>
                <Tooltip arrow placement='top' title='Show More' aria-label='Show More'>
                  <IconButton color='inherit' aria-label='more' onClick={handleClick} style={{ marginLeft: -10 }} size='large'>
                    <MoreVert />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Popper open={this.state.selectMenu} onClose={handleClose} anchorEl={this.state.selectMenuAnchor} placement='bottom-start'>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper>
              <Typography variant='caption' style={{ padding: '10px 12px 6px 14px', display: 'block' }}>
                Select to show more info
              </Typography>
              <MenuItem onClick={this.handleIssuesCheck}>
                <Checkbox className={classes.menuCheckbox} checked={this.state.issuesChecked} color='secondary' />
                Address Issues
              </MenuItem>
              <MenuItem onClick={this.handleActionCheck}>
                <Checkbox className={classes.menuCheckbox} checked={this.state.actionChecked} color='secondary' />
                Action Items
              </MenuItem>
              <Grid container justifyContent='space-between'>
                <Grid item></Grid>
                <Grid item>
                  <Button variant='outlined' disableElevation size='small' color='primary' aria-label='submit' onClick={handleSubmit} style={{ margin: 10, display: 'block' }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popper>
        <WidgetTitle className={classes.printHide} title={`Workbench - ${!loop || !loop.processName ? 'Loop Name Not Found' : loop.processName}`} />
        <Grid container spacing={1} style={{ marginBottom: 0 }} className={classes.tileMarginFix}>
          <PrioritizeTile loop={this.props.loops[this.props.activeLoop]} hasBorder expanded title='Loop Details' />
        </Grid>
        <Grid container justifyContent='space-between' className={classes.printHide}>
          <Grid item sm={12} md={12} lg={printStyles ? 4 : 3} xs={12}>
            <Grid container>
              <Grid item style={{ marginRight: 16 }}>
                <span className={classes.buttonWrapper}>
                  <CustomSelect
                    id={null}
                    label='Analysis Range'
                    single
                    //prefix
                    inline
                    variant='outlined'
                    //onCloseFunction={this.multiSelectOnClose}
                    onChangeFunction={(event, value, type) => {
                      this.props.fetchLoopDiagnostics(this.props.activeLoop, value, this.props.dataset);
                      this.props.updateAnalysisRange(value /*.split(' ')[0]*/);
                    }}
                    prefix='Analysis Range: '
                    suffix=' Days'
                    options={['7', '14', '30', '90', '180', '365']}
                    values={!this.props.analysisRange ? '90' : this.props.analysisRange.toString()}
                    //selectedOption={'2 - Subsystem Shutdown or Sustained KPI Loss'}
                    helperText={null}
                  />
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} lg={printStyles ? 8 : 9} xs={12}>
            <Grid container justifyContent='space-between'>
              <Grid item style={printStyles ? { display: 'none' } : null}>
                <Grid container style={{ paddingTop: 16, paddingBottom: 4 }}>
                  <Grid item>
                    <Typography variant='subtitle2'>
                      {!this.props.chartSelections || this.props.chartSelections.length < 1 ? 'No Selection - click "Select" on the chart menu to begin selecting time ranges' : 'Selected:'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {this.props.chartSelections.map((selection, index) => {
                      return (
                        <Grid item key={`Selection_${index}`}>
                          <Grid container>
                            <Grid item>
                              {/*<Typography variant='body2' style={{ margin: '0px 2px 0px 24px' }}>
                                {index + 1}:
                      </Typography>*/}
                            </Grid>
                            <Grid item>
                              <Typography variant='body2' style={{ margin: '2px 8px 0px 24px', width: 180 }}>
                                Start: {functions.dateToString(new Date(selection[0]))}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant='body2' style={{ margin: '2px 8px 0px 16px', width: 180 }}>
                                End {functions.dateToString(new Date(selection[1]))}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid item>
                    {!this.props.chartSelections || this.props.chartSelections.length < 1 ? null : (
                      <Button variant='text' onClick={this.props.clearChartSelections} size='small' style={{ marginTop: -4 }}>
                        Clear Selection
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item></Grid>
              <Grid item>
                <span className={classes.buttonWrapper}>
                  <Button variant='outlined' color='primary' className={classes.buttonFilter} onClick={this.toggleEventAdd}>
                    <AddLocation className={classes.buttonIcon}></AddLocation>Add Event
                  </Button>
                  {/*TODO Prio_1.0
                  <Button variant='outlined' color='primary' className={classes.buttonFilter} onClick={this.toggleEventManager}>
                    <DateRange className={classes.buttonIcon}></DateRange>Manage Events
                  </Button>
                  <Button variant='outlined' color='primary' className={classes.buttonFilter} onClick={this.enableEvents}>
                    {this.state.events ? (
                      <React.Fragment>
                        <VisibilityOff className={classes.buttonIcon}></VisibilityOff>Hide Events
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Visibility className={classes.buttonIcon}></Visibility>Show Events
                      </React.Fragment>
                    )}
                  </Button>
                  <Button variant='outlined' color='primary' className={classes.buttonFilter} onClick={this.toggleShutdownManager}>
                    <OfflineBolt className={classes.buttonIcon}></OfflineBolt>Manage Shutdowns
                  </Button>
                  <Button variant='outlined' color='primary' className={classes.buttonFilter} onClick={this.toggleShutdownAdd}>
                    <AddLocation className={classes.buttonIcon}></AddLocation>Add Shutdown
                  </Button>
                  */}
                  <Button variant='outlined' color='primary' className={classes.buttonFilter} onClick={this.enableShutdowns}>
                    {this.state.shutdowns ? (
                      <React.Fragment>
                        <VisibilityOff className={classes.buttonIcon}></VisibilityOff>Hide Shutdowns
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Visibility className={classes.buttonIcon}></Visibility>Show Shutdowns
                      </React.Fragment>
                    )}
                  </Button>
                  <EventMenu
                    //open={this.state.eventMenu}
                    onClose={handleCloseEvent}
                    //anchorEl={this.state.eventMenuAnchor}
                    eventList={eventList}
                    classes={this.props.classes}
                  />
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.widgetPrintMargin}>
          <Grid item sm={printStyles ? 12 : 3} md={printStyles ? 12 : 3} lg={printStyles ? 12 : 3} xs={12} className={printStyles ? classes.printWidgetSpacing : classes.widgetSpacing}>
            <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
              <PrioritizeIssuesWidget loop={null} />
            </Paper>
          </Grid>
          <Grid item sm={printStyles ? 12 : 9} md={printStyles ? 12 : 9} lg={printStyles ? 12 : 9} xs={12}>
            <Grid item xs={12}>
              <PrioritizeMultiStockChart
                // issueFlag={false}
                issueFilter={filterIssues(this.state.issues)}
                shutdownFlag={this.state.shutdowns}
              />
            </Grid>
          </Grid>
        </Grid>
        {this.renderIssuesWidgets()}
        {this.renderActionWidgets()}
        {!this.props.toggledWidget ? (
          <Grid className={classes.printHide}>
            <Grid container spacing={1}>
              <Grid item>
                <Tooltip arrow placement='top' title='Show More' aria-label='Show More'>
                  <Button variant='outlined' size='medium' color='primary' aria-label='more' onClick={handleClick} style={{ marginTop: 4 }}>
                    <MoreVert className={classes.buttonIcon}></MoreVert>Show More
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}
WorkbenchWidgets.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeLoop: state.prioritize.activeLoop,
    //activeVariables: state.prioritize.activeVariables,
    events: state.events,
    format: state.format.format,
    loops: state.loops.loops,
    //devices: state.devices.devices,
    //tests: state.tests.tests,
    test_status: state.tests.status,
    analysisRange: state.sort.filter.AnalysisRange,
    dataset: state.sort.filter.Dataset,
    issuetypes: state.issuetypes,
    chartSelections: state.chartSelections.selections,
  };
};

export default connect(mapStateToProps, {
  fetchLoop,
  fetchLoopEvents,
  fetchLoopDevices,
  fetchLoopVariable,
  setTestStatus,
  fetchLoopDiagnostics,
  prioritizeClearActiveVariables,
  updateAnalysisRange,
  updatePreset,
  fetchIssueTypes,
  clearChartSelections,
})(withStyles(styles)(WorkbenchWidgets));
