import 'date-fns';
import React from 'react';
//import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from '../styles';
import functions from '../../functions/functions';

const useStyles = makeStyles(styles);

//TODO Fix how data is exchanged with this component so that we aren't redrawing this component when we pass data down and losing the focus mid-typing of a date
export default function MaterialUIPickers(props) {
  // The first commit of Material-UI
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(!!props.date ? props.date : new Date());
  const [helperText, setHelperText] = React.useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date); //state stores the input part of the date so as to avoid submitting invalid dates to redux
    if (!!props.dateFunction && functions.isValidDate(date)) {
      props.dateFunction(date);
    }
    //error validation
    const maxCheck = functions.isValidDate(date) && !props.maxDate ? true : date <= props.maxDate;
    const minCheck = functions.isValidDate(date) && !props.minDate ? true : date >= props.minDate;

    //TODO improve clarity of date errors
    if (!functions.isValidDate(date)) {
      setHelperText('Please Set Valid Date');
      /*} else if (date < props.minDate) {
      setHelperText(`Date before Start Date.`);*/
    } else if (!minCheck) {
      setHelperText(`Minimum Date Exceeded.`);
      /*} else if (date > props.maxDate) {
      setHelperText(`Start Date after End Date.`);*/
    } else if (!maxCheck) {
      setHelperText(`Maximum Date Exceeded.`);
    } else {
      setHelperText('');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        disableFuture={!props.disableFuture ? false : props.disableFuture}
        disabled={props.disabled}
        //disableOpenPicker={true}
        label={props.label}
        maxDate={!props.maxDate ? null : props.maxDate}
        minDate={!props.minDate ? null : props.minDate}
        //views={['year', 'month', 'day']}
        value={!props.value ? selectedDate : props.value}
        onChange={handleDateChange}
        /*onError={(reason, value) => {
          console.log(reason, value);
        }}*/
        slotProps={{
          textField: {
            helperText: helperText,
            className: classes.filter,
          },
        }}
        //slotProps={{ textField: { variant: 'outlined' } }}
        /*renderInput={(params) => {
          let paramsLocal = params;
          paramsLocal.error = helperText !== '';
          console.log(helperText);

          //NECESSARY TO USE PROPS.VALUE TO DRIVE IN THE EVENT OF REMOTE UPDATE DNU
          //paramsLocal.inputProps.value = !selectedDate
          //  ? paramsLocal.inputProps.value
          //  : functions.dateToString(selectedDate , 'date'); //props.value
          return <TextField variant='standard' className={classes.filter} helperText={helperText} {...paramsLocal} />;
        }}*/
      />
    </LocalizationProvider>
  );
}
