import React from 'react';
//import { connect } from 'react-redux';
import { Typography, Grid, Paper, Tooltip, IconButton, CircularProgress } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import WidgetTitle from '../../../headers/WidgetTitle';
import TableWidget from '../../../widgets/TableWidget';
import Chart from '../../../charts/Chart';
import { connect } from 'react-redux';
import { fetchDevicesSummary, setDevicesSummaryStatus, setComparatorReport, fetchDevices, fetchDeviceSpecs, fetchDiagnostics, setTestStatus } from '../../../../actions/index';
import matchFilter from '../../../filters/functions/searchFilter';
import initializeTheme from '../../../theme';
import functions from '../../../../functions/functions';
require('string_score'); // USE REQUIRE TO AVOID 'no use of declared item'. // TODO replace with better search package

class PerformanceReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      valveId: -1,
      resHeight: 300,
      senHeight: 300,
      strokeHeight: 300,
      resWidth: 6,
      senWidth: 6,
      strokeWidth: 12,
      resZoom: false,
      senZoom: false,
      strokeZoom: false,
      loading: false,
    };
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchSelect = this.onSearchSelect.bind(this);
  }
  componentDidMount() {
    //console.log(this.props.currentModule);
    //console.log(this.props.devices.summary);
    //console.log(this.props.configModule);
    if (
      (this.props.currentModule === 'Optimize' || this.props.reportModule === 'Optimize' || this.props.configModule === 'Optimize' || this.props.currentModule === null) &&
      !this.props.devices.summary
    ) {
      this.props.setDevicesSummaryStatus(true);
      this.props.fetchDevicesSummary('optimize');
    }
  }

  isUndefined(value) {
    return typeof value === 'undefined';
  }
  onSearchChange = (e, value) => {
    this.setState({ value: value });
  };
  onSearchSelect = async (e, value) => {
    const deviceId = !value ? null : value.deviceId;
    if (!deviceId) {
      //do nothing
    } else if (
      this.isUndefined(this.props.devices[deviceId]) ||
      this.isUndefined(this.props.devices[deviceId].resolutionAppHistory) ||
      this.isUndefined(this.props.devices[deviceId].sensitivityAppHistory) ||
      this.isUndefined(this.props.devices[deviceId].strokeAppHistory) //||  // TODO FIX SPECBOOL CHECK
      //!this.props.devices[deviceId].specBool
    ) {
      this.setState({ loading: true });
      await this.props.fetchDevices(deviceId); //.then(this.getDeviceTest(deviceId));
      this.setState({ valveId: deviceId });
      this.setState({ loading: false });
      //this.props.fetchDeviceSpecs(deviceId);
    } else if (this.state.valveId !== deviceId) {
      this.setState({ valveId: deviceId });
    }
  };
  //TODO IMPROVE FUZZY SEARCH https://github.com/kentcdodds/match-sorter
  matchFilter(options) {
    let values = this.state.value.split(' ');
    // Remove "" values from array as result of hitting space for a new search term
    const index = values.indexOf('');
    if (index > -1) {
      values.splice(index, 1);
    }
    // search threshold
    const threshold = 0.2; //Math.min(0.1 + 0.1 * this.state.value.length, 0.5);
    let finalOptions = [];
    //search all key->value pairs in the option for value matches based on each search term, if higher than the threshold, pass it.
    for (let a = 0; a < options.length; a++) {
      let option = options[a];
      let keys = Object.keys(option);
      let pass = 0;
      for (let i = 0; i < keys.length; i++) {
        for (let j = 0; j < values.length; j++) {
          if (
            //toString in case the key is a number
            option[keys[i]] !== null &&
            (option[keys[i]].toString().score(values[j]) > threshold || values[j] === '')
          ) {
            pass++;
          }
        }
      }
      // if the number of passed key/value pairs is >= number of search terms, return
      if (pass >= values.length) {
        finalOptions.push(option);
      }
    }
    return finalOptions;
  }
  convertUnixtimeToDate = (timestamp) => {
    let date = new Date(timestamp * 1000);
    // Hours part from the timestamp
    let months = date.getMonth() + 1;
    // Minutes part from the timestamp
    let days = date.getDate();
    // Seconds part from the timestamp
    let years = date.getFullYear();

    // Will display time in 10:30:23 format
    let formattedDate = months + '/' + days + '/' + years;

    return formattedDate;
  };
  getHistoryTable = (valveId) => {
    const { classes } = this.props;
    const device = this.props.devices[valveId];
    if (!device.datetimeHistory) {
      return [];
    }
    let tableArr = [];
    for (let i = 0; i < device.datetimeHistory.length; i++) {
      //subheader
      tableArr.push({
        id: 'name' + i,
        type: (
          <Typography variant='subtitle2' className={classes.blueText}>
            {device.name} - {this.convertUnixtimeToDate(device.datetimeHistory[i])}
          </Typography>
        ),
        targetFormatted: '',
        resultFormatted: '',
        target: '',
        result: '',
        differenceFormatted: '',
        difference: '',
        fromPrevFormatted: '',
        fromPrev: '',
      });
      //Resolution
      tableArr.push({
        id: 'resolution' + i,
        type: `Resolution`,
        targetFormatted: device.resolutionAppHistory[i] === 'NULL' ? '-' : `${Math.round(device.resolutionAppHistory[i] * 100) / 100}%`,
        target: Math.round(device.resolutionAppHistory[i] * 100) / 100,
        resultFormatted: device.resolutionResultHistory[i] === 'NULL' ? '-' : `${Math.round(device.resolutionResultHistory[i] * 100) / 100}%`,
        result: Math.round(device.resolutionResultHistory[i] * 100) / 100,
        differenceFormatted: (
          <Typography
            variant='subtitle2'
            className={
              Math.round(device.resolutionResultHistory[i] * 100) / 100 > Math.round(device.resolutionAppHistory[i] * 100) / 100
                ? classes.redText
                : Math.round(device.resolutionResultHistory[i] * 100) / 100 < Math.round(device.resolutionAppHistory[i] * 100) / 100
                ? classes.greenText
                : null
            }
          >
            {device.resolutionAppHistory[i] === 'NULL' || device.resolutionResultHistory[i] === 'NULL'
              ? '-'
              : `${Math.abs(Math.round((device.resolutionResultHistory[i] - device.resolutionAppHistory[i]) * 100) / 100)}%`}
          </Typography>
        ),
        difference: Math.abs(Math.round(device.resolutionResultHistory[i] - device.resolutionAppHistory[i])),
        fromPrevFormatted: (
          <Typography
            variant='subtitle2'
            className={
              Math.round((device.resolutionResultHistory[i] - device.resolutionResultHistory[i - 1]) * 100) / 100 > 0
                ? classes.redText
                : Math.round((device.resolutionResultHistory[i] - device.resolutionResultHistory[i - 1]) * 100) / 100 < 0
                ? classes.greenText
                : null
            }
          >
            {i !== 0 && device.resolutionResultHistory[i] !== 'NULL' && device.resolutionResultHistory[i - 1] !== 'NULL'
              ? `${Math.abs(Math.round((device.resolutionResultHistory[i] - device.resolutionResultHistory[i - 1]) * 100) / 100)}%`
              : '-'}
          </Typography>
        ),
        fromPrev: Math.abs(Math.round(device.resolutionResultHistory[i] - device.resolutionResultHistory[i - 1])),
      });
      //Sensitivity
      tableArr.push({
        id: 'sensitivity' + i,
        type: `Sensitivity`,
        targetFormatted: device.sensitivityAppHistory[i] === 'NULL' ? '' : `${Math.round(device.sensitivityAppHistory[i] * 100) / 100}%`,
        resultFormatted: device.sensitivityResultHistory[i] === 'NULL' ? '' : `${Math.round(device.sensitivityResultHistory[i] * 100) / 100}%`,
        target: Math.round(device.sensitivityAppHistory[i] * 100) / 100,
        result: Math.round(device.sensitivityResultHistory[i] * 100) / 100,
        differenceFormatted: (
          <Typography
            variant='subtitle2'
            className={
              Math.round(device.sensitivityResultHistory[i] * 100) / 100 > Math.round(device.sensitivityAppHistory[i] * 100) / 100
                ? classes.redText
                : Math.round(device.sensitivityResultHistory[i] * 100) / 100 < Math.round(device.sensitivityAppHistory[i] * 100) / 100
                ? classes.greenText
                : null
            }
          >
            {device.sensitivityAppHistory[i] === 'NULL' || device.sensitivityResultHistory[i] === 'NULL'
              ? ''
              : `${Math.abs(Math.round((device.sensitivityResultHistory[i] - device.sensitivityAppHistory[i]) * 100) / 100)}%`}
          </Typography>
        ),
        difference: Math.abs(Math.round((device.strokeResultHistory[i] - device.strokeAppHistory[i]) * 100) / 100),
        fromPrevFormatted: (
          <Typography
            variant='subtitle2'
            className={
              Math.round((device.sensitivityResultHistory[i] - device.sensitivityResultHistory[i - 1]) * 100) / 100 > 0
                ? classes.redText
                : Math.round((device.sensitivityResultHistory[i] - device.sensitivityResultHistory[i - 1]) * 100) / 100 < 0
                ? classes.greenText
                : null
            }
          >
            {i !== 0 && device.sensitivityResultHistory[i] !== 'NULL' && device.sensitivityResultHistory[i - 1] !== 'NULL'
              ? `${Math.abs(Math.round((device.sensitivityResultHistory[i] - device.sensitivityResultHistory[i - 1]) * 100) / 100)}%`
              : ''}
          </Typography>
        ),
        fromPrev: Math.abs(Math.round((device.sensitivityResultHistory[i] - device.sensitivityResultHistory[i - 1]) * 100) / 100),
      });
      //Stroke
      tableArr.push({
        id: 'stroke' + i,
        type: `Stroke`,
        targetFormatted: device.strokeAppHistory[i] === 'NULL' ? '' : `${Math.round(device.strokeAppHistory[i] * 100) / 100}s`,
        resultFormatted: device.strokeResultHistory[i] === 'NULL' ? '' : `${Math.round(device.strokeResultHistory[i] * 100) / 100}s`,
        target: (Math.round(device.strokeAppHistory[i] * 100) / 100) * 10,
        result: (Math.round(device.strokeResultHistory[i] * 100) / 100) * 10,
        differenceFormatted: (
          <Typography
            variant='subtitle2'
            className={
              Math.round(device.strokeResultHistory[i] * 100) / 100 > Math.round(device.strokeAppHistory[i] * 100) / 100
                ? classes.redText
                : Math.round(device.strokeResultHistory[i] * 100) / 100 < Math.round(device.strokeAppHistory[i] * 100) / 100
                ? classes.greenText
                : null
            }
          >
            {device.strokeAppHistory[i] === 'NULL' || device.strokeResultHistory[i] === 'NULL'
              ? ''
              : `${Math.abs(Math.round((device.strokeResultHistory[i] - device.strokeAppHistory[i]) * 100) / 100)}s`}
          </Typography>
        ),
        difference: Math.abs(Math.round(device.strokeResultHistory[i] - device.strokeAppHistory[i])) * 10,
        fromPrevFormatted: (
          <Typography
            variant='subtitle2'
            className={
              Math.round((device.strokeResultHistory[i] - device.strokeResultHistory[i - 1]) * 100) / 100 > 0
                ? classes.redText
                : Math.round((device.strokeResultHistory[i] - device.strokeResultHistory[i - 1]) * 100) / 100 < 0
                ? classes.greenText
                : null
            }
          >
            {i !== 0 && device.strokeResultHistory[i] !== 'NULL' && device.strokeResultHistory[i - 1] !== 'NULL'
              ? `${Math.abs(Math.round((device.strokeResultHistory[i] - device.strokeResultHistory[i - 1]) * 100) / 100)}s`
              : ''}
          </Typography>
        ),
        fromPrev: Math.abs(Math.round(device.strokeResultHistory[i] - device.strokeResultHistory[i - 1])) * 10,
      });
    }
    return tableArr;
  };
  getOptions = () => {
    let options = [];
    // console.log(this.props.devices);
    const deviceKeys = Object.keys(this.props.devices);
    options = deviceKeys.map((key) => {
      const device = this.props.devices[key];
      //console.log(device);
      //console.log(device.datetimeHistory);
      return {
        serial: device.serial,
        name: device.name,
        device: device.site,
        application: device.application,
        deviceId: device.deviceId,
      };
    });

    return options;
  };

  convertStringArrayToNumber(stringArray) {
    if (!stringArray) return []; //check for sending undefineds
    let numArray = [];
    for (let i = 0; i < stringArray.length; i++) {
      const value = stringArray[i].toLowerCase() === 'null' ? null : parseFloat(stringArray[i]);
      numArray.push(value);
    }
    return numArray;
  }

  getPerformanceChartData(value, target, time, state, title) {
    let series = [];
    let valueData = [];
    let targetData = [];
    let areaHighData = [];
    let areaLowData = [];
    let device = this.props.devices[this.state.valveId];
    let valueArr = this.convertStringArrayToNumber(device[value]);
    let targetArr = this.convertStringArrayToNumber(device[target]);
    let timeArr = this.convertStringArrayToNumber(device[time]);
    let stateArr = this.convertStringArrayToNumber(device[state]);
    //console.log(stateArr);
    if (timeArr.length === 0) {
      return null;
    }
    for (let i = 0; i < timeArr.length; i++) {
      if (timeArr[i] !== null) {
        let timePoint = timeArr[i];
        let valuePoint = !valueArr[i] ? null : valueArr[i]; //hedge against uneven series
        let targetPoint = !targetArr[i] ? null : targetArr[i]; //hedge against uneven series
        let symbolPoint = this.getSymbolFromState(stateArr[i]); //hedge in function (default symbol)
        let symbolColor = valuePoint > targetPoint ? 'rgb(211, 0, 27)' : 'rgb(48, 141, 59)'; //this.getColorFromState(stateArr[i]);
        let symbolRadius = this.getRadiusFromState(stateArr[i]);
        valueData.push({
          x: timePoint * 1000,
          y: valuePoint,
          marker: {
            enabled: !!symbolPoint,
            symbol: symbolPoint,
            radius: symbolRadius,
            fillColor: symbolColor,
            lineWidth: 1.5,
            lineColor: this.props.colorMode === 'dark' ? '#0f0f0f' : '#fcfcfc',
          },
        });
        targetData.push([timePoint * 1000, targetPoint]);
        areaHighData.push([timePoint * 1000, valuePoint, targetPoint]);
        areaLowData.push([timePoint * 1000, targetPoint, valuePoint]);
      }
    }
    series.push({
      name: 'Target',
      dashStyle: 'Dash',
      data: targetData,
      color: this.props.colorMode === 'dark' ? '#eee' : '#464749',
      marker: {
        radius: 3,
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
    });
    series.push({
      name: title,
      data: valueData,
      color: '#009CDC',
      lineWidth: 3,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    });
    /*series.push({
      name: `${title} Error`,
      enableMouseTracking: false,
      marker: { enabled: false },
      type: 'arearange',
      data: areaHighData,
      showInLegend: false,
      zones: !targetData[targetData.length - 1]
        ? null
        : [
            {
              color: '#56B260',
              value: targetData[targetData.length - 1][1],
            },
            {
              color: '#E89556',
            },
          ],
      //color: 'rgba(201,116,25,.4)',
      fillOpacity: 0.2,
      lineWidth: 0,
      zIndex: -1,
    });*/
    series.push({
      name: 'As Found',
      color: '#009CDC',
      lineWidth: 0,
      marker: { symbol: 'square' },
      events: {
        legendItemClick: function () {
          return false;
        },
      },
    });
    series.push({
      name: 'Intermediate',
      color: '#7AD8FF',
      lineWidth: 0,
      marker: { symbol: 'circle' },
      events: {
        legendItemClick: function () {
          return false;
        },
      },
    });
    series.push({
      name: 'As Left',
      color: '#AD70E2',
      lineWidth: 0,
      events: {
        legendItemClick: function () {
          return false;
        },
      },
    });
    return series;
  }

  getSymbolFromState(state) {
    let symbol = null;
    switch (state) {
      case 1: {
        symbol = 'square'; //as found
        break;
      }

      case 2: {
        symbol = 'cirlce'; //indeterminate
        break;
      }
      case 3: {
        symbol = 'triangle'; //as left
        break;
      }
      default:
        break;
    }
    return symbol;
  }
  getColorFromState(state) {
    let color = null;
    switch (state) {
      case 1: {
        color = '#009CDC'; //as found
        break;
      }
      case 2: {
        color = '#7AD8FF'; //indeterminate
        break;
      }
      case 3: {
        color = '#AD70E2'; //as left
        break;
      }
      default:
        break;
    }
    return color;
  }
  getRadiusFromState(state) {
    let radius = null;
    switch (state) {
      case 1: {
        radius = 6; //as found
        break;
      }
      case 2: {
        radius = 6; //indeterminate
        break;
      }
      case 3: {
        radius = 7; //as left
        break;
      }
      default:
        break;
    }
    return radius;
  }
  renderHistory() {
    const { classes } = this.props;
    let device = this.props.devices[this.state.valveId];
    if (!device) return {}; //check for undefineds
    const theme = initializeTheme(this.props.colorMode);
    const resolutionData = this.getPerformanceChartData(`resolutionResultHistory`, `resolutionAppHistory`, `resolutionDatetimeHistory`, `resolutionStateHistory`, `Resolution`);
    const resolutionChartObj = {
      chart: {
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        height: this.state.resHeight,
        marginLeft: 40,
        marginTop: 40,
      },
      credits: { enabled: false },
      legend: {
        verticalAlign: 'top',
        align: 'left',
        itemMarginBottom: 2,
        itemDistance: 10,
        maxHeight: 60,
        padding: 5,
        width: '100%',
        alignColumns: false,
        style: {
          '@media print': {
            fontSize: 10,
          },
        },
        y: -10,
        itemStyle: {
          color: theme.palette.text.primary,
          fontWeight: 500,
        },
        itemHoverStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
        },
      },
      title: { text: '' },
      tooltip: {
        backgroundColor: this.props.colorMode === 'dark' ? '#333' : '#fff',
        style: {
          color: theme.palette.text.primary,
        },
        shared: true,
        valueSuffix: ' %',
        valueDecimals: 2,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      series: resolutionData,
    };
    const sensitivityData = this.getPerformanceChartData(`sensitivityResultHistory`, `sensitivityAppHistory`, `sensitivityDatetimeHistory`, `sensitivityStateHistory`, `Sensitivity`);
    const sensitivityChartObj = {
      chart: {
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        height: this.state.senHeight,
        marginLeft: 40,
        marginTop: 40,
      },
      credits: { enabled: false },
      legend: {
        verticalAlign: 'top',
        align: 'left',
        itemMarginBottom: 2,
        itemDistance: 10,
        maxHeight: 60,
        padding: 5,
        width: '100%',
        alignColumns: false,
        style: {
          '@media print': {
            fontSize: 10,
          },
        },
        y: -10,
        itemStyle: {
          color: theme.palette.text.primary,
          fontWeight: 500,
        },
        itemHoverStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
        },
      },
      title: { text: '' },
      tooltip: {
        backgroundColor: this.props.colorMode === 'dark' ? '#333' : '#fff',
        style: {
          color: theme.palette.text.primary,
        },
        shared: true,
        valueSuffix: ' %',
        valueDecimals: 2,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      series: sensitivityData,
    };
    const strokeData = this.getPerformanceChartData(`strokeResultHistory`, `strokeAppHistory`, `strokeDatetimeHistory`, `strokeStateHistory`, `Stroke`);
    const strokeChartObj = {
      chart: {
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        height: this.state.strokeHeight,
        marginLeft: 40,
        marginTop: 40,
      },
      credits: { enabled: false },
      legend: {
        verticalAlign: 'top',
        align: 'left',
        itemMarginBottom: 2,
        itemDistance: 10,
        maxHeight: 60,
        padding: 5,
        width: '100%',
        alignColumns: false,
        style: {
          '@media print': {
            fontSize: 10,
          },
        },
        y: -10,
        itemStyle: {
          color: theme.palette.text.primary,
          fontWeight: 500,
        },
        itemHoverStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
        },
      },
      title: { text: '' },
      tooltip: {
        backgroundColor: this.props.colorMode === 'dark' ? '#333' : '#fff',
        style: {
          color: theme.palette.text.primary,
        },
        shared: true,
        valueSuffix: ' s',
        valueDecimals: 2,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      series: strokeData,
    };
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    const content = (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={this.state.resWidth}>
          <WidgetTitle title='Resolution Performance History' />
          <Paper className={`${printStyles ? classes.printPaper : classes.paper} ${this.state.gridHeight}`} elevation={3}>
            <Tooltip arrow placement='top' title={!!this.state.resZoom ? 'Collapse' : 'Expand'} aria-label={!!this.state.resZoom ? 'Collapse' : 'Expand'}>
              <IconButton
                className={classes.chartButton}
                style={{ float: 'right' }}
                onClick={() =>
                  this.setState({
                    resHeight: !this.state.resZoom ? 450 : 300,
                    resWidth: !this.state.resZoom ? 12 : 6,
                    resGridHeight: !this.state.resZoom ? classes.gridHeight : null,
                    resZoom: !this.state.resZoom,
                  })
                }
                size='large'
              >
                {this.state.resZoom ? <FullscreenExit></FullscreenExit> : <Fullscreen></Fullscreen>}
              </IconButton>
            </Tooltip>
            <div className={classes.chart}>
              {!resolutionData ||
              (!!this.props.devices[this.state.valveId] &&
                !!this.props.devices[this.state.valveId].resolutionAppHistory &&
                this.props.devices[this.state.valveId].resolutionAppHistory.length === 0) ? (
                <Typography>No Resolution Data Found for this Device</Typography>
              ) : this.state.loading ? (
                <Typography variant='subtitle2'>
                  <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                  Loading Chart...
                </Typography>
              ) : (
                <Chart options={resolutionChartObj} />
              )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={this.state.senWidth}>
          <WidgetTitle title='Sensitivity Performance History' />
          <Paper className={`${printStyles ? classes.printPaper : classes.paper} ${this.state.gridHeight}`} elevation={3}>
            <Tooltip arrow placement='top' title={!!this.state.senZoom ? 'Collapse' : 'Expand'} aria-label={!!this.state.senZoom ? 'Collapse' : 'Expand'}>
              <IconButton
                className={classes.chartButton}
                style={{ float: 'right' }}
                onClick={() =>
                  this.setState({
                    senHeight: !this.state.senZoom ? 450 : 300,
                    senWidth: !this.state.senZoom ? 12 : 6,
                    senGridHeight: !this.state.senZoom ? classes.gridHeight : null,
                    senZoom: !this.state.senZoom,
                  })
                }
                size='large'
              >
                {this.state.senZoom ? <FullscreenExit></FullscreenExit> : <Fullscreen></Fullscreen>}
              </IconButton>
            </Tooltip>
            <div className={classes.chart}>
              {!sensitivityData ||
              (!!this.props.devices[this.state.valveId] &&
                !!this.props.devices[this.state.valveId].sensitivityAppHistory &&
                this.props.devices[this.state.valveId].sensitivityAppHistory.length === 0) ? (
                <Typography>No Sensitivity Data Found for this Device</Typography>
              ) : this.state.loading ? (
                <Typography variant='subtitle2'>
                  <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                  Loading Chart...
                </Typography>
              ) : (
                <Chart options={sensitivityChartObj} />
              )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <WidgetTitle title='Stroke Speed Performance History' />
          <Paper className={`${printStyles ? classes.printPaper : classes.paper} ${this.state.gridHeight}`} elevation={3}>
            <Tooltip arrow placement='top' title={!!this.state.strokeZoom ? 'Collapse' : 'Expand'} aria-label={!!this.state.strokeZoom ? 'Collapse' : 'Expand'}>
              <IconButton
                className={classes.chartButton}
                style={{ float: 'right' }}
                onClick={() =>
                  this.setState({
                    strokeHeight: !this.state.strokeZoom ? 450 : 300,
                    strokeWidth: !this.state.strokeZoom ? 12 : 12,
                    strokeGridHeight: !this.state.strokeZoom ? classes.gridHeight : null,
                    strokeZoom: !this.state.strokeZoom,
                  })
                }
                size='large'
              >
                {this.state.strokeZoom ? <FullscreenExit></FullscreenExit> : <Fullscreen></Fullscreen>}
              </IconButton>
            </Tooltip>
            <div className={classes.chart}>
              {!strokeData ||
              (!!this.props.devices[this.state.valveId] && !!this.props.devices[this.state.valveId].strokeAppHistory && this.props.devices[this.state.valveId].strokeAppHistory.length === 0) ? (
                <Typography>No Stroke Speed Data Found for this Device</Typography>
              ) : this.state.loading ? (
                <Typography variant='subtitle2'>
                  <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                  Loading Chart...
                </Typography>
              ) : (
                <Chart options={strokeChartObj} />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
    return content;
  }

  render() {
    const { classes } = this.props;
    const options = this.getOptions();
    const headerArr = [
      { id: 'type', label: 'Performance Type' },
      { id: 'targetFormatted', label: 'Target' },
      { id: 'resultFormatted', label: 'Result' },
      { id: 'differenceFormatted', label: 'Difference', sortable: false },
      { id: 'fromPrevFormatted', label: 'From Previous', sortable: false },
    ];
    const tableData = this.state.valveId === -1 ? [] : this.getHistoryTable(this.state.valveId);

    return (
      <React.Fragment>
        <Autocomplete
          //multiple
          limitTags={6}
          id='checkboxes-tags-demo'
          options={options}
          //getOptionLabel={(option) => option.title}
          //getOptionSelected=
          isOptionEqualToValue={(option, value) => {
            //TODO MAKE SURE THIS MATCHING OF OPTIONS IS UNIQUE
            return option.name === value.name && option.deviceId === value.deviceId;
          }}
          getOptionLabel={(option) => option.name.toString()}
          filterOptions={(options) => {
            const limit = 200;
            return matchFilter(options, this.state.value, null, limit);
          }}
          /*filterOptions={(options) => {
            return this.matchFilter(options);
          }}*/
          renderOption={(props, option, { selected }) => (
            <span style={{ fontSize: 14 }} {...props} className={classes.autoCompleteListItem} key={`${option.deviceId}_${option.name}`}>
              {option.name ? option.name : null}
              <br></br>
              {option.site ? <span style={{ marginRight: 10 }}>Site: {option.site}</span> : null}
              {option.application ? <span style={{ marginRight: 10 }}>Application: {option.application}</span> : null}
              {option.serial ? <span>Serial: {option.serial}</span> : null}
            </span>
          )}
          onInputChange={this.onSearchChange}
          onChange={this.onSearchSelect}
          style={{ maxWidth: 500 }}
          renderInput={(params) => (
            <div>
              <TextField
                {...params}
                placeholder='Select Devices'
                variant='outlined'
                inputProps={{
                  classes: {},
                  ...params.inputProps,
                }}
              />
            </div>
          )}
        />
        {this.state.valveId === -1 ? (
          <Typography style={{ marginTop: 8 }}>Please Select a Device</Typography>
        ) : (
          <React.Fragment>
            <div className={classes.printWidgetSpacer}></div>
            {this.renderHistory()}
            <Grid item xs={12}>
              <WidgetTitle title='Performance History Table' />
              <TableWidget xs={12} defaultRows={25} data={tableData} header={this.state.valveId === -1 ? [] : headerArr} type='performanceHistory' loading={this.state.loading} />
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    devices: state.devices.devices,
    reportDevices: state.reports.report_data.comparator,
    currentModule: state.currentModule.currentModule,
    optimize: state.optimize,
    reportModule: state.reports.activeTab,
    tests: state.tests.tests,
    tests_status: state.tests.status,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {
  fetchDevicesSummary,
  setDevicesSummaryStatus,
  setComparatorReport,
  fetchDevices,
  fetchDeviceSpecs,
  fetchDiagnostics,
  setTestStatus,
})(withStyles(styles)(PerformanceReport));
