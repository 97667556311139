import _ from 'lodash';
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles.js';
import { connect } from 'react-redux';
import { BuildCircle, Construction, Error, PowerOff, /*Replace,*/ Sync, Troubleshoot, Warning } from '@mui/icons-material';
import { /*Checkbox,*/ Typography, /*List, ListItem, Tooltip, /*TablePagination, Button,*/ Paper /*Alert*/ } from '@mui/material';
//import IBATile from '../tiles/IBATile';
import TextDisplay from '../filters/TextDisplay.js';
//import CustomSelect from '../filters/CustomSelect.js';
//import { RestartAlt } from '@mui/icons-material';
//import VerifiedIcon from '@mui/icons-material/Verified';
//import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
//import ErrorIcon from '@mui/icons-material/Error';
import Grid from '@mui/material/Grid';
import functions from '../../functions/functions.js';

import {
  fetchOptimizeDevices,
  fetchDevicesSummary,
  fetchDevices,
  fetchEvents,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  setModule,
  updateSort,
  updateDateRange,
  fetchLocations,
  fetchApplications,
  fetchImportances,
  fetchSites,
  fetchIssueTypes,
  deleteLoops,
  deleteDevices,
  fetchSort,
  fetchLoops,
  updateFilter,
  clearFilter,
} from '../../actions/index';

import { CircularProgress } from '@mui/material';
import WidgetTitle from '../headers/WidgetTitle.js';
//import ReportChartWidget from '../widgets/ReportChartWidget.js';
//import Highcharts from 'highcharts';
import initializeTheme from '../theme.js';
//import TableWidget from '../widgets/TableWidget.js';
//import fastfield from '../../apis/fastfield.js';
//import api from '../../apis/api.js';
//! Watch the sourc on this one
import FastFieldImage from '../test/FastFieldImage.js';

class IBA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.processData = this.processData.bind(this);
    this.textDisplayFactory = this.textDisplayFactory.bind(this);
  }
  componentDidMount() {
    if (this.state.currentModule !== 'IBA') {
      this.props.setModule('IBA');
    }
    this.props.fetchDevices(this.props.match.params.id);
    //this.props.fetchDevicesSummary();
    //this.props.fetchOptimizeDevices(0, 24);
  }

  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <div>
          <Typography variant='subtitle2' style={{ marginTop: 12 }}>
            <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
            Loading...
          </Typography>
        </div>
      );
    } else {
      return;
    }
  }

  resetFilters = async () => {
    this.setState({ changes: true });
    await this.props.clearFilter();
    this.props.updateSort('Importance');
    this.multiSelectOnClose();
  };

  getFastFieldReport = async (submission_id) => {
    console.log('FAILURE');
    return 'failure';
    /*try {
      const auth = await fastfield.getFastFieldAuth();
      if (auth === '' || !auth) {
        //! TODO
        console.log('THROW NO AUTH ERROR');
      } else {
        const url = 'iba/getSubmission';
        const pdfUrl = `https://api.fastfieldforms.com/services/v3/reports/pdf`;
        const response = await api.post(
          url,
          {
            token: auth,
            submission_id,
            //responseType: 'arraybuffer',
            //responseEncoding: 'binary',
          },
          { responseType: 'arraybuffer', responseEncoding: 'binary' }
        );

        function base64ToArrayBuffer(data) {
          var binaryString = window.atob(data);
          var binaryLen = binaryString.length;
          var bytes = new Uint8Array(binaryLen);
          for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }
          return bytes;
        }

        // In my response handler:
        //const arraybuffer = base64ToArrayBuffer(response);
        //const blob = new Blob([arraybuffer], { type: 'application/pdf' });
        const blob = new Blob([response.data]);

        const url2 = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url2;
        const fileName = 'test.pdf';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        //console.log('res', response.data);

        // /*const link = document.createElement('a');
        // link.setAttribute('href', 'data:"application/pdf;' + response.data);
        // link.setAttribute('download', 'bp.pdf');

        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        //! Has to be done on API and file sent from there due to CORS
        // /*const response2 = await api.get(pdfUrl, {
        //   'X-Gatekeeper-SessionToken': auth,
        //   'Content-Type': 'application/json',
        //   'FastField-API-Key': process.env.FAST_FIELD_API_KEY,
        // });
        // console.log(response2);
      }
    } catch (e) {
      console.log(e);
    }*/
  };

  processData = () => {
    /*let data = _.map(this.props.loops, (loop) => {
      let loopAdj = loop;
      loopAdj.meterCheck = 'TRUE';
      loopAdj.actionCheck = 'TRUE';
      //return loopAdj;
    });*/
    const filters = this.props.sort.filter;
    //const report_link_function = this.getFastFieldReport;
    let filterList = ['Plant', 'Unit', 'Application', 'Line', 'Manufacturer', 'Year', 'Status', 'Importance', 'Recommendation'];
    let filterListRow = ['plant_name', 'plant_location', 'device_location', 'vessel_line_location', 'manufacturer', 'year_manufactured', 'masterCheck', 'criticality', 'actionCheck'];
    //console.log(filters);
    let data = _.reduce(
      this.props.loops,
      function (acc, obj) {
        //acc stands for accumlation, [] is the accumulator
        let match = true;
        for (let i = 0; i < filterList.length; i++) {
          if ((!filters[filterList[i]] || filters[filterList[i]].length === 0 || filters[filterList[i]].indexOf(obj[filterListRow[i]]) !== -1) && match) {
          } else {
            match = false;
          } /*&& true*/
        }
        //console.log(ignore);
        if (match) {
          obj.meterCheck = 'TRUE';
          obj.actionCheck = 'TRUE';
          /*obj.report_link = (
            <Button variant='outlined' onClick={() => report_link_function(obj.submission_id)}>
              Get Report
            </Button>
          );*/
          obj.report_link = <FastFieldImage file={obj.asset_photos[0]} />;
          acc.push(obj);
        }
        //console.log(acc);
        return acc;
      },
      []
    );
    //console.log(data);
    return data;
  };

  createDevicePie = (data) => {
    let device_types = {};

    for (let i = 0; i < data.length; i++) {
      if (!device_types[data[i].asset_description]) {
        device_types[data[i].asset_description] = 1;
      } else {
        device_types[data[i].asset_description]++;
      }
    }
    let obj_keys = Object.keys(device_types);
    let pie_data = [];
    for (let i = 0; i < obj_keys.length; i++) {
      pie_data.push({
        name: obj_keys[i],
        y: device_types[obj_keys[i]],
      });
    }
    return pie_data;
  };

  checkDeviceOperatingStatus = (device) => {
    if (device.meter_measurement_check === '0') {
      return 'Incorrect';
    } else if (device.meter_power_check === 'False') {
      return 'Off';
    } else {
      return 'On';
    }
  };

  createDeviceOperationColumn = (data) => {
    let year_manufactured_count = {};

    for (let i = 0; i < data.length; i++) {
      if (!year_manufactured_count[data[i].year_manufactured]) {
        year_manufactured_count[data[i].year_manufactured] = { On: 0, Off: 0, Incorrect: 0 };
      }
      year_manufactured_count[data[i].year_manufactured][this.checkDeviceOperatingStatus(data[i])]++;
    }
    //console.log(year_manufactured_count);
    let types = ['Incorrect', 'Off', 'On'];
    let colorTypes = ['#D3001B', '#e3a61b', '#308D3B'];
    let obj_keys = Object.keys(year_manufactured_count);
    let stacking_data = [];
    for (let i = 0; i < types.length; i++) {
      let dataArr = [];
      for (let j = 0; j < obj_keys.length; j++) {
        dataArr.push(year_manufactured_count[data[j].year_manufactured][types[i]]);
      }
      //console.log(obj_keys[i]);
      //console.log(dataArr);

      stacking_data.push({
        name: types[i],
        data: dataArr,
        color: colorTypes[i],
      });
    }
    //console.log({ importanceTypes: obj_keys, stackingData: stacking_data });
    return { importanceTypes: obj_keys, stackingData: stacking_data };
    /* let importanceTypes = ['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important'];
    let stackingData = [
      {
        name: 'Incorrect Measurement',
        data: [1, 2, 1, 0, 0],
        color: '#D3001B',
      },
      {
        name: 'Off',
        data: [4, 3, 3, 0, 0],
        color: '#e3a61b',
      },
      {
        name: 'On',
        data: [6, 7, 2, 1, 0],
        color: '#308D3B',
      },
    ];
    return { importanceTypes, stackingData };*/
  };

  createPieData = (data) => {
    let on_count = 0;
    let off_count = 0;
    let incorrect_count = 0;

    for (let i = 0; i < data.length; i++) {
      //console.log(data[i].meter_measurement_check, data[i].meter_power_check);
      if (data[i].meter_measurement_check === '0') {
        incorrect_count++;
      } else if (data[i].meter_power_check === 'False') {
        off_count++;
      } else {
        on_count++;
      }
    }
    return [
      {
        name: 'On',
        y: on_count,
      },
      {
        name: 'Off',
        y: off_count,
      },
      {
        name: 'Incorrect Measurement',
        y: incorrect_count,
      },
    ];
  };

  getUniqueValues = (data, prop) => {
    let uniqueValues = [];
    for (let i = 0; i < data.length; i++) {
      if (uniqueValues.indexOf(data[i][prop]) === -1) {
        uniqueValues.push(data[i][prop]);
      }
    }
    return uniqueValues;
  };

  textDisplayFactory = (item) => {
    let device = !this.props.loops || !this.props.loops[this.props.match.params.id] ? {} : this.props.loops[this.props.match.params.id];
    //const { classes } = this.props;
    return <TextDisplay id={null} key={`text_display_${item.label}`} label={item.label} text={!device[item.id] ? '- ' : device[item.id]} type='prioritize' />;
  };

  getGoogleMapLink = (device) => {
    if (!device.location_gps_lat || !device.location_gps_long) return null;
    let lat = device.location_gps_lat;
    //let latSuff = lat > 0 ? 'N' : 'S';
    let long = device.location_gps_long;
    let zoom = 14; //higher is closer
    //let longSuff = long > 0 ? 'E' : 'W';
    //return `https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${lat},${long}+(My%20Name%20Here)&t=&z=14&ie=UTF8&iwloc=B&output=embed`;
    return `https://maps.google.com/maps?hl=en&q=${lat},${long}&z=${zoom}&ie=UTF8&iwloc=B&output=embed`;
  };

  getDeviceRecommendations = (device) => {
    //console.log(device);
    const { classes } = this.props;
    let list = [];
    if (device.meter_measurement_check === '0' && device.recommendation_type.indexOf('Replace') === -1) {
      list.push({ type: 'Measurement', text: 'Device type is incorrect for measurement value.' });
    }
    if (device.meter_power_check === 'False') {
      list.push({ type: 'Off', text: 'Device is unpowered.' });
    }
    if (!!device.recommendations && device.recommendations !== '') {
      let recommendations = device.recommendations.split(','); //'Test 1||| where was my comma, Test 2'.split(','); //
      let recommendation_types = device.recommendation_type.split(',');
      for (let i = 0; i < recommendations.length; i++) {
        let recommendationsComma = recommendations[i].replace('|||', ',');
        list.push({ type: recommendation_types[i], text: recommendationsComma });
      }
    }
    let defaultColors = ['#E19878', '#598FD6', '#C866A7', '#48959B', '#9473C5', '#CEAD9F', '#88A5C9', '#CB8A9F', '#84AFBB', '#A796C1'];
    let backgroundColors = [
      'rgba(225, 152, 120, 0.1)',
      'rgba(89, 143, 214, 0.1)',
      'rgba(200, 102, 167, 0.1)',
      'rgba(72, 149, 155, 0.1)',
      'rgba(148, 115, 197, 0.1)',
      'rgba(206, 173, 159, 0.1)',
      'rgba(136, 165, 201, 0.1)',
      'rgba(203, 138, 159, 0.1)',
      'rgba(132, 175, 187, 0.1)',
      'rgba(167, 150, 193, 0.1)',
    ];
    return (
      <React.Fragment>
        {list.map((item, index) => {
          let icon = null;
          let color = '#666';
          let backgroundColor = this.props.colorMode === 'dark' ? '#666' : '#EEE';
          switch (item.type) {
            case 'Error':
              color = defaultColors[0];
              backgroundColor = backgroundColors[0];
              icon = <Error className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Warning':
              color = defaultColors[1];
              backgroundColor = backgroundColors[1];
              icon = <Warning className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Off':
              color = defaultColors[1];
              backgroundColor = backgroundColors[1];
              icon = <PowerOff className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Measurement':
              color = defaultColors[1];
              backgroundColor = backgroundColors[1];
              icon = <Sync className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Calibration':
              color = defaultColors[2];
              backgroundColor = backgroundColors[2];
              icon = <BuildCircle className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Repair':
              color = defaultColors[3];
              backgroundColor = backgroundColors[3];
              icon = <Construction className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Replace':
              color = defaultColors[4];
              backgroundColor = backgroundColors[4];
              icon = <Sync className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Troubleshoot':
              color = defaultColors[5];
              backgroundColor = backgroundColors[5];
              icon = <Troubleshoot className={classes.alertIcon} sx={{ color: color }} />;
              break;
            default:
              color = defaultColors[6];
              backgroundColor = backgroundColors[6];
              icon = <Warning className={classes.alertIcon} sx={{ color: color }} />;
          }
          return (
            <Grid key={`recommendation_${index}`} style={{ marginBottom: 4 }}>
              <div className={classes.alert} style={{ borderLeft: '8px solid ' + color, borderColor: color, backgroundColor: backgroundColor }} elevation={3}>
                <Typography>
                  {icon} {item.type} - {item.text}
                </Typography>
              </div>
            </Grid>
          );
          /*return (
            <Alert key={`alert_${item.text}`} severity={item.type} style={{ marginBottom: 1 }}>
              {item.text}
            </Alert>
          );*/
        })}
      </React.Fragment>
    );
  };

  render() {
    const { classes } = this.props;

    const theme = initializeTheme(this.props.colorMode);
    let device = !this.props.loops || !this.props.loops[this.props.match.params.id] ? {} : this.props.loops[this.props.match.params.id];

    //console.log(this.props.loops);
    // console.log(this.props.match.params.id);
    //console.log(device);
    // console.log(Object.keys(device));
    let headerArr = [
      //{ id: 'tag', label: 'Device' },
      //{ id: 'plant_name', label: 'Plant' },
      //{ id: 'plant_location', label: 'Unit' },
      //{ id: 'device_location', label: 'Application' },
      //{ id: 'vessel_line_location', label: 'Line' },
      { id: 'manufacturer', label: 'Manuf.' },
      { id: 'order_code', label: 'order_code' },
      { id: 'year_manufactured', label: 'Year Manuf.' },
      //{ id: 'meterCheck', label: 'Meter Status' },
      //{ id: 'criticality', label: 'Importance' },
      //{ id: 'actionCheck', label: 'Recommendations' },
      { id: 'tech_name', label: 'Technician' },
      //{ id: 'actionCheck', label: 'Recommendations' },
      { id: 'serial_code', label: 'Serial' },
      { id: 'ease_of_access', label: 'Ease of Access' },
      { id: 'asset_description', label: 'Description' },
      { id: 'general_asset_notes', label: 'Notes' },
      //{ id: '', label: '' },
      //{ id: '', label: '' },
      //{ id: '', label: '' },
      //{ id: '', label: '' },
      //{ id: '', label: '' },
    ];
    /*let objValues = [
      //'deviceId',
      //'form_name',
      //'submitted_on',
      //'form_version',
      //'submitted_by',
      //'submission_id',
      //'tech_name',
      'date_time',
      //'plant_name',!
      //'plant_location',!
      //'device_location',!
      //'vessel_line_location',!
      //'tag',!
      //'manufacturer',
      //'order_code',
      //'serial_code',
      //'year_manufactured',
      'asset_description', //*! Do we want to replace this with type
      'meter_power_check', //*!
      'meter_measurement_check', //*!
      'criticality', //*!
      'correct_instrument_check', //*!
      'recommendations', //*
      //'ease_of_access',
      'general_asset_notes',
      //'location_gps_long',
      //'location_gps_lat',
      //'asset_photos',
    ];*/

    let googleMapLink = this.getGoogleMapLink(device);
    if (!this.props.auth) {
      return <div>Loading...</div>;
    }
    if (!!this.props.auth && this.props.auth.prioritize === 0) {
      return <div>You do not have permission to access this module</div>;
    }
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    //console.log(this.props);
    console.log(device);
    //! BORDER?
    //! Icons in alarms
    return (
      <React.Fragment>
        <WidgetTitle title={`Device Information - ${!device || !device.tag ? 'Device Name Not Found' : device.tag}`} />
        <Paper className={printStyles ? classes.printPaper : classes.hidePaper} /*style={{ borderLeft: '1em solid #888' }}*/ elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={2}>
              <Typography variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                {!device.plant_name ? 'Plant Not Found' : device.plant_name} {!device.plant_location ? null : '- ' + device.plant_location}
              </Typography>
              <Typography variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                {!device.device_location ? 'Location Not Found' : device.device_location} {!device.vessel_line_location ? null : '- ' + device.vessel_line_location}
              </Typography>
              <Typography variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                {!device.criticality || device.importance === 'Unknown' ? null : device.criticality}
              </Typography>
              {/*<Typography variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                Optimize Valve
    </Typography>*/}

              <Typography style={theme.palette.blue} variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                {!device.asset_type ? 'No Device Exists' : device.asset_type}
              </Typography>
              <Typography style={theme.palette.blue} variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                {!device.date_time ? 'Unknown Test Date' : functions.dateToString(new Date(device.date_time), 'date')}
              </Typography>
              {/*<Typography style={theme.palette.blue} variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                {!device ? 'Unknown Test Time' : functions.dateToString(device.dateTime * 1000, 'time')}
  </Typography>*/}
              {/*this.checkDeviceOperatingStatus(device)*/}
            </Grid>
            <Grid item xs={8} sm={10}>
              <Grid item className={classes.leftMargin}>
                <Grid container>
                  {headerArr.map((item) => {
                    return this.textDisplayFactory(item);
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {!device || !device.recommendations || device.recommendations === '' ? null : (
          <React.Fragment>
            <WidgetTitle title={`Recommendations`} />
            {!device || !device.recommendations ? null : this.getDeviceRecommendations(device)}
          </React.Fragment>
        )}
        {!device || !device.asset_photos || device.asset_photos.length === 0 ? null : (
          <React.Fragment>
            <WidgetTitle title={`Images`} />
            <Grid container spacing={2}>
              {!device || !device.asset_photos
                ? null
                : device.asset_photos.map((src, index) => {
                    return (
                      <Grid item key={`list_device_img_${index}`}>
                        <FastFieldImage printThumbnail key={`device_img_${index}`} file={src} width={200} />
                      </Grid>
                    );
                  })}
            </Grid>
          </React.Fragment>
        )}
        {!googleMapLink ? null : (
          <React.Fragment>
            <WidgetTitle title={`Map`} />
            <Paper className={printStyles ? classes.mapPaper : classes.hidePaper} elevation={3}>
              <iframe
                title={`Google Map for Device ${device.tag}`}
                width='100%'
                height='400'
                frameBorder='0'
                marginHeight='0'
                marginWidth='0'
                src={googleMapLink}
                style={{ marginBottom: -8, borderRadius: 4 }}
              />
            </Paper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  //'https://maps.google.com/maps?q=Tangesir%20Dates%20Products&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed'
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    applications: state.applications,
    importances: state.importances,
    locations: state.locations,
    loops: state.devices.devices,
    loopCount: state.devices.count,
    format: state.format.format,
    sort: state.sort,
    table: state.table,
    sites: state.sites,
    issueTypes: state.issuetypes,
    currentModule: state.currentModule,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {
  fetchOptimizeDevices,
  fetchDevicesSummary,
  fetchDevices,
  fetchEvents,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  setModule,
  updateSort,
  updateDateRange,
  fetchLocations,
  fetchApplications,
  fetchImportances,
  fetchSites,
  fetchIssueTypes,
  deleteLoops,
  deleteDevices,
  fetchSort,
  fetchLoops,
  updateFilter,
  clearFilter,
})(withStyles(styles)(IBA));
