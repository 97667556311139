import {
  RELIABILITY_SET_ACTIVE_TAB,
  RELIABILITY_SET_ACTIVE_LOOP,
  RELIABILITY_SET_ACTIVE_DEVICE,
  RELIABILITY_TOGGLE_ACTIVE_VARIABLE,
  RELIABILITY_ADD_ACTIVE_VARIABLE,
  RELIABILITY_CLEAR_ACTIVE_VARIABLES,
  RELIABILITY_REMOVE_ACTIVE_VARIABLE,
} from '../actions/types';

const INITIAL_STATE = {
  activeTab: 'Maintenance',
  activeLoop: '',
  activeDevice: '',
  activeVariables: ['Primary'],
};

const reliabilityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RELIABILITY_SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case RELIABILITY_SET_ACTIVE_LOOP:
      return { ...state, activeLoop: action.payload };
    case RELIABILITY_SET_ACTIVE_DEVICE:
      return { ...state, activeDevice: action.payload };
    case RELIABILITY_CLEAR_ACTIVE_VARIABLES: {
      return { ...state, activeVariables: ['Primary'] };
    }
    case RELIABILITY_TOGGLE_ACTIVE_VARIABLE: {
      let activeVariables = state.activeVariables.slice();
      let currentVar = action.payload;
      var index = activeVariables.indexOf(currentVar);
      if (index > -1) {
        //so already exists. now remove it.
        activeVariables.splice(index, 1);
      } else {
        //didn't exist, add it
        activeVariables.push(action.payload);
      }
      return {
        ...state,
        activeVariables,
      };
    }
    case RELIABILITY_ADD_ACTIVE_VARIABLE: {
      let activeVariables = state.activeVariables.slice();
      activeVariables.push(action.payload);
      /*let currentVar = action.payload;
        var index = variables.indexOf(varNameLower);
        if (index > -1) {
          //so already exists. now remove it.
          variables.splice(index, 1);
        } else {
          //does not exist, now add it
          currentActiveVariables.push(varNameLower);
        }
        
      }*/
      //console.log(activeVariables);
      return {
        ...state,
        activeVariables,
      };
    }
    case RELIABILITY_REMOVE_ACTIVE_VARIABLE: {
      let activeVariables = state.activeVariables.slice();

      let currentVar = action.payload;
      index = activeVariables.indexOf(currentVar);
      if (index > -1) {
        //so already exists. now remove it.
        activeVariables.splice(index, 1);
      }
      return {
        ...state,
        activeVariables,
      };
    }

    default:
      return state;
  }
};

export default reliabilityReducer;
