import React, { useState } from 'react';
import { Typography, Grid, IconButton } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from '../styles';
import upload1 from '../../images/screenshots_FAQ/upload_ss1.JPG';
import upload2 from '../../images/screenshots_FAQ/upload_ss2.JPG';
import upload3 from '../../images/screenshots_FAQ/upload_ss3.JPG';
import upload4 from '../../images/screenshots_FAQ/upload_ss4.JPG';
import upload5 from '../../images/screenshots_FAQ/upload_ss5.jpg';
import upload6 from '../../images/screenshots_FAQ/upload_ss6.jpg';
import upload7 from '../../images/screenshots_FAQ/upload_ss7.jpg';
import upload8 from '../../images/screenshots_FAQ/upload_ss8.jpg';

const useStyles = makeStyles(styles);

function UploadDetails(props) {
  const classes = useStyles();
  const [genOpen, setGenOpen] = useState(true);
  const [bdOpen, setBdOpen] = useState(true);
  const [profOpen, setProfOpen] = useState(true);
  function handleGeneral(e) {
    e.preventDefault();
    setGenOpen(!genOpen);
  }
  function handleBd(e) {
    e.preventDefault();
    setBdOpen(!bdOpen);
  }
  function handleProfiler(e) {
    e.preventDefault();
    setProfOpen(!profOpen);
  }
  let content = (
    <Grid container spacing={0} justifyContent='space-between'>
      <Grid item xs={12}>
        {props.hideTitle ? (
          <Typography variant='subtitle1' className={classes.blueText}>
            General Upload
          </Typography>
        ) : (
          <Grid container justifyContent='space-between' onClick={(e) => handleGeneral(e)} className={classes.expansionTabTop}>
            <Grid item>
              <Typography variant='subtitle1' className={classes.blueText}>
                General Upload
              </Typography>
              {genOpen ? (
                <Typography variant='caption' className={classes.helperText}>
                  Click to Collapse
                </Typography>
              ) : (
                <Typography variant='caption' className={classes.helperText}>
                  Click to Expand
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton style={{ marginTop: 5 }} size='large'>
                {genOpen ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown></KeyboardArrowDown>}
              </IconButton>
            </Grid>
            <div className={classes.expansionTabBorder}></div>
          </Grid>
        )}
        {!genOpen ? null : (
          <React.Fragment>
            <Typography variant='subtitle1' className={classes.listText}>
              Click ‘upload’ on the left hand menu.
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              You can select files to upload by one of two methods:
            </Typography>
            <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
              First, you can click the ‘select files’ button and a file explorer window will pop up. Go to where the files are located, select the files you wish to upload, and select open. For advice
              on where to find files, see the instructions below for the valve testing device you are using.
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={upload1} alt='upload screenshot 1' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
              You can open up the windows file explorer and navigate to where the files are stored. From there, you can ‘drag and drop’ the files you select over the ‘select files’ button and those
              files will be selected for upload.
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={upload2} alt='upload screenshot 2' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
              Once you have selected what files to upload, hit the upload button on the upper right of the page.
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={upload3} alt='upload screenshot 3' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
              Once you start uploading, wait for all files to report they are completed before leaving the page. The page will let you know if the process has ‘timed out’.
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={upload4} alt='upload screenshot 4' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='body1' className={classes.listText}>
              If a test doesn’t upload the first time, try refreshing the webpage and uploading the tests that didn’t upload again. The most frequent cause of upload failure is a temporary loss of
              internet connection.
            </Typography>
            <Typography variant='body1' className={classes.listText} style={{ marginBottom: 60 }}>
              If you wish to upload another batch, select the ‘clear’ button on the upper right (where the upload button was before) when all tests have finished uploading and you can repeat the
              process again.
            </Typography>
          </React.Fragment>
        )}
        {props.hideTitle ? (
          <Typography variant='subtitle1' className={classes.blueText}>
            If using a Black Diamond Benchmark Diagnostic Tool
          </Typography>
        ) : (
          <Grid container justifyContent='space-between' onClick={(e) => handleBd(e)} className={classes.expansionTab}>
            <Grid item>
              <Typography variant='subtitle1' className={classes.blueText}>
                If using a Black Diamond Benchmark Diagnostic Tool
              </Typography>
              {bdOpen ? (
                <Typography variant='caption' className={classes.helperText}>
                  Click to Collapse
                </Typography>
              ) : (
                <Typography variant='caption' className={classes.helperText}>
                  Click to Expand
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton style={{ marginTop: 5 }} size='large'>
                {bdOpen ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown></KeyboardArrowDown>}
              </IconButton>
            </Grid>
            <div className={classes.expansionTabBorder}></div>
          </Grid>
        )}
        {!bdOpen ? null : (
          <React.Fragment>
            <Typography variant='body1' className={classes.listText}>
              These systems produce .zip exchange files and pdf files. Optimize uses the information inside the .zip files.
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              To create an export of the data, first select the relevant tag record in the BenchMark system if you have not done so already. This can be done in the ‘Records’ function of the BenchMark
              Diagnostic Software. Once you have selected the record, go to the ‘Reports’ function of the BenchMark Diagnostic Software. There you can select the tests you wish to export and save them
              by using the ‘Exchange File’ button. This will save the selected tests in a .zip file in the designated BenchMark database folders.
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              These exchange files are stored wherever you set your database file paths to. You can find those folders by going to the ‘Records’ function of the BenchMark Diagnostic Software:
            </Typography>
            <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
              • Default location (C:/BenchMark_v/Datfile/)
            </Typography>
            <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
              • Files are typically stored in folders by year and month
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={upload7} alt='upload screenshot 7' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
              If you would like to pull all files from the day, you can use the file explorer’s search bar inside the Datfile folder or the folder for the month(s) you are interested in. Just search
              “.zip” or “Type: .zip” and it will show you all of the exchange files in those folders.
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={upload8} alt='upload screenshot 8' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='body1' className={classes.listText}>
              NOTE: If you have a custom save location already set up, files will go to that directory. You can usually create a custom save location by going to the ‘Records’ section of the program.
              We recommend leaving the settings to the default settings unless your company has specific reasons for saving in another location.
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              Once these files are located, you can extract the raw data by selecting the zip files, right clicking the files, and selecting ‘extract’. This will extract the .txt files and .dat files
              into folders. These .txt and .dat files can be uploaded to Optimize using the general instructions above.
            </Typography>
            <Typography variant='subtitle1' style={{ marginTop: 20, marginBottom: 5 }}>
              Tips on Location the test files you want quickly:
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              You can use the search bar in the windows file explorer pane to find and filter files, particularly if you are searching inside the Test Reports folder.
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              • Type: .txt, Type: .dat, and name: 'your tag here' are good search parameters.
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              You can also use the sorting tools to sort by date and other criteria to find the files you wish to select. You can select multiple files at once by shift-clicking or by selecting the
              checkmarks next to each file name
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              You can make a shortcut and put it on your desktop of the folders you want to use frequently to look for files.
            </Typography>
            <Typography variant='body1' className={classes.listText} style={{ marginBottom: 60 }}>
              You can also elect to copy or transfer files by hand to other locations, such as your company’s data store. If you have the files stored elsewhere (such as your company’s data store),
              you will need to find the files wherever you have placed them.
            </Typography>
          </React.Fragment>
        )}
        {props.hideTitle ? (
          <Typography variant='subtitle1' className={classes.blueText}>
            If using a ValveScopePro / Profiler
          </Typography>
        ) : (
          <Grid container justifyContent='space-between' onClick={(e) => handleProfiler(e)} className={classes.expansionTab}>
            <Grid item>
              <Typography variant='subtitle1' className={classes.blueText}>
                If using a ValveScopePro / Profiler
              </Typography>
              {profOpen ? (
                <Typography variant='caption' className={classes.helperText}>
                  Click to Collapse
                </Typography>
              ) : (
                <Typography variant='caption' className={classes.helperText}>
                  Click to Expand
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton style={{ marginTop: 5 }} size='large'>
                {profOpen ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown></KeyboardArrowDown>}
              </IconButton>
            </Grid>
            <div className={classes.expansionTabBorder}></div>
          </Grid>
        )}
        {!profOpen ? null : (
          <React.Fragment>
            <Typography variant='body1' className={classes.listText}>
              These systems produce text files and pdf files, Optimize uses the text files.
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              These files are stored wherever you set your database file paths to. You can access those folders by using the Windows file explorer.
            </Typography>
            <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
              • Default location (C:/Program Files/Softek/Test Reports/)
            </Typography>
            <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
              • Files are typically stored in folders by year, month, and then by test-type with these settings.
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={upload5} alt='upload screenshot 5' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
              If you would like to pull all the files from the day, you can use the file explorer’s search bar inside the Test Reports folder or the folder for the month(s) you are interested in. Just
              search “.txt” or “Type: .txt” and it will show you all of the text files in those folders.
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={upload6} alt='upload screenshot 6' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='body1' className={classes.listText}>
              NOTE: If you have a custom save location already set up, files will go to that directory. You can usually create a custom save location by going to the database section of the program.
              We recommend leaving the settings to the default settings unless your company has specific reasons for saving in another location.
            </Typography>
            <Typography variant='subtitle1' style={{ marginTop: 20, marginBottom: 5 }}>
              Tips on locating the test files you want quickly
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              You can use the search bar in the windows file explorer pane to find and filter files, particularly if you are searching inside the Test Reports folder.
            </Typography>
            <Typography variant='body1' className={classes.indent1} style={{ marginBottom: 15 }}>
              • Type: .txt and name: 'your tag here' are good search parameters.
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              You can also use the sorting tools to sort by date and other criteria to find the files you wish to select. You can select multiple files at once by shift-clicking or by selecting the
              checkmarks next to each file name
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              You can make a shortcut and put it on your desktop of the folders you want to use frequently to look for files.
            </Typography>
            <Typography variant='body1' className={classes.listText}>
              You can also elect to copy or transfer files by hand to other locations, such as your company’s data store. If you have the files stored elsewhere (such as your company’s data store),
              you will need to find the files wherever you have placed them.
            </Typography>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
  return content;
}

export default UploadDetails;
