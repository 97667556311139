import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from '../styles';
import { Typography, ListItem, ListItemText, List, Grid, Button } from '@mui/material';
import ChecklistIndicator from '../filters/ChecklistIndicator';
import imagesArray from '../../arrays/prioritize/imagesArray';
import prioritizeDetails from '../../arrays/prioritize/prioritizeDetails';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const useStyles = makeStyles(styles);

function PrioritizeDetails(props) {
  const classes = useStyles();
  const id = props.id;
  const [showMore, setShowMore] = useState(false);
  const [learnMore, setLearnMore] = useState({});
  if (!prioritizeDetails[id]) {
    return <Typography style={{ marginRight: 40 }}>Loading...</Typography>;
  }
  function handleShowMore(e) {
    e.preventDefault();
    setShowMore(!showMore);
  }
  function handleLearnMore(e, index) {
    e.preventDefault();
    setLearnMore({ [index]: !learnMore[index] });
  }
  let content = (
    <Grid container spacing={0} justifyContent='space-between'>
      <Grid item>
        {props.activeTab === 'intro' ? (
          <React.Fragment>
            <div className={classes.helperSection}>
              <Typography variant='subtitle1' className={classes.bodyHeader}>
                Description
              </Typography>
              <Typography variant='body1' className={classes.body}>
                {!prioritizeDetails[id].description ? `Description Not Found!` : prioritizeDetails[id].description}
              </Typography>
            </div>
            <div className={classes.helperSection}>
              {!prioritizeDetails[id].alarms ? null : (
                <Typography variant='subtitle1' className={classes.bodyHeader}>
                  Concern
                </Typography>
              )}
              {!prioritizeDetails[id].alarms
                ? null
                : Object.keys(prioritizeDetails[id].alarms).map((alarm) => {
                    const labelId = `checkbox-list-label-${alarm.replace(/\s/g, '')}`;
                    if (props.colorValue === alarm.toLowerCase() || alarm.toLowerCase().includes(props.colorValue) || showMore === true) {
                      if (labelId.includes('Value')) {
                        return (
                          <Typography id={labelId} variant='body1' className={classes.body} style={{ marginBottom: 10 }}>
                            {prioritizeDetails[id].alarms[alarm]}
                          </Typography>
                        );
                      } else if (labelId.includes('Sub')) {
                        return (
                          <Typography id={labelId} variant='body2' className={classes.body} style={{ marginBottom: 15 }}>
                            {prioritizeDetails[id].alarms[alarm]}
                          </Typography>
                        );
                      } else {
                        return (
                          (<div key={`${alarm.replace(/\s/g, '')}_item`} style={{ marginTop: 10, marginBottom: 10 }}>
                            <ChecklistIndicator color={alarm.toLowerCase()} bodyCheckbox></ChecklistIndicator>
                            <Typography style={{ width: '100%' }} id={labelId} className={classes.body}>
                              <span style={{ fontWeight: 500 }}>{alarm}</span>
                              <br></br>
                              {prioritizeDetails[id].alarms[alarm]}
                            </Typography>
                          </div>)
                        );
                      }
                    } else return null;
                  })}
              {!props.colorValue ? null : (
                <Button onClick={handleShowMore}>
                  {showMore ? (
                    <React.Fragment>
                      <ArrowDropUp className={classes.buttonIcon}></ArrowDropUp>Show Less
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ArrowDropDown className={classes.buttonIcon}></ArrowDropDown>Show More
                    </React.Fragment>
                  )}
                </Button>
              )}
            </div>
            <div className={classes.helperSection}>
              {!prioritizeDetails[id].types ? null : (
                <Typography variant='subtitle1' className={classes.bodyHeader}>
                  Types
                </Typography>
              )}
              {!prioritizeDetails[id].types
                ? null
                : Object.keys(prioritizeDetails[id].types).map((type) => {
                    const labelId = `checkbox-list-label-${type.replace(/\s/g, '')}`;
                    if (labelId.includes('title')) {
                      return (
                        (<Typography variant='subtitle2' color='secondary' key={`${type.replace(/\s/g, '')}_item`} className={classes.body}>
                          {prioritizeDetails[id].types[type]}
                        </Typography>)
                      );
                    } else if (labelId.includes('screenshot')) {
                      return (
                        <img
                          key={`img-${prioritizeDetails[id].types[type]}`}
                          src={imagesArray[prioritizeDetails[id].types[type]]}
                          className={classes.helperScreenshot}
                          alt={prioritizeDetails[id].types[type]}
                          style={{
                            maxWidth: 400,
                            marginBottom: 20,
                            marginTop: -10,
                          }}
                        />
                      );
                    } else {
                      return (
                        (<Typography
                                                                            variant='body1'
                                                                            key={`${type.replace(/\s/g, '')}_item`}
                                                                            className={classes.body}
                                                                            style={{
                                                                              marginBottom: 15,
                                                                            }}
                                                                          >
                          {prioritizeDetails[id].types[type]}
                        </Typography>)
                      );
                    }
                  })}
            </div>
            <div className={classes.helperSection}>
              {!prioritizeDetails[id].importance ? null : (
                <Typography variant='subtitle1' className={classes.bodyHeader}>
                  Importance
                </Typography>
              )}
              {!prioritizeDetails[id].importance
                ? null
                : Object.keys(prioritizeDetails[id].importance).map((importanceItem) => {
                    const labelId = `checkbox-list-label-${importanceItem.replace(/\s/g, '')}`;
                    if (labelId.includes('title')) {
                      return (
                        (<Typography variant='subtitle2' color='secondary' key={`${importanceItem.replace(/\s/g, '')}_item`} className={classes.body}>
                          {prioritizeDetails[id].importance[importanceItem]}
                        </Typography>)
                      );
                    } else {
                      return (
                        (<Typography
                                                                            variant='body1'
                                                                            key={`${importanceItem.replace(/\s/g, '')}_item`}
                                                                            className={classes.body}
                                                                            style={{
                                                                              marginBottom: 15,
                                                                            }}
                                                                          >
                          {prioritizeDetails[id].importance[importanceItem]}
                        </Typography>)
                      );
                    }
                  })}
            </div>
          </React.Fragment>
        ) : null}
        {props.activeTab === 'importance' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Importance
            </Typography>
            <List>
              {!prioritizeDetails[id].importance
                ? null
                : Object.keys(prioritizeDetails[id].importance).map((i) => {
                    return (
                      (<ListItem key={`${i.replace(/\s/g, '')}_item`}>
                        <ListItemText style={{ width: '100%' }} primary={<React.Fragment>{prioritizeDetails[id].importance[i]}</React.Fragment>} />
                      </ListItem>)
                    );
                  })}
            </List>
          </div>
        ) : null}
        {props.activeTab === 'recommendations' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Recommendations
            </Typography>
            <List>
              {!prioritizeDetails[id].recommendations
                ? `Recommendations Not Found!`
                : Object.keys(prioritizeDetails[id].recommendations).map((recommendation, index) => {
                    const labelId = `checkbox-list-label-${recommendation.replace(/\s/g, '')}`;
                    if (labelId.includes('Title')) {
                      return (
                        (<Typography variant='subtitle2' key={`${recommendation.replace(/\s/g, '')}_item`} role={undefined} className={classes.blueText} id={labelId}>
                          {prioritizeDetails[id].recommendations[recommendation]}
                        </Typography>)
                      );
                    } else {
                      return (
                        (<Typography key={`${recommendation.replace(/\s/g, '')}_item`} role={undefined} className={classes.bodyList} id={labelId}>
                          {prioritizeDetails[id].recommendations[recommendation]}
                        </Typography>)
                      );
                    }
                  })}
            </List>
          </div>
        ) : null}
        {props.activeTab === 'recommendationsSpecific' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Recommendations Specific to {`${prioritizeDetails[id].title}`}
            </Typography>
            <List>
              {!prioritizeDetails[id].recommendationsSpecific
                ? `Recommendations Not Found!`
                : Object.keys(prioritizeDetails[id].recommendationsSpecific).map((recommendation, index) => {
                    const labelId = `checkbox-list-label-${recommendation.replace(/\s/g, '')}`;
                    if (labelId.includes('Title')) {
                      return (
                        (<Typography variant='subtitle2' key={`${recommendation.replace(/\s/g, '')}_item`} role={undefined} className={classes.blueText} id={labelId}>
                          {prioritizeDetails[id].recommendationsSpecific[recommendation]}
                        </Typography>)
                      );
                    } else if (labelId.includes('LearnMore')) {
                      return (
                        (<div style={{ paddingLeft: 25, paddingBottom: 25 }} key={`${recommendation.replace(/\s/g, '')}_item`}>
                          <Button onClick={(e) => handleLearnMore(e, index)} style={{ marginBottom: 10 }}>
                            {!!learnMore[index] || learnMore[index] ? (
                              <React.Fragment>
                                <ArrowDropUp className={classes.buttonIcon}></ArrowDropUp>Hide Learn More
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <ArrowDropDown className={classes.buttonIcon}></ArrowDropDown>Learn More
                              </React.Fragment>
                            )}
                          </Button>
                          {!learnMore[index]
                            ? null
                            : Object.keys(prioritizeDetails[id].recommendationsSpecific[recommendation]).map((learnMore, index) => {
                                const labelId = `checkbox-list-label-${learnMore.replace(/\s/g, '')}`;
                                if (labelId.includes('Title')) {
                                  return (
                                    (<Typography
                                                                                        variant='subtitle2'
                                                                                        key={`${recommendation.replace(/\s/g, '')}_item_${learnMore.replace(/\s/g, '')}_item_`}
                                                                                        role={undefined}
                                                                                        className={classes.blueText}
                                                                                        id={labelId}
                                                                                      >
                                      {prioritizeDetails[id].recommendationsSpecific[recommendation][learnMore]}
                                    </Typography>)
                                  );
                                } else {
                                  return (
                                    (<Typography
                                                                                        variant='body1'
                                                                                        key={`${recommendation.replace(/\s/g, '')}_item_${learnMore.replace(/\s/g, '')}_item_`}
                                                                                        className={classes.bodyList}
                                                                                        role={undefined}
                                                                                        id={labelId}
                                                                                      >
                                      {prioritizeDetails[id].recommendationsSpecific[recommendation][learnMore]}
                                    </Typography>)
                                  );
                                }
                              })}
                        </div>)
                      );
                    } else {
                      return (
                        (<Typography variant='body1' key={`${recommendation.replace(/\s/g, '')}_item`} role={undefined} className={classes.bodyList} id={labelId}>
                          {prioritizeDetails[id].recommendationsSpecific[recommendation]}
                        </Typography>)
                      );
                    }
                  })}
            </List>
          </div>
        ) : null}
        {props.activeTab === 'examples' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Examples
            </Typography>
            <List>
              {!prioritizeDetails[id].examples
                ? null
                : Object.keys(prioritizeDetails[id].examples).map((example, index) => {
                    return (
                      <Grid key={example} container style={{ marginTop: 10, marginBottom: 30 }}>
                        <Typography variant='subtitle2' className={classes.blueText} style={{ width: '100%' }}>
                          <span style={{ fontWeight: 500 }}>Example {index + 1}</span> - {prioritizeDetails[id].examples[example].title}
                        </Typography>
                        <Typography variant='body1'>{prioritizeDetails[id].examples[example].caption}</Typography>
                        <Grid container>
                          <img
                            key={`img-${prioritizeDetails[id].examples[example].src}`}
                            src={imagesArray[prioritizeDetails[id].examples[example].src]}
                            alt={prioritizeDetails[id].examples[example].caption}
                            className={classes.helperScreenshot}
                            style={{
                              maxWidth: !!props.resize ? 1000 : prioritizeDetails[id].examples[example].size === 'small' ? 600 : 1000,
                            }}
                          />
                        </Grid>
                        <Grid
                          container
                          justifyContent='space-between'
                          style={{
                            maxWidth: !!props.resize ? 1000 : prioritizeDetails[id].examples[example].size === 'small' ? 600 : 1000,
                          }}
                        >
                          <Grid item></Grid>
                          <Grid item>
                            <Typography variant='caption' style={{ fontStyle: 'italic' }}>
                              Example {index + 1} image
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
            </List>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
  return content;
}

export default PrioritizeDetails;
