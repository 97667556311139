import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Typography, List, ListItem, ListItemText, Tooltip, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ChecklistIndicator from '../filters/ChecklistIndicator';
import InfoPopover from '../popup/InfoPopover.js';
import { fetchDiagnostics, setTestStatus } from '../../actions/index';
import { connect } from 'react-redux';
import { styles } from '../styles';
import { CircularProgress } from '@mui/material';
import diagnosticsArray from '../../arrays/optimize/diagnostics';
import diagnosticsDetails from '../../arrays/optimize/diagnosticsDetails';
import functions from '../../functions/functions';
import { Block } from '@mui/icons-material';

class OptimizeDiagnosticsWidget extends React.Component {
  constructor() {
    super();
    this.getCompareTestId = this.getCompareTestId.bind(this);
    this.state = {
      loading: false,
      hasBorder: false,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const device = this.props.device;
    if (this.props.currentModule === 'Optimize' && this.props.optimize.compareTest) {
      let testId = this.getCompareTestId();
      if (
        !this.props.tests_status ||
        !this.props.tests_status['diagnostics'] ||
        !(this.props.tests_status['diagnostics'][testId] === 'loading' || this.props.tests_status['diagnostics'][testId] === 'loaded')
      ) {
        //console.log(2);
        this.props.fetchDiagnostics(testId);
        this.props.setTestStatus('diagnostics', testId, 'loading');
      }
    }
    //const tests_status = this.props.tests_status;
    if (
      !!device.activeProfileId &&
      (!this.props.tests_status ||
        !this.props.tests_status['diagnostics'] ||
        !(this.props.tests_status['diagnostics'][device.activeProfileId] === 'loading' || this.props.tests_status['diagnostics'][device.activeProfileId] === 'loaded'))
    ) {
      //console.log(!tests.diagnostics ? 'waiting...' : this.props.tests.status['diagnostics'][device.activeProfileId]);
      this.props.fetchDiagnostics(device.activeProfileId);
      this.props.setTestStatus('diagnostics', device.activeProfileId, 'loading');
      //console.log('diagnostics');
    }
  }

  componentDidMount() {
    if (this.props.hasBorder) {
      this.setState({ hasBorder: true });
    }
    //const device = this.props.device;
    if (!this.props.device.diagnostics) {
      //this.props.fetchDiagnostics(device.activeProfileId);
      //console.log('diagnostics');
    }
  }
  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <Typography variant='subtitle2'>
          <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
          Loading...
        </Typography>
      );
    } else {
      return;
    }
  }
  renderMoreEvents() {
    const device = this.props.device;
    const eventCount = device.events.length - 4;
    if (eventCount > 0) {
      return <Typography variant='subtitle2'>... {eventCount} More Event(s) Found</Typography>;
    }
  }
  getCompareTestId() {
    let compareTest = this.props.optimize.compareTest;
    let compareDevice = this.props.optimize.compareDevice;
    let device = this.props.devices[compareDevice];
    let testIndex = device.testIdHistory.indexOf(compareTest.toString());

    let testId = device.profileIdHistory[testIndex];

    return testId;
  }
  /* DEPRECATED
  renderDiagnostics(diagnosticsArray, compare = false) {
    const { classes } = this.props;
    const tests = this.props.tests;
    const profileId = this.props.device.activeProfileId;
    const compareId = !this.props.optimize.compareFlag ? null : this.getCompareTestId();
    const activeTest = !tests.diagnostics ? {} : tests['diagnostics'][profileId];
    const compareTest = !this.props.optimize.compareFlag || !tests.diagnostics ? {} : tests['diagnostics'][compareId];
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    //console.log(diagnosticsArray);
    let diagnostics = diagnosticsArray.map((diagnosticSet) => {
      return (
        <Grid item xs={12} key={diagnosticSet.title}>
          <Typography variant='subtitle2' className={classes.diagnosticSetTitle}>
            {diagnosticSet.title}
          </Typography>
          <List style={{ lineHeight: '1 !important' }}>
            {diagnosticSet.checks.map((check) => {
              const labelId = `checkbox-list-label-${check.subtitle.replace(/\s/g, '')}`;
              if (!check.check) {
                console.log(`Error: missing check ${check}`);
                return null;
              }
              //console.log(activeTest);
              //console.log(this.props);
              //console.log(this.props.devices[this.props.activeDevice]);
              return (
                <ListItem key={`${check.check.replace(/\s/g, '')}_item`} role={undefined} dense className={classes.checklistItem}>
                  {Object.keys(this.props.tests_status).length === 0 || !profileId ? (
                    <Tooltip arrow placement='left' title='No data found for this diagnostic issue' aria-label='No data found for this diagnostic issue'>
                      <Block className={classes.grayIcon} />
                    </Tooltip>
                  ) : !activeTest || activeTest[check.check] === undefined ? (
                    <CircularProgress className={classes.loadIcon} size={14} color='secondary' style={{ opacity: 0.5 }} />
                  ) : (
                    <React.Fragment>
                      {printStyles ? null : (
                        <InfoPopover
                          resize
                          placement='left'
                          title={diagnosticsDetails[check.check].title}
                          titleIcon={diagnosticsDetails[check.check].title}
                          useOptimizeTabs
                          id={check.check}
                          type='diagnostics'
                          diagnosticIssue={{ name: check.subtitle, checkValue: activeTest[check.check], test: activeTest, device: this.props.activeDevice }}
                          colorValue={functions.numberToColor(!activeTest ? -1 : activeTest[check.check])}
                        />
                      )}
                      <ChecklistIndicator
                        color={functions.numberToColor(!activeTest ? -1 : activeTest[check.check])}
                        tooltip={functions.numberToOptimizeTooltip(!activeTest ? -1 : activeTest[check.check])}
                        style={!activeTest[check.check] ? { background: 'none' } : null}
                      ></ChecklistIndicator>
                    </React.Fragment>
                  )}
                  {this.props.optimize.compareFlag ? (
                    <React.Fragment>
                      <div className={classes.dividerVertical}></div>
                      <ChecklistIndicator
                        color={functions.numberToColor(!compareTest ? -1 : compareTest[check.check])}
                        tooltip={functions.numberToOptimizeTooltip(!activeTest ? -1 : activeTest[check.check])}
                      ></ChecklistIndicator>
                    </React.Fragment>
                  ) : null}
                  <ListItemText id={labelId} primary={`${check.nickname}`} classes={this.props.format || printStyles ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }} />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      );
    });
    return diagnostics;
  }*/
  renderDiagnostics(diagnosticsArray, compare = false) {
    const { classes } = this.props;
    const tests = this.props.tests;
    const profileId = this.props.device.activeProfileId;
    const compareId = !this.props.optimize.compareFlag ? null : this.getCompareTestId();
    const activeTest = !tests.diagnostics ? {} : tests['diagnostics'][profileId];
    const compareTest = !this.props.optimize.compareFlag || !tests.diagnostics ? {} : tests['diagnostics'][compareId];
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    let diagnostics = diagnosticsArray.map((diagnosticSet) => {
      return (
        (<Grid item xs={12} key={diagnosticSet.title}>
          <Typography variant='subtitle2' className={printStyles ? classes.printSubtitle : classes.diagnosticSetTitle}>
            {diagnosticSet.title}
          </Typography>
          <List style={{ lineHeight: '1 !important' }}>
            {diagnosticSet.checks.map((check) => {
              const labelId = `checkbox-list-label-${check.subtitle.replace(/\s/g, '')}`;
              if (!check.check) {
                console.log(`Error: missing check ${check}`);
                return null;
              }
              return (
                (<ListItem key={`${check.check.replace(/\s/g, '')}_item`} role={undefined} dense className={classes.checklistItem}>
                  {Object.keys(this.props.tests_status).length === 0 ? (
                    <Tooltip arrow placement='left' title='No data found for this diagnostic issue' aria-label='No data found for this diagnostic issue'>
                      <Block className={classes.grayIcon} />
                    </Tooltip>
                  ) : !activeTest || activeTest[check.check] === undefined ? (
                    <CircularProgress className={classes.loadIcon} size={14} color='secondary' style={{ opacity: 0.5 }} />
                  ) : (
                    <React.Fragment>
                      {printStyles ? null : (
                        <InfoPopover
                          resize
                          placement='left'
                          title={diagnosticsDetails[check.check].title}
                          titleIcon={diagnosticsDetails[check.check].title}
                          useOptimizeTabs
                          id={check.check}
                          type='diagnostics'
                          diagnosticIssue={{ name: check.subtitle, checkValue: activeTest[check.check], test: activeTest, device: this.props.activeDevice }}
                          colorValue={functions.numberToColor(!activeTest ? -1 : activeTest[check.check])}
                        />
                      )}
                      <ChecklistIndicator
                        color={functions.numberToColor(!activeTest ? -1 : activeTest[check.check])}
                        tooltip={functions.numberToOptimizeTooltip(!activeTest ? -1 : activeTest[check.check])}
                        style={!activeTest[check.check] ? { background: 'none' } : null}
                      ></ChecklistIndicator>
                    </React.Fragment>
                  )}
                  {this.props.optimize.compareFlag ? (
                    <React.Fragment>
                      <div className={classes.dividerVertical}></div>
                      <ChecklistIndicator
                        color={functions.numberToColor(!compareTest ? -1 : compareTest[check.check])}
                        tooltip={functions.numberToOptimizeTooltip(!activeTest ? -1 : activeTest[check.check])}
                      ></ChecklistIndicator>
                    </React.Fragment>
                  ) : null}
                  <ListItemText id={labelId} primary={`${check.nickname}`} classes={printStyles ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }} />
                </ListItem>)
              );
            })}
          </List>
        </Grid>)
      );
    });
    return (
      <Grid container>
        <Grid item xs={12} sm={this.props.format || this.props.activeTab === 'Certification' ? 3 : 6} md={3} lg={this.props.format || this.props.activeTab === 'Certification' ? 3 : 12}>
          {diagnostics[0]}
          {diagnostics[1]}
        </Grid>
        <Grid item xs={12} sm={this.props.format || this.props.activeTab === 'Certification' ? 3 : 6} md={3} lg={this.props.format || this.props.activeTab === 'Certification' ? 3 : 12}>
          {diagnostics[2]}
          {diagnostics[3]}
        </Grid>
        <Grid item xs={12} sm={this.props.format || this.props.activeTab === 'Certification' ? 3 : 6} md={3} lg={this.props.format || this.props.activeTab === 'Certification' ? 3 : 12}>
          {diagnostics[4]}
        </Grid>
        <Grid item xs={12} sm={this.props.format || this.props.activeTab === 'Certification' ? 3 : 6} md={3} lg={this.props.format || this.props.activeTab === 'Certification' ? 3 : 12}>
          {diagnostics[5]}
        </Grid>
      </Grid>
    );
  }
  //TODO resolution,sensitivity etc driven from 'concern' prop, cleaner to add optimize variables to state of OptimizeProgress.js
  render() {
    const { classes } = this.props;
    //const hasBorder = this.state.hasBorder;
    const device = this.props.device;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    //const tests = this.props.tests;
    /*const activeTest = !tests.diagnostics
      ? {}
      : tests['diagnostics'][this.props.device.activeProfileId];*/
    //console.log(!tests.diagnostics ? {} : tests['diagnostics']);
    let content = <div>Could not find data for this device!</div>;

    if (device) {
      //console.log(diagnosticsArray);
      // console.log(device.testId);
      // console.log(this.props.tests);
      // console.log(Object.keys(this.props.tests));
      // console.log(this.props.tests[device.testId]);
      content = (
        <React.Fragment>
          {printStyles ? (
            <div style={{ marginTop: -8 }}></div>
          ) : (
            <Typography className={`${classes.printHide}`} style={{ width: '100%' }}>
              Click {<InfoIcon className={classes.checklistInfoIcon} style={{ marginBottom: -2 }} />} to learn more about each diagnostic
            </Typography>
          )}
          {this.props.optimize.compareFlag ? (
            <Typography className={`${classes.printHide} ${classes.infoHelper}`} style={{ width: '100%' }}>
              Comparison is shown in the right hand column
            </Typography>
          ) : null}
          <Grid container>{this.renderDiagnostics(diagnosticsArray)}</Grid>
        </React.Fragment>
      );
    }
    return <React.Fragment>{content}</React.Fragment>;
  }
}

OptimizeDiagnosticsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    /*isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    locations: state.locations,
    errors: state.errors,
    events: state.events,*/
    activeDevice: state.optimize.activeDevice,
    activeTest: state.optimize.activeTest,
    optimizeCheckActiveTest: state.optimize.optimizeCheckActiveTest,
    devices: state.devices.devices,
    tests: state.tests.tests,
    tests_status: state.tests.status,
    optimize: state.optimize,
    currentModule: state.currentModule.currentModule,
    format: state.format.format,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, { fetchDiagnostics, setTestStatus })(withStyles(styles)(OptimizeDiagnosticsWidget));
