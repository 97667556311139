//import _ from 'lodash';
import {
  CLEAR_STATE,
  //FETCH_LOCATION,
  FETCH_DATASETS /*,
  CREATE_LOCATION,
  DELETE_LOCATION,
  EDIT_LOCATION*/,
} from '../actions/types';

const INITIAL_STATE = { datasets: {} };

const datasetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return {
        ...state,
        list: [],
      };
    }
    /*case FETCH_LOCATION:
      return { ...state, [action.payload.locationId]: action.payload };*/
    case FETCH_DATASETS:
      let datasets = Object.assign({}, state.datasets);
      for (let i = 0; i < action.payload.length; i++) {
        let dataset = action.payload[i];
        if (!state.datasets[dataset.name]) {
          //test doesn't exist yet, add
          //console.log('add');
          datasets[dataset.name] = dataset;
        } else {
          //console.log('merge');
          //test exists, merge data
          const mergedDataset = Object.assign(state.datasets[dataset.name], dataset);
          datasets[dataset.name] = mergedDataset;
        }
      }
      return {
        ...state,
        datasets,
      };
    /*case EDIT_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_LOCATION:
      return _.omit(state, action.payload);*/
    default:
      return state;
  }
};

export default datasetReducer;
