import _ from 'lodash';
import { FETCH_SORT, UPDATE_SORT, UPDATE_FILTER, UPDATE_PRESET, UPDATE_CONCERN, UPDATE_DATE_RANGE, UPDATE_ACTION_RANGE, UPDATE_ANALYSIS_RANGE, CLEAR_FILTER, CLEAR_STATE } from '../actions/types';
import presets from '../arrays/prioritize/presets.js';

const INITIAL_STATE = {
  sort: '',
  preset: { name: 'No Preset', value: 'noPreset' },
  filter: {
    Range: {
      startDate: Math.floor(
        new Date(`${new Date().getFullYear() - 4}-01-01`).getTime() / 1000 // 3 years on initialization
        // TODO FIX INITIAL VS SUBSQUENT PULLS
      ),
      endDate: Math.floor(new Date().getTime() / 1000),
    },
    AnalysisRange: 90,
    ActionRange: { start: null, end: null },
  },
  concern: {
    Good: true,
    Advisory: true,
    Critical: true,
  },
};

const sortReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case UPDATE_PRESET:
      let merged = presets[action.payload.value];
      if (!!state.filter.Site) {
        merged.filter.Site = state.filter.Site;
      }
      if (!!state.filter.Location) {
        merged.filter.Location = state.filter.Location;
      }
      if (!!state.filter.Application) {
        merged.filter.Application = state.filter.Application;
      }
      if (!!state.filter.Range) {
        merged.filter.Range = state.filter.Range;
      }
      if (!!merged.filter.sort) {
        merged.sort = merged.filter.sort;
      }
      if (!!merged.filter.concern) {
        merged.concern = merged.filter.concern;
      }
      merged.preset = action.payload;
      return {
        ...state,
        ...merged,
      };
    case UPDATE_DATE_RANGE:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
      };
    case UPDATE_ANALYSIS_RANGE:
      //console.log(action.payload);
      if (!action.payload.AnalysisRange) {
        return state;
      } else {
        return {
          ...state,
          filter: { ...state.filter, ...action.payload },
        };
      }

    case UPDATE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
      };
    case UPDATE_CONCERN:
      return {
        ...state,
        concern: { ...state.concern, ...action.payload },
      };
    case UPDATE_ACTION_RANGE:
      if (!action.payload.ActionRange) {
        return state;
      } else {
        return {
          ...state,
          filter: { ...state.filter, ...action.payload },
        };
      }
    case FETCH_SORT:
      return {
        ...state,
        sort: { ...state.sort, ..._.mapKeys(action.payload, 'sort') },
      };
    case CLEAR_STATE:
    case CLEAR_FILTER:
      //console.log('clearing');
      //! THIS HAS TO MATCH THE DEFAULT STATE ABOVE
      return {
        preset: { name: 'No Preset', value: 'noPreset' },
        sort: '',
        filter: {
          Range: {
            startDate: Math.floor(new Date(`${new Date().getFullYear() - 4}.01.01`).getTime() / 1000), //4 years on all subsequent pulls
            // TODO FIX INITIAL VS SUBSQUENT PULLS
            endDate: Math.floor(new Date().getTime() / 1000),
          },
          AnalysisRange: 90,
          ActionRange: { start: null, end: null },
        },
        concern: {
          Good: true,
          Advisory: true,
          Critical: true,
        },
      };
    default:
      return state;
  }
};

export default sortReducer;
