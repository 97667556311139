import { CONFIG_SET_ACTIVE_TAB, CONFIG_SET_DATA } from '../actions/types';

const INITIAL_STATE = {
  activeTab: null,
  data: {},
};

const configReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONFIG_SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case CONFIG_SET_DATA:
      let data = Object.assign({}, state.data);
      data[action.payload.id] = action.payload.data;
      return {
        ...state,
        data,
      };
    default:
      return state;
  }
};

export default configReducer;
