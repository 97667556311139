import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { Paper, Tooltip, IconButton, Grid, Typography, CircularProgress } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import WidgetTitle from '../headers/WidgetTitle';
import functions from '../../functions/functions';
import Chart from '../charts/Chart';

class ReportChartWidget extends React.Component {
  state = {
    chartHeight: 300,
    gridHeight: null,
    gridWidth: this.props.initialWidth,
    zoom: false,
  };
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    let type = this.props.type;
    let data = this.props.data;
    let seriesData = this.props.seriesData;
    const validDataSum = validateData(type, data, seriesData, this.props.loading);
    return (
      <Grid item xs={printStyles ? this.state.gridWidth : 12} md={this.state.gridWidth} className={classes.avoidBreak}>
        {!this.props.name ? null : <WidgetTitle title={!this.props.name ? 'No title found!' : this.props.name} />}
        <Paper className={`${printStyles ? classes.printPaper : classes.paper} ${this.state.gridHeight} ${classes.avoidBreak}`} elevation={3}>
          {this.props.customLegend && this.props.loading === false ? (
            <Grid container spacing={2} justifyContent='center'>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <div className={`${classes.legendSymbol} ${classes.redBG}`}></div>
                  </Grid>
                  <Grid>
                    <Typography variant='caption' style={{ fontSize: 12 }}>
                      Critical
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <div className={`${classes.legendSymbol} ${classes.yellowBG}`}></div>
                  </Grid>
                  <Grid item>
                    <Typography variant='caption' style={{ fontSize: 12 }}>
                      Advisory
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <div className={`${classes.legendSymbol} ${classes.greenBG}`}></div>
                  </Grid>
                  <Grid item>
                    <Typography variant='caption' style={{ fontSize: 12 }}>
                      Good
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {validDataSum === -1 ? (
            <Typography variant='subtitle2'>
              <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
              Loading Chart...
            </Typography>
          ) : validDataSum === 0 ? (
            <Typography variant='subtitle2'>No data found for this chart.</Typography>
          ) : (
            <React.Fragment>
              <Tooltip arrow placement='top' title={!!this.state.zoom ? 'Collapse' : 'Expand'} aria-label={!!this.state.zoom ? 'Collapse' : 'Expand'}>
                <IconButton
                  className={classes.chartButton}
                  style={!this.props.customLegend ? { float: 'right' } : { float: 'right', marginTop: -33 }}
                  onClick={() =>
                    this.setState({
                      chartHeight: !this.state.zoom ? 450 : 300,
                      gridWidth: !this.state.zoom ? 12 : this.props.initialWidth,
                      gridHeight: !this.state.zoom ? classes.gridHeight : null,
                      zoom: !this.state.zoom,
                    })
                  }
                  size='large'
                >
                  {this.state.zoom ? <FullscreenExit></FullscreenExit> : <Fullscreen></Fullscreen>}
                </IconButton>
              </Tooltip>
              <Chart
                type={this.props.type}
                title={this.props.title}
                colors={this.props.colors}
                chartHeight={this.state.chartHeight}
                categories={this.props.categories}
                stacking={this.props.stacking}
                options={
                  !this.props.options
                    ? {
                        series: !this.props.seriesData
                          ? [
                              {
                                name: this.props.name,
                                data: this.props.data,
                              },
                            ]
                          : this.props.seriesData,
                      }
                    : this.props.options
                }
                suffix={this.props.suffix}
                titleY={this.props.titleY}
              />
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    );
  }
}

//totals data with the intent of saying 'no data' when sum === 0
const validateData = (type, pieData, seriesData, loading) => {
  let sum = 0;
  if (loading) {
    sum = -1;
  } else if (type === 'pie' && !!pieData) {
    for (let i = 0; i < pieData.length; i++) {
      sum += pieData[i].y;
    }
  } else if (type === 'concernShift' && !!seriesData) {
    for (let i = 0; i < seriesData.length; i++) {
      sum += seriesData[i].y;
    }
  } else if ((type === 'stack' || type === 'concern' || type === 'bar' || type === 'pie') && !!seriesData) {
    for (let i = 0; i < seriesData.length; i++) {
      let series = seriesData[i].data;
      for (let j = 0; j < series.length; j++) {
        sum += series[j];
      }
    }
  }
  return sum;
};

export default withStyles(styles)(ReportChartWidget);
