import { SUPPORT_SET_ACTIVE_TAB } from '../actions/types';
import { SUPPORT_SET_ACTIVE_OPTIMIZE } from '../actions/types';
import { SUPPORT_SET_ACTIVE_PRIORITIZE } from '../actions/types';

const INITIAL_STATE = {
  activeTab: 'Optimize',
  activeOptimize: 'gettingstarted',
  activePrioritize: 'gettingstarted',
};

const supportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUPPORT_SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case SUPPORT_SET_ACTIVE_OPTIMIZE:
      return { ...state, activeOptimize: action.payload };
    case SUPPORT_SET_ACTIVE_PRIORITIZE:
      return { ...state, activePrioritize: action.payload };
    default:
      return state;
  }
};

export default supportReducer;
