import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
//import { Link } from 'react-router-dom';
//import ToggleButton from '@mui/material/ToggleButton';
//import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
//import ComparatorReport from './optimize/ComparatorReport';
import { Paper, Typography } from '@mui/material';
import { getImprovementsReport, reportsSetActiveReport } from '../../../actions/index';
//import functions from '../../../functions/functions';

class OptimizeWidgets extends React.Component {
  componentDidMount() {
    let url = window.location.href.toLowerCase();
    url.includes('improvements')
      ? this.props.reportsSetActiveReport('improvements')
      : url.includes('comparator')
      ? this.props.reportsSetActiveReport('comparator')
      : url.includes('performance')
      ? this.props.reportsSetActiveReport('performance')
      : this.props.reportsSetActiveReport('portfolio');
  }
  handleReport(event, value) {
    this.props.reportsSetActiveReport(value);
    if (!value) {
      this.props.reportsSetActiveReport('portfolio');
    }
    //console.log(value);
  }
  //TODO fix wrapper buttons onclick
  render() {
    //const { classes } = this.props;
    //const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        <Paper>
          <Typography>There is nothing here yet!</Typography>
        </Paper>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reports: state.reports.report_data,
    activeReport: state.reports.activeReport,
    view: state.auth.view,
  };
};

export default connect(mapStateToProps, {
  getImprovementsReport,
  reportsSetActiveReport,
})(withStyles(styles)(OptimizeWidgets));
