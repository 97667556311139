import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
//import InfoPopover from '../../../popup/InfoPopover';
//import configDetails from '../../../../arrays/optimize/configDetails';
import { styles } from '../../../styles';
import InputAdornment from '@mui/material/InputAdornment';
//import DatePicker from '../../../filters/DatePicker';
import {
  //InputLabel,
  //FormControl,
  //Select,
  Button,
  Divider,
  Paper,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import CustomSelect from '../../../filters/CustomSelect';
//import TableComponent from '../../../charts/Table';
//import api from '../../../../apis/api';
import { fetchLocations, fetchApplications, fetchSites, updateFilter } from '../../../../actions/index';

class SetDefaultsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Site: [], Application: [], Location: [], rowChange: {} };
  }

  componentDidMount() {
    if (!this.props.locations || Object.entries(this.props.locations).length === 0 || this.props.locations.list.length === 0) {
      this.props.fetchLocations();
    }
    if (!this.props.applications || Object.entries(this.props.applications).length === 0 || this.props.applications.list.length === 0) {
      this.props.fetchApplications();
    }
    if (!this.props.sites || Object.entries(this.props.sites).length === 0 || this.props.sites.list.length === 0) {
      this.props.fetchSites();
    }
  }
  handleRowSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
  };

  multiSelectOnClose = async (event, label) => {
    let filterObj = {
      Site: this.state.Site,
      Location: this.state.Location,
      Application: this.state.Application,
    };
    this.props.fetchLocations(null, filterObj);

    this.props.fetchApplications(null, filterObj);

    this.props.fetchSites(null, filterObj);
  };

  multiSelectOnChange = async (event, value, type) => {
    let obj = this.state;
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    obj[name] = value;
    obj.changes = true;

    this.setState(obj);
  };

  processSpeedAccuracy(value) {
    if (typeof value === 'string') {
      switch (value) {
        case 'Extremely Important':
          return 5;
        case 'Very Important':
          return 4;
        case 'Important':
          return 3;
        case 'Standard':
          return 2;
        case 'Not Important':
          return 1;
        default:
          return 2;
      }
    } else {
      switch (value) {
        case 5:
          return 'Extremely Important';
        case 4:
          return 'Very Important';
        case 3:
          return 'Important';
        case 2:
          return 'Standard';
        case 1:
          return 'Not Important';
        default:
          return 'Standard';
      }
    }
  }
  processReliabilityImportance(value) {
    if (typeof value === 'string') {
      switch (value) {
        case 'Extremely Important':
          return 5;
        case 'Very Important':
          return 4;
        case 'Important':
          return 3;
        case 'Standard':
          return 2;
        case 'Not Important':
          return 1;
        default:
          return 2;
      }
    } else {
      switch (value) {
        case 5:
          return 'Extremely Important';
        case 4:
          return 'Very Important';
        case 3:
          return 'Important';
        case 2:
          return 'Standard';
        case 1:
          return 'Not Important';
        default:
          return 'Standard';
      }
    }
  }
  processSatisfaction(value) {
    if (typeof value === 'string') {
      switch (value) {
        case 'Extremely Happy':
          return 5;
        case 'Happy':
          return 4;
        case 'Neutral':
          return 3;
        case 'Unhappy':
          return 2;
        case 'Very Unhappy':
          return 1;
        default:
          return 3;
      }
    } else {
      switch (value) {
        case 5:
          return 'Extremely Happy';
        case 4:
          return 'Happy';
        case 3:
          return 'Neutral';
        case 2:
          return 'Unhappy';
        case 1:
          return 'Very Unhappy';
        default:
          return 'Neutral';
      }
    }
  }
  calculateDefaults(type) {
    const device = {
      accuracyImportance: null,
      speedImportance: null,
      bodySize: null,
    };
    /*this.props.row.id !== -1
        ? this.props.devices[this.props.row.id]
        : this.props.row;*/

    const {
      //resApp,
      //senApp,
      //strokeApp,
      //performanceSatisfaction,

      accuracyImportance,
      speedImportance,
      bodySize,
    } = !this.state ? device : this.state.rowChange;
    const floatBodySize = Math.round(bodySize * 1000) / 1000;
    let resRec, senRec, strokeRec;
    switch (accuracyImportance) {
      case 5:
        resRec = 0.5;
        senRec = 0.25;
        break;
      case 4:
        resRec = 1;
        senRec = 0.5;
        break;
      case 3:
        resRec = 1;
        senRec = 1;
        break;
      case 2:
        resRec = 2;
        senRec = 1;
        break;
      case 1:
        resRec = 10;
        senRec = 5;
        break;
      default:
        resRec = 2;
        senRec = 1;
        break;
    }
    if (floatBodySize <= 6) {
      switch (speedImportance) {
        case 5:
          strokeRec = 2;
          break;
        case 4:
          strokeRec = 4;
          break;
        case 3:
          strokeRec = 8;
          break;
        case 2:
          strokeRec = 10;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 10;
          break;
      }
    } else if (floatBodySize <= 20) {
      switch (speedImportance) {
        case 5:
          strokeRec = 4;
          break;
        case 4:
          strokeRec = 6;
          break;
        case 3:
          strokeRec = 12;
          break;
        case 2:
          strokeRec = 20;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 20;
          break;
      }
    } else {
      switch (speedImportance) {
        case 5:
          strokeRec = 6;
          break;
        case 4:
          strokeRec = 8;
          break;
        case 3:
          strokeRec = 16;
          break;
        case 2:
          strokeRec = 30;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 30;
          break;
      }
    }
    if (type === 'stroke') {
      return strokeRec;
    }
    if (type === 'resolution') {
      return resRec;
    }
    if (type === 'sensitivity') {
      return senRec;
    }
  }

  recommendTargets(e) {
    e.preventDefault();
    //const currentSettings = this.state.rowChange;
    const {
      //resApp,
      //senApp,
      //strokeApp,
      //performanceSatisfaction,
      reliabilityImportance,
      accuracyImportance,
      speedImportance,
      bodySize,
    } = this.state.rowChange;

    const floatBodySize = Math.round(bodySize * 1000) / 1000;
    let resRec, senRec, strokeRec, importanceRec;
    switch (accuracyImportance) {
      case 5:
        resRec = 0.5;
        senRec = 0.25;
        break;
      case 4:
        resRec = 1;
        senRec = 0.5;
        break;
      case 3:
        resRec = 1;
        senRec = 1;
        break;
      case 2:
        resRec = 2;
        senRec = 1;
        break;
      case 1:
        resRec = 10;
        senRec = 5;
        break;
      default:
        resRec = 2;
        senRec = 1;
        break;
    }
    if (floatBodySize <= 6) {
      switch (speedImportance) {
        case 5:
          strokeRec = 2;
          break;
        case 4:
          strokeRec = 4;
          break;
        case 3:
          strokeRec = 8;
          break;
        case 2:
          strokeRec = 10;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 10;
          break;
      }
    } else if (floatBodySize <= 20) {
      switch (speedImportance) {
        case 5:
          strokeRec = 4;
          break;
        case 4:
          strokeRec = 6;
          break;
        case 3:
          strokeRec = 12;
          break;
        case 2:
          strokeRec = 20;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 20;
          break;
      }
    } else {
      switch (speedImportance) {
        case 5:
          strokeRec = 6;
          break;
        case 4:
          strokeRec = 8;
          break;
        case 3:
          strokeRec = 16;
          break;
        case 2:
          strokeRec = 30;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 30;
          break;
      }
    }
    importanceRec = Math.max(reliabilityImportance, accuracyImportance, speedImportance);
    //console.log(strokeRec, senRec, resRec);
    this.setState({
      recConfirm: true,
      strokeRec,
      senRec,
      resRec,
      importanceRec,
    });
    //console.log(currentSettings);
    //console.log(this.props.devices[this.props.clickedRow]);
  }

  render() {
    const { classes } = this.props;
    //const currentDay = new Date();
    //console.log(this.props.row.id, this.props.clickedRow);
    //let sites = !this.props.sites.list ? [] : this.props.sites.list;
    //console.log(this.state);
    return (
      <React.Fragment>
        <Paper className={classes.paper} elevation={3}>
          <form className={classes.form} autoComplete='off'>
            <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
              Default Information
            </Typography>
            <CustomSelect
              id={null}
              label='Site'
              disabled={this.state.loading}
              values={!this.state.Site ? [] : this.state.Site}
              //type='site'
              options={!this.props.sites || !this.props.sites.list ? [] : this.props.sites.list.sort()}
              onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //selectedOption={'Resolution'}
              value={null}
              helperText={null}
            />
            <CustomSelect
              id={null}
              label='Location'
              disabled={this.state.loading}
              values={!this.state.Location ? [] : this.state.Location}
              //type='site'
              options={!this.props.locations || !this.props.locations.list ? [] : this.props.locations.list.sort()}
              onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //selectedOption={'Resolution'}
              value={null}
              helperText={null}
            />
            <CustomSelect
              id={null}
              label='Application'
              disabled={this.state.loading}
              values={!this.state.Application ? [] : this.state.Application}
              //type='site'
              options={!this.props.applications || !this.props.applications.list ? [] : this.props.applications.list.sort()}
              onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //selectedOption={'Resolution'}
              value={null}
              helperText={null}
            />
            <Typography variant='subtitle1' color='primary' className={classes.rowTitle} style={{ marginTop: 10 }}>
              Targets
            </Typography>
            <Grid container style={{ marginBottom: 20 }}>
              <TextField
                variant='standard'
                className={classes.textField}
                label='Resolution Target'
                type='number'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Typography>%</Typography>
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  if (event.target.value === '' || (!isNaN(event.target.value) && event.target.value < 100 && event.target.value > 0 && event.target.value.indexOf('e') === -1)) {
                    let currentRow = this.state.rowChange;
                    currentRow.resApp =
                      /*event.target.value === ''
                      ? this.calculateDefaults('resolution')
                      :*/ event.target.value;
                    this.setState(currentRow);
                  }
                }}
                value={!this.state.rowChange || (!this.state.rowChange.resApp && this.state.rowChange.resApp !== '') ? this.calculateDefaults('resolution') : this.state.rowChange.resApp}
                helperText={
                  !this.state.rowChange || (!this.state.rowChange.resApp && this.state.rowChange.resApp !== '') || parseFloat(this.state.rowChange.resApp) === this.calculateDefaults('resolution') ? (
                    <span className={classes.blueText}>(System Default)</span>
                  ) : this.state.rowChange.resApp === '' ? (
                    <span className={classes.redText}>(Enter a Value!)</span>
                  ) : (
                    ''
                  )
                }
              />

              <TextField
                variant='standard'
                className={classes.textField}
                label='Sensitivity Target'
                type='number'
                onChange={(event) => {
                  if (event.target.value === '' || (!isNaN(event.target.value) && event.target.value < 100 && event.target.value > 0 && event.target.value.indexOf('e') === -1)) {
                    let currentRow = this.state.rowChange;
                    currentRow.senApp =
                      /*event.target.value === ''
                      ? this.calculateDefaults('sensitivity')
                      : */ event.target.value;

                    this.setState(currentRow);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Typography>%</Typography>
                    </InputAdornment>
                  ),
                }}
                value={!this.state.rowChange || (!this.state.rowChange.senApp && this.state.rowChange.senApp !== '') ? this.calculateDefaults('sensitivity') : this.state.rowChange.senApp}
                helperText={
                  !this.state.rowChange || (!this.state.rowChange.senApp && this.state.rowChange.senApp !== '') || parseFloat(this.state.rowChange.senApp) === this.calculateDefaults('sensitivity') ? (
                    <span className={classes.blueText}>(System Default)</span>
                  ) : this.state.rowChange.senApp === '' ? (
                    <span className={classes.redText}>(Enter a Value!)</span>
                  ) : (
                    ''
                  )
                }
              />
              <TextField
                variant='standard'
                className={classes.textField}
                label='Stroke Speed Target'
                type='number'
                onChange={(event) => {
                  if (event.target.value === '' || (!isNaN(event.target.value) && event.target.value >= 0 && event.target.value.indexOf('e') === -1)) {
                    let currentRow = this.state.rowChange;
                    currentRow.strokeApp =
                      /*event.target.value === ''
                      ? this.calculateDefaults('stroke')
                      :*/ event.target.value;

                    this.setState(currentRow);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Typography>s</Typography>
                    </InputAdornment>
                  ),
                }}
                value={!this.state.rowChange || (!this.state.rowChange.strokeApp && this.state.rowChange.strokeApp !== '') ? this.calculateDefaults('stroke') : this.state.rowChange.strokeApp}
                helperText={
                  !this.state.rowChange ||
                  (!this.state.rowChange.strokeApp && this.state.rowChange.strokeApp !== '') ||
                  parseFloat(this.state.rowChange.strokeApp) === this.calculateDefaults('stroke') ? (
                    <span className={classes.blueText}>(System Default)</span>
                  ) : this.state.rowChange.strokeApp === '' ? (
                    <span className={classes.redText}>(Enter a Value!)</span>
                  ) : (
                    ''
                  )
                }
              />
              {/*<TextField
                  variant='standard'
                  id='date'
                  label='Change Effective'
                  type='date'
                  defaultValue={currentDay}
                  className={classes.textField}
                  onChange={(event) => {
                    let currentRow = this.state.rowChange;
                    currentRow.effectiveTargetDate = new Date(event.target.value).getTime() / 1000;
                    //console.log(currentRow);
                    this.setState(currentRow);
                  }}
                />*/}
              {/*<DatePicker
              label='Change Effective'
              value={new Date(this.state.rowChange.effectiveTargetDate * 1000)}
              date={currentDay}
              dateFunction={(startDate) => {
                let currentRow = this.state.rowChange;
                currentRow.effectiveTargetDate = startDate.getTime() / 1000;
                //console.log(currentRow);
                this.setState(currentRow);
              }}
            ></DatePicker>*/}
              {/*<Typography
                variant='subtitle1'
                color='primary'
                className={classes.rowTitle}
              >
                Performance
              </Typography>
              <Grid container spacing={4}>
                <Grid item>
                  <Typography>
                    What is the cost to the plant if the valve fails to meet its
                    performance requirements or fails during operations?
                  </Typography>
                  <SingleSelect
                    id={null}
                    label='Cost'
                    //type="sort"
                    //handleChange={this.updateSort}
                    options={['2 - Subsystem Shutdown or Sustained KPI Loss']}
                    selectedOption={'2 - Subsystem Shutdown or Sustained KPI Loss'}
                    helperText={null}
                  />
                </Grid>
                <Grid item>
                  <Typography>Does this valve have a bypass?</Typography>
                  <SingleSelect
                    id={null}
                    label='Bypass'
                    //type="sort"
                    //handleChange={this.updateSort}
                    options={['Yes', 'No']}
                    selectedOption={'No'}
                    helperText={null}
                  />
                </Grid>
              </Grid>
              <div className={classes.dividerMd}></div>
              <Typography
                variant='subtitle1'
                color='primary'
                className={classes.rowTitle}
              >
                Reliability
              </Typography>
              <Grid container spacing={4}>
                <Grid item>
                  <Typography>
                    Has this valve been tested for early mortality or metallurgy
                    issues?
                  </Typography>
                  <SingleSelect
                    id={null}
                    label='Tested'
                    //type="sort"
                    //handleChange={this.updateSort}
                    options={['Yes', 'No']}
                    selectedOption={'No'}
                    helperText={null}
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    Has the performance of this valve been suspect or caused a plant
                    upset in the past?
                  </Typography>
                  <SingleSelect
                    id={null}
                    label='Bypass'
                    //type="sort"
                    //handleChange={this.updateSort}
                    options={['Yes', 'No']}
                    selectedOption={'No'}
                    helperText={null}
                  />
                </Grid>
              </Grid>*/}
            </Grid>
            <Divider className={`${classes.dialogDivider} ${classes.accordionDialogDivider}`}></Divider>
            <Grid container justifyContent='space-between' style={{ marginBottom: -8 }}>
              <Grid item></Grid>
              <Grid item>
                <Button
                  type='text'
                  onClick={(e) => {
                    //console.log('click');
                    this.handleRowSubmit(e);
                  }}
                  disabled={this.state.loading}
                  color='secondary'
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          {/*<InputLabel shrink htmlFor='select-multiple-native'>
            Native
          </InputLabel>
          <Select
            multiple
            native
            value={this.state.selected_sites}
            // @ts-ignore Typings are not considering `native`
            onChange={this.handleChangeMultiple}
            label='Native'
            inputProps={{
              id: 'select-multiple-native',
            }}
          >
            {sites.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
            </Select>*/}
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sites: state.sites,
    locations: state.locations,
    applications: state.applications,
  };
};

export default connect(mapStateToProps, {
  fetchLocations,
  fetchApplications,
  fetchSites,
  updateFilter,
})(withStyles(styles)(SetDefaultsContent));
