import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
//import { History } from '@mui/icons-material';
import { connect } from 'react-redux';
import { reportsSetActiveTab, reportsSetActiveReport, setModule } from '../../actions/index';
import { styles } from '../styles';
import { SvgIcon } from '@mui/material';

class ReportsTabs extends React.Component {
  setActiveTab = (event, value) => {
    this.props.reportsSetActiveTab(value);
    //console.log(value);
    // !IMPORTANT //TAB MUST ALWAYS BE A MODULE OR THIS WILL CAUSE FAILURES (see Reports.js usage of setModule())
    //CHECKS PREVIOUS TAB
    if (this.props.activeTab !== value) {
      if (value === 'Prioritize') {
        this.props.reportsSetActiveReport('concern');
      } else if (value === 'Optimize') {
        this.props.reportsSetActiveReport('performance');
      }
      this.props.setModule(value);
    }
  };
  // !IMPORTANT //TAB MUST ALWAYS BE A MODULE OR THIS WILL CAUSE FAILURES (see Reports.js usage of setModule())
  render() {
    const { classes } = this.props;
    return (
      <Paper square className={classes.tabsWrapper} elevation={3}>
        <Tabs
          value={!this.props.activeTab ? 'Prioritize' : this.props.activeTab}
          onChange={this.setActiveTab}
          variant='fullWidth'
          indicatorColor='secondary'
          textColor='secondary'
          scrollButtons
          className={classes.tabs}
          allowScrollButtonsMobile
        >
          {/*<Tab
            className={classes.tab}
            icon={<History />}
            label="History"
            value="History"
            component={Link}
            to={`History`}
          />*/}
          {!this.props.prioritize ? null : (
            <Tab
              className={classes.tab}
              icon={
                <SvgIcon>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                    <path fill='none' strokeWidth='3.499965' strokeMiterlimit='10' d='M2.585 17.983L18 2.568l15.415 15.415L18 33.397z' />
                    <path d='M9.427 18.06l8.556-8.556 8.556 8.556-8.556 8.556z' />
                  </svg>
                </SvgIcon>
              }
              label='Prioritize'
              value='Prioritize'
              component={Link}
              to={`/reports/Prioritize/Concern`}
            />
          )}
          {!this.props.optimize ? null : (
            <Tab
              className={classes.tab}
              icon={
                <SvgIcon>
                  <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' x='0' y='0' version='1.1' viewBox='0 0 36 36'>
                    <defs />
                    <path id='Rectangle_2364' d='M9.7 15.5h5.6v5.6H9.7z' transform='rotate(-45.001 12.54 18.302)' />
                    <path id='Rectangle_2365' d='M15.3 9.9h5.6v5.6h-5.6z' transform='rotate(-45.001 18.063 12.68)' />
                    <path id='Rectangle_2363' d='M20.8 15.5h5.6v5.6h-5.6z' transform='rotate(-45.001 23.606 18.286)' />
                    <path className={classes.tabIconStroke} fill='none' strokeMiterlimit='10' strokeWidth='3.499965' d='M2.563 18.108L17.978 2.694l15.415 15.414-15.415 15.415z' />
                  </svg>
                </SvgIcon>
              }
              label='Optimize'
              value='Optimize'
              component={Link}
              to={`/reports/Optimize/Portfolio`}
            />
          )}
          {!this.props.stabilize ? null : (
            <Tab
              className={classes.tab}
              icon={
                <SvgIcon>
                  <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' x='0' y='0' version='1.1' viewBox='0 0 36 36'>
                    <rect
                      x='7.6'
                      y='7.7'
                      transform='matrix(0.7071 -0.7071 0.7071 0.7071 -7.7692 18.5346)'
                      class='st0'
                      width='21.8'
                      height='21.8'
                      fill='none'
                      strokeWidth='3.499965'
                      strokeMiterlimit='10'
                    />
                    <rect x='9.9' y='13.8' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.8059 16.0318)' class='st1' width='12.1' height='4.9' />
                    <rect x='15' y='18.8' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -8.9073 21.1051)' class='st1' width='12.1' height='4.9' />
                  </svg>
                </SvgIcon>
              }
              label='Stabilize'
              value='Stabilize'
              component={Link}
              to={`/reports/Stabilize/Concern`}
            />
          )}
        </Tabs>
      </Paper>
    );
  }
}

ReportsTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.reports.activeTab,
    activeReport: state.reports.activeReport,
    prioritize: state.auth.prioritize,
    optimize: state.auth.optimize,
    stabilize: state.auth.stabilize,
  };
};

export default connect(mapStateToProps, {
  reportsSetActiveTab,
  reportsSetActiveReport,
  setModule,
})(withStyles(styles)(ReportsTabs));
