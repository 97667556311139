const configDetails = {
  accuracyImportance: {
    title: `How important is it that this valve moves accurately?`,
    description: `Some valves (such as throttling valves) are required to move accurately in order to meet their intended purpose in the plant. Valves that deal with dosing, quality control, energy costs, and throughput should have accuracy requirements to ensure they will perform as intended within the application.`,
    importance: {
      Extremely: `If this valve fails to complete steps accurately then a plant outage is possible, there may be a safety issue, or millions of dollars would be lost.`,
      Very: `If this valve fails to complete steps accurately then an application/sub-unit outage is possible or close to a million dollars would be lost.`,
      Important: `If this valve fails to complete steps accurately then significant dollars would be lost.`,
      Standard: `This valve is used as a modulating valve but no significant function or dollars are tied to its performance.`,
      Not: `This valve has no measured criteria for accuracy (ex: on-off valve)`,
    },
    recommendations: {
      Rec1: `Once you have answered each question in the recommendations section then you can then select the ‘View Recommendations’ button. This will give you suggested targets for valves that do not have pre-established performance criteria.`,
      Rec2: `You can adjust these recommendations manually if they do not meet the plant's needs or the plant has predefined performance criteria. In general, targets should be set based on the worst performance the plant can tolerate before significant consequences would occur. This ensures that valves are always acted upon when financial consequence would occur, rather than incurring needless expense to over-engineer valves.`,
      Rec3: `Targets will not be confirmed into the system until the submit button is pressed. You can change the day these targets are made effective if you would like recent tests to be held to this new standard. If you do not change the default date, then only tests conducted after today will be affected by the new targets.`,
    },
  },
  speedImportance: {
    title: `How important is it that this valve moves quickly?`,
    description: `Some valves (such as ‘vent’ valves) are required to move quickly in order to meet their intended purpose in the plant. Valves that need to make large movements to address upsets, prevent sub-unit shutdowns from cascading, or to minimize damage to equipment should have stroke speed criteria to ensure they will perform as intended within the application.`,
    importance: {
      Extremely: `If this valve fails to complete steps quickly then a plant outage is possible, there may be a safety issue, or millions of dollars would be lost.`,
      Very: `If this valve fails to complete steps quickly then an application/sub-unit outage is possible or close to a million dollars would be lost.`,
      Important: `If this valve fails to complete steps quickly then significant dollars would be lost.`,
      Standard: `This valve is used to make large steps but no significant function or dollars are tied to its performance.`,
      Not: `This valve has no measured criteria for speed (ex: a valve that ramps over minutes)`,
    },
    recommendations: {
      Rec1: `Once you have answered each question in the recommendations section then you can then select the ‘View Recommendations’ button. This will give you suggested targets for valves that do not have pre-established performance criteria.`,
      Rec2: `You can adjust these recommendations manually if they do not meet the plant's needs or the plant has predefined performance criteria. In general, targets should be set based on the worst performance the plant can tolerate before significant consequences would occur. This ensures that valves are always acted upon when financial consequence would occur, rather than incurring needless expense to over-engineer valves.`,
      Rec3: `Targets will not be confirmed into the system until the submit button is pressed. You can change the day these targets are made effective if you would like recent tests to be held to this new standard. If you do not change the default date, then only tests conducted after today will be affected by the new targets.`,
    },
  },
  reliabilityImportance: {
    title: `How important is it that this valve remains reliable?`,
    description: `Even if a valve has no important performance criterion, it can still be important because of reliability purposes. An example is a valve that moves to full open as the plant starts up and goes to full close as the plant shuts down. If that valve fails, it can still potentially take the plant offline.  For these types of valves, ensuring diagnostic checks are in good order becomes increasingly important. For very important and extremely important valves, certain ‘yellow light’ checks become strongly recommended repairs and ‘red light’ checks should be addressed as soon as possible.`,
    importance: {
      Extremely: `If this valve fails in-line then a plant outage is possible, there may be a safety issue, or millions of dollars would be lost.`,
      Very: `If this valve fails to complete steps accurately then an application/sub-unit outage is possible or close to a million dollars would be lost.`,
      Important: `If this valve fails in-line then significant dollars would be lost.`,
      Standard: `This valve has no significant function or dollars tied to its performance.`,
      Not: `This valve has no measured criteria for reliability (ex: this valve is not a single point-of-failure)`,
    },
    recommendations: {
      Rec1: `Once you have answered each question in the recommendations section then you can then select the ‘View Recommendations’ button. This will give you suggested targets for valves that do not have pre-established performance criteria.`,
      Rec2: `You can adjust these recommendations manually if they do not meet the plant's needs or the plant has predefined performance criteria. In general, targets should be set based on the worst performance the plant can tolerate before significant consequences would occur. This ensures that valves are always acted upon when financial consequence would occur, rather than incurring needless expense to over-engineer valves.`,
      Rec3: `Targets will not be confirmed into the system until the submit button is pressed. You can change the day these targets are made effective if you would like recent tests to be held to this new standard. If you do not change the default date, then only tests conducted after today will be affected by the new targets.`,
    },
  },
  importance: {
    title: `How important is this valve?`,
    description: `In general, a valve’s importance should be a direct function of its needs to move accurately, quickly, or function. Typically, the importance of a valve should be the highest importance determined for each of these criteria. For example, a valve that is extremely important for modulating an expensive material is still extremely important to financial outcomes even if it modulates slowly and a bypass is available in the event of failure.`,
    importance: {
      Extremely: `This valve has accuracy, speed, or reliability consequences that are extremely important (plant outage possible, safety issue, or millions of dollars lost).`,
      Very: `This valve has accuracy, speed, or reliability consequences that are very important (application/sub-unit outage possible, close to a million dollars lost).`,
      Important: `This valve has accuracy, speed, or reliability consequences that are important (significant dollars lost).`,
      Standard: `This valve has no accuracy, speed, or reliability consequences that are important (ex: non-essential valve that controls, functional and/or sub-optimal performance is acceptable, etc.)`,
      Not: `This valve has no measured criteria for accuracy, speed, or reliability consequences (ex: on-off valve, can make movements over minutes, with bypass, etc.).`,
    },
    recommendations: {
      Rec1: `Once you have answered each question in the recommendations section then you can then select the ‘View Recommendations’ button. This will give you suggested targets for valves that do not have pre-established performance criteria.`,
      Rec2: `You can adjust these recommendations manually if they do not meet the plant's needs or the plant has predefined performance criteria. In general, targets should be set based on the worst performance the plant can tolerate before significant consequences would occur.  This ensures that valves are always acted upon when financial consequence would occur, rather than incurring needless expense to over-engineer valves.`,
      Rec3: `Targets will not be confirmed into the system until the submit button is pressed. You can change the day these targets are made effective if you would like recent tests to be held to this new standard. If you do not change the default date, then only tests conducted after today will be affected by the new targets.`,
    },
  },
};

export default configDetails;
