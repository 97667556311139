import axios from 'axios';
//console.log(window.location.origin);
let baseURL = 'https://www.ten-one.app/api/api/v1';
if (
  typeof window !== 'undefined' &&
  window.location.origin.indexOf('localhost') !== -1
  // window.location.origin ===  'http://localhost:3000' ||
  // window.location.origin === 'https://localhost:3000'
) {
  baseURL = 'https://localhost:4000/api/v1';
}
if (
  typeof window !== 'undefined' &&
  (window.location.origin === 'http://ten-one.io' ||
    window.location.origin === 'https://ten-one.io' ||
    window.location.origin === 'http://www.ten-one.io' ||
    window.location.origin === 'https://www.ten-one.io')
) {
  baseURL = 'https://www.ten-one.io/api/api/v1';
}
var client = axios.create({
  //baseURL: 'https://98.116.214.188:4010/api/v1/',
  //baseURL: 'https://68.129.207.55:4000/api/v1',
  //baseURL: 'https://localhost:4000/api/v1',
  //baseURL: 'https://www.ten-one.app/api/api/v1',
  baseURL,
  responseType: 'json',
  withCredentials: true,
  //timeout: 5*60*1000 //5m timeout
});

//TODO EXAMINE IF THIS IS BEST PRACTICE FOR 401 UNAUTHORIZED ERRORS AND ADD GLOBAL HANDLING FOR UNAUTHORIZED EVENTS
// Potentially handle in the reducers!
// https://blog.logrocket.com/data-fetching-in-redux-apps-a-100-correct-approach-4d26e21750fc/
// https://code-maze.com/react-net-core-error-handling/
// https://stackoverflow.com/questions/34403269/what-is-the-best-way-to-deal-with-a-fetch-error-in-react-redux/34403521#34403521
const UNAUTHORIZED = 401;
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status } = !error.response ? 200 : error.response;
    if (status === UNAUTHORIZED) {
      let path = window.location.pathname;
      if (path !== '/' && path !== '/login' && path !== '/forgot' && window.location.pathname !== '/reset-password' && window.location.pathname !== '/signup') {
        //prevent infinite loop
        window.location.assign('/'); //send them to the main page to login
      }
      //window.location.reload();
      return Promise.reject(error);
    }
    if (!error.response) {
      return Promise.reject(error);
    }
    return Promise.reject(error.response);
  }
);

export default client;

// TODO REMOVE WHEN DONE FIXING CORS
var client2 = axios.create({
  //baseURL: 'https://98.116.214.188:4010/api/v1/',
  //baseURL: 'https://68.129.207.55:4000/api/v1',
  //baseURL: 'https://localhost:4000/api/v1',
  baseURL: 'https://68.129.207.55:8082/api/api/v1',
  responseType: 'json',
  withCredentials: false,
  //timeout: 5*60*1000 //5m timeout
});

//TODO EXAMINE IF THIS IS BEST PRACTICE FOR 401 UNAUTHORIZED ERRORS AND ADD GLOBAL HANDLING FOR UNAUTHORIZED EVENTS
// Potentially handle in the reducers!
// https://blog.logrocket.com/data-fetching-in-redux-apps-a-100-correct-approach-4d26e21750fc/
// https://code-maze.com/react-net-core-error-handling/
// https://stackoverflow.com/questions/34403269/what-is-the-best-way-to-deal-with-a-fetch-error-in-react-redux/34403521#34403521
//const UNAUTHORIZED = 401;
client2.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = !error.response ? 200 : error.response;
    if (status === UNAUTHORIZED) {
      return Promise.reject(error);
      //history.push('/login');
    }
    return Promise.reject(error.response);
  }
);
export { client2 };
