import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';

class Bugsplat extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <Typography variant='h5'>Oops... Something went wrong.</Typography>
          <Button
            className={classes.refreshButton}
            onClick={this.refreshPage}
            component={Link}
            to='/'
          >
            Refresh Page
          </Button>
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}

export default withStyles(styles, { withTheme: true })(Bugsplat);
