import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import CustomSelect from '../../../filters/CustomSelect';
import TableComponent from '../../../charts/Table';
import api from '../../../../apis/api';
import { fetchLoopDevicesSummary } from '../../../../actions/index';

class DeviceContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      acknowledgements: {},
      diagnostics: {},
      status: '',
      statusMessage: '',
      rowChange: {},
      clickedRow: !this.props.row ? null : this.props.row.id,
    };
  }
  componentDidMount() {
    let device = !this.props.row ? {} : this.props.devices[this.props.row.id];
    this.setState((state) => ({
      rowChange: {
        id: device.device_id,
        name: device.name,
        client: device.client,
        site: device.site,
        application: device.applicationType,
        description: device.description,
        deviceType: !device.deviceType ? 'Unknown' : device.deviceType,
        importance: !device.importance ? 'Standard' : device.importance,
        processName: device.processName,
        processId: device.processid,
        applicationOrder: !device.applicationOrder || device.applicationOrder === 0 ? 1 : device.applicationOrder,
        deviceOrder: !device.deviceOrder || device.deviceOrder === 0 ? 1 : device.deviceOrder,
      },
    }));
  }
  validateDeviceEntry = (data) => {
    //console.log(data);
    let valid = true;
    let emptyStr = '';
    if (!data.application || data.application === '') {
      emptyStr += 'Application';
      valid = false;
    }
    if (!data.client || data.client === '') {
      if (emptyStr !== '') {
        emptyStr += ', ';
      }
      emptyStr += 'Client';
      valid = false;
    }
    if (!data.site || data.site === '') {
      if (emptyStr !== '') {
        emptyStr += ', ';
      }
      emptyStr += 'Site';
      valid = false;
    }
    if (!data.deviceType || data.deviceType === '') {
      if (emptyStr !== '') {
        emptyStr += ', ';
      }
      emptyStr += 'Device Type';
      valid = false;
    }
    if (!data.importance || data.importance === '') {
      if (emptyStr !== '') {
        emptyStr += ', ';
      }
      emptyStr += 'Importance';
      valid = false;
    }
    if (!data.name || data.name === '') {
      if (emptyStr !== '') {
        emptyStr += ', ';
      }
      emptyStr += 'Device Name';
      valid = false;
    }
    let nameFlag = this.validateDeviceName(data);
    if (nameFlag) {
      valid = false;
    }

    let errorStr = 'Error: '; // TODO GET RID OF 'ERROR:' WHEN SETTING VALID TO BE FUNCTION
    errorStr += !nameFlag ? '' : `Device Name ${data.name} is already taken. `;
    errorStr += emptyStr === '' ? '' : `${emptyStr} is blank. `;

    return { valid: valid, message: errorStr };
  };

  validateDeviceName = (data) => {
    let deviceKeys = Object.keys(this.props.devices);
    let nameFlag = false;
    for (let i = 0; i < deviceKeys.length; i++) {
      let device = this.props.devices[deviceKeys[i]];
      if (device.name === data.name && device.device_id !== data.id) {
        nameFlag = true;
      }
    }
    return nameFlag;
  };
  handleSubmit = async () => {
    try {
      //console.log(this.state.rowChange);
      // put in status checks, error messages, ect (ref user config)
      const userData = this.state.rowChange;
      const url = `prioritize/devices/${this.state.rowChange.id}/configure`; //`
      //console.log(userData);
      //console.log('submit'); //await api.post(url, userData);
      const validate = this.validateDeviceEntry(userData);
      //console.log(validate);
      if (validate.valid) {
        this.setState({
          loading: true,
          status: 'Success',
          statusMessage: 'Processing...',
        });
        const response = await api.post(url, userData);
        //console.log(response);
        //const response = { data: { changedRows: 1 } };
        if (response.data.changedRows > 0) {
          this.setState({ status: 'Success', statusMessage: 'Success!' });
          //console.log('t');
          this.props.fetchLoopDevicesSummary();
        }
      } else {
        this.setState({
          status: 'Failure',
          statusMessage: validate.message,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
        loading: false,
      });
      console.log(e);
    }
  }; //`
  renderChildRowContent = (row) => {
    const { classes } = this.props;
    const shutdownHeaderArr = [
      { id: 'type', label: 'Type' },
      { id: 'condition', label: 'Condition' },
      //{ id: 'duration', label: 'Duration' },
      //{ id: 'note', label: 'Note' },
      //{ id: 'menu', label: '' },
    ];
    let shutdownData = [
      {
        id: 1,
        startDate: 'Tim',
        endDate: 'Tim',
        duration: 'Tim',
        note: 'Tim',
        menu: 'Menu',
      },
      {
        id: 2,
        startDate: 'Tim2',
        endDate: 'Tim2',
        duration: 'Tim2',
        note: 'Tim2',
        menu: 'Menu',
      },
    ];
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    let loopList = [];
    let loopIdList = {};
    for (const loopKey in this.props.loops) {
      let loop = this.props.loops[loopKey];
      loopList.push(loop.processName);
      loopIdList[loop.processName] = loop.processId;
    }
    loopList = loopList.sort();
    return (
      <form
      /* onSubmit={(e) => {
          e.preventDefault();
          console.log('submitted');
          this.handleSubmit();
          //this.setState({ changeConfirm: true });
        }}*/
      >
        {this.props.create ? null : (
          <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
            Device Configuration {!this.props.deviceName ? null : this.props.deviceName}
          </Typography>
        )}
        <Grid>
          <TextField
            variant='standard'
            className={classes.textField}
            label='Device Name'
            onChange={(event) => {
              //console.log(row.id);
              let currentRow = this.state.rowChange;
              currentRow.name = event.target.value;
              this.setState({
                changeRow: currentRow,
                // tagError: row.id === -1 ? false : this.checkDuplicate(event.target.value, 'name', device.valveIDHistory[currentSerialIndex], this.state.rowChange.deviceId, //currentSerialIndex),
              });
            }}
            defaultValue={!this.state.clickedRow || this.state.rowChange.name === null || this.props.devices[this.state.clickedRow].name === null ? '' : this.props.devices[this.state.clickedRow].name}
          />
          {this.state.tagError ? <Typography color='error'>Duplicate Tag # / Serial Combination.</Typography> : null}
          {/*<TextField
          variant='standard'
          className={classes.textField}
          label='Client'
          onChange={(event) => {
            let currentRow = this.state.rowChange;
            currentRow.client = event.target.value;
            this.setState(currentRow);
          }}
          defaultValue={!this.state.clickedRow || !this.props.devices[this.state.clickedRow].client ? '' : this.props.devices[this.state.clickedRow].client}
        />
        <TextField
          variant='standard'
          className={classes.textField}
          label='Site'
          onChange={(event) => {
            let currentRow = this.state.rowChange;
            currentRow.site = event.target.value;
            this.setState(currentRow);
          }}
          defaultValue={!this.state.clickedRow || this.state.rowChange.site === null ? '' : this.props.devices[this.state.clickedRow].site}
        />
        <TextField
          variant='standard'
          className={classes.textField}
          label='Application'
          onChange={(event) => {
            let currentRow = this.state.rowChange;
            currentRow.application = event.target.value;
            this.setState(currentRow);
          }}
          defaultValue={!this.state.clickedRow || this.state.rowChange.application === null ? '' : this.props.devices[this.state.clickedRow].applicationType}
        />*/}
          <TextField
            variant='standard'
            className={classes.textField}
            label='Description'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.description = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={
              !this.state.clickedRow || this.state.rowChange.description === null || !this.props.devices[this.state.clickedRow].description ? '' : this.props.devices[this.state.clickedRow].description
            }
          />
          <CustomSelect
            single
            id={null}
            label='Device Type'
            //type='sort'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={(event, value) => {
              //console.log(event);
              let currentRow = this.state.rowChange;
              currentRow.deviceType = value;
              //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
              //console.log(currentRow);
              this.setState(currentRow);
            }}
            values={!this.props.devicetypes || this.props.devicetypes.length === 0 ? 'Unknown' : this.state.rowChange.deviceType}
            options={!this.props.devicetypes || this.props.devicetypes.length === 0 ? ['Unknown'] : this.props.devicetypes.sort()}
            //value={null}
            helperText={null}
          />

          {
            /* TODO Prio_1.0 if device type = valve */
            !this.state.rowChange || !this.state.rowChange.deviceType || this.state.rowChange.deviceType !== 'Valve' ? null : (
              <CustomSelect
                single
                id={null}
                label='Full Open/Close Valve'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  //console.log(event);
                  //!FINISH
                  let currentRow = this.state.rowChange;
                  currentRow.valveClose = value;
                  //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
                  //console.log(currentRow);
                  this.setState(currentRow);
                }}
                options={['Yes', 'No', 'Unknown']}
                selectedOption={!this.state.rowChange || !this.state.rowChange.valveClose ? 'Unknown' : this.state.rowChange.valveClose}
                //values={}
                helperText={null}
              />
            )
          }

          <CustomSelect
            single
            id={null}
            label='Importance'
            //type='sort'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={(event, value) => {
              //console.log(event);
              let currentRow = this.state.rowChange;
              currentRow.importance = value;
              //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
              //console.log(currentRow);
              this.setState(currentRow);
            }}
            values={!this.state.rowChange.importance ? 'Unknown' : this.state.rowChange.importance}
            options={['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important', 'Unknown']}
            //value={null}
            helperText={null}
          />
          {/* TODO Prio_1.0*/}
          <CustomSelect
            single
            id={null}
            label='Paired Device'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={(event, value) => {
              //console.log(event);
              //!FINISH, do we allow multiples, BUILD RETRIEVE DEVICENAMES FUNCTIONS
              let currentRow = this.state.rowChange;
              currentRow.pairedDevice = value;
              //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
              //console.log(currentRow);
              this.setState(currentRow);
            }}
            options={['Unknown']}
            //values={}
            values={!this.state.rowChange.pairedDevice ? 'Unknown' : this.state.rowChange.pairedDevice}
            helperText={null}
          />
          {/*console.log(this.props)*/}
          <CustomSelect
            single
            id={null}
            label='Redundant Device'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={(event, value) => {
              //console.log(event);
              //!FINISH, do we allow multiples, BUILD RETRIEVE DEVICENAMES FUNCTIONS
              let currentRow = this.state.rowChange;
              currentRow.redundantDevice = value;
              //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
              //console.log(currentRow);
              this.setState(currentRow);
            }}
            options={['Unknown']}
            values={!this.state.rowChange.redundantDevice ? 'Unknown' : this.state.rowChange.dedundantDevice}
            helperText={null}
          />
          <TextField
            variant='standard'
            className={classes.textField}
            label='Shutdown Value (Low)'
            type='number'
            onChangeFunction={(event, value) => {
              //console.log(event);
              //!FINISH
              let currentRow = this.state.rowChange;
              currentRow.shutdownLow = value;
              //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
              //console.log(currentRow);
              this.setState(currentRow);
            }}
            defaultValue={''}
          />
          <TextField
            variant='standard'
            className={classes.textField}
            label='Shutdown Value (High)'
            type='number'
            onChangeFunction={(event, value) => {
              //console.log(event);
              //!FINISH
              let currentRow = this.state.rowChange;
              currentRow.shutdownHigh = value;
              //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
              //console.log(currentRow);
              this.setState(currentRow);
            }}
            defaultValue={''}
          />
        </Grid>
        {/*<CustomSelect
          single
          id={null}
          label='Application Order'
          //type='sort'
          disabled={this.state.loading}
          //onCloseFunction={this.multiSelectOnClose}
          onChangeFunction={(event, value) => {
            //console.log(event);
            let currentRow = this.state.rowChange;
            currentRow.applicationOrder = parseInt(value);
            //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
            //console.log(currentRow);
            this.setState(currentRow);
          }}
          values={
            !this.state.clickedRow || !this.state.rowChange.applicationOrder || this.state.rowChange.applicationOrder === null || !this.props.devices[this.state.clickedRow].applicationOrder
              ? '1'
              : this.state.rowChange.applicationOrder.toString()
          }
          options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']}
          //value={null}
          helperText={null}
        />*/}
        {/*<ListItem role={undefined} dense className={false ? classes.checklistItemActive : classes.checklistItemSelectable} style={{ cursor: 'pointer', marginTop: 20, marginBottom: 10 }}>
              <Checkbox
                checked={false}
                onClick={(e) => {
                  this.handleLoopClick();
                }}
                className={classes.checkbox}
                color='secondary'
              ></Checkbox>
              <ListItemText
                onClick={(e) => {
                  this.handleLoopClick();
                }}
                primary={<Typography variant='subtitle2'>Is this device in a loop?</Typography>}
                classes={this.props.format ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }}
              />
              </ListItem>*/}
        {/*<TextField
          variant='standard'
          className={classes.textField}
          label='Loop Name'
          onChange={(event) => {
            //console.log(row.id);
            let currentRow = this.state.rowChange;
            currentRow.processName = event.target.value;
            this.setState({
              changeRow: currentRow,
              //tagError: row.id === -1 ? false : this.checkDuplicate(event.target.value, 'name', device.valveIDHistory[currentSerialIndex], this.state.rowChange.deviceId, //currentSerialIndex),
            });
          }}
          
          defaultValue={
            !this.state.clickedRow || this.state.rowChange.processName === null || this.props.devices[this.state.clickedRow].processName === null
              ? ''
              : this.props.devices[this.state.clickedRow].processName
          }
        />*/}
        <Grid>
          <CustomSelect
            single
            id={null}
            label='Loop'
            //type='sort'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={(event, value) => {
              //console.log(row.id);
              let currentRow = this.state.rowChange;
              currentRow.processName = value;
              currentRow.processId = loopIdList[value];
              console.log(currentRow);
              this.setState({
                changeRow: currentRow,
                //tagError: row.id === -1 ? false : this.checkDuplicate(event.target.value, 'name', device.valveIDHistory[currentSerialIndex], this.state.rowChange.deviceId, //currentSerialIndex),
              });
            }}
            values={!this.state.rowChange.processName ? 'No Loop Selected' : this.state.rowChange.processName}
            options={!loopList ? [this.props.devices[this.state.clickedRow].processName] : loopList}
            //value={null}
            helperText={null}
          />
          <CustomSelect
            single
            id={null}
            label='Device Order'
            //type='sort'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={(event, value) => {
              //console.log(event);
              let currentRow = this.state.rowChange;
              currentRow.deviceOrder = parseInt(value);
              //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
              //console.log(currentRow);
              this.setState(currentRow);
            }}
            values={!this.state.rowChange.deviceOrder || this.state.rowChange.deviceOrder === null ? '1' : this.state.rowChange.deviceOrder.toString()}
            options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']}
            //value={null}
            helperText={null}
          />
        </Grid>
        {this.props.create || true ? null : (
          <React.Fragment>
            <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
              Shutdown Information
            </Typography>
            <Grid item xs={12}>
              <TableComponent data={shutdownData} header={shutdownHeaderArr} rows={25} tileTable printHidePagination></TableComponent>
            </Grid>
          </React.Fragment>
        )}

        <Grid container justifyContent='space-between' style={{ marginBottom: -8 }}>
          <Grid item>
            {this.state.status !== '' ? (
              <Typography color={color} variant='subtitle1' style={{ marginTop: 3 }}>
                {this.state.statusMessage}
              </Typography>
            ) : null}
          </Grid>
          <Grid item>
            {!this.props.closeFunction ? null : (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.closeFunction();
                }}
                type='text'
                color='secondary'
                value='Close'
                style={{ marginRight: 8 }}
              >
                Close
              </Button>
            )}
            <Button
              onClick={(e) => {
                e.preventDefault();
                //console.log('submitted');
                this.handleSubmit();
              }}
              color='secondary'
              variant='text'
              type='submit'
              className={classes.buttonFilter}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.dialogDivider} style={{ margin: '10px -16px 16px -16px' }}></Divider>
      </form>
    );
  };

  render() {
    //console.log(this.props.row.id, this.props.clickedRow);

    return <React.Fragment>{this.renderChildRowContent()}</React.Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    realms: state.auth.realms,
    current_realm: state.auth.current_realm,
    permission: state.auth.view,
    sites: state.sites,
    locations: state.locations,
    clients: state.clients,
    applications: state.applications,
    devices: state.devices.devices,
    loops: state.loops.loops,
    devicetypes: state.devicetypes.list,
    shutdowns: state.shutdowns,
  };
};

export default connect(mapStateToProps, {
  //fetchDiagnostics,
  fetchLoopDevicesSummary,
  //fetchDeviceShutdowns,
  //setTestStatus,
})(withStyles(styles)(DeviceContent));
