import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { Grid, Paper, Typography } from '@mui/material';
import TableWidget from '../../../widgets/TableWidget';
import WidgetTitle from '../../../headers/WidgetTitle';
import CustomSelect from '../../../filters/CustomSelect';
import functions from '../../../../functions/functions';
import DatePicker from '../../../filters/DatePicker';

import { fetchDevices, fetchDeviceByTest, fetchDevicesSummary, setDevicesSummaryStatus } from '../../../../actions/index';

const headerArr = [
  { id: 'valveFormatted', label: 'Valve Info' },
  { id: 'date', label: 'Last Test Date' },
  { id: 'consequencesFormatted', label: 'Potential Consequences' },
  { id: 'importance', label: 'Importance' },
  //combined as resolutionResultFormatted
  //{ id: 'resolutionApp', label: 'Resolution Target' },
  //{ id: 'resolutionResult', label: 'Resolution' },
  //{ id: 'sensitivityApp', label: 'Sensitivity Target' },
  //{ id: 'sensitivityResult', label: 'Sensitivity' },
  //{ id: 'strokeApp', label: 'Stroke Speed Target' },
  //{ id: 'strokeResult', label: 'Stroke Speed' },
  { id: 'resolutionResultFormatted', label: 'Causes' },
  { id: 'improvementsFormatted', label: 'Improvement Paths' },
  { id: 'cost', label: 'Estimated Cost' },
  { id: 'roi', label: 'ROI Index' },
];
const newData = [
  {
    id: 1,
    name: 'C9_AO_1121',
    site: 'Texas',
    unit: 'C9',
    application: 'Application 1',
    customer: 'Arika Energy',
    date: '2/1/2021',
    consequences: ['Throughput Loss', 'Outage'],
    importance: 'Extremely Important',
    resolutionApp: 0.5,
    resolutionResult: 1.5,
    sensitivityApp: 0.25,
    sensitivityResult: 0.31,
    strokeApp: 8,
    strokeResult: 10,
    level1: 2,
    level2: 1,
    level3: 0,
    cost: '$1,000 - $5,000',
    roi: '5',
  },
];
for (let i = 0; i < newData.length; i++) {
  newData[i].valveFormatted = (
    <React.Fragment>
      <Typography variant='subtitle2'>{newData[i].name}</Typography>
      <Typography variant='subtitle2'>{newData[i].site}</Typography>
      <Typography variant='subtitle2'>{newData[i].unit}</Typography>
      <Typography variant='subtitle2'>{newData[i].application}</Typography>
      <Typography variant='subtitle2'>{newData[i].customer}</Typography>
    </React.Fragment>
  );
  newData[i].consequencesFormatted = (
    <React.Fragment>
      {newData[i].consequences.map((consequence) => (
        <Typography key={consequence} style={{ marginBottom: 10 }} variant='subtitle2'>
          {consequence}
        </Typography>
      ))}
    </React.Fragment>
  );
  newData[i].resolutionResultFormatted = (
    <React.Fragment>
      <Typography variant='subtitle2'>Resolution: {Math.round((newData[i].resolutionResult - newData[i].resolutionApp) * 100) / 100}%</Typography>
      <Typography variant='subtitle2'>Sensitivity: {Math.round((newData[i].sensitivityResult - newData[i].sensitivityApp) * 100) / 100}%</Typography>
      <Typography variant='subtitle2'>Stroke Speed: {Math.round((newData[i].strokeResult - newData[i].strokeApp) * 100) / 100}s</Typography>
    </React.Fragment>
  );
  /*newData[i].resolutionFormatted = (
    <React.Fragment>
      <Typography variant='subtitle2'>
        As Found:{' '}
        <span
          style={
            newData[i].resolutionResultFound > newData[i].resolutionApp
              ? { color: '#CC4848' }
              : newData[i].resolutionResultFound < newData[i].resolutionApp
              ? { color: '#56B260' }
              : { color: '#e3a61b' }
          }
        >
          {Math.round(newData[i].resolutionResultFound * 100) / 100 + '%'}
        </span>
      </Typography>
      <Typography variant='subtitle2'>
        As Left:{' '}
        <span
          style={
            newData[i].resolutionResult > newData[i].resolutionApp
              ? { color: '#CC4848' }
              : newData[i].resolutionResult < newData[i].resolutionApp
              ? { color: '#56B260' }
              : { color: '#e3a61b' }
          }
        >
          {Math.round(newData[i].resolutionResult * 100) / 100 + '%'}
        </span>
      </Typography>
    </React.Fragment>
  );
  newData[i].sensitivityFormatted = (
    <React.Fragment>
      <Typography variant='subtitle2'>
        As Found:{' '}
        <span
          style={
            newData[i].sensitivityResultFound > newData[i].sensitivityApp
              ? { color: '#CC4848' }
              : newData[i].sensitivityResultFound < newData[i].sensitivityApp
              ? { color: '#56B260' }
              : { color: '#e3a61b' }
          }
        >
          {Math.round(newData[i].sensitivityResultFound * 100) / 100 + '%'}
        </span>
      </Typography>
      <Typography variant='subtitle2'>
        As Left:{' '}
        <span
          style={
            newData[i].sensitivityResult > newData[i].sensitivityApp
              ? { color: '#CC4848' }
              : newData[i].sensitivityResult < newData[i].sensitivityApp
              ? { color: '#56B260' }
              : { color: '#e3a61b' }
          }
        >
          {Math.round(newData[i].sensitivityResult * 100) / 100 + '%'}
        </span>
      </Typography>
    </React.Fragment>
  );
  newData[i].strokeFormatted = (
    <React.Fragment>
      <Typography variant='subtitle2'>
        As Found:{' '}
        <span
          style={
            newData[i].strokeResultFound > newData[i].strokeApp
              ? { color: '#CC4848' }
              : newData[i].strokeResultFound < newData[i].strokeApp
              ? { color: '#56B260' }
              : { color: '#e3a61b' }
          }
        >
          {Math.round(newData[i].strokeResultFound * 100) / 100 + 's'}
        </span>
      </Typography>
      <Typography variant='subtitle2'>
        As Left:{' '}
        <span
          style={
            newData[i].strokeResult > newData[i].strokeApp
              ? { color: '#CC4848' }
              : newData[i].strokeResult < newData[i].strokeApp
              ? { color: '#56B260' }
              : { color: '#e3a61b' }
          }
        >
          {Math.round(newData[i].strokeResult * 100) / 100 + 's'}
        </span>
      </Typography>
    </React.Fragment>
        );*/
  newData[i].improvementsFormatted = (
    <React.Fragment>
      <Typography variant='subtitle2'>{newData[i].level1} Field Adjustments</Typography>
      <Typography variant='subtitle2'>{newData[i].level2} Parts Replacements</Typography>
      <Typography variant='subtitle2'>{newData[i].level3} Body Repairs</Typography>
    </React.Fragment>
  );
}
class RoiReport extends React.Component {
  render() {
    //const checkTime = 1616185928;
    //const deviceKeys = Object.keys(this.props.devices);
    //const validKeys = deviceKeys.filter((key) => this.props.devices[key].lastTestDate > checkTime);
    /*const validDevices = validKeys.reduce((obj, key) => {
      obj[key] = this.props.devices[key];
      return obj;
    }, {});*/
    //console.log(validKeys);
    //console.log(validDevices);
    //const recentTests = this.props.devices;
    //console.log(recentTests);
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    const { classes } = this.props;
    return (
      <React.Fragment>
        <form className={classes.form} autoComplete='off'>
          <CustomSelect
            id={null}
            label='Customer'
            //type='site'
            options={['Customer 1', 'Customer 2']}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={(e, v) => {
              //TODO THIS IS A DUMMY MULTIPLE SELECT, DOES NOTHING
              console.log(v);
            }}
            //selectedOption={'Resolution'}
            values={'Customer 1'}
            helperText={null}
          />

          <CustomSelect
            id={null}
            label='Site'
            //type='site'
            options={!this.props.sites || !this.props.sites.list ? [] : this.props.sites.list.sort()}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //selectedOption={'Resolution'}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Location'
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            options={!this.props.locations || !this.props.locations.list ? [] : this.props.locations.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Application'
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='application'
            options={!this.props.applications || !this.props.applications.list ? [] : this.props.applications.list.sort()}
            value={null}
            helperText={null}
          />
          <DatePicker
            label='Start Date'
            date={`${new Date().getFullYear() - 2}-01-01T12:00:00`}
            dateFunction={(startDate) => {
              this.updateDateRange(startDate, 'start');
            }}
          ></DatePicker>
          <DatePicker
            label='End Date'
            dateFunction={(endDate) => {
              this.updateDateRange(endDate, 'end');
            }}
          ></DatePicker>
        </form>
        <WidgetTitle title='Valve Summary' />
        <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
          <Grid container align='center' spacing={2} justifyContent='space-between'>
            <Grid item align='center'>
              <Typography color='secondary' variant='subtitle2'>
                Valves Investigated
              </Typography>
              <Typography variant='body1'>2</Typography>
            </Grid>
            <Grid item>
              <Typography color='secondary' variant='subtitle2'>
                Potential Consequences
              </Typography>
              <Typography variant='body1'>1</Typography>
            </Grid>
            <Grid item>
              <Typography color='secondary' variant='subtitle2'>
                Extremely Important
              </Typography>
              <Typography variant='body1'>2</Typography>
            </Grid>
            <Grid item>
              <Typography color='secondary' variant='subtitle2'>
                Very Important
              </Typography>
              <Typography variant='body1'>2</Typography>
            </Grid>
            <Grid item>
              <Typography color='secondary' variant='subtitle2'>
                Important
              </Typography>
              <Typography variant='body1'>2</Typography>
            </Grid>
            <Grid item>
              <Typography color='secondary' variant='subtitle2'>
                Standard
              </Typography>
              <Typography variant='body1'>2</Typography>
            </Grid>
            <Grid item>
              <Typography color='secondary' variant='subtitle2'>
                Unimportant
              </Typography>
              <Typography variant='body1'>2</Typography>
            </Grid>
          </Grid>
        </Paper>
        <WidgetTitle title='ROI Opportunities' />
        <TableWidget header={headerArr} data={newData}></TableWidget>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { devices: state.devices.devices };
};

export default connect(mapStateToProps, {
  fetchDevices,
  fetchDeviceByTest,
  fetchDevicesSummary,
  setDevicesSummaryStatus,
})(withStyles(styles)(RoiReport));
