import { SET_PRINT } from '../actions/types';

const INITIAL_STATE = {
  print: false,
};

const printReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRINT:
      return { ...state, print: action.payload };
    default:
      return state;
  }
};

export default printReducer;
