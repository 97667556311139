export const API_ERROR = 'API_ERROR';
export const APPROVE_IMPROVEMENT = 'APPROVE_IMPROVEMENT';
export const ADD_CHART_SELECTIONS = 'ADD_CHART_SELECTIONS';
export const REPLACE_CHART_SELECTIONS = 'REPLACE_CHART_SELECTIONS';
export const UPDATE_CHART_SELECTION = 'UPDATE_CHART_SELECTION';
export const REMOVE_CHART_SELECTIONS = 'REMOVE_CHART_SELECTIONS';
export const DELETE_IMPROVEMENT = 'DELETE_IMPROVEMENT';
export const CLEAR_CHART_SELECTIONS = 'CLEAR_CHART_SELECTIONS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_STATE = 'CLEAR_STATE';
export const CLEAR_DEVICE = 'CLEAR_DEVICE';
export const CLEAR_DEVICE_ISSUES_IMPROVEMENTS = 'CLEAR_DEVICE_ISSUES_IMPROVEMENTS';
export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_APPLICATIONS = 'FETCH_APPLICATIONS';
export const FETCH_IMPORTANCES = 'FETCH_IMPORTANCES';
export const FETCH_ISSUE_TYPES = 'FETCH_ISSUE_TYPES';
export const DELETE_DEVICES = 'DELETE_DEVICES';
export const DELETE_LOOPS = 'DELETE_LOOPS';
export const DELETE_LOOPS_ARCHIVE = 'DELETE_LOOPS_ARCHIVE';
export const FETCH_LOOP = 'FETCH_LOOP';
export const FETCH_LOOP_ARCHIVE = 'FETCH_LOOP_ARCHIVE';
export const FETCH_LOOP_DEVICES = 'FETCH_LOOP_DEVICES';
export const FETCH_LOOPS = 'FETCH_LOOPS';
export const FETCH_LOOPS_ARCHIVE = 'FETCH_LOOPS_ARCHIVE';
export const FETCH_SITES = 'FETCH_SITES';
export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_DATASETS = 'FETCH_DATASETS';
export const FETCH_DEVICE_TYPES = 'FETCH_DEVICE_TYPES';
export const FETCH_SORT = 'FETCH_SORT';
export const FETCH_VARIABLES = 'FETCH_VARIABLES';
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const UPDATE_PRESET = 'UPDATE_PRESET';
//export const FETCH_DEVICE_COMPARE_TESTS = 'FETCH_DEVICE_COMPARE_TESTS';
export const FETCH_DEVICE_SPECS = 'FETCH_DEVICE_SPECS';
export const SET_DEVICE_SUMMARY_STATUS = 'SET_DEVICE_SUMMARY_STATUS';
export const SET_LOOP_SUMMARY_STATUS = 'SET_LOOP_SUMMARY_STATUS';
export const FETCH_DEVICE_IMPROVEMENTS = 'FETCH_DEVICE_IMPROVEMENTS';
export const FETCH_DEVICE_ISSUES = 'FETCH_DEVICE_ISSUES';
export const SET_DEVICE_ISSUE_LOAD = 'SET_DEVICE_ISSUE_LOAD';
export const FETCH_DEVICE_HISTORY = 'FETCH_DEVICE_HISTORY';
export const FETCH_ACTIONS = 'FETCH_ACTIONS';
export const FETCH_INVESTIGATIONS = 'FETCH_INVESTIGATIONS';
export const FETCH_ISSUES_DETAILS = 'FETCH_ISSUES_DETAILS';
export const FETCH_TEST = 'FETCH_TEST';
export const FETCH_PRIORITIZE_VARIABLE = 'FETCH_PRIORITIZE_VARIABLE';
export const SET_LOOP_VARIABLE_STATUS = 'SET_LOOP_VARIABLE_STATUS';
export const FETCH_DIAGNOSTICS = 'FETCH_DIAGNOSTICS';
export const FETCH_PRIORITIZE_DIAGNOSTICS = 'FETCH_PRIORITIZE_DIAGNOSTICS';
export const SET_TEST_STATUS = 'SET_TEST_STATUS';
export const SET_TABLE_OFFSET = 'SET_TABLE_OFFSET';
export const SET_TABLE_PAGE_SIZE = 'SET_TABLE_PAGE_SIZE';
export const SET_TABLE_PAGE = 'SET_TABLE_PAGE';
export const UPDATE_SORT = 'UPDATE_SORT';
export const UPDATE_DATE_RANGE = 'UPDATE_DATE_RANGE';
export const UPDATE_ANALYSIS_RANGE = 'UPDATE_ANALYSIS_RANGE';
export const UPDATE_ACTION_RANGE = 'UPDATE_ACTION_RANGE';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_CONCERN = 'UPDATE_CONCERN';
export const UPDATE_LOOP_COUNT = 'UPDATE_LOOP_COUNT';
export const UPDATE_LOOP_SUMMARY = 'UPDATE_LOOP_SUMMARY';
export const UPDATE_DEVICE_COUNT = 'UPDATE_DEVICE_COUNT';
export const FETCH_ACTION_ITEMS = 'FETCH_ACTION_ITEMS';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_SHUTDOWNS = 'FETCH_SHUTDOWNS';
export const SIGN_IN = 'SIGN_IN';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_MODULE = 'SET_MODULE';
export const OPTIMIZE_SET_ACTIVE_TAB = 'OPTIMIZE_SET_ACTIVE_TAB';
export const OPTIMIZE_SET_ACTIVE_LOOP = 'OPTIMIZE_SET_ACTIVE_LOOP';
export const OPTIMIZE_SET_ACTIVE_DEVICE = 'OPTIMIZE_SET_ACTIVE_DEVICE';
export const OPTIMIZE_SET_ACTIVE_TEST = 'OPTIMIZE_SET_ACTIVE_TEST';
export const OPTIMIZE_SET_COMPARE_DEVICE = 'OPTIMIZE_SET_COMPARE_DEVICE';
export const OPTIMIZE_SET_COMPARE_FLAG = 'OPTIMIZE_SET_COMPARE_FLAG';
export const OPTIMIZE_SET_COMPARE_TEST = 'OPTIMIZE_SET_COMPARE_TEST';
export const PRIORITIZE_SET_ACTIVE_TAB = 'PRIORITIZE_SET_ACTIVE_TAB';
export const PRIORITIZE_SET_ACTIVE_LOOP = 'PRIORITIZE_SET_ACTIVE_LOOP';
export const PRIORITIZE_SET_ACTIVE_DEVICE = 'PRIORITIZE_SET_ACTIVE_DEVICE';
export const PRIORITIZE_TOGGLE_ACTIVE_VARIABLE = 'PRIORITIZE_TOGGLE_ACTIVE_VARIABLES';
export const PRIORITIZE_CLEAR_ACTIVE_VARIABLES = 'PRIORITIZE_CLEAR_ACTIVE_VARIABLES';
export const PRIORITIZE_ADD_ACTIVE_VARIABLE = 'PRIORITIZE_ADD_ACTIVE_VARIABLES';
export const PRIORITIZE_REMOVE_ACTIVE_VARIABLE = 'PRIORITIZE_REMOVE_ACTIVE_VARIABLES';
export const RELIABILITY_SET_ACTIVE_TAB = 'RELIABILITY_SET_ACTIVE_TAB';
export const RELIABILITY_SET_ACTIVE_LOOP = 'RELIABILITY_SET_ACTIVE_LOOP';
export const RELIABILITY_SET_ACTIVE_DEVICE = 'RELIABILITY_SET_ACTIVE_DEVICE';
export const RELIABILITY_TOGGLE_ACTIVE_VARIABLE = 'RELIABILITY_TOGGLE_ACTIVE_VARIABLES';
export const RELIABILITY_CLEAR_ACTIVE_VARIABLES = 'RELIABILITY_CLEAR_ACTIVE_VARIABLES';
export const RELIABILITY_ADD_ACTIVE_VARIABLE = 'RELIABILITY_ADD_ACTIVE_VARIABLES';
export const RELIABILITY_REMOVE_ACTIVE_VARIABLE = 'RELIABILITY_REMOVE_ACTIVE_VARIABLES';
export const REPORTS_SET_ACTIVE_TAB = 'REPORTS_SET_ACTIVE_TAB';
export const REPORTS_SET_ACTIVE_REPORT = 'REPORTS_SET_ACTIVE_REPORT';
export const ROADMAP_SET_ACTIVE_TAB = 'ROADMAP_SET_ACTIVE_TAB';
export const CONFIG_SET_ACTIVE_TAB = 'CONFIG_SET_ACTIVE_TAB';
export const CONFIG_SET_DATA = 'CONFIG_SET_DATA';
export const GUIDE_SET_ACTIVE_TAB = 'GUIDE_SET_ACTIVE_TAB';
export const SUPPORT_SET_ACTIVE_TAB = 'SUPPORT_SET_ACTIVE_TAB';
export const SUPPORT_SET_ACTIVE_OPTIMIZE = 'SUPPORT_SET_ACTIVE_OPTIMIZE';
export const SUPPORT_SET_ACTIVE_PRIORITIZE = 'SUPPORT_SET_ACTIVE_PRIORITIZE';
export const SET_ACTIVE_NAV = 'SET_ACTIVE_NAV';
export const GET_SUMMARY_REPORT = 'GET_SUMMARY_REPORT';
export const GET_PORTFOLIO_REPORT = 'GET_PORTFOLIO_REPORT';
export const GET_PRIORITIZE_SUMMARY_REPORT = 'GET_PRIORITIZE_SUMMARY_REPORT';
export const GET_IMPROVEMENT_REPORT = 'GET_IMPROVEMENT_REPORT';
export const SET_COMPARATOR_REPORT = 'SET_COMPARATOR_REPORT';
export const FETCH_USER_REALMS = 'FETCH_USER_REALMS';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const SET_PRINT = 'SET_PRINT';
export const SET_FORMAT = 'SET_FORMAT';
export const CLEAR_DATA = 'CLEAR_DATA';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const UPLOAD_SET_ACTIVE_TAB = 'UPLOAD_SET_ACTIVE_TAB';
export const SET_COLOR_MODE = 'SET_COLOR_MODE';
export const CHANGE_EVENT_SELECTIONS = 'CHANGE_EVENT_SELECTIONS';
export const CHANGE_EVENT_SELECTIONS_CONSOLIDATED = 'CHANGE_EVENT_SELECTIONS_CONSOLIDATED';
export const SET_FETCH_REALMS_LOADING = 'SET_FETCH_REALMS_LOADING';
