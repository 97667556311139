import {
  SET_TABLE_OFFSET,
  SET_TABLE_PAGE_SIZE,
  SET_TABLE_PAGE,
} from '../actions/types';

const INITIAL_STATE = {
  offset: 0,
  pageSize: 24,
  page: 0,
};

const tableReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TABLE_OFFSET:
      return { ...state, offset: action.payload };
    case SET_TABLE_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case SET_TABLE_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export default tableReducer;
