import React from 'react';
import { Button, Grid, TextField, Paper, Typography } from '@mui/material';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import WidgetTitle from '../headers/WidgetTitle';
import api from '../../apis/api';
import { fetchUserProfile } from '../../actions/index';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      statusMessage: '',
      userlist: [],
      deleteConfirm: false,
      changeConfirm: false,
      clickedRow: null,
      dialogOpen: false,
      rowOpen: false,
      username: '',
      email: '',
      first: '',
      last: '',
      password: '',
      confirmPassword: '',
      //permission: 99,
      //realms: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    //this.onSearchSelect = this.onSearchSelect.bind(this);
  }
  async componentDidMount() {
    await this.props.fetchUserProfile().then(() => {
      //console.log('t');
      //console.log(this.props);
      this.setState({
        username: this.props.userId,
        email: this.props.email,
        first: this.props.first_name,
        last: this.props.last_name,
      });
    });
  }
  async updateUser() {
    try {
      this.setState({ status: '', statusMessage: '' });
      if ((this.state.password !== '' && this.state.password.length < 7) || this.state.password !== this.state.confirmPassword) {
        console.log('FAILED PASS MATCH OR PASS UNDER 8 CHARS');
        this.setState({
          status: 'Failure',
          statusMessage: 'Password does not match or is under 8 characters in length!',
        });
      } else {
        //console.log('create');
        const userData = {
          username: this.state.username,
          password: this.state.password.length === 0 ? null : this.state.password,
          first: this.state.first,
          last: this.state.last,
          email: this.state.email,
          //permission: this.state.permission,
          //realms: this.state.realms.join(','),
        };
        const url = `updateuser`;
        //console.log(userData);
        const response = await api.post(url, userData);

        //console.log(response);

        if (response.status === 200 && (response.data.changedRows > 0 || response.data === 'success')) {
          this.setState({ status: 'Success', statusMessage: 'Changes Saved' });
        } else {
          this.setState({
            status: 'Failure',
            statusMessage: 'Profile Changes Unsuccesful. If this persists please contact your administrator.',
          });
        }
        /*if (true) {
        if (newUser) {
          console.log('Created'); //TODO POPUP SAYING SUCCESS GOES HERE
        } else {
          console.log('Edited'); //TODO POPUP SAYING SUCCESS GOES HERE
        }
      }*/
        /*const url = `getrealms`;
      const response = await api.get(url);*/
        //console.log(response);
        //const url2 = `getUserList`;
        //const response2 = await api.get(url2);
        //console.log(response);
      }
    } catch (e) {
      if (e.status === 200 && e.data.changedRows > 0) {
        this.setState({ status: 'Success', statusMessage: 'Changes Saved' });
      } else {
        this.setState({
          status: 'Failure',
          statusMessage: !e.data || !e.data.error ? 'Profile Changes Unsuccesful. If this persists please contact your administrator.' : e.data.error,
        });
      }
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  formContent = () => {
    //let realms = [];
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={2} justifyContent='space-between'>
          <Grid item>
            <TextField
              autoFocus
              id='username'
              label='Username'
              name='username'
              disabled={true}
              value={this.state.username}
              //fullWidth={fullWidthBool}
              //onChange={this.handleInputChange}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFixNoPaper,
                },
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              size='medium'
              color='primary'
              onClick={() => {
                this.updateUser(true);
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <WidgetTitle title='Change Password'></WidgetTitle>
        <Paper className={classes.paper} elevation={3}>
          <Grid container spacing={2}>
            {/*<Grid item>
            <TextField
              autoFocus
              id='old'
              label='Old Password'
              name='oldPassword'
              type='password'
              //fullWidth={fullWidthBool}
              onChange={this.handleInputChange}
            />
          </Grid>*/}
            <Grid item>
              <TextField
                autoFocus
                id='password'
                label='Password'
                name='password'
                type='password'
                //fullWidth={fullWidthBool}
                onChange={this.handleInputChange}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                autoFocus
                id='confirmPassword'
                label='Confirm Password'
                name='confirmPassword'
                type='password'
                //fullWidth={fullWidthBool}
                onChange={this.handleInputChange}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <WidgetTitle title='Profile'></WidgetTitle>
        <Paper className={classes.paper} elevation={3}>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                autoFocus
                id='email'
                label='Email'
                name='email'
                type='email'
                value={this.state.email}
                //fullWidth={true}
                onChange={this.handleInputChange}
                style={{ width: 280 }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                autoFocus
                id='first'
                label='First'
                name='first'
                value={this.state.first}
                //fullWidth={fullWidthBool}
                onChange={this.handleInputChange}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                autoFocus
                id='last'
                label='Last'
                name='last'
                value={this.state.last}
                //fullWidth={fullWidthBool}
                onChange={this.handleInputChange}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  };
  render() {
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    return (
      <React.Fragment>
        {this.formContent()}
        {this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginTop: 20 }}>{`${this.state.statusMessage}`}</Typography> : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    email: state.auth.email,
    first_name: state.auth.first_name,
    last_name: state.auth.last_name,
    full_name: state.auth.full_name,
    locations: state.locations,
    errors: state.errors,
    realms: state.auth.realms,
    current_realm: state.auth.current_realm,
  };
};

export default connect(mapStateToProps, { fetchUserProfile })(withStyles(styles)(Profile));
