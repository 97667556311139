import React from 'react';
import { IconButton, Tooltip, Popper, Paper, ClickAwayListener, Typography, Button } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }
  handleMenuClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      open: !this.state.open,
    });
  }
  handleMenuClose() {
    this.setState({ anchorEl: null, open: false });
  }

  render() {
    const { classes } = this.props;
    const menu = (
      <div className={classes.printHide}>
        <Tooltip arrow placement='top' title={!!this.props.tooltip ? this.props.tooltip : ''} aria-label={!!this.props.tooltip ? this.props.tooltip : ''}>
          {!this.props.text ? (
            <IconButton
              id={this.props.id}
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={this.handleMenuClick}
              color='inherit'
              size="large">
              <MoreHoriz></MoreHoriz>
            </IconButton>
          ) : (
            <Button id={this.props.id} aria-controls='simple-menu' aria-haspopup='true' onClick={this.handleMenuClick} color='inherit'>
              <MoreHoriz style={{ marginRight: 4 }}></MoreHoriz> <Typography variant='subtitle1'>{this.props.text}</Typography>
            </Button>
          )}
        </Tooltip>
        <Popper id='simple-menu' anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.handleMenuClose}>
          <ClickAwayListener onClickAway={this.handleMenuClose}>
            <Paper variant='outlined'>
              {!this.props.helperText ? null : (
                <Typography
                  variant='caption'
                  style={{
                    padding: '10px 14px 6px 14px',
                    display: 'block',
                  }}
                >
                  {this.props.helperText}
                </Typography>
              )}
              {
                //For each item in options, make a menuItem in order that does the actions from the onClick
                this.props.options.map((item) => {
                  const MenuListIcon = item.icon;
                  return (
                    <MenuItem
                      disabled={this.props.disabled || item.disabled}
                      key={`${item.id}_${item.title}_key`}
                      onClick={() => {
                        item.onClick();
                        this.setState({ anchorEl: null, open: false });
                      }}
                    >
                      <MenuListIcon color='primary' className={classes.menuIcon} />
                      {item.title}
                    </MenuItem>
                  );
                })
              }
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    );

    return menu;
  }
}

export default withStyles(styles)(DropdownMenu);
