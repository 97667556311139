import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import { Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

class Releases extends React.Component {
  state = { patch100: false, patch110: false, patch111: false, patch120: false, patch200: false, patch201: false, patch202: false, patch203: false, patch210: false, patch211: false, patch212: false };
  render212() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Optimize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Visually responsive diagnostics widget UI
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          Prioritize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added customizable event selection in the Workbench
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added functionality for custom action item creation
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added new variable: Process Downtime
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continued helper content additions
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          General Updates
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Misc fixes to links and routes
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continuous minor bug fixes
        </Typography>
      </React.Fragment>
    );
  }
  render211() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Prioritize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added new report: Application Improvement
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Various action items bug fixes
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          General Updates
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Certain UI elements hidden when printing
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Helper UI functionality improvements
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added loading indicators for reports
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continuous minor bug fixes
        </Typography>
      </React.Fragment>
    );
  }
  render210() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Optimize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Implemented valve certifications
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          General Updates
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Print Rework
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Added a print button that loads a formatted page
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Implemented url ‘/print’ to automatically format pages
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Styles for print including visual differences as well as hidden UI elements
        </Typography>
      </React.Fragment>
    );
  }
  render203() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Prioritize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added new reports: Concern Summary and Action Items
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added events to prioritize issues
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Various helper bug fixes
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          General Updates
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Performance
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Improved tooltip functionality
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added new chart widget to handle multiple reports
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Improved feedback when submitting forms
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continued addition of helper content
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continuous minor bug fixes
        </Typography>
      </React.Fragment>
    );
  }
  render202() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Optimize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added a concern legend to the main index
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added inactive recommendations
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added additional examples to helpers
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added issue type filter
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Chart placement improvements for issues
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          Prioritize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Performance
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Improved data pulls for primary variables
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Analysis range improvements for charts
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added color coding to max concern
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Initial data pull set to two years
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          General Updates
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Improved select functionality
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continued addition of helper content
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Screen size responsiveness improvements
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continuous minor bug fixes
        </Typography>
      </React.Fragment>
    );
  }
  render201() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Optimize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Performance
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Improved data visualization performance for valve charts
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Dynamically rendered helper content introduced
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added a quick navigation menu on the index page
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Full implementation of optimize issues
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Data visualization
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Recommendations
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Investigation functionality
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added additional examples to helpers
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          General Updates
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Helpers updated for readability and clarity
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Mobile visual improvements
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continuous minor bug fixes
        </Typography>
      </React.Fragment>
    );
  }
  render200() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Optimize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Performance
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • New driver introduced to intake black diamond tests
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Introduced Optimize issues
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added alignment tab
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • New algorithms to detect degenerative valve conditions
        </Typography>
      </React.Fragment>
    );
  }
  render120() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Prioritize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Performance
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Data upload and driver updated
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          General Updates
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Implemented dark mode to reduce eyestrain
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Updated dialog containers
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continuous minor bug fixes
        </Typography>
      </React.Fragment>
    );
  }
  render111() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Optimize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Introduced Portfolio Report
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          Prioritize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Performance
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Workbench performance improvements for multi-charts, checklist and issues
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Shutdowns introduced
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added basic date range selection to workbench
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Pre-generated action items implemented
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added event functionality and visualization
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Issue submission visual improvements
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Helper content updated
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          General Updates
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Performance
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Filter performance improved
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Loading indicators added
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Background component visual cleanup
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continuous minor bug fixes
        </Typography>
      </React.Fragment>
    );
  }
  render110() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Optimize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added misc. valve conditions
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          Prioritize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Performance
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Rendering improvements
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added sorts and filters to main index
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added multi-chart component to handle multiple prioritize variables
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added examples to prioritize helpers
        </Typography>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader} style={{ marginTop: 32 }}>
          General Updates
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added print header components for organizing general output
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Added password reset
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Mobile website visual improvements
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continuous minor bug fixes
        </Typography>
      </React.Fragment>
    );
  }
  render100() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' className={classes.outlineHeader}>
          Optimize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Performance
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Implemented a 2-3x speedup on analysis phase of uploading in Optimize
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Optimization of back end API
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Reduced transmission size of data on mobile networks
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Features
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Config Expansion
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Role based user access
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          User creation
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Performance target recommendations
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Support Expansion
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          UI overhaul, added complete optimize walkthrough and comprehensive support documentation
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Added the Getting Started Video Guide
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Introduced Demo Realms for Optimize
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Destructible
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Complete Examples
        </Typography>
        <Typography variant='body2' className={classes.outlineListItem}>
          Comments
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Bug Fixes and Misc.
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continuous implementation and improvement of tooltips
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Continuous bug fixes in Optimize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          User Interface
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Print functionality, consistency across multiple browsers
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Compact grid improvements
        </Typography>
        <Typography variant='subtitle1' color='secondary' style={{ marginTop: 32, marginBottom: 12 }}>
          Prioritize
        </Typography>
        <Typography variant='subtitle2' className={classes.outlineListHeader}>
          Prioritize Module Introduced
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Initial Data Structure
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Concern Scores
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Data Visualization
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Sorts and Filters
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Issues Checklist
        </Typography>
        <Typography variant='body1' className={classes.outlineList}>
          • Action Items
        </Typography>
      </React.Fragment>
    );
  }
  render() {
    //const { classes } = this.props;
    return (
      <React.Fragment>
        <Accordion style={{ marginTop: 20 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 2.1.2 (Q1 2024)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render212()}</AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 2.1.1 (Q4 2023)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render211()}</AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 2.1.0 (Q3 2023)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render210()}</AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 2.0.3 (Q2 2023)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render203()}</AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 2.0.2 (Q1 2023)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render202()}</AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 2.0.1 (Q4 2022)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render201()}</AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 2.0.0 (Q3 2022)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render200()}</AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 1.2.0 (Q2 2022)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render120()}</AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 1.1.1 (Q1 2022)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render111()}</AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 1.1.0 (Q4 2021)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render110()}</AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Release Version 1.0.0 (Q3 2021)</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.render100()}</AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Releases));
