import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { connect } from 'react-redux';
import { uploadSetActiveTab, setModule } from '../../actions/index';
import { styles } from '../styles';
import { SvgIcon } from '@mui/material';

class UploadTabs extends React.Component {
  setActiveTab = (event, value) => {
    this.props.uploadSetActiveTab(value);
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper square className={classes.tabsWrapper} elevation={3}>
        <Tabs
          value={!this.props.activeTab || (this.props.activeTab !== 'Optimize' && this.props.activeTab !== 'Prioritize') ? 'Optimize' : this.props.activeTab}
          onChange={this.setActiveTab}
          variant='fullWidth'
          indicatorColor='secondary'
          textColor='secondary'
          scrollButtons
          className={classes.tabs}
          allowScrollButtonsMobile
        >
          {!this.props.prioritize ? null : (
            <Tab
              className={classes.tab}
              icon={
                <SvgIcon>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                    <path fill='none' strokeWidth='3.499965' strokeMiterlimit='10' d='M2.585 17.983L18 2.568l15.415 15.415L18 33.397z' />
                    <path d='M9.427 18.06l8.556-8.556 8.556 8.556-8.556 8.556z' />
                  </svg>
                </SvgIcon>
              }
              label='Prioritize'
              value='Prioritize'
              component={Link}
              to={`/upload/Prioritize`}
            />
          )}
          {!this.props.optimize ? null : (
            <Tab
              className={classes.tab}
              icon={
                <SvgIcon>
                  <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' x='0' y='0' version='1.1' viewBox='0 0 36 36'>
                    <defs />
                    <path id='Rectangle_2364' d='M9.7 15.5h5.6v5.6H9.7z' transform='rotate(-45.001 12.54 18.302)' />
                    <path id='Rectangle_2365' d='M15.3 9.9h5.6v5.6h-5.6z' transform='rotate(-45.001 18.063 12.68)' />
                    <path id='Rectangle_2363' d='M20.8 15.5h5.6v5.6h-5.6z' transform='rotate(-45.001 23.606 18.286)' />
                    <path className={classes.tabIconStroke} fill='none' strokeMiterlimit='10' strokeWidth='3.499965' d='M2.563 18.108L17.978 2.694l15.415 15.414-15.415 15.415z' />
                  </svg>
                </SvgIcon>
              }
              label='Optimize'
              value='Optimize'
              component={Link}
              to={`/upload/Optimize`}
            />
          )}
        </Tabs>
      </Paper>
    );
  }
}

UploadTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.upload.activeTab,
    prioritize: state.auth.prioritize,
    optimize: state.auth.optimize,
  };
};

export default connect(mapStateToProps, {
  uploadSetActiveTab,
  setModule,
})(withStyles(styles)(UploadTabs));
