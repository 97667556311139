const alignmentDetails = {
  Resolution: {
    title: `Resolution`,
    description: `Some applications require valves to make precise changes in order to maximize plant safety and profitability. A resolution test determines if a valve is likely to provide the accuracy levels the plant needs. This is done by testing the ability to move in alternating directions – similar to how a control valve might move in day-to-day operations.  Valves with poor alternating-step response can adversely affect raw material costs, quality control, energy costs, throughput and/or yield. Testing is done at the stem to capture the ‘real world’ dead-band the valve is likely to see, as well as any additional issues like overshoot, sluggish response, or delay.`,
    alarms: {
      Green: `The valve meets the stated alternating step requirements for this application.`,
      Yellow: `The valve meets the stated alternating step requirements for this application, but is within 25% of exceeding the allowable error for this application. This valve will still meet performance requirements for the time being. If this valve will not be revisited for a very significant period of time, it is recommended repairs or replacements are made so the valve does not impact operations. If the profile test reveals degenerative issues (ex. high dynamic error), it is recommended to make repairs so the valve continues to meet site performance needs.`,
      Red: `The valve does not meet the stated alternating step requirements for this application. This alarm should be resolved and will adversely impact site performance until it is addressed.`,
    },
    investigate: {
      Title1: `Review the Data`,
      Rec1: `Review the profile test results to determine which aspects of the valve are performing properly. Wear in linkages, feedback arms, and connections or high dead-band from valve design are the most common reasons for poor resolution performance. Bad I/Ps (if present), bad positioners and high friction in the valve are also likely causes.`,
      Indent1: `•	Make sure the calibration is correct as incorrect calibrations can produce a sequential error in step changes (accuracy will increase/decrease as step sizes increase).`,
      Title2: `Address Any Overshoot`,
      Rec2: `If the valve has significant overshoot and the valve has boosters, consider tuning or repairing the boosters to prevent them from activating on smaller steps. A positioner that is tuned to have too much gain may also create significant overshoot.`,
      Title3: `Investigate Root Causes`,
      Rec3: `If the valve has trouble setting through several steps, there may be issues with the tuning, the boosters, or a friction issue.  Review the diagnostic data to determine root causes. In general, a least-invasive approach is recommended - fifteen minutes to re-tune the positioner/boosters and re-test is better than having to explain why an expensive or invasive repair wasn’t necessary.`,
      Title4: `Calibrate Any Digital Positioners`,
      Rec4: `With digital positioners the calibration settings can have a significant impact on valve performance. It is recommended that you have a trained expert properly set up any digital positioners to optimize performance for the application performance needs.`,
      Title5: `Low Performance Components`,
      Rec5: `If all parts are performing adequately and the valve does not meet performance requirements, it may be that the specified components used in the valve naturally lead to low performance.  Loose tolerances and multiple shaft splines/connections are potential examples of design elements that may limit accuracy. Oversized actuators, insufficient air capacity (ex. air supply pressure, leaks), or insufficient air flow (ex. small tubing, blockages, leaks) can also adversely affect performance (insufficient force to move the stem).`,
    },
    examples: {
      example1: {
        src: 'resEx1',
        title: `Good Resolution Graph`,
        caption: 'This is an example of a good resolution graph. Always compare the alignment results against the target, as lower performance may be sufficient for less important applications.',
        size: 'lg',
      },
      example2: {
        src: 'resEx3',
        title: `Poor Resolution Graph`,
        caption:
          'This is an example of a valve with poor resolution. Even though valve diagnostic testing is done with ‘open control’ tests, most control valves are utilized in a ‘closed control’ loop.  In that setting, the controller will continue to increase the control signal until the desired output is reached. As a result, responses with an error of less than 50% will often provide the plant enough control at that target step. In this case, the first step is over twice as large as the requested signal, likely leading to unacceptable control. Subsequent steps are non-responsive.',
        size: 'lg',
      },
      example3: {
        src: 'resEx4',
        title: `Overshoot`,
        caption:
          'This is an example of a valve with overshoot on each step. Even though the valve settles to the correct target value, it initially opens further than required and must re-correct back to the settled value.  Some facilities will tolerate overshoot, but many facilities will want the overshoot corrected to avoid oscillations or upsets during plant operations.',
        size: 'lg',
      },
      example4: {
        src: 'resEx2',
        title: `Inconsistent Data`,
        caption:
          'Though this resolution result is inconsistent, it is acceptable for the given application (the oscillation is less than the 2% accuracy requested). Though this valve may meet requirements, inconsistency can be a result of galling or other reliability issues, so a review of diagnostics is warranted.Even if a valve is acceptable for application purposes, degenerative issues should be corrected at the first opportunity.',
        size: 'lg',
      },
    },
  },
  Sensitivity: {
    title: `Sensitivity`,
    description: `Some applications require valves to make precise changes in order to maximize plant safety and profitability. A sensitivity test determines if a valve is likely to provide the accuracy levels the plant needs. This is done by testing the ability to move in the same direction repeatedly – similar to how a gap valve might move in day-to-day operations. Valves with poor same-step response can adversely affect raw-material costs, quality control, energy costs, throughput and/or yield. `,
    alarms: {
      Green: `The valve meets the stated same-direction step requirements for this application.`,
      Yellow: `The valve meets the stated same-direction step requirements for this application, but is within 25% of exceeding the allowable error for this application. This valve will still meet performance requirements for the time being. If this valve will not be revisited for a very significant period of time, it is recommended repairs or replacements are made so the valve does not impact operations. If the profile test reveals degenerative issues, it is also recommended to make repairs early so the valve continues to meet site performance needs.`,
      Red: `The valve does not meet the stated same-direction step requirements for this application. This alarm should be resolved and will adversely impact site performance until it is addressed.`,
    },
    investigate: {
      Title1: `Review the Data`,
      Rec1: `Review the improvement paths and diagnostic tab to determine which aspects of the valve are performing properly. Tuning, Wear in linkages, feedback arms, mis-alignment of crossover mechanisms or contactless sensors are common reasons for poor sensitivity performance. Bad I/Ps (if present), and high friction in the valve are also common causes.`,
      Indent1: `•	Make sure the calibration is correct as incorrect calibrations can produce a sequential error in step changes (accuracy will increase/decrease as step sizes increase).`,
      Title2: `Address Any Overshoot`,
      Rec2: `If the valve has significant overshoot and the valve has boosters, consider tuning or repairing the boosters to prevent them from activating on smaller steps. A positioner that is tuned to have too much gain may also create significant overshoot.`,
      Title3: `Investigate Root Causes`,
      Rec3: `If the valve has trouble setting through several steps, there may be issues with the tuning, the boosters, or a friction issue.  Review the diagnostic data to determine root causes.  In general, a least-invasive approach is recommended - fifteen minutes to rede-tune the positioner/boosters and re-test is better than having to explain why an expensive or invasive repair wasn’t necessary.a good first step to avoid expensive or invasive repairs.`,
      Title4: `Calibrate Any Digital Positioners`,
      Rec4: `With digital positioners the calibration settings can have a significant impact on valve performance. It is recommended that you have a trained expert properly set up any digital positioners to optimize performance for the application performance needs.`,
      Title5: `Low Performance Components`,
      Rec5: `If all parts are performing adequately and the valve does not meet performance requirements, it may be that the specified components used in the valve naturally lead to low performance.  Loose tolerances and multiple shaft splines/connections are potential examples of design elements that may limit accuracy. Oversized actuators, insufficient air capacity (ex. air supply pressure, leaks), or insufficient air flow (ex. small tubing, blockages, leaks) can also adversely affect performance (insufficient force to move the stem).`,
    },
    examples: {
      example1: {
        src: 'senEx1',
        title: `Good Sensitivity Graph`,
        caption: 'This is an example of a good sensitivity graph. Always compare the alignment results against the target, as lower performance may be sufficient for less important applications.',
        size: 'lg',
      },
      example2: {
        src: 'senEx4',
        title: `Poor Sensitivity Graph`,
        caption:
          'This is an example of a valve with poor sensitivity. Even though valve diagnostic testing is done with ‘open control’ tests, most control valves are utilized in a ‘closed control’ loop.  In that setting, the controller will continue to increase the control signal until the desired output is reached. As a result, responses with an error of less than 50% will often provide the plant enough control at that target step. In this case, the first step is over twice as large as the requested signal, likely leading to unacceptable control. Subsequent steps are non-responsive.',
        size: 'lg',
      },
      example3: {
        src: 'senEx5',
        title: `Overshoot`,
        caption:
          'This is an example of a valve with overshoot on most steps. Even though the valve settles to the correct target value, it initially moves further than required and must re-correct back to the settled value.  Some facilities will tolerate overshoot, but many facilities will want the overshoot corrected to avoid oscillations or upsets during plant operations.',
        size: 'lg',
      },
      example4: {
        src: 'senEx2',
        title: `Incorrect Starting Position`,
        caption:
          'If valve travel has not settled by the start of the test, the results will alert you to this fact.  Test results will be adversely affected by a valve that is not at the correct starting position at the start of the test. If a valve will not settle by the start of a test, then proceed to diagnostics to determine the root cause.',
        size: 'lg',
      },
      example5: {
        src: 'senEx3',
        title: `Galling`,
        caption:
          'This is an example of a valve that is not settling due to friction issues (galling). Even if this is acceptable for application purposes, degenerative issues should be corrected at the first opportunity.',
        size: 'lg',
      },
    },
  },
  Stroke: {
    title: `Stroke Speed`,
    description: `Some applications require valves to make larger step changes quickly in order to maximize plant safety and profitability. For example, during a process upset a vent valve may need to open or a compressor valve may need to close very quickly. Valves with poor stroke times may cause subsystem trips or plant outages, depending on application needs.  A stroke test determines if a valve is likely to handle any day-to-day conditions or upset conditions that require the valve to move quickly.`,
    alarms: {
      Green: `The valve meets the stated stroke speed requirements for this application.`,
      Yellow: `The valve meets the stated stroke speed requirements for this application, but is within 25% of exceeding the allowable error for this application. This valve will still meet performance requirements for the time being. If this valve will not be revisited for a very significant period of time, it is recommended repairs or replacements are made so the valve does not impact operations. If the profile test reveals degenerative issues, it is also recommended to make repairs early so the valve continues to meet site performance needs.`,
      Red: `The valve does not meet the stated stroke speed requirements for this application. This alarm should be resolved and will adversely impact site performance until it is addressed.`,
    },
    investigate: {
      Title1: `Check Full Stroke`,
      Rec1: `Confirm that the valve was capable of completing a full stroke. If the valve cannot make 99% of full stroke, then the test will report that the valve will never reach full stroke.`,
      Title2: `Review the Data`,
      Rec2: `Review the improvement paths and diagnostic tab to determine which aspects of the valve are performing properly. Stroke problems usually relate to too much actuator volume and/or too little flow. They can also be a function of high friction. Undersized air lines, air/actuator blockages and leaks, actuator balance pressure, and high air/actuator pressures are common reasons for poor stroke speed. Tight packing, oversized actuators, sticky seats, and valve size are also common causes. The non-performing parts most likely to impact speed are insufficient valve travel/calibration, improper positioner cutoff settings, the air supply volume (ex: actuator size), regulator, lack of boosters, tuning of boosters, actuator air leaks, the overall friction of the valve, and forces at the seat.`,
      Title3: `Calibrate Any Digital Positioners`,
      Rec3: `With digital positioners the calibration settings can have a significant impact on valve performance. In particular, position cutoffs and gain must be set correctly to achieve optimal speed. It is recommended that you have a trained expert properly set up any digital positioners to optimize performance for the application performance needs.`,
      Title4: `Low Performance Components`,
      Rec4: `If all parts are performing adequately and the valve does not meet performance requirements, it may be that the specified components used in the valve naturally lead to low performance. Oversized actuators (excess air volume) or insufficient airflow can also adversely affect performance (insufficient force to move the stem/insufficient flow to handle air volume in actuator). If the valve does not have boosters, consider adding boosters in order to increase speed. Properly tuned, they can frequently improve performance on large steps without significantly impacting small steps.`,
    },
    examples: {
      example1: {
        src: 'strokeEx1',
        title: `Good Stroke Graph`,
        caption:
          'This is an example of a good stroke graph. Always compare the alignment results against the target, as lower performance may be sufficient for less important applications. Bear in mind that larger valves will run inherently slower than smaller valves absent specific engineering to make them move quickly.',
        size: 'lg',
      },
      example2: {
        src: 'strokeEx3',
        title: `Poor Stroke Graph`,
        caption:
          'This is an example of a valve that fails to meet its stroke time. If the valve has a significant delay leaving the seat, check that there are no seating issues.  If the velocity of the valve is slow, the issue is usually one of too little air flow against too much actuator volume or too much friction. With a large valve, it may need boosters or high-flow airlines/parts. Air blockages or air leaks could also be hampering flow.  Valves with high friction may need to be repacked or inspected out of line to address friction issues.  ',
        size: 'lg',
      },
      example3: {
        src: 'strokeEx2',
        title: `Under-Stroking`,
        caption:
          'Valves that do not reach 99% of stroke will fail a stroke test, as they will never reach full stroke.  This issue will be classified as ‘under-stroking’ in the stroke speed results and should be addressed.',
        size: 'lg',
      },
    },
  },
  StepTimes: {
    title: `Step Times`,
    description: `Some applications require valves to make precise changes in order to maximize site safety and profitability. While it is important that the valve makes these precise changes accurately, it is also important to know how quickly these changes will be made. This is done by testing the ability to take smaller steps and measuring the time until total response. This information is particularly important to ensure control tuning is specified correctly for the valve. Valves with poor step times can adversely affect raw material costs, quality control, energy costs, throughput, and/or yield. Testing is done at the stem to capture the ‘real world’ dead-time the valve is likely to see, as well as any additional issues like overshoot, sluggish response, or delay.`,
    investigate: {
      Title1: `Review the Data`,
      Rec1: `Review the improvement paths and diagnostic tab to determine which aspects of the valve are performing properly. Stroke problems usually relate to too much actuator volume and/or too little flow. They can also be a function of high friction. Undersized air lines, air/actuator blockages and leaks, actuator balance pressure, and high air/actuator pressures are common reasons for poor stroke speed. Tight packing, oversized actuators, sticky seats, and valve size are also common causes. The non-performing parts most likely to impact speed are insufficient valve travel/calibration, improper positioner cutoff settings, the air supply volume (ex: actuator size), regulator, lack of boosters, tuning of boosters, actuator air leaks, the overall friction of the valve, and forces at the seat.`,
      Title2: `Investigate Root Causes`,
      Rec2: `If the valve has trouble setting through several steps, there may be issues with the tuning, the boosters, a friction issue, or an actuation issue. Review the diagnostic data to Investigate Root Causes. In general, a least-invasive approach is recommended - fifteen minutes to re-tune the positioner/boosters and re-test is better than having to explain why an expensive or invasive repair wasn’t necessary.`,
      Title3: `Calibrate Any Digital Positioners`,
      Rec3: `With digital positioners the calibration settings can have a significant impact on valve performance. In particular, position cutoffs and gain must be set correctly to achieve optimal speed. It is recommended that you have a trained expert properly set up any digital positioners to optimize performance for the application performance needs.`,
      Title4: `Low Performance Components`,
      Rec4: `If all parts are performing adequately and the valve does not meet performance requirements, it may be that the specified components used in the valve naturally lead to low performance. Oversized actuators (excess air volume) or insufficient airflow can also adversely affect performance (insufficient force to move the stem/insufficient flow to handle air volume in actuator). If the valve does not have boosters, consider adding boosters in order to increase speed. Properly tuned, they can frequently improve performance on large steps without significantly impacting small steps.`,
    },
    examples: {
      example1: {
        src: 'stepTimesEx1',
        title: `Normal Step Times Graph`,
        caption:
          'This is an example of a normal step-time graph. Step times should be symmetric (similar time for increasing and decreasing steps). As step sizes get smaller, the response time will typically increase.',
        size: 'lg',
      },
      example2: {
        src: 'stepTimesEx2',
        title: `Possible Air Blockage`,
        caption:
          'Valves with performance issues will deviate from the normal step-time graph shape.  In this case, the valve has difficulty completing increasing steps. This can be due to issues such as an air blockage. If the performance issues cannot be addressed, this information is useful for control tuning.',
        size: 'lg',
      },
      example3: {
        src: 'stepTimesEx3',
        title: `Possible Oversized Actuator or Insufficient Air Flow`,
        caption:
          'Valves with performance issues will deviate from the normal step-time graph shape. In this case, the valve has difficulty completing larger steps. This can be due to issues such as an oversized actuator or insufficient air flow.  If the performance issues cannot be addressed, this information is useful for control tuning.',
        size: 'lg',
      },
    },
  },
};

export default alignmentDetails;
