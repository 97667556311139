import React from 'react';
import validateClientPermission from '../../filters/functions/validateClientPermission';
import { Button, Grid, TextField, Typography, Popper, ClickAwayListener, Paper, MenuItem, Checkbox, Tooltip, IconButton } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import TableWidget from '../../widgets/TableWidget';
import WidgetTitle from '../../headers/WidgetTitle';
import DropdownMenu from '../../menus/DropdownMenu';
import InfoPopover from '../../popup/InfoPopover';
import { MailOutline } from '@mui/icons-material';
import CustomSelect from '../../filters/CustomSelect';
import { connect } from 'react-redux';
import functions from '../../../functions/functions';
import {
  fetchIssueTypes,
  fetchLoop,
  fetchLoopDevices,
  fetchLoopActionItems,
  fetchLoopDiagnostics,
  approveImprovement,
  deleteImprovement,
  updateAnalysisRange,
  fetchLoopEvents,
} from '../../../actions/index';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import api from '../../../apis/api';
import { CheckCircle, Undo, Edit, Delete, ThumbUpAlt, ThumbDownAlt, MoreVert, Settings } from '@mui/icons-material';
import PrintHeader from '../../headers/PrintHeader';
import CustomDialog from '../../popup/CustomDialog';
import AccordionWidgetPrioritize from '../../widgets/AccordionWidgetPrioritize';
import WorkbenchWidgets from './WorkbenchWidgets';
import ActionWidgets from './ActionWidgets';

class IssuesWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      formBool: false,
      improvement: '',
      comment: '',
      level: -1,
      improvementType: '',
      issue: null,
      device: null,
      test: null,
      testDate: null,
      stateDate: null,
      editId: null,
      menus: {},
      approvalOpen: false,
      approvalName: '',
      approvalEmail: '',
      approvalId: null,
      approvalType: '',
      deleteId: null,
      deleteOpen: false,
      type: 'Prioritize',
      selectMenu: false,
      workbenchCheck: false,
      workbenchToggle: false,
      actionCheck: false,
      actionToggle: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeIssue = this.handleInputChangeIssue.bind(this);
    this.handleInputChangeDevice = this.handleInputChangeDevice.bind(this);
    this.handleInputChangeLevel = this.handleInputChangeLevel.bind(this);
    this.handleInputChangeImprovementType = this.handleInputChangeImprovementType.bind(this);
    this.handleInputChangeDate = this.handleInputChangeDate.bind(this);
    this.handleApproval = this.handleApproval.bind(this);
    this.openApproval = this.openApproval.bind(this);
    this.closeApproval = this.closeApproval.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.openDelete = this.openDelete.bind(this);
    this.closeDelete = this.closeDelete.bind(this);
    this.processStatus = this.processStatus.bind(this);
  }

  componentDidMount() {
    if (!this.props.loops[this.props.activeLoop]) {
      this.props.fetchLoop(this.props.activeLoop, this.props.dataset);
      this.props.fetchLoopDevices(this.props.activeLoop, this.props.dataset);
    }
    if (!this.props.issueTypes || Object.keys(this.props.issueTypes).length === 0) {
      this.props.fetchIssueTypes();
    }
    this.props.fetchLoopActionItems(this.props.activeLoop, this.props.dataset);
  }
  processStatus(data) {
    if (data.notApproved === 1) {
      return {
        status: 'Not Approved',
        contact: data.notApprovedBy,
        user: data.notApprovedByUser,
        date: data.notApprovedByDate,
        email: data.notApprovedByEmail,
      };
    } else if (data.completed === 1) {
      return {
        status: 'Completed',
        contact: data.completedBy,
        user: data.completedByUser,
        date: data.completedByDate,
        email: data.completedByEmail,
      };
    } else if (data.approved === 1) {
      return {
        status: 'Approved',
        contact: data.approvedBy,
        user: data.approvedByUser,
        date: data.approvedByDate,
        email: data.approvedByEmail,
      };
    } else {
      return {
        status: 'Pending',
        contact: '-',
        user: data.user,
        date: data.date,
      };
    }
  }
  processState(state) {
    switch (parseInt(state)) {
      case 1:
        return ' - As Left';
      case 3:
        return ' - As Found';
      default:
        return '';
    }
  }
  processTestType(state) {
    switch (parseInt(state)) {
      case 1:
        return 'As Left';
      case 3:
        return 'As Found';
      default:
        return 'Standard';
    }
  }
  processDate(date) {
    return (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) + '/' + (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) + '/' + date.getFullYear();
  }
  processTimestampArray(arr, stateArr) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      const timestamp = new Date(parseInt(arr[i]) * 1000).toLocaleString();

      result.push(`${timestamp}${this.processState(stateArr[i])}`);
    }
    return result;
  }
  selectActiveTest = (date) => {
    const timestampArr =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory ? this.props.devices[this.props.activeDevice].datetimeHistory : [];
    const stateArr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].stateHistory ? this.props.devices[this.props.activeDevice].stateHistory : [];
    //let indexNum = -1;
    let newActiveTestId = -1;
    for (let i = 0; i < timestampArr.length; i++) {
      const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
      //result.push(timestamp);

      if (timestamp === date) {
        //indexNum = i;
        newActiveTestId = this.props.devices[this.props.activeDevice].testIdHistory[i];
        //this.props.fetchDeviceByTest(this.props.activeDevice, newActiveTestId);
        //console.log(this.props.activeDevice, newActiveTestId);
        return newActiveTestId;
      }
    }
    return newActiveTestId;
  };
  selectActiveTestValve = (date) => {
    const timestampArr =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory ? this.props.devices[this.props.activeDevice].datetimeHistory : [];
    const stateArr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].stateHistory ? this.props.devices[this.props.activeDevice].stateHistory : [];
    //let indexNum = -1;
    let newActiveTestValveId = -1;
    for (let i = 0; i < timestampArr.length; i++) {
      const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
      //result.push(timestamp);

      if (timestamp === date) {
        //indexNum = i;
        newActiveTestValveId = this.props.devices[this.props.activeDevice].valveTestIdHistory[i];
        //this.props.fetchDeviceByTest(this.props.activeDevice, newActiveTestId);
        //console.log(this.props.activeDevice, newActiveTestId);
        return newActiveTestValveId;
      }
    }
    return newActiveTestValveId;
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleInputChangeIssue(issue) {
    this.setState({
      issue,
    });
  }
  handleInputChangeDevice(device) {
    const index = this.props.loops[this.props.activeLoop].devices.indexOf(device);
    if (device === 'Select Device' || index === -1) {
      this.setState({
        device: null,
      });
    } else {
      this.setState({
        device: this.props.loops[this.props.activeLoop].device_ids[index],
      });
    }
  }
  handleInputChangeLevel(event) {
    //console.log(event);
    //console.log(event.target);
    let value = -2;
    switch (event) {
      case 'Not Important':
        value = 1;
        break;
      case 'Standard':
        value = 2;
        break;
      case 'Important':
        value = 3;
        break;
      case 'Very Important':
        value = 4;
        break;
      case 'Extremely Important':
        value = 5;
        break;
      default:
        value = -1;
    }
    const name = 'level';

    this.setState({
      [name]: value,
      levelName: event,
    });
    return value;
    /* const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;
    console.log(event);
    console.log(event.target);
    this.setState({
      [name]: value,
    });*/
  }
  handleInputChangeImprovementType(event) {
    let value = event;
    let name = 'improvementType';
    this.setState({
      [name]: value,
    });
  }
  handleInputChangeDate(event) {
    //console.log(event);
    //console.log(this.selectActiveTest(event));
    //const target = event.target;
    //const value = target.name === 'isGoing' ? target.checked : target.value;
    const value = event;
    const name = 'test';

    this.setState({
      [name]: value,
    });
  }

  /* handleClickOpen = () => {
    this.setState({ open: true, status: '', statusMessage: '' });
  };*/

  handleClose = () => {
    this.setState({
      open: false,
      formBool: false,
      testDate: null,
      stateDate: null,
    });
  };
  handleSubmit = async () => {
    const selectedLoop = this.props.activeLoop;
    //console.log(selectedTestValve);
    //console.log(selectedTest);
    //console.log(this.state);
    //this.props.addImprovement(this.state, this.selectActiveTest(test),this.props.devices[this.props.activeDevice] );
    //console.log(this.props.activeDevice);
    //console.log(this.props.devices[this.props.activeDevice]);
    const url = this.state.formBool
      ? `/${this.props.currentModule}/loops/${this.props.activeLoop}/improvements/update/${this.state.editId}`
      : `/${this.props.currentModule}/loops/${this.props.activeLoop}/improvements`;
    //console.log(url);
    //console.log(this.state);
    //console.log(test);
    //console.log(selectedTest);
    //console.log(

    //);
    //console.log(response);
    let currentState = this.state;
    if (isNaN(currentState.level)) {
      currentState.level = this.handleInputChangeLevel(currentState.level);
    }
    try {
      if (validateClientPermission(this.props.clientPermission)) {
        //console.log([currentState, selectedLoop]);
        /*const response = */
        await api.post(url, [
          currentState,
          selectedLoop,
          //selectedTestValve,
        ]);
        this.props.fetchLoopActionItems(this.props.activeLoop);
      } else {
        //add local state addition here
        //TODO redux persistance
        // https://stackoverflow.com/questions/52161128/react-redux-state-is-lost-at-page-refresh
        // https://github.com/rt2zz/redux-persist
        //console.log([currentState, selectedLoop]);
        //console.log(this.props.devices[this.props.activeDevice].improvements);
      }
      //console.log('t');
      this.setState({
        status: '',
        statusMessage: '',
        open: false,
        formBool: false,
        improvement: '',
        comment: '',
        level: 1,
        improvementType: '',
        test: null,
        testDate: null,
        stateDate: null,
        editId: null,
        menus: {},
        approvalOpen: false,
        approvalName: '',
        approvalEmail: '',
        approvalId: null,
        approvalType: '',
        deleteId: null,
        deleteOpen: false,
        type: 'Prioritize',
      });
    } catch (e) {
      console.log(e);
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
        //loading: false,
      });
    }
  };
  processTroubleshootingLevel(level) {
    switch (level) {
      case 1:
        return 'Not Important';
      case 2:
        return 'Standard';
      case 3:
        return 'Important';
      case 4:
        return 'Very Important';
      case 5:
        return 'Extremely Important';
      default:
        return 'Unknown';
    }
  }
  async handleApproval(action, rowId, type) {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    let validEmail = true;
    if (!regex.test(this.state.approvalEmail.replace(/\s/g, '')) && this.state.approvalEmail !== '') {
      validEmail = false;
    }
    if (validEmail) {
      try {
        const id = !rowId ? this.state.approvalId : rowId;
        const url = `/${this.props.currentModule}/loops/${this.props.activeLoop}/improvements/approve`; /*this.props.fetchDeviceImprovements(
      this.props.activeDevice
    );
    this.setState({ open: false });*/
        const finalType = !type ? this.state.approvalType : type;
        /*console.log({
        rowId: id,
        action,
        name: this.state.approvalName,
        email: this.state.approvalEmail,
        type: finalType,
      });*/
        //console.log(type, finalType);

        await api.post(url, {
          rowId: id,
          action,
          name: this.state.approvalName,
          email: this.state.approvalEmail,
          type: finalType,
        }); //console.log(response);
        this.props.approveImprovement(id, action, this.state.approvalName, this.state.approvalEmail, finalType);
        this.setState({
          approvalId: null,
          approvalOpen: false,
          approvalName: '',
          approvalEmail: '',
          approvalType: '',
          status: '',
          statusMessage: '',
        });
      } catch (e) {
        console.log(e);
        this.setState({
          status: 'Failure',
          statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
          //loading: false,
        });
      }
    } else {
      this.setState({
        status: 'Failure',
        statusMessage: `Invalid Email Format`,
      });
    }
    /*const response = */
  }

  approvalButton(id, approval) {
    //console.log(id, approval);
    //console.log(approval === null);
    const { classes } = this.props;
    const action = approval === null;
    return (
      <Button
        id={id}
        color={approval === null ? `primary` : `secondary`}
        variant='outlined'
        onClick={() => {
          this.handleApproval(id, action);
        }}
      >
        {approval === null ? (
          <React.Fragment>
            <CheckCircle className={classes.buttonIcon}></CheckCircle>
            <React.Fragment>Approve</React.Fragment>
          </React.Fragment>
        ) : (
          <React.Fragment>Unapprove</React.Fragment>
        )}
      </Button>
    );
  }

  processEdit(id) {
    let data = !this.props.loops[this.props.activeLoop] || !this.props.loops[this.props.activeLoop].improvements ? [] : this.props.loops[this.props.activeLoop].improvements;
    let improvement = data.filter((e) => e.id === id)[0];
    //const loop = this.props.loops[this.props.activeLoop];
    /*const testIndex = device.testIdHistory.indexOf(
      improvement.testID.toString()
    );*/
    //console.log(improvement);
    //console.log(improvement);
    this.setState({
      open: true,
      status: '',
      statusMessage: '',
      formBool: true,
      editId: id,
      issue: improvement.issue_id.replace(this.props.activeLoop, ''),
      improvement: improvement.improvement,
      comment: improvement.comment,
      level: improvement.level,
      levelName: this.processTroubleshootingLevel(improvement.level),
      improvementType: improvement.improvementType,
      device: improvement.device_id,
      //testDate: device.datetimeHistory[testIndex],
      //stateDate: device.stateHistory[testIndex],
    });
  }
  processDelete(id) {
    let data = !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].improvements ? [] : this.props.devices[this.props.activeDevice].improvements;
    let improvement = data.filter((e) => e.id === id)[0];
    const device = this.props.devices[this.props.activeDevice];

    const testIndex = device.testIdHistory.indexOf(improvement.testId.toString());
    //console.log(improvement);
    this.setState({
      open: true,
      formBool: true,
      editId: id,
      improvement: improvement.improvement,
      comment: improvement.comment,
      level: improvement.level2,
      improvementType: improvement.improvementType,
      testDate: device.datetimeHistory[testIndex],
      stateDate: device.stateHistory[testIndex],
    });
  }
  editButton(id, user, userId) {
    //console.log(id, approval);
    //console.log(approval === null);
    const { classes } = this.props;
    //const action = approval === null;
    const button =
      userId === user ? (
        <Button
          id={id}
          color={userId === user ? `primary` : `secondary`}
          className={classes.button}
          variant='outlined'
          onClick={(e) => {
            //console.log(e);
            //console.log(id);

            //console.log('edit');
            this.processEdit(id);
            //this.handleApproval(id, action);
          }}
        >
          <Edit className={classes.buttonIcon}></Edit>
        </Button>
      ) : null;
    return button;
  }
  openApproval() {
    this.setState({ approvalOpen: true, status: '', statusMessage: '' });
  }
  closeApproval() {
    this.setState({
      approvalOpen: false,
      approvalName: '',
      approvalEmail: '',
      approvalId: null,
      approvalType: '',
    });
  }
  approvalDialog() {
    const { classes } = this.props;
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    return (
      <CustomDialog
        center
        open={this.state.approvalOpen}
        onClose={this.closeApproval}
        title='Contact'
        content={
          <React.Fragment>
            <DialogContentText style={{ marginTop: 20 }}>Please enter the name of the contact person.</DialogContentText>
            <form>
              <TextField
                autoFocus
                id='approvalName'
                label='Name'
                name='approvalName'
                fullWidth
                //multiline
                //rows={4}
                //defaultValue=''
                //variant="outlined"
                value={this.state.approvalName}
                onChange={this.handleInputChange}
                className={classes.textField}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
              <TextField
                autoFocus
                id='approvalEmail'
                label='Email'
                name='approvalEmail'
                fullWidth
                type='email'
                //multiline
                //rows={4}
                //defaultValue=''
                //variant="outlined"
                value={this.state.approvalEmail}
                onChange={this.handleInputChange}
                className={classes.textField}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </form>
            {this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginRight: 20 }}>{`${this.state.statusMessage}`}</Typography> : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.closeApproval} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.handleApproval(true);
              }}
              color='primary'
            >
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  openDelete() {
    this.setState({ deleteOpen: true, status: '', statusMessage: '' });
  }
  closeDelete() {
    this.setState({
      deleteOpen: false,
      deleteId: null,
    });
  }
  async handleDelete(action, rowId) {
    try {
      const id = !rowId ? this.state.deleteId : rowId;
      const url = `/${this.props.currentModule}/loops/${this.props.activeLoop}/improvements/delete`; /*this.props.fetchDeviceImprovements(
      this.props.activeDevice
    );
    this.setState({ open: false });*/
      //console.log({ rowId: id });
      await api.post(url, {
        rowId: id,
      }); //console.log(response);
      this.props.deleteImprovement(id);
      this.setState({
        deleteId: null,
        deleteOpen: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
        //loading: false,
      });
    }
    /*const response = */
  }
  deleteDialog() {
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    //const { classes } = this.props;
    return (
      <CustomDialog
        center
        open={this.state.deleteOpen}
        onClose={this.closeDelete}
        title='Delete Improvement'
        content={
          <React.Fragment>
            <Typography style={{ marginTop: 20 }}>Are you sure you want to delete this improvement?</Typography>
            {this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginRight: 20 }}>{`${this.state.statusMessage}`}</Typography> : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.closeDelete} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.handleDelete(true);
              }}
              color='primary'
            >
              Delete
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }

  getLoopIssuesList() {
    if (!this.props.events || Object.keys(this.props.events).length === 0) {
      return '';
    }
    const activeLoop = this.props.activeLoop;
    const loop = this.props.loops[activeLoop];
    const loopIssues = !loop.issues ? [] : loop.issues;
    const events = this.props.events;
    let issueTypes = !this.props.issueTypes || this.props.issueTypes.length === 0 ? [] : this.props.issueTypes;
    issueTypes.sort();
    let issueNames = !this.props.issueTypes ? ['Non-Specific Issue'] : ['Non-Specific Issue'].concat(issueTypes);
    //console.log(events);
    //console.log(loopIssues);
    for (let i = 0; i < loopIssues.length; i++) {
      if (!!events[loopIssues[i]] && !issueNames.includes(events[loopIssues[i]].eventName)) {
        issueNames.push(events[loopIssues[i]].eventName);
      }
    }
    //console.log(issueNames);

    return issueNames;
  }
  getLoopDeviceId(id) {
    if (!this.props.devices || Object.keys(this.props.devices).length === 0 || id === null) {
      return '';
    }
    const activeLoop = this.props.activeLoop;
    const loop = this.props.loops[activeLoop];
    const index = loop.device_ids.indexOf(id.toString());

    const loopDevices = index === -1 ? loop.devices[0] : loop.devices[index];
    //const devices = this.props.devices;
    //let deviceNames = [];
    //console.log(devices);
    //console.log(loopDevices);
    //for (let i = 0; i < loopDevices.length; i++) {
    //  deviceNames.push(devices[loopDevices[i]].name);
    //}
    //console.log(loopDevices);

    return loopDevices;
  }
  getLoopDevicesList() {
    if (!this.props.devices || Object.keys(this.props.devices).length === 0) {
      return [];
    }
    const activeLoop = this.props.activeLoop;
    const loop = this.props.loops[activeLoop];
    const loopDevices = ['Select Device'].concat(loop.devices);
    //const devices = this.props.devices;
    //let deviceNames = [];
    //console.log(devices);
    //console.log(loopDevices);
    //for (let i = 0; i < loopDevices.length; i++) {
    //  deviceNames.push(devices[loopDevices[i]].name);
    //}
    //console.log(loopDevices);

    return loopDevices;
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
      status: '',
      statusMessage: '',
      //open: false,
      //formBool: false,
      improvement: '',
      comment: '',
      level: 1,
      improvementType: 'Investigate',
      device: null,
      test: null,
      testDate: null,
      stateDate: null,
      editId: null,
      menus: {},
      approvalOpen: false,
      approvalName: '',
      approvalEmail: '',
      approvalId: null,
      approvalType: '',
      deleteId: null,
      deleteOpen: false,
    });
  };
  /* handleClose = () => {
    this.setState({
      open: false,
      formBool: false,
      testDate: null,
      stateDate: null,
    });
  };*/
  addImprovementForm() {
    const { classes } = this.props;
    const bool = this.state.formBool;

    //const bool = true;
    //const testOptions = [];
    /*!!this.props.devices[this.props.activeDevice] &&
      !!this.props.devices[this.props.activeDevice].datetimeHistory
        ? this.processTimestampArray(
            this.props.devices[this.props.activeDevice].datetimeHistory,
            this.props.devices[this.props.activeDevice].stateHistory
          )
        : [];*/
    return (
      <CustomDialog
        center
        open={this.state.open}
        onClose={this.handleClose}
        title={<React.Fragment>{bool ? `Edit` : `Add`} Action Item</React.Fragment>}
        content={
          <React.Fragment>
            <DialogContentText style={{ paddingTop: 20 }}>Please describe the action item you wish to add.</DialogContentText>
            <form>
              <TextField
                autoFocus
                id='improvement'
                label='Action Item'
                name='improvement'
                fullWidth
                //multiline
                //rows={4}
                //defaultValue=''
                //variant="outlined"
                value={this.state.improvement}
                onChange={this.handleInputChange}
                className={classes.textField}
                style={{ minWidth: '100%', marginTop: 10, marginBottom: 15 }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
              <CustomSelect
                single
                id='priority'
                label='Priority'
                type='priority'
                name='priority'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.handleInputChangeLevel(value);
                }}
                //type='unit'
                values={!this.state.levelName ? 'Unknown' : this.state.levelName}
                options={['Unknown', 'Not Important', 'Standard', 'Important', 'Very Important', 'Extremely Important']}
                //value={null}
                helperText={null}
              />
              <CustomSelect
                single
                id='improvementType'
                label='Action Item Type'
                type='improvementType'
                name='improvementType'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.handleInputChangeImprovementType(value);
                }}
                //type='unit'
                values={!this.state.improvementType ? 'Investigate' : this.state.improvementType}
                options={['Investigate', 'On-line Improvement', 'Outage Required', 'Application Shutdown', 'Plant Shutdown Required', 'Note']}
                //value={null}
                helperText={null}
              />
              <br />
              <CustomSelect
                single
                id='issue'
                label='Source Issue'
                type='issue'
                name='issue'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.handleInputChangeIssue(value);
                }}
                //type='unit'
                values={this.state.issue === null || this.state.issue === 'null' ? 'Non-Specific Issue' : this.state.issue}
                options={true ? this.getLoopIssuesList() : []}
                //value={null}
                helperText={null}
              />
              <CustomSelect
                single
                id='device'
                label='Source Device'
                type='device'
                name='device'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.handleInputChangeDevice(value);
                }}
                //type='unit'
                values={true && !!this.state.device ? this.getLoopDeviceId(this.state.device) : 'Select Device'}
                options={true ? this.getLoopDevicesList() : ['Select Device']}
                //value={null}
                helperText={null}
              />
              <br />
              <TextField
                id='comment'
                label='Comment'
                name='comment'
                multiline
                fullWidth
                rows={4}
                variant='outlined'
                value={this.state.comment}
                onChange={this.handleInputChange}
                style={{ marginTop: 15 }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </form>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Typography color='Red' variant='subtitle2' style={{ fontSize: 14, marginTop: 7, marginLeft: 10, float: 'left' }}>
                  {this.state.improvement === '' || this.state.device === null ? 'Please Enter: ' : ''}
                  {this.state.improvement === '' ? 'Title' : ''}
                  {this.state.improvement === '' && this.state.device === null ? ', ' : ''}
                  {this.state.device === null ? 'Device' : ''}
                </Typography>
              </Grid>
              <Grid item>
                <Button onClick={this.handleClose} color='secondary'>
                  Cancel
                </Button>
                <Button disabled={this.state.improvement === '' || this.state.device === null} onClick={this.handleSubmit} color='secondary'>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }

  createMenu(row) {
    //console.log(row);
    let menuOptions = [];
    const approved = row.approved === 1;
    const notApproved = row.notApproved === 1;
    const completed = row.completed === 1;
    const base = !approved && !notApproved && !completed;
    if ((base || approved) && !completed && !notApproved) {
      if (row.approved === 0 || row.approved === null) {
        //Approve if unapproved, unapprove if approved
        menuOptions.push({
          id: row.id,
          title: 'Approve',
          icon: ThumbUpAlt,
          onClick: () => {
            this.setState({
              approvalId: row.id,
              approvalType: `approved`,
            });
            this.openApproval();
          },
        });
      } else {
        menuOptions.push({
          id: row.id,
          title: 'Undo Approval',
          icon: Undo,
          onClick: (id) => {
            //console.log('Time to Unapprove! ' + row.id);
            //this.setState({ approvalType: 'approve' });
            this.handleApproval(false, row.id, `approved`);
          },
        });
      }
    }
    if ((base || approved || notApproved) && !completed) {
      if (row.notApproved === 0 || row.notApproved === null) {
        menuOptions.push({
          id: row.id,
          title: 'Not Approved',
          icon: ThumbDownAlt,
          onClick: () => {
            //console.log('Time to cancel ' + row.id);
            this.setState({
              approvalId: row.id,
              approvalType: `notApproved`,
            });
            this.openApproval();
            //this.setState({ approvalId: row.id });
            //this.openApproval();
          },
        });
      } else {
        menuOptions.push({
          id: row.id,
          title: 'Undo Not Approved',
          icon: Undo,
          onClick: (id) => {
            //console.log('Time to Unapprove! ' + row.id);
            //this.setState({ approvalType: 'approve' });
            this.handleApproval(false, row.id, `notApproved`);
          },
        });
      }
    }
    if (!notApproved) {
      if (row.completed === 0 || row.completed === null) {
        menuOptions.push({
          id: row.id,
          title: 'Complete',
          icon: CheckCircle,
          onClick: () => {
            //console.log('Time to complete ' + row.id);
            this.setState({
              approvalId: row.id,
              approvalType: `completed`,
            });
            this.openApproval();
            //this.setState({ approvalId: row.id });
            //this.openApproval();
          },
        });
      } else {
        menuOptions.push({
          id: row.id,
          title: 'Undo Completed',
          icon: Undo,
          onClick: (id) => {
            //console.log('Time to Unapprove! ' + row.id);
            //this.setState({ approvalType: 'approve' });
            this.handleApproval(false, row.id, `completed`);
          },
        });
      }
    }
    /*menuOptions.push({
        id: row.id,
        title: 'More Info',
        icon: Info,
        onClick: () => {
          console.log('More Info ' + row.id);
          console.log(row.user);
          console.log(row.date);
          //this.setState({ approvalId: row.id });
          //this.openApproval();
        },
      });*/

    if (this.props.userId === row.user) {
      //permissioning for edit capability, logged in user is the same as the writer. // TODO needs same check on backend
      menuOptions.push({
        id: row.id,
        title: 'Edit',
        icon: Edit,
        onClick: (e) => {
          this.processEdit(row.id);
        },
      });
      menuOptions.push({
        id: row.id,
        title: 'Delete',
        icon: Delete,
        onClick: (e) => {
          this.setState({ deleteId: row.id });
          this.openDelete();
          //this.processDelete(row.id);
        },
      });
    }
    return <DropdownMenu tooltip='Update Status' helperText='Select to update status' options={menuOptions} />;
  }

  styleStatus(status) {
    const { classes } = this.props;
    return (
      <Typography
        variant='subtitle2'
        className={
          status.status === 'Not Approved'
            ? classes.redText
            : status.status === 'Approved'
            ? classes.blueText
            : status.status === 'Completed'
            ? classes.greenText
            : status.status === 'Pending'
            ? classes.grayText
            : null
        }
      >
        {status.status}
      </Typography>
    );
  }
  styleActionItem(row) {
    return (
      <React.Fragment>
        <Typography variant='subtitle2'>{row.improvement}</Typography>
        <Typography variant='subtitle2' color='secondary' style={{ fontStyle: 'italic', marginTop: 0 }}>
          {row.improvementType}
        </Typography>
      </React.Fragment>
    );
  }
  styleContact(status) {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle2'>{status.contact}</Typography>
        {!!status.user && status.email !== '' && status.contact !== '-' ? (
          <React.Fragment>
            <InfoPopover
              tooltip='Copy Email'
              title='Copy Email'
              resize
              onClick={() => {
                //console.log('t');
                navigator.clipboard.writeText(status.email);
                this.setState({ copy: true, copyText: status.email });
              }}
              style={{ marginBottom: -15 }}
              icon={<MailOutline />}
              content={
                <Typography style={{ marginRight: 40 }} variant='subtitle2' className={classes.printHide}>
                  '{this.state.copyText}' Copied to Clipboard
                </Typography>
              }
            />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
  styleDevice(device) {
    return (
      <React.Fragment>
        <Typography variant='subtitle2'>{device.name}</Typography>
        {/*<Typography
          variant='subtitle2'
          color='secondary'
          style={{ marginTop: 5 }}
        >
          Type
        </Typography>*/}
        <Typography variant='subtitle2' color='secondary'>
          {device.deviceType}
        </Typography>
      </React.Fragment>
    );
  }
  renderWorkbenchWidgets() {
    const { classes } = this.props;
    if (this.state.workbenchToggle) {
      return (
        <div className={classes.pageBreak}>
          <WorkbenchWidgets toggledWidget></WorkbenchWidgets>
        </div>
      );
    }
  }
  renderActionWidgets() {
    const { classes } = this.props;
    if (this.state.actionToggle) {
      return (
        <div className={classes.pageBreak}>
          <ActionWidgets toggledWidget></ActionWidgets>
        </div>
      );
    }
  }
  handleWorkbenchCheck = () => {
    this.setState({
      workbenchCheck: !this.state.workbenchCheck,
    });
  };
  handleActionCheck = () => {
    this.setState({
      actionCheck: !this.state.actionCheck,
    });
  };
  render() {
    const { classes } = this.props;
    const loop = this.props.loops[this.props.activeLoop];
    const header = [
      { id: 'actionFormatted', label: 'Action Item' },
      //{ id: 'improvement', label: 'Action Item' },
      { id: 'deviceFormatted', label: 'Device' },
      { id: 'issue_id', label: 'Issue' },
      { id: 'comment', label: 'Comment' },
      { id: 'statusDate', label: 'Date' },
      { id: 'statusFormatted', label: 'Status' },
      { id: 'contactFormatted', label: 'Contact' },
      { id: 'menu', label: 'Update Status' },
    ];
    //console.log(loop);
    let data = [];
    //TODO Add menu and correction/formatted items
    if (!!loop && !!loop.improvements) {
      for (let i = 0; i < loop.improvements.length; i++) {
        if (!!loop.improvements[i]) {
          let actionItem = loop.improvements[i];
          //console.log(actionItem);
          let event = this.props.events[actionItem.issue_id];
          let status = this.processStatus(actionItem);
          /*console.log(actionItem);
          console.log(this.props.events[actionItem.issue_id]);
          console.log('');*/
          //this.props.actions[loop.actions[i]]
          //console.log(actionItem);
          //console.log(event);
          let dataObj = {
            id: actionItem.id,
            improvement: actionItem.improvement,
            deviceFormatted: !this.props.devices[actionItem.device_id] ? '' : this.styleDevice(this.props.devices[actionItem.device_id]),
            device: !this.props.devices[actionItem.device_id] ? 'unknown' : this.props.devices[actionItem.device_id].deviceType,
            improvementType: actionItem.improvementType,
            actionFormatted: this.styleActionItem(actionItem),
            action: actionItem.improvementType,
            //issue: !event ? '' : event.issue_id,
            issue_id: !event ? '' : event.eventName,
            comment: actionItem.comment,
            statusFormatted: this.styleStatus(status),
            status: status.status,
            statusUser: status.user,
            statusEmail: status.email,
            statusDate: status.date === '-' ? '-' : this.processDate(new Date(status.date * 1000)),
            contactFormatted: this.styleContact(status),
            contact: status.contact,
            menu: this.createMenu(actionItem),
          };
          data.push(dataObj);
        }
      }
    }
    const handleClick = (event) => {
      this.setState({
        selectMenu: !this.state.selectMenu,
        selectMenuAnchor: event.currentTarget,
      });
    };
    const handleClose = () => {
      this.setState({ selectMenu: false });
    };
    const handleSubmit = () => {
      this.setState({ selectMenu: false, workbenchToggle: this.state.workbenchCheck, actionToggle: this.state.actionCheck });
    };
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    if (this.props.loops[this.props.activeLoop]) {
      return (
        <React.Fragment>
          {!!this.props.toggledWidget ? <PrintHeader></PrintHeader> : null}
          <Grid container justifyContent='space-between' style={{ marginBottom: -42 }} className={classes.printHide}>
            <Grid item></Grid>
            <Grid item>
              <Grid container spacing={1}>
                {!printStyles ? (
                  <Grid item>
                    <InfoPopover
                      center
                      tooltip='Configure'
                      title='Configure'
                      titleIcon='Settings'
                      resize
                      style={{ marginBottom: -15 }}
                      icon={<Settings />}
                      type='prioritizeConfig'
                      usePrioritizeConfigTabs
                      loop={loop}
                      devices={['1', '2', '3']}
                    />
                  </Grid>
                ) : null}
                <Grid item>
                  <Tooltip arrow placement='top' title='Show More' aria-label='Show More'>
                    <IconButton color='inherit' aria-label='more' onClick={handleClick} style={{ marginLeft: -10 }} size='large'>
                      <MoreVert />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Popper open={this.state.selectMenu} onClose={handleClose} anchorEl={this.state.selectMenuAnchor} placement='bottom-start'>
            <ClickAwayListener onClickAway={handleClose}>
              <Paper>
                <Typography variant='caption' style={{ padding: '10px 12px 6px 14px', display: 'block' }}>
                  Select to show more info
                </Typography>
                <MenuItem onClick={this.handleWorkbenchCheck}>
                  <Checkbox className={classes.menuCheckbox} checked={this.state.workbenchCheck} color='secondary' />
                  Workbench
                </MenuItem>
                <MenuItem onClick={this.handleActionCheck}>
                  <Checkbox className={classes.menuCheckbox} checked={this.state.actionCheck} color='secondary' />
                  Action Items
                </MenuItem>
                <Grid container justifyContent='space-between'>
                  <Grid item></Grid>
                  <Grid item>
                    <Button variant='outlined' disableElevation size='small' color='primary' aria-label='submit' onClick={handleSubmit} style={{ margin: 10, display: 'block' }}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </ClickAwayListener>
          </Popper>
          <WidgetTitle className={classes.printHide} title={`Issues and Auto-Generated Action Items - ${!loop || !loop.processName ? 'Loop Name Not Found' : loop.processName}`} />
          <Grid container justifyContent='space-between' style={{ marginBottom: 8 }}>
            <Grid item>
              <CustomSelect
                id={null}
                label='Analysis Range'
                single
                inline
                variant='outlined'
                onChangeFunction={(event, value, type) => {
                  // this.props.fetchLoop(this.props.activeLoop, { AnalysisRange: value });
                  this.props.fetchLoopDiagnostics(this.props.activeLoop, value, this.props.dataset);
                  this.props.fetchLoopEvents(this.props.activeLoop, { AnalysisRange: value }, this.props.dataset);

                  //console.log(value);
                  this.props.updateAnalysisRange(value);
                }}
                prefix='Analysis Range: '
                suffix=' Days'
                options={['7', '14', '30', '90', '180', '365']}
                values={!this.props.analysisRange ? '90' : this.props.analysisRange.toString()}
                //selectedOption={'2 - Subsystem Shutdown or Sustained KPI Loss'}
                helperText={null}
              />
            </Grid>
            <Grid item>
              <Typography variant='body2' style={{ marginBottom: 4 }} className={classes.printHide}>
                Click any row to expand and view Auto-Generated Action Items
              </Typography>
            </Grid>
          </Grid>
          <AccordionWidgetPrioritize />
          <WidgetTitle className={classes.printHide} title={`Action Items - ${!loop || !loop.processName ? 'Loop Name Not Found' : loop.processName}`} />
          <Grid container spacing={1}>
            <TableWidget xs={12} defaultRows={25} data={data} header={header} />
          </Grid>

          {!!this.props.hideInfo && this.props.hideInfo === false ? null : (
            <div style={{ marginTop: 4 }}>
              <Button onClick={this.handleClickOpen} className={`${classes.buttonFilter} ${classes.printHide}`} color='primary' variant='outlined'>
                Add Action Item
              </Button>
            </div>
          )}
          {this.renderWorkbenchWidgets()}
          {this.renderActionWidgets()}
          {this.addImprovementForm()}
          {this.approvalDialog()}
          {this.deleteDialog()}
          {!this.props.toggledWidget ? (
            <Grid className={classes.printHide}>
              <Grid container spacing={1}>
                <Grid item>
                  <Tooltip arrow placement='top' title='Show More' aria-label='Show More'>
                    <Button variant='outlined' size='medium' color='primary' aria-label='more' onClick={handleClick} style={{ marginTop: 4 }}>
                      <MoreVert className={classes.buttonIcon}></MoreVert>Show More
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    activeLoop: state.prioritize.activeLoop,
    currentModule: state.currentModule.currentModule,
    devices: state.devices.devices,
    loops: state.loops.loops,
    actions: state.improvements,
    events: state.events,
    userId: state.auth.userId,
    issueTypes: state.issuetypes.list,
    analysisRange: state.sort.filter.AnalysisRange,
    dataset: state.sort.filter.Dataset,
  };
};

export default connect(mapStateToProps, {
  fetchIssueTypes,
  fetchLoop,
  fetchLoopDevices,
  fetchLoopActionItems,
  fetchLoopDiagnostics,
  approveImprovement,
  deleteImprovement,
  updateAnalysisRange,
  fetchLoopEvents,
})(withStyles(styles)(IssuesWidgets));
