import _ from 'lodash';
import React from 'react';
import { Checkbox, Typography, ListItem, Tooltip, TablePagination, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PrioritizeTile from '../tiles/PrioritizeTile';
import CustomSelect from '../filters/CustomSelect';
import Divider from '@mui/material/Divider';
import { RestartAlt } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { styles } from '../styles';
import { connect } from 'react-redux';
import functions from '../../functions/functions';
import {
  fetchLoops,
  fetchLoopsSummary,
  fetchSites,
  fetchDeviceTypes,
  fetchApplications,
  fetchLocations,
  fetchImportances,
  fetchEvents,
  fetchIssueTypes,
  fetchDatasets,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  updateSort,
  updateFilter,
  deleteLoops,
  deleteDevices,
  setModule,
  prioritizeSetActiveLoop,
  clearFilter,
  updatePreset,
  updateDateRange,
  setActiveNav,
} from '../../actions/index';
import { CircularProgress } from '@mui/material';
import Legend from '../headers/Legend';
//import Presets from '../../arrays/prioritize/presets';
//import DatePicker from '../filters/DatePicker';

//TODO Prioritize is treating partial data as full data in the store
//TODO Events is trating full data as partial data in the store
//TODO Investigate refactor of Prioritize/Optimize components into 1 Module component
class Prioritize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 24,
      page: 0,
      order: '',
      orderBy: '',
      loading: true,
      changes: false,
    };
    this.resetFilters = this.resetFilters.bind(this);
  }
  async componentDidMount() {
    if (this.state.currentModule !== 'Prioritize') {
      this.props.setModule('Prioritize');
    }
    this.props.prioritizeSetActiveLoop('');
    this.props.deleteLoops();
    this.props.deleteDevices();
    let preset = {
      name: functions.getCookie('presetName'),
      value: functions.getCookie('presetValue'),
    }; // = `preset=${value}`;
    if (preset.name !== '' && preset.value !== '') {
      this.setState({ changes: true });
      await this.props.updatePreset(preset);
      this.multiSelectOnClose();
    } else {
      this.props.updateSort('Importance');
      let filters = {
        Application: this.props.sort.filter.Application,
        Site: this.props.sort.filter.Site,
        Location: this.props.sort.filter.Location,
        Disruption: this.props.sort.filter.Disruption,
        startDate: this.props.sort.filter.Range.startDate,
        endDate: this.props.sort.filter.Range.endDate,
        analysisRange: this.props.sort.filter.analysisRange,
        Devicetypes: this.props.sort.filter['Device Types'],
        Issuetypes: this.props.sort.filter['Issue Types'],
        Importance: this.props.sort.filter.Importance,
        SpecificDevice: this.props.sort.filter.SpecificDevice,
        Concerns: JSON.stringify(this.props.sort.concern),
      };
      //console.log(filters);
      //console.log(this.props.sort.filter.Dataset);
      this.props.fetchLoops(this.state.page, this.props.table.pageSize, this.props.sort.sort, filters, this.props.sort.filter.Dataset).then(() => {
        this.setState({
          //page,
          //rowsPerPage,
          loading: false,
        });
      });
    }

    this.props.fetchSites();
    this.props.fetchApplications();
    this.props.fetchLocations();
    this.props.fetchDeviceTypes();
    this.props.fetchImportances();
    this.props.fetchIssueTypes();
    this.props.fetchDatasets();
    //this.props.fetchEvents();
    /* {Object.keys(this.props.loops).map(key => 
            <PrioritizeTile hasBorder key = {key} loop = {this.props.loops[key].name} />
          )}*/
  }
  setDeviceSpecific = async (event) => {
    //99.9% SAME AS MULTISELECTONCLOSE
    //console.log('Closed');
    //console.log(this.props.sort);
    let obj = { ...this.props.sort.filter };
    obj[`SpecificDevice`] = event.target.checked;
    this.props.updateFilter(obj);
    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      Disruption: this.props.sort.filter.Disruption,
      startDate: this.props.sort.filter.Range.startDate,
      endDate: this.props.sort.filter.Range.endDate,
      analysisRange: this.props.sort.filter.analysisRange,
      Devicetypes: this.props.sort.filter['Device Types'],
      Issuetypes: this.props.sort.filter['Issue Types'],
      Importance: this.props.sort.filter.Importance,
      SpecificDevice: event.target.checked,
      Concerns: JSON.stringify(this.props.sort.concern),
    };
    if (
      !(
        !filters.Application &&
        !filters.Disruption &&
        !filters.Devicetypes &&
        !filters.Issuetypes &&
        !filters.Importance &&
        !filters.Location &&
        !filters.Site &&
        !filters.Concerns &&
        this.props.auth.require_filter
      )
    ) {
      this.props.deleteDevices();
      this.props.deleteLoops();
      //TODO Finish with update of page

      //console.log(filters);
      //console.log(this.props.sort.filter);
      this.setState({ loading: true });
      this.props.setTablePage(0);

      //console.log(filters);
      await this.props.fetchLoops(0, this.props.table.pageSize, this.props.sort.sort, filters, this.props.sort.filter.Dataset).then(() => {
        this.setState({
          //page,
          //rowsPerPage,
          loading: false,
        });
      });
      //this.props.fetchLoopsSummary();
    }
  };
  /*(value) => {
              //console.log(event);
              this.props.updateSort(value);
              this.props.deleteLoops();
              // TODO FINISH THIS WHEN TABLE FULLY IN REDUX
              this.props.setTablePage(0);
              this.props.fetchLoops(
                0,
                this.props.table.pageSize,
                value,
                this.props.sort.filter.Application
              );
            }*/
  handleSort = async (event, value, type) => {
    this.props.updateSort(value);
    // TODO FINISH THIS WHEN TABLE FULLY IN REDUX
    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      Disruption: this.props.sort.filter.Disruption,
      startDate: this.props.sort.filter.Range.startDate,
      endDate: this.props.sort.filter.Range.endDate,
      analysisRange: this.props.sort.filter.analysisRange,
      Devicetypes: this.props.sort.filter['Device Types'],
      Issuetypes: this.props.sort.filter['Issue Types'],
      Importance: this.props.sort.filter.Importance,
      SpecificDevice: this.props.sort.filter.SpecificDevice,
      Concerns: JSON.stringify(this.props.sort.concern),
    };
    if (
      !(
        !filters.Application &&
        !filters.Disruption &&
        !filters.Devicetypes &&
        !filters.Issuetypes &&
        !filters.Importance &&
        !filters.Location &&
        !filters.Site &&
        !filters.Concerns &&
        this.props.auth.require_filter
      )
    ) {
      this.props.deleteDevices();
      this.props.deleteLoops();
      //TODO Finish with update of page

      //console.log(filters);
      //console.log(this.props.sort.filter);
      this.setState({ loading: true });
      this.props.setTablePage(0);
      //}

      //console.log(filters);

      await this.props.fetchLoops(0, this.props.table.pageSize, value, filters, this.props.sort.filter.Dataset).then(() => {
        this.setState({
          //page,
          //rowsPerPage,
          loading: false,
          changes: false,
        });
      });
      //this.props.fetchLoopsSummary();
    }
  };
  changeAnalysisRange = async (obj) => {
    this.props.updateFilter(obj);
    //this.setState({ changes: true });
    //this.multiSelectOnClose();

    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      Disruption: this.props.sort.filter.Disruption,
      startDate: this.props.sort.filter.Range.startDate,
      endDate: this.props.sort.filter.Range.endDate,
      analysisRange: obj.AnalysisRange,
      Devicetypes: this.props.sort.filter['Device Types'],
      Issuetypes: this.props.sort.filter['Issue Types'],
      Importance: this.props.sort.filter.Importance,
      SpecificDevice: this.props.sort.filter.SpecificDevice,
      Concerns: JSON.stringify(this.props.sort.concern),
    };
    if (
      !(
        !filters.Application &&
        !filters.Disruption &&
        !filters.Devicetypes &&
        !filters.Issuetypes &&
        !filters.Importance &&
        !filters.Location &&
        !filters.Site &&
        !filters.Concerns &&
        this.props.auth.require_filter
      )
    ) {
      this.props.deleteDevices();
      this.props.deleteLoops();
      //TODO Finish with update of page

      //console.log(filters);
      //console.log(this.props.sort.filter);
      this.setState({ loading: true });
      this.props.setTablePage(0);
      //if (!this.props.locations || Object.entries(this.props.locations).length === 0) {
      //if (label !== 'Location') {
      this.props.fetchLocations(null, filters);
      //}
      //}

      //if (!this.props.applications || Object.entries(this.props.applications).length === 0) {
      //if (label !== 'Application') {
      this.props.fetchApplications(null, filters);
      //}
      //}

      //if (label !== 'Importance') {
      this.props.fetchImportances(null, filters);
      //}
      //}

      //if (!this.props.sites || Object.entries(this.props.sites).length === 0) {
      //if (label !== 'Site') {
      this.props.fetchSites(null, filters);
      //}
      //}
      //if (label !== 'Device Types') {
      this.props.fetchDeviceTypes(null, filters);
      //}
      //if (label !== 'Issue Types') {
      this.props.fetchIssueTypes(null, filters);
      //}
      this.props.fetchDatasets(null, filters);
      //console.log(filters);
      await this.props.fetchLoops(0, this.props.table.pageSize, this.props.sort.sort, filters, this.props.sort.filter.Dataset).then(() => {
        this.setState({
          //page,
          //rowsPerPage,
          loading: false,
          changes: false,
        });
      });
      //this.props.fetchLoopsSummary();
    }
  };

  multiSelectOnClose = async (event, label, changesOverride) => {
    //console.log('t');
    if (this.state.changes || changesOverride) {
      //99.9% SAME AS SETDEVICESPECIFIC
      //console.log('Closed');
      //console.log(this.props.sort);
      let filters = {
        Application: this.props.sort.filter.Application,
        Site: this.props.sort.filter.Site,
        Location: this.props.sort.filter.Location,
        Disruption: this.props.sort.filter.Disruption,
        startDate: this.props.sort.filter.Range.startDate,
        endDate: this.props.sort.filter.Range.endDate,
        analysisRange: this.props.sort.filter.analysisRange,
        Devicetypes: this.props.sort.filter['Device Types'],
        Issuetypes: this.props.sort.filter['Issue Types'],
        Importance: this.props.sort.filter.Importance,
        SpecificDevice: this.props.sort.filter.SpecificDevice,
        Concerns: JSON.stringify(this.props.sort.concern),
      };
      if (
        !(
          !filters.Application &&
          !filters.Disruption &&
          !filters.Devicetypes &&
          !filters.Issuetypes &&
          !filters.Importance &&
          !filters.Location &&
          !filters.Site &&
          !filters.Concerns &&
          this.props.auth.require_filter
        )
      ) {
        this.props.deleteDevices();
        this.props.deleteLoops();
        //TODO Finish with update of page

        //console.log(filters);
        //console.log(this.props.sort.filter);
        this.setState({ loading: true });
        this.props.setTablePage(0);
        //if (!this.props.locations || Object.entries(this.props.locations).length === 0) {
        //if (label !== 'Location') {
        this.props.fetchLocations(null, filters);
        //}
        //}

        //if (!this.props.applications || Object.entries(this.props.applications).length === 0) {
        //if (label !== 'Application') {
        this.props.fetchApplications(null, filters);
        //}
        //}

        //if (label !== 'Importance') {
        this.props.fetchImportances(null, filters);
        //}
        //}

        //if (!this.props.sites || Object.entries(this.props.sites).length === 0) {
        //if (label !== 'Site') {
        this.props.fetchSites(null, filters);
        //}
        //}
        //if (label !== 'Device Types') {
        this.props.fetchDeviceTypes(null, filters);
        //}
        //if (label !== 'Issue Types') {
        this.props.fetchIssueTypes(null, filters);
        //}
        this.props.fetchDatasets(null, filters);
        //console.log(filters);
        await this.props.fetchLoops(0, this.props.table.pageSize, this.props.sort.sort, filters, this.props.sort.filter.Dataset).then(() => {
          this.setState({
            //page,
            //rowsPerPage,
            loading: false,
            changes: false,
          });
        });
        //this.props.fetchLoopsSummary();
      }
    }
  };
  updateDataset = async (start, end /*value, type*/) => {
    //console.log(value);
    //console.log(functions.isValidDate(value));
    //if (functions.isValidDate(value)) {
    //do nothing if the date isn't valid

    /*if (type === 'start') {
        this.props.updateDateRange({
          startDate: Math.floor(value.getTime() / 1000),
          endDate: this.props.sort.filter.Range.endDate,
        });
      } else {
        this.props.updateDateRange({
          startDate: this.props.sort.filter.Range.startDate,
          endDate: Math.floor(value.getTime() / 1000),
        });
      }*/

    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      //Disruption: this.props.sort.filter.Disruption,
      Devicetypes: this.props.sort.filter['Device Types'],
      Issuetypes: this.props.sort.filter['Issue Types'],
      Importance: this.props.sort.filter.Importance,
      startDate: start,
      endDate: end,
    };

    let obj = { ...this.props.sort.filter };
    obj[`Range`] = { startDate: start, endDate: end };
    console.log(obj);
    this.props.updateFilter(obj);
    /*if (type === 'start') {
        filters.startDate = Math.floor(value.getTime() / 1000);
        filters.endDate = this.props.sort.filter.Range.endDate;
      } else {
        filters.startDate = this.props.sort.filter.Range.startDate;
        filters.endDate = Math.floor(value.getTime() / 1000);
      }*/
    //! NOTES TO MAKE DATASET SELECTION VIABLE
    //! Need to add start date viability to this function
    //! Needs to work with 'All Data'
    /*
      Redux needs a new value (dataSet) which is a utc timestamp
      All fetch loops/ect need to check and use this value when initialized (multionselectclose/ect)
      All relevant API calls need to detect it and use it
      Variable pulls as well

      LIST OF QUERIES ADJUSTED:
      


      */
    //console.log(filters);
    if (!(!filters.Application && !filters.Disruption && !filters.Importance && !filters.Location && !filters.Site && this.props.auth.require_filter)) {
      this.setState({
        loading: true,
      });
      this.props.deleteDevices();
      this.props.deleteLoops();

      //TODO Finish with update of page
      console.log(filters.Range);
      this.props.fetchLoops(0, this.props.table.pageSize, this.props.sort.sort, filters, /*this.props.sort.filter.Range.endDate*/ 0, false).then(() => {
        this.setState({
          //page,
          //rowsPerPage,
          loading: false,
          changes: false,
        });
      });
      //console.log(filters);
      //console.log(this.props.sort.filter);
    }
    //}
  };
  multiSelectOnChange = async (event, value, type) => {
    let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    obj[name] = value;
    this.props.updateFilter(obj);
    this.setState({ changes: true });
  };
  refreshPageView = (offset, rowsPerPage, expectedPage) => {
    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      Disruption: this.props.sort.filter.Disruption,
      startDate: this.props.sort.filter.Range.startDate,
      endDate: this.props.sort.filter.Range.endDate,
      analysisRange: this.props.sort.filter.analysisRange,
      Devicetypes: this.props.sort.filter['Device Types'],
      Issuetypes: this.props.sort.filter['Issue Types'],
      Importance: this.props.sort.filter.Importance,
      SpecificDevice: this.props.sort.filter.SpecificDevice,
      Concerns: JSON.stringify(this.props.sort.concern),
    };
    if (
      !(
        !filters.Application &&
        !filters.Disruption &&
        !filters.Devicetypes &&
        !filters.Issuetypes &&
        !filters.Importance &&
        !filters.Location &&
        !filters.Site &&
        !filters.Concerns &&
        this.props.auth.require_filter
      )
    ) {
      //Check to see if we already pulled the row data, if so don't bother the API again
      //console.log(this.props.sort);
      let rowsExist = true;
      const page = Math.floor(offset / rowsPerPage); // correct the page number for changing the page size
      //const rowList = this.props.loops.map(loop => loop.rowId);
      const rowList = _.map(this.props.loops, (loop) => loop.rowId);
      for (let i = offset; i < offset + rowsPerPage; i++) {
        if (!rowList.includes(i + 1)) {
          rowsExist = false;
        }
      }
      if (rowsExist) {
        this.setState({
          page,
          rowsPerPage,
          loading: false,
        });
      } else {
        this.props.fetchLoops(offset, rowsPerPage, this.props.sort.sort, filters, this.props.sort.filter.Dataset).then(() => {
          this.setState({
            page,
            rowsPerPage,
            loading: false,
          });
        });
      }
    }
  };

  handleChangePage = (event, page) => {
    this.setState({
      loading: true,
    });
    this.props.setTableOffset(this.props.table.pageSize * page);
    this.props.setTablePage(page);
    const offset = this.props.table.pageSize * page;
    const rowsPerPage = this.props.table.pageSize;
    this.refreshPageView(offset, rowsPerPage, page);
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({
      loading: true,
    });
    this.props.setTablePageSize(event.target.value);
    this.props.setTableOffset(this.props.table.page * this.props.table.pageSize);
    const offset = this.props.table.page * this.props.table.pageSize;
    const rowsPerPage = event.target.value;
    const page = Math.floor(offset / rowsPerPage);
    this.props.setTablePage(page);
    this.refreshPageView(offset, rowsPerPage, page);
  };
  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <div>
          <Typography variant='subtitle2' style={{ marginTop: 12 }}>
            <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
            Loading...
          </Typography>
        </div>
      );
    } else {
      return;
    }
  }
  async resetFilters() {
    this.setState({ changes: true });
    await this.props.clearFilter();
    this.props.updateSort('Importance');
    this.multiSelectOnClose();
  }
  setPreset = async (event, valueObj, type) => {
    let name = valueObj.name;
    let value = valueObj.value;
    this.setState({ changes: true });

    if (value === 'noPreset') {
      this.props.clearFilter();
      functions.setCookie('presetName', name, -4);
      functions.setCookie('presetValue', value, -4);
      this.multiSelectOnClose();
    } else {
      functions.setCookie('presetName', name);
      functions.setCookie('presetValue', value);
      await this.props.updatePreset(valueObj);
      this.multiSelectOnClose();
    }
  };
  renderTotals() {
    const { classes } = this.props;
    let issueKeys = Object.keys(this.props.loops).map((key) => {
      return this.props.loops[key].issues.length;
    });
    let totalIssues = 0;
    issueKeys.forEach((issue) => {
      totalIssues += issue;
    });
    let actionKeys = Object.keys(this.props.loops).map((key) => {
      return this.props.loops[key].actions.length;
    });
    let totalActions = 0;
    actionKeys.forEach((action) => {
      totalActions += action;
    });
    return (
      <Grid container className={classes.totalsContainer} spacing={2}>
        <Grid item>{<Typography variant='subtitle2'>Total Issues: {!totalIssues ? 0 : totalIssues}</Typography>}</Grid>
        <Grid item>{<Typography variant='subtitle2'>Total Action Items: {!totalActions ? 0 : totalActions}</Typography>}</Grid>
      </Grid>
    );
  }
  render() {
    const { classes } = this.props;
    //    let datasetDates = [functions.dateToString(Math.round(new Date().getTime()), 'date'), functions.dateToString(1546365689000, 'date'), functions.dateToString(1569434489000, 'date')];
    let datasetNames = Object.keys(this.props.datasets);
    /*for (const [key, datasetObj] of Object.entries(this.props.datasets)) {
      datasetNames.push(datasetObj.name);
    }*/
    /*this.props.datasets.filter((dataset) => {
      return dataset.name;
    });*/

    //console.log(datasetDates);
    // Now, 1-1-2029, 9-25-2019
    //TODO PROVIDE NICE NO PERMISSION AND LOADING RESPONSES
    if (!this.props.auth) {
      return <div>Loading...</div>;
    }
    if (!!this.props.auth && this.props.auth.prioritize === 0) {
      return <div>You do not have permission to access this module</div>;
    }
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    //console.log(this.props);
    return (
      <React.Fragment>
        <form className={classes.form} autoComplete='off' style={{ marginTop: 10 }}>
          <CustomSelect
            single
            id={null}
            label='Analysis Range'
            disabled={this.state.loading}
            onChangeFunction={(event, value, type) => {
              let obj = { ...this.props.sort.filter };
              let name = 'AnalysisRange';
              obj[name] = value;
              this.changeAnalysisRange(obj);
            }}
            suffix=' Days'
            values={!this.props.sort.filter.AnalysisRange ? '90' : this.props.sort.filter.AnalysisRange.toString()}
            options={['7', '14', '30', '90', '180', '365']}
            helperText={null}
          />
          {
            /*TODO Prio_1.0*/ //! dropdown needs to change
            <CustomSelect
              single
              id={null}
              label='Dataset'
              type='dataset'
              disabled={this.state.loading}
              onChangeFunction={(event, value, type) => {
                //console.log(value);
                let obj = { ...this.props.sort.filter };
                let name = 'dataset';
                obj[name] = value;
                this.props.updateFilter(obj);
                this.updateDataset(value === 'All Data' ? 0 : this.props.datasets[value].start, value === 'All Data' ? 0 : this.props.datasets[value].end);
              }}
              //selectedOption={datasetDates[0]}
              //!Replace dates with real dataset values when installed in database
              //values={!this.props.sort.filter.Dataset ? functions.dateToString(Math.round(new Date().getTime()), 'date') : functions.dateToString(this.props.sort.filter.Dataset * 1000, 'date')}
              //options={datasetDates}
              values={!this.props.sort.filter.dataset ? 'All Data' : this.props.sort.filter.dataset /*this.props.datasets[Object.keys(this.props.datasets)[0]]*/}
              options={['All Data'].concat(datasetNames)}
              // values={'Test1'}
              // options={
              //   /*!this.props.sort.sort
              //   ? ''
              //   :*/ ['Concern', 'Concern Max', 'Concern Shift', 'Test1', 'Name' /*'Priority'*/]
              // }
              //values={!this.props.sort.sort ? 'Date (Recent)' : this.props.sort.sort}
              //options={['Date (Recent)', 'Resolution (Highest)', 'Sensitivity (Highest)', 'Stroke (Highest)', 'Importance']}
              helperText={null}
              width={190}
            />
          }
          {/*<CustomSelect
            single
            id={null}
            label='Roadmap Preset'
            type='Preset'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.setPreset}
            values={!this.props.sort.preset ? { name: 'No Preset', value: 'noPreset' } : this.props.sort.preset}
            //TODO SET FROM THE ARRAY IN EXISTENCE
            options={[
              { name: 'No Preset', value: 'noPreset' },
              {
                name: 'Long Term System and Asset Stability',
                value: 'stability',
              },
              { name: 'High Risk of Sudden Outage', value: 'outage' },
              {
                name: 'High Frequency Maintenance',
                value: 'frequentMaintenance',
              },
              { name: 'Wet Analytical Maintenance', value: 'wetMaintenance' },
              { name: 'Turnaround Projects', value: 'turnaround' },
              { name: 'Critical Saturation', value: 'saturation' },
              {
                name: 'Fighting Corrosion, Erosion, and Coating',
                value: 'corrosion',
              },
              { name: 'Technology Review by Asset Type', value: 'technology' },
              { name: 'DCS Timing and Tuning', value: 'dcs' },
            ]}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            getOptionLabel={(option) => {
              return !option.name ? 'Error' : option.name;
            }}
            //value={null}
            helperText={null}
          />*/}
          <CustomSelect
            single
            id={null}
            label='Sort'
            type='sort'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.handleSort}
            values={!this.props.sort.sort ? 'Importance' : this.props.sort.sort}
            options={
              /*!this.props.sort.sort
                ? ''
                :*/ ['Concern', 'Concern Max', 'Concern Shift', 'Importance', 'Name', 'Application Order' /*'Priority'*/]
            }
            //value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Site'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter.Site ? [] : this.props.sort.filter.Site}
            options={!this.props.sites || !this.props.sites.list ? [] : this.props.sites.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Location'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter.Location ? [] : this.props.sort.filter.Location}
            options={!this.props.locations || !this.props.locations.list ? [] : this.props.locations.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Application'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter.Application ? [] : this.props.sort.filter.Application}
            options={!this.props.applications || !this.props.applications.list ? [] : this.props.applications.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Importance'
            disabled={this.state.loading}
            values={!this.props.sort.filter.Importance ? [] : this.props.sort.filter.Importance}
            options={!this.props.importances || !this.props.importances.list ? [] : this.props.importances.list.sort(functions.sortImportance)}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Issue Types'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter['Issue Types'] ? [] : this.props.sort.filter['Issue Types']}
            options={!this.props.issuetypes || !this.props.issuetypes.list ? [] : this.props.issuetypes.list.sort()}
            value={null}
            helperText={null}
          />
          {/*<SingleSelect
            id={null}
            label='Analysis Range'
            short
            suffix=' Days'
            handleChange={(value) => {
              let obj = { ...this.props.sort.filter };
              let name = 'AnalysisRange';
              obj[name] = value;
              this.changeAnalysisRange(obj);
              //this.props.updateFilter(obj);
              //this.setState({ changes: true });
              //this.multiSelectOnClose();
            }}
            options={['30', '90', '180', '365']}
            selectedOption={'90'}
            //selectedOption={'2 - Subsystem Shutdown or Sustained KPI Loss'}
            helperText={null}
          />*/}
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Grid container>
                <Grid item>
                  <CustomSelect
                    id={null}
                    label='Device Types'
                    disabled={this.state.loading}
                    onCloseFunction={this.multiSelectOnClose}
                    onChangeFunction={this.multiSelectOnChange}
                    //type='unit'
                    values={!this.props.sort.filter['Device Types'] ? [] : this.props.sort.filter['Device Types']}
                    options={!this.props.devicetypes || !this.props.devicetypes.list ? [] : this.props.devicetypes.list.sort()}
                    value={null}
                    helperText={null}
                  />
                </Grid>
                <Grid item>
                  <Tooltip arrow placement='top' title='View Concern Scores exclusive to devices selected from the "Device Types" dropdown' enterDelay={300}>
                    <ListItem style={{ padding: '20px 8px 0px 0px' }}>
                      <Checkbox
                        className={classes.checkbox}
                        color='secondary'
                        name={'deviceSpecific'}
                        onClick={(e) => {
                          //console.log(e.target.checked);
                          //console.log('t');
                          this.setDeviceSpecific(e);
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        style={{ marginRight: 8 }}
                      />
                      <Typography variant='subtitle2' style={{ marginTop: 2 }}>
                        Show <span style={{ fontWeight: 'bold' }}>Only</span> Selected Device Types
                      </Typography>
                    </ListItem>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button className={`${classes.buttonFilterRight} ${classes.printHide}`} variant='outlined' onClick={this.resetFilters} style={{ float: 'right', marginTop: 14 }}>
                <RestartAlt className={classes.buttonIcon}></RestartAlt>Clear All Filters
              </Button>
              {/*<Button
                className={`${classes.buttonFilterRight} ${classes.printHide}`}
                variant='outlined'
                component={Link}
                to={'/support/Prioritize'}
                style={{ float: 'right', marginLeft: 0, marginTop: 14 }}
                onClick={() => this.props.setActiveNav(7)}
              >
                <Directions className={classes.buttonIcon}></Directions>View Roadmaps
                      </Button>*/}
            </Grid>
          </Grid>
          {/*<DatePicker
            label='Start Date'
            disabled={this.state.loading}
            date={`${new Date().getFullYear() - 2}-01-01T12:00:00`}
            dateFunction={(startDate) => {
              this.updateDateRange(startDate, 'start');
            }}
          ></DatePicker>
          <DatePicker
            label='End Date'
            disabled={this.state.loading}
            dateFunction={(endDate) => {
              this.updateDateRange(endDate, 'end');
            }}
          ></DatePicker>*/}
        </form>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Legend type='Concern'></Legend>
          </Grid>
          <Grid item>{this.renderTotals()}</Grid>
        </Grid>
        <Divider className={classes.divider} />
        {this.props.loopCount === 0 || !!this.state.loading ? null : (
          <Grid container>
            <Grid item>
              <TablePagination
                rowsPerPageOptions={[24, 48, 96]}
                component='div'
                count={this.props.loopCount}
                rowsPerPage={this.props.table.pageSize}
                page={this.props.table.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                labelRowsPerPage='Loops per Page'
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                onClick={(e) => e.preventDefault()}
                className={classes.paginationFix}
                showFirstButton
                showLastButton
              />
            </Grid>
          </Grid>
        )}
        {this.renderLoading()}
        {this.props.loopCount === 0 && !this.state.loading ? (
          !!this.props.auth.require_filter && Object.keys(this.props.sort.filter).length <= 3 ? (
            <Typography variant='h6'>Please select a Site, Location, Application, or Importance.</Typography>
          ) : (
            <Typography variant='h6'>No items match the criteria you have selected.</Typography>
          )
        ) : (
          Object.keys(this.props.loops).map((key) => {
            //TODO FIX THE PAGE AND OFFSET VARIABLES TO BE THE SAME
            //TODO CORRECT EVENTS AND OTHER FIXED DATA
            if (
              this.props.table.page * this.props.table.pageSize < this.props.loops[key].rowId &&
              this.props.loops[key].rowId <= this.props.table.page * this.props.table.pageSize + this.props.table.pageSize
            ) {
              return <PrioritizeTile hasLink={!printStyles} hasBorder key={key} loop={this.props.loops[key]} />;
            } else {
              return null;
            }
          })
        )}
        {this.props.loopCount === 0 || !!this.state.loading ? null : (
          <Grid container>
            <Grid item>
              <TablePagination
                rowsPerPageOptions={[24, 48, 96]}
                component='div'
                count={this.props.loopCount}
                rowsPerPage={this.props.table.pageSize}
                page={this.props.table.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                labelRowsPerPage='Loops per Page'
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                onClick={(e) => e.preventDefault()}
                className={classes.paginationFix}
                showFirstButton
                showLastButton
              />
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    applications: state.applications,
    importances: state.importances,
    issuetypes: state.issuetypes,
    locations: state.locations,
    loops: state.loops.loops,
    loopCount: state.loops.count,
    devicetypes: state.devicetypes,
    sites: state.sites,
    sort: state.sort,
    table: state.table,
    currentModule: state.currentModule,
    activeNav: state.nav.activeNav,
    format: state.format.format,
    datasets: state.datasets.datasets,
  };
};

export default connect(mapStateToProps, {
  fetchLoops,
  fetchLoopsSummary,
  fetchSites,
  fetchDeviceTypes,
  fetchApplications,
  fetchLocations,
  fetchImportances,
  fetchEvents,
  fetchIssueTypes,
  fetchDatasets,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  updateSort,
  updateFilter,
  deleteLoops,
  deleteDevices,
  setModule,
  prioritizeSetActiveLoop,
  clearFilter,
  updatePreset,
  setActiveNav,
  updateDateRange,
})(withStyles(styles)(Prioritize));
