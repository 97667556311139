import React from 'react';
import PrioritizeTabs from '../menus/PrioritizeTabs';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import functions from '../../functions/functions';
import { fetchLoop, fetchLoopDevices, fetchLoopEvents, fetchLoopShutdowns, prioritizeSetActiveLoop, prioritizeSetActiveTab, setModule, updateAnalysisRange } from '../../actions/index';
import WorkbenchWidgets from '../widgetGroups/prioritize/WorkbenchWidgets';
import IssuesWidgets from '../widgetGroups/prioritize/IssuesWidgets';
import ActionWidgets from '../widgetGroups/prioritize/ActionWidgets';
import { styles } from '../styles';

class PrioritizeDetails extends React.Component {
  /*componentDidMount() {
    if (this.props.currentModule !== 'Prioritize') {
      this.props.setModule('Prioritize');
    }
  }*/
  constructor(props) {
    super(props);
    this.state = {
      currentAnalysisRange: null,
    };
    /*this.props.prioritizeSetActiveTab(this.props.match.params.tab);
    const urlParams = new URLSearchParams(window.location.search);

    const analysisRange = urlParams.get('analysisrange');
    this.props.updateAnalysisRange(analysisRange);
    const params = {
      analysisRange: analysisRange,
    };
    this.props.prioritizeSetActiveLoop(this.props.match.params.id);
    this.props.fetchLoop(this.props.match.params.id, params);
    this.props.fetchLoopDevices(this.props.match.params.id, params);

    this.props.fetchLoopEvents(this.props.match.params.id); //TODO FIX
    this.props.fetchLoopShutdowns(this.props.match.params.id); //TODO FIX*/
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    const analysisRange = urlParams.get('analysisrange');
    this.props.updateAnalysisRange(analysisRange);
    const params = {
      analysisRange: analysisRange,
    };
    this.setState({ currentAnalysisRange: analysisRange });
    if (this.props.match.params.tab !== this.props.activeTab) {
      this.props.prioritizeSetActiveTab(this.props.match.params.tab);
    }
    if (this.props.match.params.id !== this.props.activeLoop) {
      this.props.prioritizeSetActiveLoop(this.props.match.params.id);
      this.props.fetchLoop(this.props.match.params.id, params, this.props.dataset);
      this.props.fetchLoopDevices(this.props.match.params.id, params, this.props.dataset);

      this.props.fetchLoopEvents(this.props.match.params.id, params); //TODO FIX
      this.props.fetchLoopShutdowns(this.props.match.params.id, this.props.dataset); //TODO FIX
    }

    //this.props.prioritizeSetActiveLoop(this.props.match.params.id);
    //this.props.prioritizeSetActiveTab(this.props.match.params.tab);
    if (this.props.currentModule !== 'Prioritize') {
      this.props.setModule('Prioritize');
    }
  }
  componentDidUpdate() {
    if (this.props.match.params.tab !== this.props.activeTab) {
      this.props.prioritizeSetActiveTab(this.props.match.params.tab);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const analysisRange = urlParams.get('analysisrange');
    const params = {
      analysisRange: analysisRange,
    };
    if (this.props.match.params.id !== this.props.activeLoop) {
      this.props.prioritizeSetActiveLoop(this.props.match.params.id);
      this.props.fetchLoop(this.props.match.params.id, params, this.props.dataset);
      this.props.fetchLoopDevices(this.props.match.params.id, params, this.props.dataset);

      this.props.fetchLoopEvents(this.props.match.params.id, params, this.props.dataset); //TODO FIX
      this.props.fetchLoopShutdowns(this.props.match.params.id, this.props.dataset); //TODO FIX
    }
    if (this.state.currentAnalysisRange !== this.props.analysisRange) {
      this.setState({ currentAnalysisRange: this.props.analysisRange });
      const params = {
        analysisRange: this.props.analysisRange,
      };
      this.props.fetchLoop(this.props.match.params.id, params, this.props.dataset);
      this.props.fetchLoopDevices(this.props.match.params.id, params, this.props.dataset);
      this.props.fetchLoopShutdowns(this.props.match.params.id, params, this.props.dataset);
      this.props.fetchLoopEvents(this.props.match.params.id, params, this.props.dataset);
    }
  }
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        {this.props.format ? null : <PrioritizeTabs />}
        <div className={printStyles ? null : classes.details}>
          {this.props.activeTab === 'Workbench' && <WorkbenchWidgets />}
          {this.props.activeTab === 'Issues' && <IssuesWidgets />}
          {this.props.activeTab === 'Actions' && <ActionWidgets />}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.prioritize.activeTab,
    activeLoop: state.prioritize.activeLoop,
    currentModule: state.currentModule.currentModule,
    analysisRange: state.sort.filter.AnalysisRange,
    dataset: state.sort.filter.Dataset,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {
  fetchLoop,
  fetchLoopDevices,
  fetchLoopEvents,
  fetchLoopShutdowns,
  prioritizeSetActiveLoop,
  prioritizeSetActiveTab,
  setModule,
  updateAnalysisRange,
})(withStyles(styles, { withTheme: true })(PrioritizeDetails));
