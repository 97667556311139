import React from 'react';
import { Search, Update, Settings, Merge, AssignmentTurnedIn, AddBox, Edit, Info, Folder, AddLocation, DateRange, OfflineBolt, SquareFoot, Image } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';

class DiagnosticIcon extends React.Component {
  renderIcon() {
    switch (this.props.icon) {
      case 'Investigate':
        return <Search fontSize={this.props.size}></Search>;
      case 'Update':
        return <Update fontSize={this.props.size}></Update>;
      case 'Settings':
        return <Settings fontSize={this.props.size}></Settings>;
      case 'Merge':
        return <Merge fontSize={this.props.size}></Merge>;
      case 'Acknowledge':
        return <AssignmentTurnedIn fontSize={this.props.size}></AssignmentTurnedIn>;
      case 'Add':
        return <AddBox fontSize={this.props.size}></AddBox>;
      case 'Edit':
        return <Edit fontSize={this.props.size}></Edit>;
      case 'Folder':
        return <Folder fontSize={this.props.size}></Folder>;
      case 'DateRange':
        return <DateRange fontSize={this.props.size}></DateRange>;
      case 'AddLocation':
        return <AddLocation fontSize={this.props.size}></AddLocation>;
      case 'OfflineBolt':
        return <OfflineBolt fontSize={this.props.size}></OfflineBolt>;
      case 'SquareFoot':
        return <SquareFoot fontSize={this.props.size}></SquareFoot>;
      case 'Image':
        return <Image fontSize={this.props.size}></Image>;
      default:
        return <Info fontSize={this.props.size}></Info>;
    }
  }
  render() {
    const { classes } = this.props;
    if (this.renderIcon()) {
      return <div className={this.props.size === 'small' ? classes.diagnosticIconSmall : classes.diagnosticIcon}> {this.renderIcon()}</div>;
    } else {
      return null;
    }
  }
}
export default withStyles(styles, { withTheme: true })(DiagnosticIcon);
