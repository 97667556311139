//import _ from 'lodash';
import { CLEAR_CHART_SELECTIONS, ADD_CHART_SELECTIONS, REPLACE_CHART_SELECTIONS, UPDATE_CHART_SELECTION, REMOVE_CHART_SELECTIONS } from /*,
  CREATE_LOCATION,
  DELETE_LOCATION,
  EDIT_LOCATION*/ '../actions/types';
//! FIX BAD SELECTION DESIGN
const chartSelectionsReducer = (state = { selections: [] }, action) => {
  switch (action.type) {
    case CLEAR_CHART_SELECTIONS: {
      return {
        ...state,
        selections: [],
      };
    }
    case ADD_CHART_SELECTIONS: {
      let selections = Object.assign([], state.selections);
      //console.log(action.payload);
      //console.log(selections);
      //for (let i = 0; i < action.payload.length; i++) {
      //  selections.push(action.payload[i]);
      //}
      selections.push(action.payload);
      return { ...state, selections };
    }
    case REMOVE_CHART_SELECTIONS: {
      let selections = Object.assign([], state.selections);
      //console.log(action.payload);
      //console.log(selections);
      if (Array.isArray(action.payload)) {
        for (let i = 0; i < action.payload.length; i++) {
          //  selections.push(action.payload[i]);
          selections.splice(action.payload[i], 1);
        }
      } else {
        selections.splice(action.payload, 1);
      }

      return { ...state, selections };
    }
    case REPLACE_CHART_SELECTIONS: {
      let selections = [];
      //console.log(action.payload);
      //console.log(selections);
      //for (let i = 0; i < action.payload.length; i++) {
      //  selections.push(action.payload[i]);
      //}
      selections.push(action.payload);
      return { ...state, selections };
    }
    case UPDATE_CHART_SELECTION: {
      let selections = Object.assign([], state.selections);
      if (typeof action.payload.index === 'undefined' || typeof action.payload.index2 === 'undefined' || !action.payload.utcDate) {
        console.log(action.payload);
        throw new Error('Incomplete Submission.');
      }
      selections[action.payload.index][action.payload.index2] = action.payload.utcDate;

      return { ...state, selections };
    }
    default:
      return state;
  }
};

export default chartSelectionsReducer;
