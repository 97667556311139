import React from 'react';
import { CircularProgress, Grid, TextField, Paper, Typography, Autocomplete } from '@mui/material';
import ValveChartWidget from '../../../widgets/ValveChartWidget';
import OptimizeDiagnosticsWidget from '../../../widgets/OptimizeDiagnosticsWidget';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { connect } from 'react-redux';
import {
  fetchDevicesSummaryVerbose,
  setDevicesSummaryStatus,
  setComparatorReport,
  fetchDevices,
  fetchDeviceSpecs,
  fetchDiagnostics,
  setTestStatus,
  deleteDevices,
  deleteLoops,
} from '../../../../actions/index';
import WidgetTitle from '../../../headers/WidgetTitle';
import ChecklistTable from '../../../charts/ChecklistTable';
import ChecklistIndicator from '../../../filters/ChecklistIndicator';
import InfoPopover from '../../../popup/InfoPopover';
import diagnosticsArray from '../../../../arrays/optimize/diagnostics';
//import { Typography } from '@mui/material';
import matchFilter from '../../../filters/functions/searchFilter';
import functions from '../../../../functions/functions';
require('string_score');

class ComparatorReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchSelect = this.onSearchSelect.bind(this);
  }
  componentDidMount() {
    this.props.deleteDevices();
    this.props.deleteLoops();
    this.props.setComparatorReport([]);
    //console.log(this.props.currentModule);
    //console.log(this.props.devices.summary);
    if (this.props.currentModule === 'Optimize' || this.props.reportModule === 'Optimize' || this.props.currentModule === null) {
      this.props.setDevicesSummaryStatus(true);
      this.props.fetchDevicesSummaryVerbose('optimize');
    }
  }
  isUndefined(value) {
    return typeof value === 'undefined';
  }
  onSearchChange = (e, value) => {
    this.setState({ value: value });
  };
  onSearchSelect = (e, values) => {
    if (values !== null) {
      this.props.setComparatorReport(values);
    }
    for (let i = 0; i < values.length /*1*/; i++) {
      const deviceId = values[i].deviceId;
      if (
        this.isUndefined(this.props.devices[deviceId]) ||
        this.isUndefined(this.props.devices[deviceId].resolutionApp) ||
        this.isUndefined(this.props.devices[deviceId].sensitivityApp) ||
        this.isUndefined(this.props.devices[deviceId].strokeApp) ||
        this.isUndefined(this.props.devices[deviceId].datetimeHistory)
        //||  // TODO FIX SPECBOOL CHECK
        //!this.props.devices[deviceId].specBool
      ) {
        this.props.fetchDevices(deviceId).then(this.getDeviceTest(deviceId));

        //this.props.fetchDeviceSpecs(deviceId);
      }
    }
  };
  debounce(func, delay) {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  }
  //TODO URGENT FIX ABRITRARY LOAD
  getDeviceTest(deviceId) {
    let device = this.props.devices[deviceId];
    let testId = device.activeProfileId;
    if (!testId) {
      //console.log('t');
      setTimeout(() => {
        //console.log('t');
        this.getDeviceTest(deviceId);
      }, 1000);
    } else {
      if (
        (!this.props.tests_status ||
          !this.props.tests_status['diagnostics'] ||
          !(this.props.tests_status['diagnostics'][testId] === 'loading' || this.props.tests_status['diagnostics'][testId] === 'loaded')) &&
        !!testId
      ) {
        this.props.fetchDiagnostics(testId);
        this.props.setTestStatus('diagnostics', testId, 'loading');
      }
    }
  }

  //TODO IMPROVE FUZZY SEARCH https://github.com/kentcdodds/match-sorter
  matchFilter(options) {
    let values = this.state.value.split(' ');
    // Remove "" values from array as result of hitting space for a new search term
    const index = values.indexOf('');
    if (index > -1) {
      values.splice(index, 1);
    }
    // search threshold
    const threshold = 0.2; //Math.min(0.1 + 0.1 * this.state.value.length, 0.5);
    let finalOptions = [];
    //search all key->value pairs in the option for value matches based on each search term, if higher than the threshold, pass it.
    for (let a = 0; a < options.length; a++) {
      let option = options[a];
      let keys = Object.keys(option);
      let pass = 0;
      for (let i = 0; i < keys.length; i++) {
        for (let j = 0; j < values.length; j++) {
          if (
            //toString in case the key is a number
            option[keys[i]].toString().score(values[j]) > threshold ||
            values[j] === ''
          ) {
            pass++;
          }
        }
      }
      // if the number of passed key/value pairs is >= number of search terms, return
      if (pass >= values.length) {
        finalOptions.push(option);
      }
    }
    return finalOptions;
  }

  getOptions = () => {
    let options = [];
    // console.log(this.props.devices);
    const deviceKeys = Object.keys(this.props.devices);
    options = deviceKeys.map((key) => {
      const device = this.props.devices[key];
      //console.log(device);
      //console.log(device.datetimeHistory);
      return {
        serial: device.serial,
        name: device.name,
        device: device.site,
        application: device.application,
        deviceId: device.deviceId,
      };
    });

    return options;
  };

  renderDiagnosticsArray = () => {
    let deviceArray = [];
    for (let i = 0; i < this.props.reportDevices.length; i++) {
      const deviceId = this.props.reportDevices[i].deviceId;
      if (!!this.props.devices[deviceId] && !!this.props.devices[deviceId].activeProfileId) {
        deviceArray.push(this.props.devices[deviceId]);
      } else {
        deviceArray.push({ deviceId: -1, activeProfileId: -1 });
      }
    }

    return (
      <div style={{ marginTop: 10 }}>
        <OptimizeDiagnosticsWidget key={`diagnosticsComparator`} device={deviceArray} />
      </div>
    );
  };
  renderCheckBox = () => {
    return <ChecklistIndicator color={null}></ChecklistIndicator>;
  };
  renderPopover = () => {
    return <InfoPopover resize /*content={diagnosticsDetails(check.check)}*/ />;
  };

  getTableData = () => {
    let data = [
      /* {
        id: 1,
        diagnostic: 'Valve Travel and Calib.',
        'AO-555': 'test',
      },*/
    ];
    let header = [
      {
        id: 'diagnostic',
        numeric: false,
        label: 'Diagnostic',
      },
    ];

    for (let i = 0; i < this.props.reportDevices.length; i++) {
      header.push({
        id: this.props.reportDevices[i].name,
        numeric: false,
        label: this.props.reportDevices[i].name,
      });
    }
    let rowId = 1;
    diagnosticsArray.map((section) => {
      let sectionRowObject = { id: `row_${rowId}`, diagnostic: section.title };
      rowId++;
      data.push(sectionRowObject);
      section.checks.map((check) => {
        //console.log(rowId);
        let detailRowObject = {
          id: `row_${rowId}`,
          diagnostic: check.subtitle,
        };
        rowId++;
        for (let i = 0; i < this.props.reportDevices.length; i++) {
          let device = this.props.devices[this.props.reportDevices[i].deviceId];
          //console.log(device);

          if (!!device) {
            let testId = device.activeProfileId;
            //console.log(testId);
            //console.log(this.props.tests);
            //console.log(this.props.tests['diagnostics'][testId]);
            //console.log('checkVal');
            //console.log(testId);
            //console.log(' ');
            //console.log(this.props.tests);
            if (!!testId && !!this.props.tests && !!this.props.tests['diagnostics'] && !!this.props.tests['diagnostics'][testId]) {
              //console.log('checkVal');
              //console.log(this.props.tests['diagnostics'][testId]);
              //console.log(this.props.tests['diagnostics'][testId][check.check]);
              //console.log(check);
              const checkNum = this.props.tests['diagnostics'][testId][check.check];
              detailRowObject['check'] = check.check;
              detailRowObject[device.name] = (
                <ChecklistIndicator
                  //key={`checklistIndicator${
                  //  !test ? 'unknown' + Math.random() : test.testId
                  //}`}
                  color={functions.numberToColor(checkNum)}
                  tooltip={functions.numberToOptimizeTooltip(checkNum)}
                ></ChecklistIndicator>
              );
            }
          }

          //console.log(this.props.devices)
        }
        data.push(detailRowObject);
        //TODO FIX RETURN PARADIGM
        return true;
      });
      //TODO FIX RETURN PARADIGM
      return true;
    });
    //console.log(data);
    return { header, data };
  };
  //TODO Joined loading

  renderPerformanceComparison = () => {
    const { classes } = this.props;

    if (this.props.reportDevices.length > 0) {
      let { header, data } = this.getTableData();
      let loading = false;
      let count = 0;

      for (let i = 0; i < this.props.reportDevices.length; i++) {
        const devices = this.props.devices;
        const deviceId = this.props.reportDevices[i].deviceId;
        const device = this.props.devices[deviceId];
        if (device.valveIDHistory.length === 0) {
          count++;
        } else {
          if (!!this.props.tests.diagnostics)
            if (!!this.props.tests.diagnostics && !!devices[deviceId] && (!!this.props.tests.diagnostics[devices[deviceId].activeProfileId] || !devices[deviceId].activeProfileId)) count++;
        }
      }
      loading = count !== this.props.reportDevices.length;
      //console.log(this.props.reportDevices);
      if (loading) {
        //  console.log('t');
        return (
          <Typography variant='subtitle2'>
            <CircularProgress style={{ marginTop: '20px' }} className={classes.loadIcon} size={15} color='secondary' />
            Loading...
          </Typography>
        );
      }
      const printStyles = functions.setPrintStyles(this.props.activeTab);
      return (
        <React.Fragment>
          {this.props.reportDevices.map((device) => {
            const currentId = device.deviceId;
            const deviceFull = this.props.devices[currentId];
            if (deviceFull.valveIDHistory.length === 0) {
              return (
                <Typography style={{ marginTop: 10 }} key={`no_data_${device.name}`}>
                  No Data Available for {device.name}.
                </Typography>
              );
            }
            return (
              <React.Fragment key={`reactFragment${currentId}`}>
                <div className={classes.printWidgetSpacer}></div>
                <WidgetTitle key={`widgetTitle${currentId}`} title={device.name}></WidgetTitle>
                <Grid container key={`gridComparator${currentId}`}>
                  <ValveChartWidget
                    key={`Resolution${currentId}`}
                    initialWidth={4}
                    type='valve'
                    valveColumns={1}
                    progress={1}
                    testId={!this.props.devices[currentId].activeResolutionId ? null : this.props.devices[currentId].activeResolutionId}
                    deviceId={currentId}
                    attribute={'Resolution'}
                    comparator
                  />
                  <ValveChartWidget
                    key={`Sensitivity${currentId}`}
                    initialWidth={4}
                    type='valve'
                    valveColumns={1}
                    progress={1}
                    testId={!this.props.devices[currentId].activeSensitivityId ? null : this.props.devices[currentId].activeSensitivityId}
                    deviceId={currentId}
                    attribute={'Sensitivity'}
                    comparator
                  />
                  <ValveChartWidget
                    key={`Stroke${currentId}`}
                    initialWidth={4}
                    type='valve'
                    valveColumns={1}
                    progress={1}
                    testId={!this.props.devices[currentId].activeStrokeId ? null : this.props.devices[currentId].activeStrokeId}
                    deviceId={currentId}
                    attribute={'Stroke'}
                    comparator
                  />
                </Grid>
              </React.Fragment>
            );
          })}
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Paper elevation={3} className={`${printStyles ? classes.printPaper : classes.paper}`}>
              <ChecklistTable data={data} header={header} rows={99}></ChecklistTable>
            </Paper>
          </Grid>
          {/*this.renderDiagnosticsArray()*/}
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  render() {
    //console.log('render');
    //const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    //const checkedIcon = <CheckBoxIcon fontSize='small' />;
    const options = this.getOptions();
    const { classes } = this.props;
    return (
      <Grid>
        <div>
          <Autocomplete
            multiple
            limitTags={6}
            id='checkboxes-tags-demo'
            options={options}
            disableCloseOnSelect
            //getOptionLabel={(option) => option.title}
            //getOptionSelected=
            isOptionEqualToValue={(option, value) => {
              //TODO MAKE SURE THIS MATCHING OF OPTIONS IS UNIQUE
              return option.name === value.name && option.deviceId === value.deviceId;
            }}
            getOptionLabel={(option) => option.name.toString()}
            filterOptions={(options) => {
              const limit = 200;
              return matchFilter(options, this.state.value, null, limit);
            }}
            /*filterOptions={(options) => {
              return this.matchFilter(options);
            }}*/
            renderOption={(props, option, { selected }) => (
              <span style={{ fontSize: 14 }} {...props} className={classes.autoCompleteListItem} key={`${option.deviceId}_${option.name}`}>
                {/*<Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8, padding: 0 }}
                  checked={selected}
                />*/}
                {option.name ? option.name : null}
                <br></br>
                {option.site ? <span style={{ marginRight: 10 }}>Site: {option.site}</span> : null}
                {option.application ? <span style={{ marginRight: 10 }}>Application: {option.application}</span> : null}
                {option.serial ? <span>Serial: {option.serial}</span> : null}
              </span>
            )}
            onInputChange={this.onSearchChange}
            onChange={this.debounce(this.onSearchSelect, 1500)}
            style={{ maxWidth: 500 }}
            renderInput={(params) => (
              <div>
                <TextField
                  {...params}
                  placeholder='Select Devices'
                  variant='outlined'
                  inputProps={{
                    classes: {},
                    ...params.inputProps,
                  }}
                />
              </div>
            )}
          />
        </div>
        {this.props.reportDevices.length === 0 ? <Typography style={{ marginTop: 8 }}>Please Select a Number of Devices</Typography> : <div>{this.renderPerformanceComparison()}</div>}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    devices: state.devices.devices,
    reportDevices: state.reports.report_data.comparator,
    currentModule: state.currentModule.currentModule,
    optimize: state.optimize,
    reportModule: state.reports.activeTab,
    tests: state.tests.tests,
    tests_status: state.tests.status,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {
  fetchDevicesSummaryVerbose,
  setDevicesSummaryStatus,
  setComparatorReport,
  fetchDevices,
  fetchDeviceSpecs,
  fetchDiagnostics,
  setTestStatus,
  deleteDevices,
  deleteLoops,
})(withStyles(styles)(ComparatorReport));
