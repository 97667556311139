import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { connect } from 'react-redux';
import { Assessment, CloudUpload, LiveHelp, Settings } from '@mui/icons-material';
import { Divider, List, ListItem, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { setActiveNav, clearState } from '../../actions';
import { withRouter } from 'react-router-dom';

class DrawerList extends React.Component {
  componentDidMount() {
    const path = this.props.location.pathname.slice(1);
    //Persistent selected menu item on refresh
    if (path.indexOf('prioritize') !== -1) {
      this.props.setActiveNav(1);
    } else if (path.indexOf('optimize') !== -1) {
      this.props.setActiveNav(2);
    } else if (path.indexOf('reliability') !== -1) {
      this.props.setActiveNav(3);
    } else if (path.indexOf('IBA') !== -1) {
      this.props.setActiveNav(4);
    } else if (path.indexOf('fsr') !== -1) {
      this.props.setActiveNav(5);
    } else if (path.indexOf('reports') !== -1) {
      this.props.setActiveNav(6);
    } else if (path.indexOf('configure') !== -1) {
      this.props.setActiveNav(7);
    } else if (path.indexOf('upload') !== -1) {
      this.props.setActiveNav(8);
    } else if (path.indexOf('support') !== -1) {
      this.props.setActiveNav(9);
    } else {
      this.props.setActiveNav(null);
    }
  }
  getAvailableModules(module) {
    //TODO Make more flexible
    if (module !== 'Optimize' && module !== 'Prioritize' && module !== 'FSR' && module !== 'IBA' && module !== 'Reliability') {
      if (!!this.props.auth.optimize && this.props.auth.optimize === 1) {
        return 'Optimize';
      } else if (!!this.props.auth.prioritize && this.props.auth.prioritize === 1) {
        return 'Prioritize';
      } else if (!!this.props.auth.reliability && this.props.auth.reliability === 1) {
        return 'Reliability';
      } else if (!!this.props.auth.fsr && this.props.auth.fsr === 1) {
        return 'FSR';
      } else if (!!this.props.auth.iba && this.props.auth.iba === 1) {
        return 'IBA';
      } /* else if (!!this.props.auth && !!this.props.auth.isSignedIn) {
      }*/ else if (!!this.props.auth && !!this.props.auth.isSignedIn) {
        console.log('Error: No Valid Module Auth Found');
        return null;
      }
    } else {
      return module;
    }
  }
  render() {
    const { classes } = this.props;
    //const activeModule = !this.props.activeModule;
    const activeModule = this.getAvailableModules(this.props.activeModule);
    return (
      <React.Fragment>
        <Divider />
        <List>
          {!!this.props.auth && this.props.auth.prioritize === 1 ? (
            <ListItem
              button
              key='Prioritize'
              component={Link}
              to={'/prioritize'}
              onClick={() => {
                if (this.props.location.pathname.slice(1) !== 'prioritize') {
                  this.props.setActiveNav(1);
                  this.props.clearState();
                }
              }}
              selected={this.props.activeNav === 1}
              className={classes.menuItem}
            >
              <ListItemIcon>
                <SvgIcon>
                  {this.props.activeNav === 1 ? (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <path
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                        strokeWidth='3.499965'
                        strokeMiterlimit='10'
                        d='M2.585 17.983L18 2.568l15.415 15.415L18 33.397z'
                      />
                      <path fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'} d='M9.427 18.06l8.556-8.556 8.556 8.556-8.556 8.556z' />
                    </svg>
                  ) : (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <path
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                        strokeWidth='3.499965'
                        strokeMiterlimit='10'
                        d='M2.585 17.983L18 2.568l15.415 15.415L18 33.397z'
                      />
                      <path fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'} d='M9.427 18.06l8.556-8.556 8.556 8.556-8.556 8.556z' />
                    </svg>
                  )}
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary='Prioritize'></ListItemText>
            </ListItem>
          ) : null}
          {!!this.props.auth && this.props.auth.optimize === 1 ? (
            <ListItem
              button
              key='Optimize'
              component={Link}
              to={'/optimize'}
              onClick={() => {
                if (this.props.location.pathname.slice(1) !== 'optimize') {
                  this.props.setActiveNav(2);
                  this.props.clearState();
                }
              }}
              selected={this.props.activeNav === 2}
              className={classes.menuItem}
            >
              <ListItemIcon>
                <SvgIcon className={classes.menuIcon}>
                  {this.props.activeNav === 2 ? (
                    <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' x='0' y='0' version='1.1' viewBox='0 0 36 36'>
                      <defs />
                      <path fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'} id='Rectangle_2364' d='M9.7 15.5h5.6v5.6H9.7z' transform='rotate(-45.001 12.54 18.302)' />
                      <path fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'} id='Rectangle_2365' d='M15.3 9.9h5.6v5.6h-5.6z' transform='rotate(-45.001 18.063 12.68)' />
                      <path fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'} id='Rectangle_2363' d='M20.8 15.5h5.6v5.6h-5.6z' transform='rotate(-45.001 23.606 18.286)' />
                      <path
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                        strokeMiterlimit='10'
                        strokeWidth='3.499965'
                        d='M2.563 18.108L17.978 2.694l15.415 15.414-15.415 15.415z'
                      />
                    </svg>
                  ) : (
                    <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' x='0' y='0' version='1.1' viewBox='0 0 36 36'>
                      <defs />
                      <path fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'} id='Rectangle_2364' d='M9.7 15.5h5.6v5.6H9.7z' transform='rotate(-45.001 12.54 18.302)' />
                      <path fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'} id='Rectangle_2365' d='M15.3 9.9h5.6v5.6h-5.6z' transform='rotate(-45.001 18.063 12.68)' />
                      <path fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'} id='Rectangle_2363' d='M20.8 15.5h5.6v5.6h-5.6z' transform='rotate(-45.001 23.606 18.286)' />
                      <path
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                        strokeMiterlimit='10'
                        strokeWidth='3.499965'
                        d='M2.563 18.108L17.978 2.694l15.415 15.414-15.415 15.415z'
                      />
                    </svg>
                  )}
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary='Optimize'></ListItemText>
            </ListItem>
          ) : null}
          {!!this.props.auth && this.props.auth.reliability === 1 ? (
            <ListItem
              button
              key='Reliability'
              component={Link}
              to={'/reliability'}
              onClick={() => {
                if (this.props.location.pathname.slice(1) !== 'reliability') {
                  this.props.setActiveNav(3);
                  this.props.clearState();
                }
              }}
              selected={this.props.activeNav === 3}
              className={classes.menuItem}
            >
              <ListItemIcon>
                <SvgIcon>
                  {this.props.activeNav === 3 ? (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <rect
                        x='7.6'
                        y='7.7'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -7.7692 18.5346)'
                        //class='st0'
                        width='21.8'
                        height='21.8'
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                        strokeWidth='3.499965'
                        strokeMiterlimit='10'
                      />
                      <rect
                        x='9.9'
                        y='13.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.8059 16.0318)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                      />
                      <rect
                        x='15'
                        y='18.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -8.9073 21.1051)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                      />
                    </svg>
                  ) : (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <rect
                        x='7.6'
                        y='7.7'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -7.7692 18.5346)'
                        //class='st0'
                        width='21.8'
                        height='21.8'
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                        strokeWidth='3.499965'
                        strokeMiterlimit='10'
                      />
                      <rect
                        x='9.9'
                        y='13.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.8059 16.0318)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                      />
                      <rect
                        x='15'
                        y='18.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -8.9073 21.1051)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                      />
                    </svg>
                  )}
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary='Reliability'></ListItemText>
            </ListItem>
          ) : null}
          {!!this.props.auth && this.props.auth.iba === 1 ? (
            <ListItem
              button
              key='IBA'
              component={Link}
              to={'/iba'}
              onClick={() => {
                if (this.props.location.pathname.slice(1) !== 'iba' || this.props.location.pathname.slice(1) !== 'IBA') {
                  this.props.setActiveNav(4);
                  this.props.clearState();
                }
              }}
              selected={this.props.activeNav === 4}
              className={classes.menuItem}
            >
              <ListItemIcon>
                <SvgIcon>
                  {this.props.activeNav === 4 ? (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <rect
                        x='7.6'
                        y='7.7'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -7.7692 18.5346)'
                        //class='st0'
                        width='21.8'
                        height='21.8'
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                        strokeWidth='3.499965'
                        strokeMiterlimit='10'
                      />
                      <rect x='14.7' y='12.8' width='13' height='4' transform='translate(16.7 -10.7) rotate(45)' fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'} />
                      <rect x='10.2' y='14.4' width='7.7' height='7.7' transform='translate(17 -4.6) rotate(45)' fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'} />
                      <rect x='17.5' y='19.5' width='3.6' height='7.7' transform='translate(22.2 -6.8) rotate(45)' fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'} />
                    </svg>
                  ) : (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <rect
                        x='7.6'
                        y='7.7'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -7.7692 18.5346)'
                        //class='st0'
                        width='21.8'
                        height='21.8'
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                        strokeWidth='3.499965'
                        strokeMiterlimit='10'
                      />
                      <rect x='14.7' y='12.8' width='13' height='4' transform='translate(16.7 -10.7) rotate(45)' fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'} />
                      <rect x='10.2' y='14.4' width='7.7' height='7.7' transform='translate(17 -4.6) rotate(45)' fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'} />
                      <rect x='17.5' y='19.5' width='3.6' height='7.7' transform='translate(22.2 -6.8) rotate(45)' fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'} />
                    </svg>
                  )}
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary='IBA'></ListItemText>
            </ListItem>
          ) : null}
          {!!this.props.auth && this.props.auth.fsr === 1 ? (
            <ListItem
              button
              key='FSR'
              component={Link}
              to={'/fsr'}
              onClick={() => {
                if (this.props.location.pathname.slice(1) !== 'fsr') {
                  this.props.setActiveNav(5);
                  this.props.clearState();
                }
              }}
              selected={this.props.activeNav === 5}
              className={classes.menuItem}
            >
              <ListItemIcon>
                <SvgIcon>
                  {this.props.activeNav === 5 ? (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <rect
                        x='7.6'
                        y='7.7'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -7.7692 18.5346)'
                        //class='st0'
                        width='21.8'
                        height='21.8'
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                        strokeWidth='3.499965'
                        strokeMiterlimit='10'
                      />
                      <rect
                        x='9.9'
                        y='13.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.8059 16.0318)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                      />
                      <rect
                        x='15'
                        y='18.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -8.9073 21.1051)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                      />
                    </svg>
                  ) : (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <rect
                        x='7.6'
                        y='7.7'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -7.7692 18.5346)'
                        //class='st0'
                        width='21.8'
                        height='21.8'
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                        strokeWidth='3.499965'
                        strokeMiterlimit='10'
                      />
                      <rect
                        x='9.9'
                        y='13.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.8059 16.0318)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                      />
                      <rect
                        x='15'
                        y='18.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -8.9073 21.1051)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                      />
                    </svg>
                  )}
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary='FSR'></ListItemText>
            </ListItem>
          ) : null}
          {/*!!this.props.auth && this.props.auth.stabilize === 1 ? (
            <ListItem
              button
              key='Stabilize'
              component={Link}
              to={'/stabilize'}
              onClick={() => {
                if (this.props.location.pathname.slice(1) !== 'stabilize') {
                  this.props.setActiveNav(3);
                  this.props.clearState();
                }
              }}
              selected={this.props.activeNav === 3}
              className={classes.menuItem}
            >
              <ListItemIcon>
                <SvgIcon>
                  {this.props.activeNav === 3 ? (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <rect
                        x='7.6'
                        y='7.7'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -7.7692 18.5346)'
                        //class='st0'
                        width='21.8'
                        height='21.8'
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                        strokeWidth='3.499965'
                        strokeMiterlimit='10'
                      />
                      <rect
                        x='9.9'
                        y='13.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.8059 16.0318)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                      />
                      <rect
                        x='15'
                        y='18.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -8.9073 21.1051)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#519bd1' : '#1879C0'}
                      />
                    </svg>
                  ) : (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <rect
                        x='7.6'
                        y='7.7'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -7.7692 18.5346)'
                        //class='st0'
                        width='21.8'
                        height='21.8'
                        fill='none'
                        stroke={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                        strokeWidth='3.499965'
                        strokeMiterlimit='10'
                      />
                      <rect
                        x='9.9'
                        y='13.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.8059 16.0318)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                      />
                      <rect
                        x='15'
                        y='18.8'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -8.9073 21.1051)'
                        //class='st1'
                        width='12.1'
                        height='4.9'
                        fill={this.props.colorMode === 'dark' ? '#eee' : '#464749'}
                      />
                    </svg>
                  )}
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary='Stabilize'></ListItemText>
            </ListItem>
          ) : null*/}
          {!!this.props.auth && this.props.auth.view < 70 ? (
            <ListItem
              button
              key='Reports'
              component={Link}
              to={`/reports/${this.getAvailableModules(this.props.activeModule)}`}
              onClick={() => this.props.setActiveNav(6)}
              selected={this.props.activeNav === 6}
              className={classes.menuItem}
            >
              <ListItemIcon>
                <Assessment className={this.props.activeNav === 6 ? classes.iconActive : classes.iconGray} />
              </ListItemIcon>
              <ListItemText primary='Reports'></ListItemText>
            </ListItem>
          ) : null}
        </List>
        <Divider />
        <List>
          {!!this.props.auth && this.props.auth.view <= 40 ? (
            <ListItem
              button
              key='Configure'
              component={Link}
              to={`/configure/${activeModule}`}
              onClick={() => this.props.setActiveNav(7)}
              selected={this.props.activeNav === 7}
              className={classes.menuItem}
            >
              <ListItemIcon>
                <Settings className={this.props.activeNav === 7 ? classes.iconActive : classes.iconGray} />
              </ListItemIcon>
              <ListItemText primary='Configure'></ListItemText>
            </ListItem>
          ) : null}
          {!!this.props.auth && this.props.auth.view < 50 ? (
            <ListItem button key='Upload' component={Link} to={`/upload/${activeModule}`} onClick={() => this.props.setActiveNav(8)} selected={this.props.activeNav === 8} className={classes.menuItem}>
              <ListItemIcon>
                <CloudUpload className={this.props.activeNav === 8 ? classes.iconActive : classes.iconGray} />
              </ListItemIcon>
              <ListItemText primary='Upload'></ListItemText>
            </ListItem>
          ) : null}
        </List>
        <Divider />
        <List>
          {/*!!this.props.auth && this.props.auth.view < 10 ? (
            <ListItem
              button
              key='Roadmap'
              component={Link}
              to={`/roadmap/${activeModule}`}
              onClick={() => this.props.setActiveNav(6)}
              selected={this.props.activeNav === 6}
              className={classes.menuItem}
            >
              <ListItemIcon>
                <Directions className={this.props.activeNav === 6 ? classes.iconActive : classes.iconGray} />
              </ListItemIcon>
              <ListItemText primary='Roadmap'></ListItemText>
            </ListItem>
          ) : null*/}
          {!!this.props.auth && this.props.auth.view < 41 ? (
            <ListItem
              button
              key='Support'
              component={Link}
              to={`/support/${activeModule}/GettingStarted`}
              onClick={() => this.props.setActiveNav(9)}
              selected={this.props.activeNav === 9}
              className={classes.menuItem}
            >
              <ListItemIcon>
                <LiveHelp className={this.props.activeNav === 9 ? classes.iconActive : classes.iconGray} />
              </ListItemIcon>
              <ListItemText primary='Support'></ListItemText>
            </ListItem>
          ) : null}
        </List>
        <Divider />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeModule: state.currentModule.currentModule,
    activeNav: state.nav.activeNav,
    auth: state.auth,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, { setActiveNav, clearState })(withRouter(withStyles(styles, { withTheme: true })(DrawerList)));
