const diagnosticsGraphDetails = {
  Travel: {
    title: `Travel and Positioner Graph`,
    descriptions: {
      description1: `The travel graph tracks the position of the valve stem as the valve slowly ramps from 0 - 100% signal and back again. It is useful for diagnosing the proper setup and function of the calibration, tuning, and positioner. It is used secondarily for friction and seat information.`,
      section1Title: `What We’re Looking For:`,
      section1Subtitle: `Calibration and Tuning`,
      section1Indent1: `The valve strokes in the stated direction`,
      section1Indent2: `The valve travel spans the appropriate values`,
      section1Indent3: `The valve is likely to have the right fail actions`,
      section2Subtitle: `Positioner`,
      section2Indent1: `Valve travel is aligned with control signal commands`,
      section2Indent2: `Valve travel is linear and parallel`,
      section2Indent3: `The difference between travel in the increasing and decreasing directions is small`,
    },
    issues: {
      section1Title: `General Testing`,
      section1Subtitle1: `Air Action Incorrect`,
      section1Indent1: `Does the valve stroke in the stated direction (ex: Air to Open or Air to Close)?`,
      section1Subtitle2: `Fail Action Incorrect`,
      section1Indent2: `Does the valve’s fail action appear to match the stated specification (Fail Open / Fail Close)?`,
      section1Subtitle3: `Calibration`,
      section1Indent3: `Does the calibration appear accurate?`,
      section1Subtitle4: `Signal Span Incorrect`,
      section1Indent4: `Does the signal span match the stated span (ex: 4 - 12 mA)?`,
      section1Subtitle5: `Nominal Travel`,
      section1Indent5: `Does the travel length match stated nominal travel in the valve information (ex: 90 degrees)?`,
      section2Title: `Positioner Testing`,
      section2Subtitle1: `Positioner Cutoff`,
      section2Indent1: `Are the positioner cutoffs set close to the travel stop?`,
      section2Subtitle2: `Incorrect Flow Characteristic`,
      section2Indent2: `Is it possible that the valve does not have the correct flow characteristic?`,
      section2Subtitle3: `Poor Positioner Performance`,
      section2Indent3: `Is the difference between increasing and decreasing travel small?`,
      section3Title: `Valve Body and Packing`,
      section3Subtitle1: `Seat Exit`,
      section3Indent1: `Does the valve depart the seat after a significant delay?`,
      section3Subtitle2: `Seat Entry`,
      section3Indent2: `Does the ramp test stop at the same location it began? Is that value close to 0% Signal?`,
      section3Subtitle3: `Stair-Stepping`,
      section3Indent3: `Is there any evidence of degenerative wear or other significant issues?`,
    },
    investigate: {
      Title1: `Review the Checklist`,
      Rec1: `Review the checklist and see what is ‘Advisory’ (yellow) vs ‘Critical’ (red) vs ‘Other’ (gray, black, purple, etc.). If you are unfamiliar with a checklist item, review its helper and the recommendations within.`,
      Title2: `Review the Graphs`,
      Rec2: `Review the graphs with the checklist alerts in mind. Using the graphs, validate or eliminate potential root causes of alarms as you proceed. As needed, refer to the visual examples in the checklist helpers.`,
      Title3: `Re-Test Valve`,
      Rec3: `If you reach alarms that indicate you should re-test the valve before continuing further, you likely need to re-test before proceeding to alarms in other groupings.`,
      Title4: `Investigate Root Causes`,
      Rec4: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure performance is within application criteria. This will vary based on the needs of the application - flawless positioner performance is not necessary if the valve is only used for on/off.`,
      Title5: `Determine Adjustments`,
      Rec5: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure the valve stays reliable until the next time it could be repaired or replaced. This will also vary based on the needs of the application, but degenerative conditions like galling should always result in a recommendation to replace the valve body. This is due to the varying nature of degenerative conditions - a valve might last another 5000 strokes, it might also just last five.`,
    },
    examples: {
      example1: {
        src: 'travelEx1',
        title: `Good Travel Graph`,
        caption: 'This travel graph is very close to the intended signal line, the increasing and decreasing directions are close together, and the graphs are very linear.',
        size: 'small',
      },
      example2: {
        src: 'travelEx2',
        title: `High Dynamic Error`,
        caption:
          'This travel graph shows the effects of higher dynamic error - the increasing and decreasing lines are further apart.  You can also see some ‘wavering’ in the lines, instead of a consistent difference between the increasing and decreasing travel as the valve completes its stroke. This would be indicative of wear.',
        size: 'small',
      },
      example3: {
        src: 'travelEx7',
        title: `Degenerative Wear`,
        caption:
          'This travel graph shows the effects of a degenerative condition. Zooming in may be necessary to spot these conditions. For a full breakdown of how to spot degenerative conditions and their patterns, review the active alarms in the friction diagnostic checklist.',
        size: 'small',
      },
    },
  },
  Air: {
    title: `Air Supply Graph`,
    descriptions: {
      description1: `The air graph tracks the pressure found in the air supply as the valve slowly ramps from 0 - 100% signal and back again. It is useful for diagnosing the proper setup and function of the air supply and regulator. It is also used for leak detection in the air supply and actuator.`,
      section1Title: `What We’re Looking For:`,
      section1Subtitle: `Air Supply and Regulator`,
      section1Indent1: `Air supply levels are appropriate for the valve size and actuator type`,
      section1Indent2: `Air supply levels are consistent through the test`,
      section1Indent3: `Air supply readings aren’t noisy`,
    },
    issues: {
      section1Title: `Air Supply Testing`,
      section1Subtitle1: `Air Supply Pressure`,
      section1Indent1: `Are the air levels appropriate for this valve?`,
      section1Subtitle2: `Inconsistent Air Supply`,
      section1Indent2: `Are the air levels consistent or are they noisy?`,
      section1Subtitle3: `Insufficient Air Flow`,
      section1Indent3: `Are the air levels consistent or do they drop to low levels?`,
      section2Title: `Actuator Testing`,
      section2Subtitle1: `Air Leak Detected`,
      section2Indent1: `Does the air supply consistently drop in pressure of the course of the test, indicating a potential leak?`,
    },
    investigate: {
      Title1: `Review the Checklist`,
      Rec1: `Review the checklist and see what is ‘Advisory’ (yellow) vs ‘Critical’ (red) vs ‘Other’ (gray, black, purple, etc.). If you are unfamiliar with a checklist item, review its helper and the recommendations within.`,
      Title2: `Review the Graphs`,
      Rec2: `Review the graphs with the checklist alerts in mind. Using the graphs, validate or eliminate potential root causes of alarms as you proceed. As needed, refer to the visual examples in the checklist helpers.`,
      Title3: `Re-Test Valve`,
      Rec3: `If you reach alarms that indicate you should re-test the valve before continuing further, you likely need to re-test before proceeding to alarms in other groupings.`,
      Title4: `Investigate Root Causes`,
      Rec4: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure performance is within application criteria. This will vary based on the needs of the application - flawless positioner performance is not necessary if the valve is only used for on/off.`,
      Title5: `Determine Adjustments`,
      Rec5: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure the valve stays reliable until the next time it could be repaired or replaced. This will also vary based on the needs of the application, but degenerative conditions like galling should always result in a recommendation to replace the valve body. This is due to the varying nature of degenerative conditions - a valve might last another 5000 strokes, it might also just last five.`,
    },
    examples: {
      example1: {
        src: 'airEx1',
        title: `Good Air Supply Graph`,
        caption:
          'This air supply is consistent and stable across the entire range of travel. We expect a small droop at each end as the valve prepares to leave the seat/stops, and it is clear the air supply is sufficient to power the valve’s travel.',
        size: 'small',
      },
      example2: {
        src: 'airEx2',
        title: `Air Droop`,
        caption:
          'This valve shows a significant air droop - a third of the air supply pressure is consumed before the valve leaves the seat. This would indicate real world performance could be impacted or indicate the valve is at risk to not have enough force to move the actuator.',
        size: 'small',
      },
      example3: {
        src: 'airEx3',
        title: `Minor Air Leak`,
        caption:
          'An air leak may result in a sloped drawdown of pressure, as more air is being utilized than is being supplied. A minor leak will typically have a drawdown of less than 1 PSI per minute. The appearance of a minor leak may occur if the positioner is a heavy consumer of air. Resolving minor drawdowns that do not trigger an alarm may not be necessary depending on application requirements and impact to site performance. Larger drawdowns should be resolved.',
        size: 'small',
      },
      example4: {
        src: 'airEx3',
        title: `Minor Air Leak`,
        caption:
          'Another sign of an air leak is if a single acting actuator appears to actively consume air in both the filling direction and the venting direction. A single acting actuator should not consume air from the air supply while venting. Active drawdown of air while venting means that there is additional airflow needed to compensate for the leak.',
        size: 'small',
      },
      example5: {
        src: 'airEx4',
        title: `Inconsistent Air Supply Pressure`,
        caption: 'This air supply is very noisy through travel, indicative of a bad regulator or an over-tuned booster/positioner.',
        size: 'small',
      },
    },
  },
  Actuator: {
    title: `Actuator Graph`,
    descriptions: {
      description1: `The travel graph tracks the pressures found in the top and/or bottom of the actuator as the valve slowly ramps from 0 - 100% signal and back again. It is useful for diagnosing the proper setup and function of the actuator.`,
      section1Title: `What We’re Looking For:`,
      section1Subtitle: `Actuator Performance and Health`,
      section1Indent1: `Proper saturation of the actuator pressures, particularly on the seating end (i.e. only 0 PSI / air supply PSI)`,
      section1Indent2: `Pressures are linear through valve travel`,
      section1Indent3: `Pressures are balanced (not too high or too low) during valve travel`,
      section1Indent4: `Springs (where present) are sized correctly for the stated configuration and air pressures`,
    },
    issues: {
      section1Title: `Actuator Testing`,
      section1Subtitle1: `Actuator Supply Pressure`,
      section1Indent1: `Are the pressure levels found in the actuator appropriate for its type (piston vs diaphragm, etc.)`,
      section1Subtitle2: `Actuator Volume`,
      section1Indent2: `Does the valve struggle to saturate during the ramp test?`,
      section1Subtitle3: `Air Leak Detected`,
      section1Indent3: `Does the actuator data indicate a potential leak?`,
      section1Subtitle4: `Air Blockage`,
      section1Indent4: `Does the actuator data indicate an air blockage?`,
      section1Subtitle5: `Balance Pressure`,
      section1Indent5: `As relevant, are the actuator pressures balanced during travel?      `,
      section1Subtitle6: `Weak or Incorrect Spring`,
      section1Indent6: `Does the detected bench set imply incorrect, weak, or missing springs?`,
      section1Subtitle7: `Actuator Saturation`,
      section1Indent7: `Does the valve have proper saturation when it finishes travel, particularly at the seating end? (i.e. only values of 0 PSI and air supply PSI)`,
      section1Subtitle8: `Poor Actuator Performance`,
      section1Indent8: `Are the actuator pressures linear and parallel through travel?`,
      section2Title: `Valve Body and Packing`,
      section2Subtitle1: `High Friction`,
      section2Indent1: `Does the valve’s friction cause the actuator pressures to span a significant portion of the available air pressure?`,
    },
    investigate: {
      Title1: `Review the Checklist`,
      Rec1: `Review the checklist and see what is ‘Advisory’ (yellow) vs ‘Critical’ (red) vs ‘Other’ (gray, black, purple, etc.). If you are unfamiliar with a checklist item, review its helper and the recommendations within.`,
      Title2: `Review the Graphs`,
      Rec2: `Review the graphs with the checklist alerts in mind. Using the graphs, validate or eliminate potential root causes of alarms as you proceed. As needed, refer to the visual examples in the checklist helpers.`,
      Title3: `Re-Test Valve`,
      Rec3: `If you reach alarms that indicate you should re-test the valve before continuing further, you likely need to re-test before proceeding to alarms in other groupings.`,
      Title4: `Investigate Root Causes`,
      Rec4: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure performance is within application criteria. This will vary based on the needs of the application - flawless positioner performance is not necessary if the valve is only used for on/off.`,
      Title5: `Determine Adjustments`,
      Rec5: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure the valve stays reliable until the next time it could be repaired or replaced. This will also vary based on the needs of the application, but degenerative conditions like galling should always result in a recommendation to replace the valve body. This is due to the varying nature of degenerative conditions - a valve might last another 5000 strokes, it might also just last five.`,
    },
    examples: {
      example1: {
        src: 'actEx1',
        title: `Good Single Acting Actuator`,
        caption:
          'This actuator has clean, linear pressure throughout valve travel. The actuator pressure is 0 at the seating end and air-supply PSI at the open end, consistent with an air-to-open valve. The span of the spring, which affects the slope of the pressures through travel, is consistent with the configured spring range (10 PSI - 30 PSI). For examples of poorly performing single acting actuators, please review the examples for other Actuator Testing Issues.',
        size: 'small',
      },
      example2: {
        src: 'actEx5',
        title: `Good Double Acting Actuator`,
        caption:
          'This action has clean, linear pressure throughout the valve travel. It is double acting, so while one end starts at 0 PSI the other end starts at the air supply pressure. Once the valve begins to move, the increasing and decreasing sides should meet around equidistant between 0 PSI and air supply PSI (in this example 30 PSI), ultimately cross, and end in reverse position from where the initial actuator pressure readings started. For examples of poorly performing double acting actuators, please review the examples for other Actuator Testing Issues.',
        size: 'small',
      },
      example3: {
        src: 'actEx3',
        title: `Underperforming Actuator`,
        caption:
          'There are a number of significant issues in this actuator graph. First, the actuator does not reach saturation at the start and end of travel. Second, the actuator pressures across the valve travel are inconsistent and non-linear.',
        size: 'small',
      },
    },
  },
  Friction: {
    title: `Friction Graph`,
    descriptions: {
      description1: `The friction graph tracks the force required to move the valve stem as the valve slowly ramps from 0 - 100% signal and back again. It is useful for diagnosing the proper setup and function of the valve body. The force required is calculated from the pressures in the actuator, so a functioning actuator is essential for valid data.`,
      section1Title: `What We’re Looking For:`,
      section1Subtitle: `Valve Packing and Body`,
      section1Indent1: `Friction levels are appropriate given valve size and specifications`,
      section1Indent2: `Friction levels are consistent (no significant changes across travel)`,
      section1Indent3: `Friction levels aren’t too noisy (no patterns or shapes)`,
      section1Indent4: `Note: Always check the data manually on the friction and travel graph for signs of degenerative wear / galling. For a description of what to look for, review the Stair-Stepping helper.`,
    },
    issues: {
      section1Title: `Valve Packing and Body`,
      section1Subtitle1: `Very Low Packing Friction`,
      section1Indent1: `Is it possible the valve may not be packed?`,
      section1Subtitle2: `High Friction Forces`,
      section1Indent2: `Does the valve’s friction cause the actuator pressures to span a significant portion of the available air pressure?`,
      section1Subtitle3: `Inconsistent Friction Forces`,
      section1Indent3: `Are the friction values consistently level across travel?`,
    },
    investigate: {
      Title1: `Review the Checklist`,
      Rec1: `Review the checklist and see what is ‘Advisory’ (yellow) vs ‘Critical’ (red) vs ‘Other’ (gray, black, purple, etc.). If you are unfamiliar with a checklist item, review its helper and the recommendations within.`,
      Title2: `Review the Graphs`,
      Rec2: `Review the graphs with the checklist alerts in mind. Using the graphs, validate or eliminate potential root causes of alarms as you proceed. As needed, refer to the visual examples in the checklist helpers.`,
      Title3: `Re-Test Valve`,
      Rec3: `If you reach alarms that indicate you should re-test the valve before continuing further, you likely need to re-test before proceeding to alarms in other groupings.`,
      Title4: `Investigate Root Causes`,
      Rec4: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure performance is within application criteria. This will vary based on the needs of the application - flawless positioner performance is not necessary if the valve is only used for on/off.`,
      Title5: `Determine Adjustments`,
      Rec5: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure the valve stays reliable until the next time it could be repaired or replaced. This will also vary based on the needs of the application, but degenerative conditions like galling should always result in a recommendation to replace the valve body. This is due to the varying nature of degenerative conditions - a valve might last another 5000 strokes, it might also just last five.`,
    },
    examples: {
      example1: {
        src: 'frictionEx1',
        title: `Consistent Friction`,
        caption:
          'The friction is consistent and at a reasonable level. Though the line is not perfectly level, the variation is well within what is acceptable. Small shifts will not impact site performance. Larger values (approx 25% of friction values) are signs for concern and extremely large values (a doubling of friction or more) are signs for alarm.',
        size: 'small',
      },
      example2: {
        src: 'frictionGraphEx2',
        title: `High Friction`,
        caption:
          'The appropriate value for friction levels can be hard to determine without expert-specific knowledge of the application, the size of the valve, the metallurgy, and the packing type. This is an example of a single acting valve with a high amount of friction given the actuator and body-size. The difference between the increasing and decreasing pressures in the actuator are quite large. Poor step-performance or slow stroke times should improve if the valve is re-packed.',
        size: 'small',
      },
      example3: {
        src: 'frictionGraphEx3',
        title: `High Friction - Double Acting`,
        caption:
          'This is an example of a double acting valve with a high amount of friction given the actuator and body-size. The difference between the increasing and decreasing pressures in the actuator are quite large. Poor step-performance or slow stroke times should improve if the valve is re-packed.',
        size: 'small',
      },
      example4: {
        src: 'frictionEx2',
        title: `Extremely High Friction`,
        caption:
          'With a median friction of 1100 ft. lbs., this valve is experiencing a very high level of friction across travel. This will greatly reduce the accuracy of the valve and reduce the reliability of the valve and its components. In addition, the friction is very noisy and increases as the valve travels. In instances of extreme friction, the friction may become noisy. This is because the actuator must build up enough pressure to overcome static friction, then "breaks free" for a period of time.',
        size: 'small',
      },
      example5: {
        src: 'frictionGraphEx4',
        title: `Ball Valve`,
        caption:
          'This is a pattern typical of a ball valve. It may also be present in other rotary valve designs where the surface area of the disk increases as the valve closes. If all other aspects of the valve are acceptable, this pattern can be acknowledged as inherent to the design.',
        size: 'small',
      },
    },
  },
  'I/P': {
    title: `I/P Graph`,
    descriptions: {
      description1: `The travel graph tracks the requested pressure of the I/P as the valve slowly ramps from 0 - 100% signal and back again. It is useful for diagnosing the proper setup and function of the I/P.`,
      section1Title: `What We’re Looking For:`,
      section1Subtitle: `I/P`,
      section1Indent1: `Does the I/P respond to the signal accurately`,
      section1Indent2: `Is there instability or noise in the response of the I/P`,
      section1Indent3: `Does the I/P respond quickly to signal commands`,
      section1Indent4: `Note: to visually confirm a bad I/P, you will need to zoom in on the I/P graphs. Very tiny differences between increasing and decreasing direction performance have an outsize impact on valve accuracy.`,
    },
    issues: {
      section1Title: `I/P Testing`,
      section1Subtitle1: `I/P Calibration`,
      section1Indent1: `Does the I/P respond to the command signal accurately?`,
      section1Subtitle2: `I/P Inaccurate`,
      section1Indent2: `Does the I/P have any instability or nonlinearity in its response?`,
    },
    investigate: {
      Title1: `Review the Checklist`,
      Rec1: `Review the checklist and see what is ‘Advisory’ (yellow) vs ‘Critical’ (red) vs ‘Other’ (gray, black, purple, etc.). If you are unfamiliar with a checklist item, review its helper and the recommendations within.`,
      Title2: `Review the Graphs`,
      Rec2: `Review the graphs with the checklist alerts in mind. Using the graphs, validate or eliminate potential root causes of alarms as you proceed. As needed, refer to the visual examples in the checklist helpers.`,
      Title3: `Re-Test Valve`,
      Rec3: `If you reach alarms that indicate you should re-test the valve before continuing further, you likely need to re-test before proceeding to alarms in other groupings.`,
      Title4: `Investigate Root Causes`,
      Rec4: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure performance is within application criteria. This will vary based on the needs of the application - flawless positioner performance is not necessary if the valve is only used for on/off.`,
      Title5: `Determine Adjustments`,
      Rec5: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure the valve stays reliable until the next time it could be repaired or replaced. This will also vary based on the needs of the application, but degenerative conditions like galling should always result in a recommendation to replace the valve body. This is due to the varying nature of degenerative conditions - a valve might last another 5000 strokes, it might also just last five.`,
    },
    examples: {
      example1: {
        src: 'ipEx1',
        title: `Good I/P Graph`,
        caption: 'A good I/P graph should look like two lines that are exactly on top of each other for the full intended span of the I/P (usually 3 PSI to 15 PSI).',
        size: 'small',
      },
      example2: {
        src: 'ipEx2',
        title: `Noisy or Diverging Data`,
        caption:
          'A bad I/P will show signs of noise or appear as two separate lines at varying points in the travel.  Though not necessary for this example, zooming in can often help with diagnosis.',
        size: 'small',
      },
    },
  },
  Seat: {
    title: `Seat Graph`,
    descriptions: {
      description1: `The travel graph tracks the position of the valve stem near the seat. It is measured against actuator pressures near the seat. It is useful for diagnosing whether the valve is consistently entering the seat and if there is seat compression.`,
      section1Title: `What We’re Looking For:`,
      section1Subtitle: `Valve Body and Packing`,
      section1Indent1: `Does the valve exit the seat properly?`,
      section1Indent2: `Does the valve close into the seat properly?`,
      section1Indent3: `Note: Many of these alarms can also be reviewed by inspecting the travel graph.`,
    },
    issues: {
      section1Title: `Valve Body and Packing`,
      section1Subtitle1: `Seat Entry`,
      section1Indent1: `Is there reason to believe the valve might not be consistently closing fully?`,
      section1Subtitle2: `Seat Exit`,
      section1Indent2: `Does the valve exit the seat properly?`,
      section1Subtitle3: `Seat Loading`,
      section1Indent3: `Where appropriate, do we have a loading force at the valve seat?`,
    },
    investigate: {
      Title1: `Review the Checklist`,
      Rec1: `Review the checklist and see what is ‘Advisory’ (yellow) vs ‘Critical’ (red) vs ‘Other’ (gray, black, purple, etc.). If you are unfamiliar with a checklist item, review its helper and the recommendations within.`,
      Title2: `Review the Graphs`,
      Rec2: `Review the graphs with the checklist alerts in mind. Using the graphs, validate or eliminate potential root causes of alarms as you proceed. As needed, refer to the visual examples in the checklist helpers.`,
      Title3: `Re-Test Valve`,
      Rec3: `If you reach alarms that indicate you should re-test the valve before continuing further, you likely need to re-test before proceeding to alarms in other groupings.`,
      Title4: `Investigate Root Causes`,
      Rec4: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure performance is within application criteria. This will vary based on the needs of the application - flawless positioner performance is not necessary if the valve is only used for on/off.`,
      Title5: `Determine Adjustments`,
      Rec5: `With root causes in mind, determine what kinds of adjustments, repairs, or new components would ensure the valve stays reliable until the next time it could be repaired or replaced. This will also vary based on the needs of the application, but degenerative conditions like galling should always result in a recommendation to replace the valve body. This is due to the varying nature of degenerative conditions - a valve might last another 5000 strokes, it might also just last five.`,
    },
    examples: {
      example1: {
        src: 'seatEx1',
        title: `Good Seat Graph`,
        caption:
          'A good seat graph should show that both increasing and decreasing direction end at 0% open. If one crosses into negative numbers or never reaches zero, then there is a chance the valve will not consistently be at the seat. Ideally, there should be signs of compression, which would show up as a gap between the % open of the increasing and decreasing direction as they approach 0. If they appear like a rotated L, then it is likely there is no seat compression.',
        size: 'small',
      },
      example2: {
        src: 'seatEx2',
        title: `No Seat Compression`,
        caption: 'This seat graph is an example of where there is likely no seat compression - there is no gap between the % open of the increasing and decreasing direction as the approach 0.',
        size: 'small',
      },
      example3: {
        src: 'seatEx3',
        title: `Inconsistent Seating`,
        caption:
          'This seat graph is an example of where it is likely the valve does not seat consistently - the valve starts at 0% open in the increasing direction but does not return to 0% by the end of the test.',
        size: 'small',
      },
      example4: {
        src: 'seatEx4',
        title: `Travel Issues`,
        caption:
          'Sometimes seat problems are so large they will show up as a travel problem. In this graph, we can see the valve doesn’t leave the seat until a command signal of 20% is applied in the ramp. This will be outside the limits of a seat graph, but should still be treated as a seat problem.',
        size: 'small',
      },
    },
  },
};

export default diagnosticsGraphDetails;
