import React from 'react';
import logo from '../../images/logoSplash.svg';
import logoWhite from '../../images/logoSplashWhiteText.svg';
import ASLogo from '../../images/ASLogo.png';
import ASLogoDark from '../../images/ASLogoDark.png';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { Grid, Typography } from '@mui/material';

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container justifyContent='space-evenly'>
        {/*this.props.secondaryLogo === '' ? null : (
          <Grid item>
            <img src={`/images/${this.props.secondaryLogo}.png`} alt='Logo2' className={classes.splashLogoBakerHughes} />
          </Grid>
        )*/}
        <Grid container justifyContent='center'>
          <Grid item>
            {this.props.colorMode === 'dark' ? <img src={logoWhite} alt='Ten One Logo' className={classes.splashLogo} /> : <img src={logo} alt='Ten One Logo' className={classes.splashLogo} />}
          </Grid>
        </Grid>
        <Grid container justifyContent='center'>
          <Grid item>
            <Typography align='center' variant='h6' className={classes.splashText}>
              Welcome to Ten One Solutions!
            </Typography>
            {/*<Typography align='center' variant='subtitle1' className={classes.splashText}>
              To get started, click on 'Optimize' on the left hand menu. Below is an outline of the Optimize method to valve performance.
        </Typography>*/}
          </Grid>
        </Grid>
        {this.props.primaryLogo === 'SetpointLogo' ? (
          <Grid item>
            <img src={`/images/SetpointLogo.png`} alt='Setpoint Logo' className={classes.splashLogoSetpoint} />
          </Grid>
        ) : this.props.primaryLogo === 'BakerHughesLogo' ? (
          <Grid item>
            <img src={`/images/BakerHughesLogo.png`} alt='Baker Hughes Logo' className={classes.splashLogoBakerHughes} />
          </Grid>
        ) : this.props.primaryLogo === 'VectorLogo' ? (
          <Grid item>
            <img src={this.props.colorMode === 'dark' ? ASLogoDark : ASLogo} alt='Vector Logo' className={classes.splashLogoVector} />
          </Grid>
        ) : this.props.primaryLogo === 'GEBoothLogo' ? (
          <Grid item>
            <img src={this.props.colorMode === 'dark' ? `/images/GEBoothLogoInverted.png` : `/images/GEBoothLogo.png`} alt='GE Booth Logo' className={classes.splashLogoGEBooth} />
          </Grid>
        ) : null}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    primaryLogo: state.auth.primaryLogo,
    secondaryLogo: state.auth.secondaryLogo,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(Welcome));
