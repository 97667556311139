import React from 'react';
import { CircularProgress, Container, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Link from '@mui/material/Link';
import { signIn } from '../../actions';
import { styles } from '../styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

class Signup extends React.Component {
  //state = {};
  constructor(props) {
    super(props);
    this.state = {
      statusText: '',
      color: null,
      loading: true,
      remember: false,
      email: '',
      password: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  /*loginHelper = () => {
    const data = {
      username: process.env.REACT_APP_SERVER_USER,
      password: process.env.REACT_APP_SERVER_PASS,
    };
    this.props.signIn(data);
  };*/
  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'remember' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    const data = {
      username: this.state.email,
      password: this.state.password,
    };
    this.props.signIn(data);
    return false; //stop url from showing things
    //console.log('submitted:');
    //console.log(this.state);
  }

  render() {
    const { classes } = this.props;
    if (this.props.auth === null) {
      // User has not yet been authorized
      return (
        <Typography variant='subtitle2'>
          <div style={this.state.color}>
            <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
            Loading...
          </div>
        </Typography>
      );
    } else if (this.props.auth === false) {
      return (
        <Container maxWidth='xs'>
          <Grid container className={classes.loginMargin}>
            <Grid item>
              <Avatar color='primary' className={classes.avatar}>
                <AccountCircleIcon fontSize='small' />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography component='h1' variant='h5'>
                Sign Up
              </Typography>
            </Grid>
          </Grid>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              onChange={this.handleInputChange}
              className={classes.loginMargin}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFixNoPaper,
                },
              }}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Desired Password'
              type='password'
              id='password'
              autoComplete='current-password'
              onChange={this.handleInputChange}
              className={classes.loginMargin}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFixNoPaper,
                },
              }}
            />
            <Button type='submit' fullWidth variant='contained' color='primary' className={classes.loginMargin}>
              Sign Up Now
            </Button>
            <Grid container>
              <Grid item>
                <Link href='/login' variant='body2'>
                  {'Already have an account? Sign In'}
                </Link>
              </Grid>
            </Grid>
          </form>
        </Container>
      );
    } else {
      console.log('ERROR. This part of the logic tree for login should be inaccessible');

      return <React.Fragment>ERROR</React.Fragment>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    errors: state.errors,
  };
};

export default connect(mapStateToProps, { signIn })(withStyles(styles, { withTheme: true })(Signup));
