import React from 'react';
//import { Grid, TextField } from '@mui/material';
//import CustomSelect from '../../../filters/CustomSelect';
import DatePicker from '../../../filters/DatePicker';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { connect } from 'react-redux';
import { fetchIssueTypes } from '../../../../actions/index';

class AddShutdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };
  }
  componentDidMount() {
    let currentRow = {
      id: this.props.eventId,
      startDate: new Date(this.props.startDate),
      endDate: new Date(this.props.endDate),
    };
    this.setState(currentRow);
  }
  handleInputChange = (event) => {
    let id = event.target.name;
    this.setState({ [id]: event.target.value });
  };
  multiSelectOnChange = async (event, value, type) => {
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    this.setState({ [name]: value });
  };
  updateDateRange = (value, type) => {
    this.setState({ [type]: value });
  };
  render() {
    //const { classes } = this.props;
    return (
      <div style={{ marginTop: 16 }}>
        <DatePicker
          label='Start Date'
          date={this.state.startDate}
          dateFunction={(startDate) => {
            this.updateDateRange(startDate, 'startDate');
          }}
        ></DatePicker>
        <DatePicker
          label='End Date'
          date={this.state.endDate}
          dateFunction={(endDate) => {
            this.updateDateRange(endDate, 'endDate');
          }}
        ></DatePicker>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    issuetypes: state.issuetypes,
  };
};
export default connect(mapStateToProps, {
  fetchIssueTypes,
})(withStyles(styles)(AddShutdown));
