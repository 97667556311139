import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { LinearProgress } from '@mui/material';

class Progress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    //console.log(this.props.progress);
    return <LinearProgress variant='determinate' value={this.props.progress} />;
  }
}

export default withStyles(styles, { withTheme: true })(Progress);
