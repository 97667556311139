import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Assessment, Insights, FactCheck, Tune } from '@mui/icons-material';
import { connect } from 'react-redux';
import { optimizeSetActiveTab } from '../../actions/index';
import functions from '../../functions/functions';
import { styles } from '../styles';

class OptimizeTabs extends React.Component {
  setActiveTab = (event, value) => {
    this.props.optimizeSetActiveTab(value);
  };
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <Paper square className={classes.tabsWrapper} elevation={3} style={printStyles ? { display: 'none' } : null}>
        <Tabs value={this.props.activeTab} onChange={this.setActiveTab} variant='fullWidth' indicatorColor='secondary' textColor='secondary' className={classes.tabs}>
          <Tab className={classes.tab} icon={<Assessment />} label='Performance' value='Performance' component={Link} to={`Performance`} />
          <Tab className={classes.tab} icon={<Insights />} label='Improvement Paths' value='Improvements' component={Link} to={`Improvements`} />
          <Tab className={classes.tab} icon={<FactCheck />} label='Diagnostics' value='Diagnostics' component={Link} to={`Diagnostics`} />
          <Tab className={classes.tab} icon={<Tune />} label='Alignment' value='Alignment' component={Link} to={`Alignment`} />
        </Tabs>
      </Paper>
    );
  }
}

OptimizeTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.optimize.activeTab,
    activeLoop: state.optimize.activeLoop,
    loops: state.loops.loops,
  };
};

export default connect(mapStateToProps, { optimizeSetActiveTab })(withStyles(styles)(OptimizeTabs));
