import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Divider, Grid, Typography } from '@mui/material';
//import ToggleSwitch from "../filters/ToggleSwitch";
import { styles } from '../styles';

class WidgetTitle extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container className={classes.titleWrapper} justifyContent='space-between' style={this.props.FSRMargin ? { marginTop: -8 } : !this.props.removeMargin ? null : { marginTop: 0 }}>
          <Grid item>
            <Typography variant='subtitle2' color='secondary'>
              {this.props.title}
            </Typography>
          </Grid>
          {/* <ToggleSwitch id={null} label="Historical Data" helperText={null} />*/}
        </Grid>
        <Divider className={classes.dividerMd} />
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(WidgetTitle);
