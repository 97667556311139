import _ from 'lodash';
import {
  FETCH_SHUTDOWNS /*,
  FETCH_LOCATIONS,
  CREATE_LOCATION,
  DELETE_LOCATION,
  EDIT_LOCATION*/,
} from '../actions/types';

const shutdownReducer = (state = {}, action) => {
  switch (action.type) {
    /*case FETCH_LOCATION:
      return { ...state, [action.payload.eventId]: action.payload };*/
    case FETCH_SHUTDOWNS:
      return { ...state, ..._.mapKeys(action.payload, 'id') };
    /*case EDIT_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_LOCATION:
      return _.omit(state, action.payload);*/
    default:
      return state;
  }
};

export default shutdownReducer;
