//import _ from 'lodash';
import {
  CLEAR_STATE,
  //FETCH_APPLICATION,
  FETCH_IMPORTANCES /*,
  CREATE_APPLICATION,
  DELETE_APPLICATION,
  EDIT_APPLICATION*/,
} from '../actions/types';

const importanceReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return {
        ...state,
        list: [],
      };
    }
    //case FETCH_APPLICATION:
    //  return { ...state, [action.payload.applicationId]: action.payload };
    case FETCH_IMPORTANCES:
      let importances = [];
      for (let i = 0; i < action.payload.length; i++) {
        importances.push(action.payload[i].importance);
      }
      return { ...state, list: importances };
    /*case EDIT_APPLICATION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_APPLICATION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_APPLICATION:
      return _.omit(state, action.payload);*/
    default:
      return state;
  }
};

export default importanceReducer;
