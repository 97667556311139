import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid, Typography } from '@mui/material';
import initializeTheme from '../theme';
import { connect } from 'react-redux';
import { styles } from '../styles';
import { TrendingDown, TrendingUp } from '@mui/icons-material';

class ProgressGuide extends React.Component {
  state = {
    completed: this.props.value,
    buffer: 0,
  };
  //TODO EVALUATE HOW MUCH HEADER SPACE WE WANT
  processProgress(props) {
    const valueMax = !props.valueMax ? 100 : props.valueMax;
    const valueShift = !props.valueShift ? 0 : props.valueShift;
    const value = !props.value ? 0 : props.value;
    if (!this.props.value) {
      return {
        value: 0,
        completed: 0,
        buffer: 0,
        valueShift: 0,
      };
    } else if (this.props.valueTarget < 0) {
      return {
        value: (value / valueMax) * 100,
        completed: (value / valueMax) * 100,
        buffer: ((value - valueShift) / valueMax) * 100,
        valueShift: valueShift,
      };
    } else {
      return {
        value: (value / valueMax) * 100,
        completed: ((value - valueShift) / valueMax) * 100,
        buffer: (value / valueMax) * 100,
        valueShift: valueShift,
      };
    }
  }
  rendervalue() {
    const { classes } = this.props;
    if (!this.props.value) {
      return (
        <Typography className={classes.valueExtraMargin} variant='subtitle1'>
          value Not Found
        </Typography>
      );
    } else {
      return <Typography variant='h6'>{this.state.value}</Typography>;
    }
  }
  renderShift() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    if (this.props.label === 'value') {
      if (this.props.valueShift > 0) {
        return (
          <Grid container>
            <Grid>
              <Typography variant='subtitle2' style={theme.palette.blue}>
                ({this.props.valueShift})
              </Typography>
            </Grid>
            <Grid>
              <TrendingUp className={classes.shiftArrow} style={theme.palette.blue}></TrendingUp>
            </Grid>
          </Grid>
        );
      } else if (this.props.valueShift < 0) {
        return (
          <Grid container>
            <Grid>
              <Typography variant='subtitle2' style={theme.palette.pink}>
                ({this.props.valueShift})
              </Typography>
            </Grid>
            <Grid>
              <TrendingDown className={classes.shiftArrow} style={theme.palette.pink}></TrendingDown>
            </Grid>
          </Grid>
        );
      } else if (this.props.valueShift === 0) {
        return <Typography variant='subtitle2'>(No Shift)</Typography>;
      } else return;
    }
  }

  render() {
    const { classes } = this.props;
    const { completed, buffer } = this.processProgress(this.props);
    return (
      <div className={classes.progressWrapper}>
        <Grid container></Grid>
        <LinearProgress
          value={completed > 100 ? 100 : completed}
          //value={50}
          variant='buffer'
          valueBuffer={buffer}
          className={classes.progressGuide}
          classes={{
            bar1Buffer: classes.markerGuide,
            bar2Buffer: classes.clearBG,
            barColorPrimary: classes.clearBG,
            dashedColorPrimary: classes.clearBG,
          }}
        />
        <div className={classes.guide} />
      </div>
    );
  }
}

ProgressGuide.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(ProgressGuide));
