import React from 'react';
//import Grid from '@mui/material/Grid';
import WidgetTitle from '../../../headers/WidgetTitle';
import {
  Button,
  Collapse,
  //Divider,
  Grid,
  //IconButton,
  //ListItem,
  //ListItemText,
  /*Input,
  Tooltip,
  TableSortLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableCell,
  TableBody,
  Table,*/
  //IconButton,
  TextField,
  Typography,
  //Typography,
} from '@mui/material';
//import { Cancel /*, Check, Cached, CheckCircle*/ } from '@mui/icons-material';
//import Confirm from '../../../dialogs/Confirm';
//import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
//import Checkbox from '@mui/material/Checkbox';
//import FormControlLabel from '@mui/material/FormControlLabel';
import { connect } from 'react-redux';
//import { IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { /*Delete,*/ /*CheckCircle,*/ AddBox } from '@mui/icons-material';
import TableWidgetExpandable from '../../../widgets/TableWidgetExpandable';
//import api from '../../../../apis/api';
import { fetchSites, fetchLocations, fetchApplications, fetchClients, fetchLoopDevices, fetchLoopDevicesSummary, fetchDeviceTypes } from '../../../../actions/index';
import DeviceContent from '../content/DeviceContent';
//import matchFilter from '../../../filters/functions/searchFilter';
//import ExpandableSection from '../../../helpers/ExpandableSection';
import matchFilter from '../../../filters/functions/searchFilter';
import CustomDialog from '../../../popup/CustomDialog';
//import TableComponent from '../../../charts/Table';

class DeviceConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterStr: '',
      clickedRow: null,
      rowOpen: false,
      dialogOpen: false,
      rowChange: {},
      loading: false,
    };
  }
  async componentDidMount() {
    this.setState({
      loading: true,
    });
    this.props.fetchLoopDevicesSummary();
    await this.props.fetchDeviceTypes();
    this.setState({
      loading: false,
    });
  }
  handleRowClick = (row) => {
    console.log(row + ' Clicked');
    //let device = this.props.devices[row];
    this.setState((state) => ({
      clickedRow: row,
      /*rowChange: {
        name: device.deviceName,
        client: device.client,
        site: device.site,
        application: device.application,
        description: device.description,
        deviceType: device.deviceType,
        importance: device.importance,
        processName: device.processName,
        applicationOrder: device.applicationOrder,
        deviceOrder: device.deviceOrder,
      },*/
    }));
    if (this.state.rowOpen && row === this.state.clickedRow) {
      this.setState((state) => ({ rowOpen: !state.rowOpen }));
    } else {
      this.setState((state) => ({ rowOpen: false }));
      this.setState((state) => ({ rowOpen: !state.rowOpen }));
    }
  };

  renderChildRowContent = (row) => {
    const { classes } = this.props;
    return (
      <Collapse in={this.state.rowOpen && this.state.clickedRow === row.id} unmountOnExit={true} className={classes.collapseBody}>
        <DeviceContent row={row} />
      </Collapse>
    );
  };
  /*renderChildRowContentOld = (row) => {
    const { classes } = this.props;
    const shutdownHeaderArr = [
      { id: 'startDate', label: 'Start Date' },
      { id: 'endDate', label: 'End Date' },
      { id: 'duration', label: 'Duration' },
      { id: 'note', label: 'Note' },
      { id: 'menu', label: '' },
    ];
    let shutdownData = [
      {
        id: 1,
        startDate: 'Tim',
        endDate: 'Tim',
        duration: 'Tim',
        note: 'Tim',
        menu: 'Menu',
      },
      {
        id: 2,
        startDate: 'Tim2',
        endDate: 'Tim2',
        duration: 'Tim2',
        note: 'Tim2',
        menu: 'Menu',
      },
    ];
    return (
      <Collapse
        in={this.state.rowOpen && this.state.clickedRow === row.id}
        unmountOnExit={true}
      >
        <div className={classes.collapseBody}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log('submitted');
              //this.setState({ changeConfirm: true });
            }}
          >
            <Typography
              variant='subtitle1'
              color='primary'
              className={classes.rowTitle}
            >
              Device Information
            </Typography>
            <TextField
              variant='standard'
              className={classes.textField}
              label='Device Name'
              onChange={(event) => {
                //console.log(row.id);
                let currentRow = this.state.rowChange;
                currentRow.name = event.target.value;
                //this.setState({
                //changeRow: currentRow,
                //tagError: row.id === -1 ? false : this.checkDuplicate(event.target.value, 'name', device.valveIDHistory[currentSerialIndex], this.state.rowChange.deviceId, //currentSerialIndex),
                // });
              }}
              defaultValue={
                !this.state.clickedRow ||
                this.state.rowChange.name === null ||
                this.props.devices[this.state.clickedRow].name === null
                  ? ''
                  : this.props.devices[this.state.clickedRow].name
              }
            />

            {this.state.tagError ? (
              <Typography color='error'>
                Duplicate Tag # / Serial Combination.
              </Typography>
            ) : null}
            <TextField
              variant='standard'
              className={classes.textField}
              label='Client'
              onChange={(event) => {
                let currentRow = this.state.rowChange;
                currentRow.client = event.target.value;
                this.setState(currentRow);
              }}
              defaultValue={
                !this.state.clickedRow ||
                !this.props.devices[this.state.clickedRow].client
                  ? ''
                  : this.props.devices[this.state.clickedRow].client
              }
            />
            <TextField
              variant='standard'
              className={classes.textField}
              label='Site'
              onChange={(event) => {
                let currentRow = this.state.rowChange;
                currentRow.site = event.target.value;
                this.setState(currentRow);
              }}
              defaultValue={
                !this.state.clickedRow || this.state.rowChange.site === null
                  ? ''
                  : this.props.devices[this.state.clickedRow].site
              }
            />
            <TextField
              variant='standard'
              className={classes.textField}
              label='Application'
              onChange={(event) => {
                let currentRow = this.state.rowChange;
                currentRow.application = event.target.value;
                this.setState(currentRow);
              }}
              defaultValue={
                !this.state.clickedRow ||
                this.state.rowChange.application === null
                  ? ''
                  : this.props.devices[this.state.clickedRow].applicationType
              }
            />
            <TextField
              variant='standard'
              className={classes.textField}
              label='Description'
              onChange={(event) => {
                let currentRow = this.state.rowChange;
                currentRow.description = event.target.value;
                this.setState(currentRow);
              }}
              defaultValue={
                !this.state.clickedRow ||
                this.state.rowChange.description === null ||
                !this.props.devices[this.state.clickedRow].description
                  ? ''
                  : this.props.devices[this.state.clickedRow].description
              }
            />
            <SingleSelect
              id={null}
              label='Device Type'
              //type="sort"
              //handleChange={this.updateSort}
              options={this.props.devicetypes}
              selectedOption={
                !this.state.clickedRow ||
                this.state.rowChange.deviceType === null ||
                !this.props.devices[this.state.clickedRow].deviceType
                  ? ''
                  : this.props.devices[this.state.clickedRow].deviceType
              }
              helperText={null}
            />
            <SingleSelect
              id={null}
              label='Importance'
              //type="sort"
              handleChange={(value) => {
                //console.log(event);
                let currentRow = this.state.rowChange;
                //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
                //console.log(currentRow);
                this.setState(currentRow);
              }}
              options={[
                'Extremely Important',
                'Very Important',
                'Important',
                'Standard',
                'Not Important',
              ]}
              selectedOption={
                !this.state.clickedRow ||
                this.state.rowChange.importance === null ||
                !this.props.devices[this.state.clickedRow].importance
                  ? ''
                  : this.props.devices[this.state.clickedRow].importance
              }
              //selectedOption={this.processSpeedAccuracy(!this.props.clickedRow ? null : this.props.devices[this.props.clickedRow].accuracyImportance)}
              helperText={null}
            />
            <SingleSelect
              id={null}
              label='Application Order'
              //type="sort"
              //handleChange={this.updateSort}
              handleChange={(value) => {
                //console.log(event);
                let currentRow = this.state.rowChange;
                currentRow.applicationOrder = value;
                //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
                //console.log(currentRow);
                this.setState(currentRow);
              }}
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
              selectedOption={
                !this.state.clickedRow ||
                this.state.rowChange.applicationOrder === null ||
                !this.props.devices[this.state.clickedRow].applicationOrder
                  ? ''
                  : this.props.devices[this.state.clickedRow].applicationOrder
              }
              helperText={null}
            />
            <SingleSelect
              id={null}
              label='Device Order'
              //type="sort"
              //handleChange={this.updateSort}
              handleChange={(value) => {
                //console.log(event);
                let currentRow = this.state.rowChange;
                currentRow.deviceOrder = value;
                //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
                //console.log(currentRow);
                this.setState(currentRow);
              }}
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
              selectedOption={
                !this.state.clickedRow ||
                this.state.rowChange.deviceOrder === null ||
                !this.props.devices[this.state.clickedRow].deviceOrder
                  ? ''
                  : this.props.devices[this.state.clickedRow].deviceOrder
              }
              helperText={null}
            />
            <ListItem
              role={undefined}
              dense
              className={
                false
                  ? classes.checklistItemActive
                  : classes.checklistItemSelectable
              }
              style={{ cursor: 'pointer', marginTop: 20, marginBottom: 10 }}
            >
              <Checkbox
                checked={false}
                onClick={(e) => {
                  this.handleLoopClick();
                }}
                className={classes.checkbox}
                color='secondary'
              ></Checkbox>
              <ListItemText
                onClick={(e) => {
                  this.handleLoopClick();
                }}
                primary={
                  <Typography variant='subtitle2'>
                    Is this device in a loop?
                  </Typography>
                }
                classes={
                  this.props.format
                    ? { primary: classes.listPrimaryFormatted }
                    : { primary: classes.listPrimary }
                }
              />
            </ListItem>
            <TextField
              variant='standard'
              className={classes.textField}
              label='Loop Name'
              onChange={(event) => {
                //console.log(row.id);
                let currentRow = this.state.rowChange;
                currentRow.processName = event.target.value;
                //this.setState({
                //changeRow: currentRow,
                //tagError: row.id === -1 ? false : this.checkDuplicate(event.target.value, 'name', device.valveIDHistory[currentSerialIndex], this.state.rowChange.deviceId, //currentSerialIndex),
                // });
              }}
              defaultValue={
                !this.state.clickedRow ||
                this.state.rowChange.processName === null ||
                this.props.devices[this.state.clickedRow].processName === null
                  ? ''
                  : this.props.devices[this.state.clickedRow].processName
              }
            />
            <Typography
              variant='subtitle1'
              color='primary'
              className={classes.rowTitle}
            >
              Shutdown Information
            </Typography>
            <Grid item xs={12}>
              <TableComponent
                data={shutdownData}
                header={shutdownHeaderArr}
                rows={25}
                tileTable
                printHidePagination
              ></TableComponent>
            </Grid>
            <Button
              variant='outlined'
              size='medium'
              color='primary'
              type='submit'
              className={classes.buttonFilter}
            >
              <CheckCircle className={classes.buttonIcon}></CheckCircle>Submit
            </Button>
          </form>
        </div>
      </Collapse>
    );
  };*/
  handleClickOpen = () => {
    //console.log('Click Open');
    this.setState({
      dialogOpen: true,
      rowOpen: false,
      clickedRow: null,
      rowChange: {
        name: '',
        client: '',
        site: '',
        application: '',
        description: '',
        deviceType: '',
        importance: '',
        processName: '',
        applicationOrder: '',
        deviceOrder: '',
      },
      status: '',
      statusMessage: '',
    });
  };

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      rowChange: {},
      status: '',
      statusMessage: '',
    });
  };

  showDeviceForm() {
    //const { classes } = this.props;
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.dialogOpen}
        onClose={this.handleClose}
        title='Add New Device'
        titleIcon='Add'
        content={<DeviceContent create closeFunction={this.handleClose} />}
      ></CustomDialog>
    );
  }

  render() {
    //console.log(this.props.devices);
    const { classes } = this.props;
    const headerArr = [
      { id: 'deviceName', label: 'Device Name' },
      { id: 'deviceType', label: 'Device Type' },
      { id: 'site', label: 'Site' },
      { id: 'location', label: 'Location' },
      { id: 'application', label: 'Application' },
      { id: 'loopName', label: 'Loop Name' },
      { id: 'importance', label: 'Importance' },
    ];
    let dataKeys = Object.keys(this.props.devices);
    let data = [];
    for (let i = 0; i < dataKeys.length; i++) {
      let key = dataKeys[i];
      let device = this.props.devices[key];
      data.push({
        id: key,
        //key: key,
        deviceName: device.name,
        deviceType: device.deviceType,
        site: device.site,
        location: device.location,
        application: device.applicationType,
        loopName: device.processName,
        importance: device.importance,
      });
    }
    data = matchFilter(data, this.state.filterStr);
    /* let data = [
      {
        id: 1,
        deviceName: 'Tim',
        deviceType: 'Tim',
        site: 'Tim',
        location: 'Tim',
        application: 'Tim',
        loopName: 'Tim',
        importance: 'Tim',
      },
      {
        id: 2,
        deviceName: 'Tim2',
        deviceType: 'Tim2',
        site: 'Tim2',
        location: 'Tim2',
        application: 'Tim2',
        loopName: 'Tim2',
        importance: 'Tim2',
      },
    ];*/
    //console.log(data);
    if (this.props.permission > 30) {
      //TODO Forcefull link to another section?
      return <React.Fragment>You do not have permission to view this module</React.Fragment>;
    }
    return (
      <React.Fragment>
        {this.showDeviceForm()}
        <WidgetTitle title='Device Database'></WidgetTitle>
        <Grid container justifyContent='space-between' className={classes.printHide}>
          <Grid item>
            <TextField
              variant='outlined'
              InputProps={{ classes: { root: classes.inlineInput } }}
              className={classes.searchField}
              placeholder='Search'
              onChange={(event) => {
                this.setState({ filterStr: event.target.value });
              }}
              value={this.state.filterStr}
            />
          </Grid>
          <Grid item>
            <Button variant='outlined' size='medium' color='primary' onClick={this.handleClickOpen} className={classes.buttonFilterRight} style={{ marginBottom: 8 }}>
              <AddBox className={classes.buttonIcon}></AddBox>Add New Device
            </Button>
          </Grid>
        </Grid>
        <Typography variant='body2' style={{ marginBottom: 4 }} className={classes.printHide}>
          Click any row to expand
        </Typography>
        <TableWidgetExpandable
          data={data}
          header={headerArr}
          rows={25}
          type='deviceConfig'
          handleClick={this.handleRowClick}
          renderChildRowContent={this.renderChildRowContent}
          rowOpen={this.state.rowOpen}
          loading={this.state.loading}
        ></TableWidgetExpandable>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    realms: state.auth.realms,
    current_realm: state.auth.current_realm,
    permission: state.auth.view,
    sites: state.sites,
    locations: state.locations,
    clients: state.clients,
    applications: state.applications,
    devices: state.devices.devices,
    devicetypes: state.devicetypes.list,
  };
};

export default connect(mapStateToProps, {
  //fetchDeviceImprovements,
  fetchSites,
  fetchLocations,
  fetchClients,
  fetchApplications,
  fetchLoopDevices,
  fetchLoopDevicesSummary,
  fetchDeviceTypes,
})(withStyles(styles)(DeviceConfig));
