import React from 'react';
import ReportsTabs from '../menus/ReportsTabs';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import HistoryWidgets from '../widgetGroups/reports/HistoryWidgets';
import PrioritizeWidgets from '../widgetGroups/reports/PrioritizeWidgets';
import OptimizeWidgets from '../widgetGroups/reports/OptimizeWidgets';
import FsrWidgets from '../widgetGroups/reports/FsrWidgets';
import { connect } from 'react-redux';
import { reportsSetActiveTab, reportsSetActiveReport, setModule } from '../../actions';
import { Redirect } from 'react-router-dom';

class Reports extends React.Component {
  componentDidMount() {
    //console.log('mount');
    //TODO FIX REDIRECT WHEN HISTORY IN
    if (this.props.match.params.tab === 'null' || this.props.match.params.tab === null || !this.props.match.params.tab) {
      console.log(this.props.match.params.tab);
      this.props.reportsSetActiveTab('Optimize');
      this.props.setModule('Optimize');
    } else {
      this.props.reportsSetActiveTab(this.props.match.params.tab);
      this.props.setModule(this.props.match.params.tab);
    }
    if (!!this.props.match.params.report && this.props.match.params.report !== 'null') {
      this.props.reportsSetActiveReport(this.props.match.params.report.toLowerCase());
    }
  }
  /*componentDidUpdate() {
    //console.log('mount');
    //TODO FIX REDIRECT WHEN HISTORY IN
    if (this.props.match.params.tab !== this.props.activeTab) {
      this.props.setModule(this.props.match.params.tab); //TAB MUST ALWAYS BE A MODULE OR THIS WILL CAUSE FAILURES
      this.props.reportsSetActiveTab(this.props.match.params.tab);
    }
    if (!!this.props.match.params.report && this.props.match.params.report !== 'null' && this.props.match.params.report !== this.props.activeReport) {
      this.props.reportsSetActiveReport(this.props.match.params.report.toLowerCase());
    }
  }*/
  render() {
    //TODO FIX REDIRECT WHEN HISTORY IN
    if (this.props.match.params.tab === 'null' || this.props.match.params.tab === null) {
      return <Redirect to='Optimize/Portfolio' />;
    }
    const { classes } = this.props;
    const printFlag = window.location.href.slice(-6);
    const printStyles = printFlag.toLowerCase() === '/print';
    return (
      <React.Fragment>
        {this.props.format ? null : <ReportsTabs />}
        <div className={printStyles ? null : classes.detailsMin}>
          {this.props.activeTab === 'History' && <HistoryWidgets></HistoryWidgets>}
          {this.props.activeTab === 'Prioritize' && <PrioritizeWidgets></PrioritizeWidgets>}
          {this.props.activeTab === 'Optimize' && <OptimizeWidgets></OptimizeWidgets>}
          {this.props.activeTab === 'FSR' && <FsrWidgets></FsrWidgets>}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.reports.activeTab,
    activeReport: state.reports.activeReport,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {
  reportsSetActiveTab,
  reportsSetActiveReport,
  setModule,
})(withStyles(styles, { withTheme: true })(Reports));
