import React from 'react';
import initializeTheme from '../theme';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { Typography, Grid } from '@mui/material';
import { styles } from '../styles';
import functions from '../../functions/functions';
import InfoPopover from '../popup/InfoPopover';

class TextDisplay extends React.Component {
  render() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    if (this.props.field) {
      return (
        <Grid item xs={12}>
          <Grid container className={classes.field} style={this.props.last ? { borderBottomWidth: 1 } : null}>
            <Grid item xs={6} className={classes.fieldName}>
              <Typography variant='subtitle2'>{this.props.label}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.fieldValue}>
              <Typography variant='body1' color={this.props.color}>
                {this.props.text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    } else
      return (
        <Grid
          item
          className={this.props.type === 'large' ? classes.textDisplayLarge : this.props.type === 'prioritize' ? classes.textDisplayWide : printStyles ? classes.printTextDisplay : classes.textDisplay}
        >
          <Grid container>
            {!this.props.hasHelper ? null : (
              <Grid item style={{ marginTop: -2 }}>
                <InfoPopover resize title={this.props.label} id={this.props.id} type='prioritize' />
              </Grid>
            )}
            <Grid item>
              <Typography
                style={theme.palette.blue}
                variant='subtitle2'
                align={this.props.align}
                className={
                  this.props.type === 'large'
                    ? classes.textDisplayLabelLarge
                    : this.props.type === 'prioritize'
                    ? classes.textDisplayLabel
                    : printStyles
                    ? classes.printTextDisplayLabel
                    : classes.textDisplayLabel
                }
              >
                {this.props.label}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            variant='body1'
            color={this.props.color}
            className={
              this.props.type === 'large'
                ? classes.textDisplayBodyLarge
                : this.props.type === 'prioritize'
                ? classes.textDisplayBody
                : printStyles
                ? classes.printTextDisplayBody
                : classes.textDisplayBody
            }
          >
            {!!this.props.text || this.props.text === 0 ? this.props.text : null}
          </Typography>
        </Grid>
      );
  }
}

TextDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    colorMode: state.colorMode.colorMode,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(TextDisplay));
