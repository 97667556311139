const diagnosticsDetails = {
  ValidityCheck: {
    title: `Data Validity`,
    examples: {
      example1: {
        src: 'validityOptimizeEx1',
        title: `Valid Graph`,
        caption:
          'Each graph has a typical behavior for high functioning graphs. Clicking the (information icon) on a graph will show you examples of high functioning results, low functioning results, and invalid results.',
        size: 'md',
      },
      example2: {
        src: 'validityOptimizeEx2',
        title: `Illogical Data`,
        caption:
          'Invalid data will typically be intuitively illogical. For example, air supply data with negative PSI values would be a sign that the test was not run properly. If the data validity issue is yellow, then the data may plausibly be due to a poorly functioning valve. Those issues should be checked closely to confirm it is or is not due to improper testing conditions.',
        size: 'md',
      },
      example3: {
        src: 'validityOptimizeEx3',
        title: `Swapped Sensors`,
        caption:
          'The most common form of invalid data is where two data sensors are swapped. This is most common with the top/bottom actuator ports. It is also common between the actuator and air supply ports, as in this example.',
        size: 'lg',
      },
      example4: {
        src: 'validityOptimizeEx4',
        title: `Incorrect Sensor Connection`,
        caption:
          'This is an example of a sensor/cable that is incorrectly hooked up or not longer working. Check all sensors are connected and check all ports/cables to ensure they are properly connected.',
        size: 'md',
      },
      example5: {
        src: 'validityOptimizeEx5',
        title: `Insufficient Time for Test to Run`,
        caption:
          'A test can fail if it doesn’t allow enough time for the test to run. If this occurs, then the information window will display the hold and ramp times that were used. If this occurs, you can set the system to its defaults and rerun the test.',
        size: 'sm',
      },
    },
  },
  CalibrationCheck: {
    title: `Calibration`,
    examples: {
      example1: {
        src: 'calibrationEx1',
        title: `Improper Calibration`,
        caption:
          'Improper calibration is not always obvious from a simple look at a travel graph. Upon closer inspection in the next example we will see that this air to close valve doesn’t reach 0% travel until -3% signal, so this valve would not close into the seat at 100% of signal.  You can always verify the calculated values and target values from the information provided in the helper and in the valve information table.',
        size: 'md',
      },
      example2: {
        src: 'calibrationEx2',
        title: `Calibration Range`,
        caption:
          'By selecting the area of the graph near 0% travel, we can zoom in on the seat portion of the graph. If we hover over the graph data, we can get the exact values for that time of the test. We can see in the tooltip that the valve doesn’t arrive into the seat (0% travel) until over 100% of signal, so this valve would not close into the seat at 20 mA.',
        size: 'md',
      },
    },
  },
  SignalSourceCheck: {
    title: `Signal Span Incorrect`,
    examples: {
      example1: {
        src: 'signalSpanEx1',
        title: `Split Range Calibration`,
        caption:
          'A common form of mis-calibration is when a valve is calibrated for split range that shouldn’t be, or vice versa. When this occurs, always confirm with the site what calibration range they prefer. Then either re-run the test with the right range and/or re-calibrate the valve as appropriate',
        size: 'md',
      },
    },
  },
  TravelCheck: {
    title: `Nominal Travel`,
    examples: {
      example1: {
        src: 'nomTravelEx1',
        title: `Nom. Stroke and Travel Fields`,
        caption:
          'Nominal travel issues may or may not be obvious from the travel graph. We recommend you refer to the ‘Nom. Stroke’ and ‘Travel’ fields in the helper and in the  valve information table to determine if the nominal travel is correctly set.',
        size: 'xs',
      },
    },
  },
  AirDirCheck: {
    title: `Air Action Incorrect`,
    examples: {
      example1: {
        src: 'airActionEx1',
        title: `Air to Open`,
        caption:
          'This is an example of an air to open valve. The position of the valve increases as the signal increases. At 0% signal, the valve is at 0% travel. At 100% signal, the valve is at 100% travel.',
        size: 'md',
      },
      example2: {
        src: 'airActionEx2',
        title: `Air to Close`,
        caption:
          'This is an example of an air to close valve. The position of the valve decreases as the signal increases. At 0% signal, the valve is at 100% travel. At 100% signal, the valve is at 0% travel.',
        size: 'md',
      },
      example3: {
        src: 'airActionEx3',
        title: `Verification`,
        caption:
          'A match between the ‘Air Action’ fields and the direction of the travel graph is necessary to determine if the air action is likely correct. Avoid using just the valve information or just the travel graph to determine if the valve has the right air action.',
        size: 'md',
      },
    },
  },
  FailDirCheck: {
    title: `Fail Action Incorrect`,
    examples: {
      example1: {
        src: 'failActionEx1',
        title: `Spring and Graph Actions`,
        caption:
          'The direction of the spring force and the direction of the positioner graph are what indicate whether the fail action is correct. If the slope of the positioner graph matches the slope of the actuator during valve travel, then the valve has a spring that will force the valve to fail closed. When the slopes are opposites, the valve will fail open (see example 2). When the valve is to lock in place, there should be no slope. This spring direction is what is compared to the ‘Fail Mode’ field in the helper and Valve Information table to determine if there is an issue.',
        size: 'lg',
      },
      example2: {
        src: 'failActionEx2',
        title: `Spring and Graph Actions cont.`,
        caption:
          'This is an example of a valve that will fail open. In this case, the slope of the positioner graph is the opposite of the slope of the actuator during valve travel.  The spring direction is what is compared to the ‘Fail mode’ field in the helper and Valve Information table to determine if there is an issue.',
        size: 'lg',
      },
      example3: {
        src: 'failActionEx3',
        title: `Positioner with Wrong Fail Action Graph`,
        caption:
          'Digital positioners are typically not tested for their failure settings during a profile test, as this would require running the valve below the required power. However, on rare occasions you will see a graph like the above. In this case, the positioner likely has fail settings that are reversed. If you are testing a digital positioner, always check the settings on the positioner to confirm the fail action is the desired action in the event of a loss of power.',
        size: 'md',
      },
    },
  },
  IPCheck: {
    title: `I/P Calibration`,
    examples: {
      example1: {
        src: 'ipTravelEx1',
        title: `Seat Contact I/P and Full Open I/P Fields`,
        caption:
          'I/P travel issues may or may not be obvious from the I/P graph. We recommend you refer to ‘Seat Contact I/P’ and ‘Full Open I/P’ fields to determine if the nominal travel is correctly set. These can be found in the Valve Information table or the issue helper. Generally, I/P values should be set to within plus or minus 0.12 PSI of the target values.',
        size: 'sm',
      },
    },
  },
  IPNoise: {
    title: `I/P Inaccurate`,
    examples: {
      example1: {
        src: 'ipErrorEx1',
        title: `Replace I/P Required`,
        caption: 'This is an example of an I/P that requires replacement. The lines of the I/P graph should be as close together as possible, with minimal difference between them.',
        size: 'md',
      },
      example2: {
        src: 'ipErrorEx2',
        title: `Zoomed In I/P Graph`,
        caption:
          'Sometimes zooming in will be necessary to see the wear of the I/P. You can also hover over the data to see exactly how far apart the I/P is in the increasing and decreasing direction throughout the travel.',
        size: 'md',
      },
    },
  },
  CutoffCheck: {
    title: `Positioner Cutoff`,
    examples: {
      example1: {
        src: 'cutoffEx1',
        title: `Cutoffs Active`,
        caption:
          'When positioner cutoffs are active, the valve will return to the closed position at the cutoff value. The valve will also not open until the specified cutoff value, and then will respond as normal. Unless the facility specifies otherwise, this value should be close to the normal shutoff (<5%) or it may disrupt the process.',
        size: 'md',
      },
    },
  },
  CamCheck: {
    title: `Incorrect Flow Characteristic`,
    examples: {
      example1: {
        src: 'camEx1',
        title: `Non-Linear Response`,
        caption:
          'Typical stem response should be linear as the signal increases and decreases. When the response is extremely non-linear and curved, this is usually due to a flow characteristic setting in the digital positioner or an incorrect characteristic cam installed on the positioner.',
        size: 'md',
      },
    },
  },
  LinkageCheck: {
    title: `Inconsistent Positioner Feedback`,
    examples: {
      example1: {
        src: '',
        title: ``,
        caption: '',
        size: 'md',
      },
    },
  },
  LagCheck: {
    title: `Poor Positioner Performance`,
    examples: {
      example1: {
        src: 'lagEx1',
        title: `Dynamic Error`,
        caption: 'A significant gap between the increasing and decreasing lines is a sign of poor positioner performance. This is tracked as ‘dynamic error’.',
        size: 'md',
      },
      example2: {
        src: 'lagEx2',
        title: `High Performing Valve`,
        caption: 'A high performing valve will be linear and with minimal differences between increasing and decreasing lines.',
        size: 'md',
      },
    },
  },
  AirLevelCheck: {
    title: `Air Supply Pressure`,
    examples: {
      example1: {
        src: 'airLevelsEx1',
        title: `High Air Supply Pressure`,
        caption:
          'Extremely high air supply pressures increase wear on diaphragm actuators and risk significant damage to the diaphragm. In applications where this much pressure is necessary, a piston is more appropriate.',
        size: 'md',
      },
      example2: {
        src: 'airLevelsEx2',
        title: `Low Air Supply Pressure`,
        caption:
          'Consider using a diaphragm actuator in applications where air pressure should be low. Alternatively, consider raising the pressure provided to the actuator. Piston actuators should be used in higher pressure applications.',
        size: 'md',
      },
    },
  },
  AirNoiseCheck: {
    title: `Inconsistent Air Supply Pressure`,
    examples: {
      example1: {
        src: 'airNoiseEx1',
        title: `Regulator Wear`,
        caption:
          'Though this noise may seem minor, this is a sign of regulator wear. This much noise is sufficient to affect actuation and friction values. Left unaddressed, it can eventually lead to noise in valve travel.',
        size: 'md',
      },
      example2: {
        src: 'airNoiseEx2',
        title: `Air Supply Closeup`,
        caption: 'A close up of the air supply readings.',
        size: 'md',
      },
      example3: {
        src: 'airNoiseEx3',
        title: `Effects of an Air Compressor`,
        caption:
          'This is an example of an air supply being affected by an air compressor. The air pressure slowly depletes until the compressor activates again, bringing pressure up to the intended target.  In general, a regulator should be installed to insulate the valve from pressure swings and to protect the valve components (such as a diaphgram).',
        size: 'md',
      },
    },
  },
  AirDroopCheck: {
    title: `Insufficient Air Flow`,
    examples: {
      example1: {
        src: 'airDroopEx1',
        title: `Air Supply Droop`,
        caption:
          'This is a significant droop (approximately 1/3rd of total air supply) as the valve heads into the seat. If friction increases or performance degrades, this valve is at risk of having insufficient air to saturate into the seat.',
        size: 'md',
      },
      example2: {
        src: 'airDroopEx2',
        title: `Large Air Supply Droop - Stroke Performance`,
        caption:
          'Large air droops (> 50% of air supply) may not affect the day to day performance of the valve, but typically affect how fast the valve completes large movements. In these instances, a review of application requirements is valuable.',
        size: 'md',
      },
      example3: {
        src: 'airDroopEx3',
        title: `Large Air Supply Droop - Air Leak`,
        caption:
          'Large air droops (> 50% of air supply) are typically accompanied by other signs of significant air issues, such as actuator leaks. Be sure to address these issues as they are likely a significant cause of air flow issues.',
        size: 'md',
      },
    },
  },
  ActPressLevel: {
    title: `Actuator Supply Pressure`,
    examples: {
      example1: {
        src: 'actPressureEx1',
        title: `Low Actuator Supply Pressure`,
        caption:
          'Though this actuator performs well, there is only a 4 PSI difference between the pressure required to overcome the spring and the air supply pressure.  If the performance of this valve degrades, it may not have enough pressure to stroke fully. The air supply pressure provided to this valve should be increased slightly at the regulator.',
        size: 'md',
      },
      example2: {
        src: 'actPressureEx2',
        title: `Insufficient Actuator Supply Pressure`,
        caption:
          'There are many issues with this actuator. The issue detected by this specific alarm is that the valve does not have enough pressure available to overcome the spring at full travel. In addition to other corrections, the air supply pressure provided to this valve should be increased slightly at the regulator.',
        size: 'md',
      },
    },
  },
  ActLeak: {
    title: `Actuator Leak Warning`,
    examples: {
      example1: {
        src: 'actLeakEx1',
        title: `Actuator Leak - Top Chamber`,
        caption:
          'If one chamber fails to reach the ideal saturation points but the other does, it is likely a leak is present within that specific chamber. In this case, the top chamber (in red) fails to saturate on both ends of valve travel.',
        size: 'md',
      },
      example2: {
        src: 'actLeakEx2',
        title: `Actuator Leak Between Chambers - Both Directions`,
        caption:
          'If the actuator fails to saturate in both chambers on both sides of a test, there may be a leak present between the two chambers. This may be due to a piston seal. It may also be due to scratches, wear, or damage. Depending on the severity, this may be difficult to spot with the naked eye. Though the right side is obvious, the left side is also not saturated. Utilizing the zoom and hover features of Optimize graphs can be extremely helpful to identify all issues.',
        size: 'md',
      },
      example3: {
        src: 'actLeakEx6',
        title: `Actuator Leak Between Chambers - One Direction`,
        caption:
          'If the actuator fails to saturate in both chambers on one side of the test, there may be a leak present between the two chambers. This may be due to scratches, wear, or damage at that end of travel. The actuator cylinder itself may also be damaged. In this example, the actuator fails to saturate in both chambers on the right side of the graph.',
        size: 'md',
      },
      example4: {
        src: 'actLeakEx7',
        title: `Actuator Leak - Limited Issue`,
        caption:
          'A leak may still be present even if only a single chamber fails to saturate on one side. Check the actuator data carefully to ensure there is not a slight leak at any other saturation point. In this graph, the top chamber fails to saturate on the lower right side of the graph, indicating a leak may be present.',
        size: 'md',
      },
      example5: {
        src: 'actLeakEx5',
        title: `Actuator Blockage`,
        caption:
          'Actuator Blockages can prevent a valve from reaching the ideal saturation points.  If a blockage is present, attempt to resolve it if it is within the air lines. If it is within the actuator, then the actuator should be repaired or replaced.',
        size: 'md',
      },
    },
  },
  ActVolume: {
    title: `Actuator Volume`,
    examples: {
      example1: {
        src: 'actVolumeEx1',
        title: `Normal Actuator`,
        caption: 'A properly sized actuator will be quick to saturate on both sides of travel. ',
        size: 'md',
      },
      example2: {
        src: 'actVolumeEx2',
        title: `Incorrect Actuator Size`,
        caption:
          'An oversized actuator or under-sized air-lines for the application in question will typically fail to saturate on both sides. Confirm there are no other issues affecting actuator pressures (such as a weak air supply, small air lines, or blocked tubing) before re-sizing actuators.',
        size: 'md',
      },
      example3: {
        src: 'actVolumeEx3',
        title: `Comparison: Blockage/Leakage`,
        caption:
          'Actuators that are affected by a constriction in air flow will usually have a less severe failure to saturate - affecting only one side, affecting only one chamber, or failing to saturate in just one direction.',
        size: 'md',
      },
    },
  },
  AirDroopLong: {
    title: `Air Leak Detected`,
    examples: {
      example1: {
        src: 'airLeakEx1',
        title: `Severe Air Supply Leak`,
        caption: 'The long, significant drawdown of pressures is typical of a severe leak. This should be corrected at the soonest available opportunity, as a leak will only get worse.',
        size: 'md',
      },
      example2: {
        src: 'airLeakEx2',
        title: `Minor Air Supply Leak`,
        caption:
          'An air leak may result in a sloped drawdown of pressure, as more air is being utilized than is being supplied. A minor leak will typically have a drawdown of less than 1 PSI per minute. The appearance of a minor leak may occur if the positioner is a heavy consumer of air. Resolving minor drawdowns that do not trigger an alarm may not be necessary depending on application requirements and impact to site performance.',
        size: 'md',
      },
      example3: {
        src: 'airLeakEx5',
        title: `Air Supply Leak - Alternate Example`,
        caption:
          'Another sign of an air leak is if a single acting actuator appears to actively consume air in both the filling direction and the venting direction. A single acting actuator should not consume air from the air supply while venting. Active drawdown of air while venting means that there is additional airflow needed to compensate for the leak.',
        size: 'md',
      },
      example4: {
        src: 'airLeakEx6',
        title: `Saturation Leak`,
        caption: 'A loss of air pressure while the actuator is saturated is typical of a severe leak.',
        size: 'md',
      },
    },
  },
  ActBlockage: {
    title: `Actuator Blockage`,
    examples: {
      example1: {
        src: 'actBlockageEx1',
        title: `Double Acting Actuator`,
        caption: 'This is a typical example of a blockage affecting one side of a double acting actuator. The pressures found in the actuator should not exceed those found from the air supply.',
        size: 'md',
      },
      example2: {
        src: 'actBlockageEx2',
        title: `Double Acting Actuator`,
        caption: 'This is a typical example of a blockage affecting one side of a double acting actuator. ',
        size: 'md',
      },
      example3: {
        src: 'actBlockageEx3',
        title: `Double Acting Actuator - Saturation`,
        caption: 'This is a typical example of a blockage affecting one side of a double acting actuator. One chamber (the top) is slow to saturate / vent at full travel.',
        size: 'md',
      },
      example4: {
        src: 'actBlockageEx4',
        title: `Single Acting Actuator - Saturation`,
        caption: 'This is a typical example of a blockage affecting a single acting actuator. In this case, the valve is slow to saturate and vent.',
        size: 'md',
      },
    },
  },
  ActBalanceCheck: {
    title: `Balance Pressure`,
    examples: {
      example1: {
        src: 'actBalEx1',
        title: `High Actuator Balance Pressure`,
        caption: 'This is an example of a balance pressure that is too high. This will frequently affect valve performance in terms of speed.',
        size: 'md',
      },
      example2: {
        src: 'actBalEx2',
        title: `Low Actuator Balance Pressure`,
        caption: 'This is an example of a balance pressure that is too low. This will frequently affect valve performance in terms of accuracy.',
        size: 'md',
      },
    },
  },
  BenchCheck: {
    title: `Weak or Incorrect Spring`,
    examples: {
      example1: {
        src: 'benchSetEx1',
        title: `Weak Spring`,
        caption: 'The slope of the actuator travel indicates the force provided by the spring. In this case, weaker springs will result in a flatter line throughout valve travel.',
        size: 'md',
      },
      example2: {
        src: 'benchSetEx2',
        title: `Incorrect or Worn Spring`,
        caption:
          'Though this actuator graph looks normal, the spring is applying 75% the designed force for this valve. Always check both the valve information (Bench Set, Spring, and Spring Range) and the actuator graph to determine the force applied by the spring. In this case, either the spring is incorrect or worn and should be replaced.',
        size: 'lg',
      },
    },
  },
  ActSaturation: {
    title: `Actuator Saturation`,
    examples: {
      example1: {
        src: 'actSatEx1',
        title: `Improper Seating`,
        caption:
          'This actuator fails to reach 0 PSI at the seating side. This means that the valve is not seated or proper seating force is not present while seated. Saturation issues on the seating side should always be corrected.',
        size: 'md',
      },
      example2: {
        src: 'actSatEx2',
        title: `Incomplete Valve Travel`,
        caption:
          'If a valve has not reached the end of its travel by the end of the test, then the actuator pressures may stop at the end of the spring range.  This is usually solved by adjusting the travel and/or by use of digital positioner cutoff settings.',
        size: 'md',
      },
      example3: {
        src: 'actSatEx4',
        title: `Incomplete Valve Travel - Double Acting`,
        caption:
          'If a valve with a double acting actuator has not reached the end of its travel by the end of the test, then the actuator pressures may stop at the end of the balance pressure. This is usually solved by adjusting the travel and/or by use of digital positioner cutoff settings.',
        size: 'md',
      },
      example4: {
        src: 'actSatEx3',
        title: `Partial Saturation - Open Side`,
        caption:
          'If a valve fails to saturate by the end of the test, then the issue is usually one of air flow rate. If the valve is able to meet its stroke requirements, the issue may be acknowledged. If no blockages or leaks are present, then larger air flow may be appropriate.',
        size: 'md',
      },
      example5: {
        src: 'actTravelEx1',
        title: `Incorrect Actuator Size`,
        caption:
          'An oversized actuator or under-sized air-lines for the application in question may fail to saturate on all sides. Confirm there are no other issues affecting actuator pressures (such as incorrect travel, a weak air supply, small air lines, or blocked tubing) before re-sizing actuators. Ensure that the new actuator will provide enough force to maintain position against the pressures inside the process.',
        size: 'md',
      },
    },
  },
  ActTravel: {
    title: `Poor Actuator Performance`,
    examples: {
      example1: {
        src: 'actTravelEx1',
        title: `Actuator Travel Example 1`,
        caption:
          'In addition to failing to saturate, this actuator does not display linear pressures throughout air travel. If positioner and air supply issues are accounted for, this is a sign of significant issues in the actuator or valve body.',
        size: 'md',
      },
      example2: {
        src: 'actTravelEx2',
        title: `Actuator Travel Example 2`,
        caption: 'Severe friction issues can disrupt the travel of the actuator. Those issues should be investigated before repairing functional actuators.',
        size: 'md',
      },
    },
  },
  ActFlow: {
    title: `Poor Actuator Air Flow`,
    examples: {
      example1: {
        src: 'actFlowEx1',
        title: `Non-Linear Air Pressure`,
        caption:
          'In addition to failing to saturate, this actuator does not display linear pressures throughout air travel. If positioner and air supply issues are accounted for, this is a sign of significant issues in the actuator or valve body.',
        size: 'md',
      },
      example2: {
        src: 'actFlowEx2',
        title: `Severe Friction`,
        caption: 'Severe friction issues can disrupt the travel of the actuator. Those issues should be investigated before repairing functional actuators.',
        size: 'md',
      },
    },
  },
  SeatExit: {
    title: `Delayed Seat Exit`,
    examples: {
      example1: {
        src: 'seatExitEx1',
        title: `Seat/Trim Issue`,
        caption: 'Extremely delayed seat exit is typical of a problem with the seat or trim.',
        size: 'md',
      },
      example2: {
        src: 'seatExitEx2',
        title: `Seat Pop`,
        caption:
          'Some valves with tight shutoff will have a seat pop. This behavior may be considered more desirable than the alternatives. Note that some valves with a tight shutoff have an ‘overshoot’ upon leaving the seat.',
        size: 'md',
      },
    },
  },
  SeatStarts: {
    title: `Incorrect Seat Entry`,
    examples: {
      example1: {
        src: 'seatEntryEx1',
        title: `Incorrect Seat Entry`,
        caption: 'This valve enters the seat below 0% signal. As a result, it will remain open when the control system expects the valve to be closed. This can cause leak-by or seat/trim wear.',
        size: 'md',
      },
      example2: {
        src: 'seatEntryEx2',
        title: `Inconsistent Seat Entry`,
        caption: 'This valve does not enter the seat at the same location it began. This shows up as the two lines not meeting on the seat graph. Inconsistent seating should be corrected.',
        size: 'md',
      },
    },
  },
  Loading: {
    title: `Seat Loading`,
    examples: {
      example1: {
        src: 'seatEx5',
        title: `Valve Not Fully Closed`,
        caption:
          'A common sign of loading issues is if the valve does not return to fully closed by the end of the test. In the seating chart, this can be spotted by seeing if the increasing and decreasing data both return to the same ‘point’ on the X axis of the seat graph.',
        size: 'md',
      },
      example2: {
        src: 'seatEx6',
        title: `Good Seat`,
        caption: 'An example of a ‘healthy’ seat compression, for comparison.',
        size: 'md',
      },
    },
  },
  FrictionLow: {
    title: `Very Low Packing Friction`,
    examples: {
      example1: {
        src: 'frictionLowEx1',
        title: `Large Valve`,
        caption:
          'Very large valves will generally have an inherent amount of friction. This 12” valve should not have almost no friction.  Its packing should be inspected to confirm it is appropriately packed.',
        size: 'md',
      },
      example2: {
        src: 'frictionLowEx2',
        title: `Low Friction Valve`,
        caption:
          'Some valve types have inherently low friction for their size, such as eccentric plug valves or high performance disc valves. In these instances, if you believe the valve is appropriately packed you may acknowledge the issue.',
        size: 'md',
      },
      example3: {
        src: 'frictionLowEx3',
        title: `Small Valve`,
        caption:
          'Small valves, like this one-inch valve, are likely to have low friction values. However, they will still have some friction. If you believe the valve is appropriately packed, you may acknowledge the issue.',
        size: 'md',
      },
    },
  },
  FrictionMedian: {
    title: `High Friction Forces`,
    examples: {
      example1: {
        src: 'frictionMedianEx1',
        title: `Single Acting`,
        caption:
          'This is an example of a single acting valve with a high amount of friction given the actuator and body-size. The difference between the increasing and decreasing pressures in the actuator are quite large. Poor step-performance or slow stroke times should improve if the valve is re-packed.',
        size: 'lg',
      },
      example2: {
        src: 'frictionMedianEx2',
        title: `Double Acting`,
        caption:
          'This is an example of a double acting valve with a high amount of friction given the actuator and body-size. The difference between the increasing and decreasing pressures in the actuator are quite large. Poor step-performance or slow stroke times should improve if the valve is re-packed.',
        size: 'lg',
      },
      example3: {
        src: 'frictionMedianEx3',
        title: `Stair-Stepping`,
        caption:
          'This is the typical pattern of a stair-stepping in a valve that has been tightened too much due packing. There is consistent stair-stepping in both directions through the entire valve travel. If the friction is very high and the position graph looks like this, it is recommended to try and resolve the stair-stepping by loosening packing as a test and/or re-packing before assuming it is a degenerative or valve-body condition. If the friction is not very high, then it is more likely a degenerative condition or another issue.',
        size: 'md',
      },
    },
  },
  FrictionStatic: {
    title: `High Static Friction`,
    examples: {
      example1: {
        src: 'frictionStaticEx1',
        title: `Single Acting`,
        caption:
          'High static friction will appear as an overshoot in the actuation pressures as the valve leaves one or both ends of travel. If it appears on both sides, this implies that more force is required to have the valve leave a static position over a dynamic one.',
        size: 'md',
      },
      example2: {
        src: 'frictionStaticEx2',
        title: `Double Acting`,
        caption:
          'High static friction will appear as an overshoot in the actuation pressures as the valve leaves one or both ends of travel.  If it appears on one side, this implies that there may be an issue that will eventually become a ‘pop’ from that side of the valve. ',
        size: 'md',
      },
    },
  },
  FrictionShape: {
    title: `Inconsistent Friction Forces`,
    examples: {
      example1: {
        src: 'frictionShapeEx1',
        title: `Small Shift in Friction`,
        caption:
          'Though this is an example of a taper, the change in friction is not significant. Small shifts will not impact site performance. Larger values (approx 25% of friction values) are signs for concern and extremely large values (a doubling of friction or more) are signs for alarm.',
        size: 'md',
      },
      example2: {
        src: 'frictionShapeEx4',
        title: `Seat Friction`,
        caption:
          'This is an example of a globe valve where the valve has increasing friction as it heads into its seat. This creates the conditions for sticking in the seat. This valve will ultimately require early placement, though the facility may opt to wait for the issue to manifest as a significant ‘pop’ at the seat first.',
        size: 'lg',
      },
      example3: {
        src: 'frictionShapeEx3',
        title: `Ball Valve`,
        caption: 'This is a pattern typical of a ball valve. If all other aspects of the valve are acceptable, this pattern can be acknowledged as inherent to the design.',
        size: 'md',
      },
    },
  },
  GallingCheck: {
    title: `Stair-Stepping`,
    examples: {
      example1: {
        src: 'gallingEx1',
        title: `Partial Range Galling`,
        caption: 'Friction noise does not necessarily occur evenly through the entire range of travel. This is an instance of a friction issue in only a portion of the valve travel.',
        size: 'md',
      },
      example2: {
        src: 'gallingEx2',
        title: `Loose Actuator Coupling`,
        caption:
          'When checking noisy friction graphs, you will often check the travel for patterns. This is a pattern in travel that is typical of a loose actuator coupling. The stair-stepping is consistent in size, frequency, and through the entire range of the graph in both directions.',
        size: 'md',
      },
      example3: {
        src: 'gallingEx3',
        title: `Bi-Directional Galling`,
        caption:
          'When checking noisy friction graphs, you will often check the travel for patterns. Galling will only occur in one direction and may have an inconsistent magnitude/frequency. The steps will have a sharp point to them. This graph has two separate instances of galling. When galling is bi-directional, the two directions will rarely share the same magnitude/frequency. Bearing failure will have a smooth pattern. Stair-stepping through only part of the range of travel is common, but may occur through the entire range of travel if it has progressed significantly. The presence of any galling of any magnitude should be addressed as soon as possible.',
        size: 'md',
      },
      example4: {
        src: 'gallingEx4',
        title: `Bearing Wear`,
        caption:
          'This is an example of the beginning stages of bearing wear. The pattern is smooth, wavy, consistent in magnitude, and will usually occur through the entire range of travel. Bearing wear may be present in only one or both directions of travel. If the wear has not progressed into galling, then the travel (y-axis) will continue to progress consistently. This is different from galling, where the travel will get ‘stuck’ at some % travel for some time and ‘break free’. ',
        size: 'md',
      },
      example5: {
        src: 'gallingEx8',
        title: `Positioner Instability`,
        caption:
          'This is an example of instability due to poor positioner performance. This looks similar to bearing wear but subsides as the test progresses. Instability is typically present on poorly performing positioners. A key identification is that dynamic error is very high but friction is not. Look for a consistently large gap between the increasing and decreasing position data.',
        size: 'md',
      },
      example6: {
        src: 'gallingEx5',
        title: `Tight Packing`,
        caption:
          'This is the typical pattern of a stair-stepping in a valve that has been tightened too much due to packing. There is stair-stepping in both directions through the entire valve travel. If the friction is very high, it is recommended to try and resolve the stair-stepping by loosening packing as a test and/or re-packing before assuming it is a degenerative or valve-body condition. If the friction is not very high, then it is more likely a degenerative condition.',
        size: 'md',
      },
      example7: {
        src: 'gallingEx6',
        title: `Different Position Patterns`,
        caption:
          'This is an example of a repeating pattern that is not related to galling. Notice how it does not look similar to a set of stairs. Positioner tuning or an overactive booster may cause overshooting and patterns similar to this.',
        size: 'md',
      },
      example8: {
        src: 'gallingEx7',
        title: `Feedback Wear`,
        caption:
          'This is an example of feedback wear or a noisy position signal. Notice how it does not look similar to a set of stairs. Make sure to inspect the entire range of travel, as degenerative conditions could be affecting other parts of the valve travel.  To determine if the cause is a noisy position signal, check the position readings at the end of travel. If they are as noisy as the signal in movement, then this issue can be acknowledged.',
        size: 'md',
      },
      example9: {
        src: 'gallingEx9',
        title: `Wire-Wrap Issues`,
        caption:
          'This is an example of a wire-wrap that is incorrectly set. While the position is decreasing (blue), the position ‘slips’ in the positive direction. This means it cannot be due to a galling-force preventing the position from decreasing. The direction of slipping will depend on the direction of the rotation compared to the location of the wrap weight. In addition to slipping, binding of the wrap may occur. When either occurs, the wire-wrap should be inspected and tests should be re-run.',
        size: 'md',
      },
    },
  },
  ValvePerfCheck: {
    title: `Inadequate Valve Performance`,
    examples: {
      example1: {
        src: 'inadequateValveEx1',
        title: `Good Application Performance`,
        caption:
          'A valve with good performance on a step test will have a green bar. This means that the valve is performing with higher accuracy than is required for the valve application. Note that a valve can perform poorly on a step test but still be within the requirements an application needs. A valve that only needs to fully open or close does not need the same levels of alternating-step accuracy as a throttling valve on a compressor.',
        size: 'md',
      },
      example2: {
        src: 'inadequateValveEx2',
        title: `Moderator Application Performance`,
        caption:
          'A valve with moderate performance will have a yellow bar. This means that the valve is performing with accuracy close to what is required for the valve application. Depending on how long until the valve is revisited again, preventative steps may be warranted.',
        size: 'md',
      },
      example3: {
        src: 'inadequateValveEx3',
        title: `Poor Application Performance`,
        caption:
          'A valve with poor performance will have a red bar. This means that the valve is exceeding the requirements for the valve application. The valve’s performance and/or reliability should be addressed as quickly as possible to bring the valve back into performance requirements.',
        size: 'md',
      },
    },
  },
  ControlPerfCheck: {
    title: `Inadequate Control Performance`,
    examples: {
      example1: {
        src: 'inadequateValveEx1',
        title: `Good Application Performance`,
        caption:
          'A valve with good performance on a step test will have a green bar. This means that the valve is performing with higher accuracy than is required for the valve application. Note that a valve can perform poorly on a step test but still be within the requirements an application needs. A valve that only needs to fully open or close does not need the same levels of alternating-step accuracy as a throttling valve on a compressor.',
        size: 'md',
      },
      example2: {
        src: 'inadequateValveEx2',
        title: `Moderator Application Performance`,
        caption:
          'A valve with moderate performance will have a yellow bar. This means that the valve is performing with accuracy close to what is required for the valve application. Depending on how long until the valve is revisited again, compensating with control logic and tuning may be warranted.',
        size: 'md',
      },
      example3: {
        src: 'inadequateValveEx3',
        title: `Poor Application Performance`,
        caption:
          'A valve with poor performance will have a red bar. This means that the valve is exceeding the requirements for the valve application. If the valve’s performance and/or reliability cannot be addressed, then steps should be taken with the control system, logic, and tuning to compensate for the poor performance. The valve itself should be addressed at the next available opportunity.',
        size: 'md',
      },
    },
  },
  Deadband: {
    title: `Control Deadband Recommended`,
    examples: {
      example1: {
        src: 'deadbandEx1',
        title: `Deadband`,
        caption:
          'Some valves are unresponsive to steps below a certain threshold. In this case, it can be beneficial to add a deadband to the positioner or the control logic. This may prevent oscillation as the control logic attempts to move the valve to a position it cannot reach.',
        size: 'md',
      },
      example2: {
        src: 'deadbandEx2',
        title: `Deadband in the Presence of Stiction`,
        caption:
          'Valves with significant stiction can develop a repeated-overshoot behavior.  If this occurs, adding a deadband to the positioner or the control logic can prevent the valve from oscillating to seek a control target it cannot reach.',
        size: 'md',
      },
    },
  },
  StepTimes: {
    title: `Step Time Recommendations`,
    examples: {
      example1: {
        src: 'stepTimesEx1',
        title: `Normal Step Times Graph`,
        caption:
          'This is an example of a normal step-time graph. Step times should be symmetric (similar time for increasing and decreasing steps). As step sizes get smaller, the response time will typically increase. These step times can be useful for determining how to tune the control valve.',
        size: 'md',
      },
      example2: {
        src: 'stepTimesEx2',
        title: `Possible Air Blockage`,
        caption:
          'Valves with performance issues will deviate from the normal step-time graph shape.  In this case, the valve has difficulty completing increasing steps. This can be due to issues such as an air blockage. If the performance issues cannot be addressed, this information is useful for control tuning. The control tuning can be set knowing that these deficiencies exist.',
        size: 'md',
      },
      example3: {
        src: 'stepTimesEx1',
        title: `Step times for a Digital Positioner`,
        caption:
          'Care is needed when tuning for a digital positioner. This step-time graph is the same as the one used in the first example. However, if this valve is digital, the implications are different. A digital positioner for a closed control loop with I and D positioner gains means that the control loop acts as a cascade with the digital positioner. The control tuning should be set as such - with loop response rates that are roughly 3 - 4 times the response rate of this inner loop (the positioner). This is not necessary if the valve is used in open control or the positioner is an analog positioner.',
        size: 'md',
      },
    },
  },
  Overshoot: {
    title: `Overshoot Detected`,
    examples: {
      example1: {
        src: 'overshootEx1',
        title: `Overshoot`,
        caption: 'Overshoot involves the valve position shooting past the intended signal target. In most cases, overshoot is momentary and the valve adjusts back to the intended target quickly.',
        size: 'md',
      },
      example2: {
        src: 'overshootEx2',
        title: `Inaccurate Steps`,
        caption:
          'Overshoot can also result from inaccurate initial steps, followed by a second correcting step after delay. This behavior can usually be eliminated through different tuning settings on the positioner.',
        size: 'md',
      },
      example3: {
        src: 'overshootEx3',
        title: `Effects of Stiction`,
        caption: 'Valves with significant stiction can develop a repeated-overshoot behavior. If this occurs, the valve should be reviewed for stair-stepping or other signs of high friction.',
        size: 'md',
      },
    },
  },
};

export default diagnosticsDetails;
