//import _ from 'lodash';
//import { ActionTypes } from '@mui/base';
import {
  APPROVE_IMPROVEMENT,
  DELETE_IMPROVEMENT,
  CLEAR_STATE,
  CLEAR_DEVICE,
  CLEAR_DEVICE_ISSUES_IMPROVEMENTS,
  DELETE_DEVICES,
  //FETCH_LOOP,
  FETCH_DEVICES,
  FETCH_DEVICE_SPECS,
  FETCH_DEVICE_IMPROVEMENTS,
  FETCH_DEVICE_ISSUES,
  FETCH_DEVICE_HISTORY,
  UPDATE_DEVICE_COUNT,
  SET_DEVICE_SUMMARY_STATUS,
  SET_DEVICE_ISSUE_LOAD,
  //FETCH_DIAGNOSTICS
  /*,
  FETCH_LOCATIONS,
  CREATE_LOCATION,
  DELETE_LOCATION,
  EDIT_LOCATION*/
} from '../actions/types';

const INITIAL_STATE = { devices: {}, device_issue_load: {}, count: 0, summary: false };

const deviceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return {
        ...state,
        devices: {},
      };
    }
    case CLEAR_DEVICE: {
      let devices = Object.assign({}, state.devices);
      devices[action.payload] = {};
      return {
        ...state,
        devices,
      };
    }
    case CLEAR_DEVICE_ISSUES_IMPROVEMENTS: {
      let devices = Object.assign({}, state.devices);
      devices[action.payload].issues = null;
      devices[action.payload].improvements = null;
      let device_issue_load = {};
      return {
        ...state,
        devices,
        device_issue_load,
      };
    }
    case DELETE_DEVICES:
      return {
        ...state,
        devices: {},
      };
    /*case FETCH_LOOP:
      return {
        ...state,
        loops: { ...state.loops, ..._.mapKeys(action.payload, 'loopId') }
      };*/
    //TODO FIX REDUCERS TO CORRECT DESIGN FOR MERGE/REWRITE
    case FETCH_DEVICES: {
      let devices = Object.assign({}, state.devices);
      for (let i = 0; i < action.payload.length; i++) {
        let device = action.payload[i];
        if (!state.devices[device.deviceId]) {
          //test doesn't exist yet, add
          //console.log('add');
          devices[device.deviceId] = device;
        } else {
          //console.log('merge');
          //test exists, merge data
          const mergedDevice = Object.assign(state.devices[device.deviceId], device);
          devices[device.deviceId] = mergedDevice;
        }
      }
      return {
        ...state,
        devices,
      };
    }
    case SET_DEVICE_SUMMARY_STATUS: {
      return {
        ...state,
        summary: action.payload,
      };
    }
    /*return {
        ...state,
        devices: { ...state.devices, ..._.mapKeys(action.payload, 'deviceId') },
      };*/
    case FETCH_DEVICE_SPECS: {
      let devices = Object.assign([], state.devices);

      for (let i = 0; i < action.payload.length; i++) {
        let device = action.payload[i];
        if (!state.devices[device.deviceId]) {
          //test doesn't exist yet, add
          devices[device.deviceId] = device;
        } else {
          //test exists, merge data
          const mergedDevice = Object.assign(state.devices[device.deviceId], device);
          devices[device.deviceId] = mergedDevice;
        }
      }

      return {
        ...state,
        devices,
      };
    }
    case APPROVE_IMPROVEMENT: {
      let devices = Object.assign([], state.devices);
      devices[action.payload.deviceId] = action.payload.device;
      return {
        ...state,
        devices,
      };
    }
    case DELETE_IMPROVEMENT: {
      let devices = Object.assign([], state.devices);
      devices[action.payload.deviceId] = action.payload.device;
      return {
        ...state,
        devices,
      };
    }
    case FETCH_DEVICE_IMPROVEMENTS: {
      if (action.payload.data.length === 0) {
        return state;
      }
      let deviceId = action.payload.data[0].deviceId;
      // console.log(deviceId);
      let devices = Object.assign([], state.devices);
      if (!state.devices[deviceId]) {
        //test doesn't exist yet, add
        //console.log('add');
        devices[deviceId] = {
          improvements: { [action.payload.type]: action.payload.data },
        };
      } else if (!state.devices[deviceId].improvements) {
        devices[deviceId].improvements = {
          [action.payload.type]: action.payload.data,
        };
      } else {
        //console.log('merge');
        //test exists, merge data
        devices[deviceId].improvements[action.payload.type] = action.payload.data;
      }
      /*for (let i = 0; i < action.payload.length; i++) {
        let device = action.payload[i];
        if (!state.devices[device.deviceId]) {
          //test doesn't exist yet, add
          devices[device.deviceId] = device;
        } else {
          //test exists, merge data
          devices[device.deviceId] = Object.assign(
            state.devices[device.deviceId],
            device
          );
        }
      }
*/
      return {
        ...state,
        devices,
      };
    }
    case FETCH_DEVICE_ISSUES: {
      //if (action.payload.data.length === 0) {
      //  return state;
      //}
      let deviceId = action.payload.deviceId;
      // console.log(deviceId);
      let devices = Object.assign([], state.devices);
      //spawn the array if it doesn't exist to confirm 'loaded'
      if (action.payload.data.length === 0) {
        if (!state.devices[deviceId]) {
          //console.log('add');
          devices[deviceId] = {
            issues: { [action.payload.type]: [] },
          };
        } else if (!state.devices[deviceId].issues) {
          devices[deviceId].issues = {
            [action.payload.type]: [],
          };
        } else if (!state.devices[deviceId].issues[action.payload.type]) {
          devices[deviceId].issues[action.payload.type] = [];
        }
      } else {
        for (let i = 0; i < action.payload.data.length; i++) {
          if (!state.devices[deviceId]) {
            //test doesn't exist yet, add
            //console.log('add');
            devices[deviceId] = {
              issues: { [action.payload.type]: [action.payload.data[i]] },
            };
          } else if (!state.devices[deviceId].issues) {
            devices[deviceId].issues = {
              [action.payload.type]: [action.payload.data[i]],
            };
          } else if (!state.devices[deviceId].issues[action.payload.type]) {
            devices[deviceId].issues[action.payload.type] = [action.payload.data[i]];
          } else if (!!devices[deviceId].issues[action.payload.type].find((element) => element.issue === action.payload.data[i].issue)) {
            let issueIndex = devices[deviceId].issues[action.payload.type].findIndex((element) => element.issue === action.payload.data[i].issue);
            devices[deviceId].issues[action.payload.type][issueIndex] = action.payload.data[i];
          } else {
            //console.log('merge');
            //test exists, merge data

            devices[deviceId].issues[action.payload.type].push(action.payload.data[i]);
          }
        }
      }
      //console.log(devices[deviceId]);
      return {
        ...state,
        devices,
      };
    }
    case SET_DEVICE_ISSUE_LOAD: {
      let device_issue_load = Object.assign([], state.device_issue_load);
      /*if (action.payload.data.length === 0) {
        return state;
      }*/
      let deviceId = action.payload.deviceId;
      // console.log(deviceId);
      //let devices = Object.assign([], state.devices);
      if (!state.device_issue_load[deviceId]) {
        //test doesn't exist yet, add
        //console.log('add');
        device_issue_load[deviceId] = {
          [action.payload.type]: { [action.payload.name]: action.payload.status },
        };
      } else if (!state.device_issue_load[deviceId][action.payload.type]) {
        device_issue_load[deviceId][action.payload.type] = { [action.payload.name]: action.payload.status };
      } else if (!state.device_issue_load[deviceId][action.payload.type][action.payload.name]) {
        device_issue_load[deviceId][action.payload.type][action.payload.name] = action.payload.status;
      } else {
        //console.log('merge');
        //test exists, merge data
        device_issue_load[deviceId][action.payload.type][action.payload.name] = action.payload.status;
      }
      return {
        ...state,
        device_issue_load,
      };
    }
    // console.log(action.payload);
    // return state;
    /*return {
        ...state,
        devices: { ...state.devices, ..._.mapKeys(action.payload, 'deviceId') },
      };*/
    //}
    case FETCH_DEVICE_HISTORY: {
      let deviceId = action.payload[0].deviceId;

      let devices = Object.assign([], state.devices);
      if (!state.devices[deviceId]) {
        //test doesn't exist yet, add
        //console.log('add');
        devices[deviceId] = { history: action.payload };
      } else {
        //console.log('merge');
        //test exists, merge data
        devices[deviceId].history = action.payload;
      }
      /*for (let i = 0; i < action.payload.length; i++) {
        let device = action.payload[i];
        if (!state.devices[device.deviceId]) {
          //test doesn't exist yet, add
          devices[device.deviceId] = device;
        } else {
          //test exists, merge data
          devices[device.deviceId] = Object.assign(
            state.devices[device.deviceId],
            device
          );
        }
      }
*/
      return {
        ...state,
        devices,
      };
    }
    case UPDATE_DEVICE_COUNT:
      return { ...state, count: action.payload };
    /*case EDIT_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_LOCATION:
      return _.omit(state, action.payload);*/
    default:
      return state;
  }
};

export default deviceReducer;
