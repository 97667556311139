import React from 'react';
import {
  Button,
  Grid,
  //TextField,
  Typography,
  Divider,
  //IconButton,
} from '@mui/material';
import CustomSelect from '../../../filters/CustomSelect';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
//import TableWidget from '../../../widgets/TableWidget';
import api from '../../../../apis/api';
import { fetchDevices } from '../../../../actions/index';

class OptimizeTestState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: {},
      status: '',
      statusMessage: '',
      loading: false,
    };
  }

  handleRowSubmit = async (event) => {
    event.preventDefault();
    try {
      const url = `optimize/devices/${this.props.activeDevice}/test/setState`;
      this.setState({
        status: 'Success',
        statusMessage: 'Processing',
        loading: true,
      });
      await api.post(url, this.state.state);

      this.setState({
        status: 'Success',
        statusMessage: 'Changes Saved',
        loading: false,
      });
      this.props.fetchDevices(this.props.activeDevice);
      //window.location.reload();
    } catch (err) {
      this.setState({
        status: 'Failure',
        statusMessage: 'Failure!',
        loading: false,
      });
      console.log(err);
    }
  };

  processState(state) {
    switch (parseInt(state)) {
      case 1:
        return 'As Left';
      case 3:
        return 'As Found';
      default:
        return 'Unassigned';
    }
  }

  convertStateToNumber(state) {
    switch (state) {
      case 'As Left':
        return 1;
      case 'As Found':
        return 3;
      default:
        return 2;
    }
  }

  refreshStates() {
    let arr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].testIdHistory ? this.props.devices[this.props.activeDevice].testIdHistory : [];
    let stateArr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory ? this.props.devices[this.props.activeDevice].stateHistory : [];
    let stateObj = this.state.state;
    for (let i = 0; i < arr.length; i++) {
      const test = arr[i];
      const state = stateArr[i];
      stateObj[test] = state;
    }
    this.setState({ state: stateObj });
  }

  componentDidMount() {
    this.refreshStates();
  }

  processTimestampStateArray(arr, stateArr) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      const timestamp = new Date(parseInt(arr[i]) * 1000).toLocaleString();
      const state = this.processState(stateArr[i]);
      result.push({ test: timestamp, state: state });
    }
    return result;
  }

  createStateDropdown(currentTest, currentLabel, currentState) {
    //const { classes } = this.props;
    //console.log(currentTest);
    return (
      <CustomSelect
        single
        id='state'
        label={currentLabel}
        type='state'
        name='state'
        disabled={this.state.loading}
        //onCloseFunction={this.multiSelectOnClose}
        onChangeFunction={(event, value) => {
          const currentStates = this.state.state;
          //console.log(currentStates);
          currentStates[currentTest] = this.convertStateToNumber(value);
          this.setState(currentStates);
        }}
        values={this.processState(this.state.state[currentTest])}
        options={['As Found', 'As Left', 'Unassigned']}
        //value={null}
        helperText={null}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    const testOptions =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory
        ? this.processTimestampStateArray(this.props.devices[this.props.activeDevice].datetimeHistory, this.props.devices[this.props.activeDevice].stateHistory)
        : [];

    /*const device =
      !!this.props.devices && !!this.props.activeDevice
        ? this.props.devices[this.props.activeDevice]
        : null;*/
    const data = testOptions;
    for (let i = 0; i < testOptions.length; i++) {
      //data[i].state2 = data[i].state + ' test';
      data[i].state2 = this.createStateDropdown(this.props.devices[this.props.activeDevice].testIdHistory[i], data[i].test, data[i].state);
    }
    /*const headerArr = [
      // { id: 'test', label: 'Test' },
      { id: 'state2', label: 'State' },
    ];*/
    return (
      <React.Fragment>
        <form>
          <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
            Please Designate the Test States:
          </Typography>
          <Grid container style={{ marginBottom: 20 }}>
            {data.map((test, index) => {
              return (
                <Grid item key={index}>
                  {this.createStateDropdown(this.props.devices[this.props.activeDevice].testIdHistory[index], test.test, test.state)}
                </Grid>
              );
            })}
          </Grid>
          {/*<TableWidget
              xs={12}
              data={data}
              defaultRows={25}
              header={
                !this.props.devices[this.props.activeDevice] ? [] : headerArr
              }
              type='improvement'
            />*/}
          <Divider className={`${classes.dialogDivider} ${classes.accordionDialogDivider}`} style={{ paddingTop: 10 }}></Divider>
          <Grid container justifyContent='space-between' style={{ marginBottom: -5 }}>
            <Grid item>{this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginTop: 3 }}>{`${this.state.statusMessage}`}</Typography> : null}</Grid>
            <Grid item>
              <Button disabled={Object.keys(this.state.state).length === 0} color='secondary' type='text' value='Submit' onClick={this.handleRowSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    devices: state.devices.devices,
  };
};

export default connect(mapStateToProps, { fetchDevices })(withStyles(styles)(OptimizeTestState));
