import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Autocomplete, FormControl, TextField, Grid, Paper, Button, Divider, FormHelperText, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { styles } from '../styles';
import { connect } from 'react-redux';
//import functions from '../../functions/functions';
import { updateFilter } from '../../actions/index';

let obj = {};

//TODO FIX REPETITION OF OPTION SWITCH
//TODO NEED TO FIGURE OUT HOW TO HANDLE SPECIFIC FILTERS... PASS PARENT AS CHILD?
class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: !props.options ? [] : !this.props.selectedOption ? [] : this.props.selectedOption,
      open: false,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange = async (event, value, reason) => {
    //console.log('change');
    await this.setState({ values: value });
    if (!!this.props.onChangeFunction) {
      await this.props.onChangeFunction(event, value, reason);
      //duplication due to await
      if (reason === 'clear') {
        //when clearing values, consider that the same as a close event
        this.handleClose(event, true);
      }
    } else {
      obj[event.target.name] = event.target.value;
      await this.props.updateFilter(obj);
      //duplication due to await
      if (reason === 'clear') {
        this.handleClose(event, true);
      }
    }
  };
  /*handleEndPrioritize = (event) => {
    //console.log('Closed');
    this.props.deleteLoops();
    //TODO Finish with update of page
    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      'Device Type': this.props.sort.filter['Device Type'],
      Disruption: this.props.sort.filter.Disruption,
    };
    this.props.fetchLoops(
      0,
      this.props.table.pageSize,
      this.props.sort.sort,
      filters
    );
  };*/
  componentDidMount() {
    if (!!this.props.mountFunction) {
      this.props.mountFunction(this.props.mountParams);
    }
  }
  handleOpen() {
    //let values = !this.props.values ? this.state.values : this.props.values;
    if (!this.state.open && !this.props.disabled) {
      this.setState({ open: true });
    }
  }
  async handleClose(event, reason) {
    let changeOverride = false; //override variable to notify the close function changes have been made
    let tempObj = obj;
    if (reason === 'blur' && !!event.relatedTarget && event.relatedTarget.localName === 'button' && event.relatedTarget.innerText === 'Clear') {
      //attempt to bugfix when object contains other selects data
      obj = !tempObj[this.props.label] ? {} : tempObj[this.props.label];
      //clear button inside select clicked
      this.setState({ values: [] });
      obj[this.props.label] = [];
      await this.props.updateFilter(obj);
      changeOverride = true;
    } else if (reason === true) {
      //attempt to bugfix when object contains other selects data
      obj = !tempObj[this.props.label] ? {} : tempObj[this.props.label];
      // is true when clear is called
      this.setState({ values: [] });
      obj[event.target.name] = [];
      await this.props.updateFilter(obj);
    }
    this.setState({ open: false });
    if (!!this.props.onCloseFunction) {
      this.props.onCloseFunction(event, !reason ? null : this.props.label, changeOverride);
    }
  }

  render() {
    const { classes } = this.props;
    let options = !this.props.options || this.props.options.length === 0 ? [] : this.props.options;
    //TODO WHEN '' IS DISALLOWED FORCE REMOVAL
    //options = options.filter((option) => option !== '');
    let values = !this.props.values ? this.state.values : this.props.values;
    const PaperComponent = ({ children }) => {
      return (
        <Paper>
          {children}
          {!this.props.single ? (
            <React.Fragment>
              <Divider className={classes.dialogDivider}></Divider>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Button variant='text' disableElevation color='secondary' aria-label='clear' style={{ margin: '8px 0px 8px 8px' }}>
                    Clear
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='text'
                    disableElevation
                    onClick={() => {
                      console.log('clicked');
                    }}
                    color='secondary'
                    aria-label='submit'
                    style={{ margin: '8px 8px 8px 0px' }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : null}
        </Paper>
      );
    }; /*(
      <Paper>
        <Button>Test</Button>
      </Paper>
    );*/
    //const printStyles = functions.setPrintStyles(this.props.activeTab);
    //console.log(this.props.label, this.state.open);
    //console.log(this.props.options);
    return (
      <FormControl
        ref={this.multipleSelect}
        className={!this.props.inline ? classes.formSelect : classes.inlineFormSelect}
        disabled={this.props.disabled}
        onClick={this.handleOpen}
        error={!!this.props.errorMessage}
      >
        <Autocomplete
          multiple={!!this.props.single ? false : true}
          disableClearable={/*!this.props.single ? false : true*/ true}
          selectOnFocus={!!this.props.single ? false : true}
          disabled={this.props.disabled}
          value={values}
          id={`multiple-select-${this.props.label}`}
          clearIcon={<ClearIcon id={`multiple-select-${this.props.label}-option-clear`} fontSize='small' />}
          ChipProps={{
            onDelete: null,
            /*deleteIcon: (
                <ClearIcon
                  id={`multiple-select-${this.props.label}-option-removeSelected`}
                  fontSize='small'
                />
            ),*/
          }}
          name={this.props.label}
          open={this.state.open}
          //openOnFocus={true}
          options={options}
          isOptionEqualToValue={
            !!this.props.isOptionEqualToValue
              ? this.props.isOptionEqualToValue
              : (option, value) => {
                  return !option.value ? option === value : option.value;
                }
          }
          getOptionLabel={
            !this.props.getOptionLabel
              ? (option) => {
                  return typeof option !== 'string'
                    ? 'error'
                    : option === ''
                    ? '(Blank)'
                    : `${!this.props.prefix ? '' : this.props.prefix}` + option + `${!this.props.suffix ? '' : this.props.suffix}`;
                }
              : this.props.getOptionLabel
          }
          PaperComponent={PaperComponent}
          onChange={this.handleChange}
          onClose={this.handleClose}
          /*disabled={
            this.props.disabled ||
            ((!this.props.options || this.props.options.length === 0) &&
              values.length === 0)
          }*/
          getOptionDisabled={(option) => (!this.props.getOptionDisabled ? false : this.props.getOptionDisabled(option))}
          disableCloseOnSelect={!!this.props.single ? false : true}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={!this.props.variant ? 'standard' : this.props.variant}
              placeholder={`Select ${this.props.label}`}
              style={{ cursor: 'pointer' }}
              label={!this.props.inline ? this.props.label : null}
              InputLabelProps={{
                classes: {
                  root: !this.props.variant === 'outlined' || !this.props.noPaper ? null : classes.inputLabelFixNoPaper,
                },
              }}
              InputProps={
                this.props.icon
                  ? {
                      ...params.InputProps,
                      startAdornment: <InputAdornment position='start'>{this.props.icon}</InputAdornment>,
                    }
                  : !this.props.inline
                  ? { ...params.InputProps }
                  : {
                      ...params.InputProps,
                      classes: { root: classes.customSelectFilterInline },
                    }
              }
            />
          )}
          style={{ minWidth: !this.props.width ? null : this.props.width }}
        />

        {/*<Select
          multiple
          name={this.props.label}
          value={!this.props.sort.filter[this.props.label] ? [] : this.props.sort.filter[this.props.label]}
          onClose={this.handleClose}
          onChange={!this.props.onChangeFunction ? this.handleChange : this.props.onChangeFunction}
          renderValue={(selected) => {
            let str = selected.join(', ');
            let needle = /, $/;
            str = str.replace(needle, '');
            return str;
          }}
          MenuProps={MenuProps}
          open={this.state.open}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={!this.props.sort.filter[this.props.label] ? false : this.props.sort.filter[this.props.label].indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
          <Grid container justifyContent='space-between'>
            <Grid item></Grid>
            <Grid item>
              <Button variant='contained' disableElevation size='small' color='secondary' aria-label='submit' type='submit' style={{ margin: 10, display: 'block' }} onClick={this.handleClose}>
                Submit
              </Button>
            </Grid>
          </Grid>
          </Select>*/}
        {!!this.props.errorMessage && <FormHelperText>{this.props.errorMessage}</FormHelperText>}
      </FormControl>
    );
  }
}

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    //sort: state.sort,
    //table: state.table,
  };
};

export default connect(mapStateToProps, {
  updateFilter,
})(withStyles(styles)(CustomSelect));
