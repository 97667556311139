import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { Button, Typography, DialogContentText, TextField } from '@mui/material';
import { Link } from '@mui/icons-material';
import CustomSelect from '../../../filters/CustomSelect';
import initializeTheme from '../../../theme.js';
import { Grid } from '@mui/material';
//import { Tooltip } from '@mui/material';
//import { ListItem } from '@mui/material';
import DatePicker from '../../../filters/DatePicker';
//import { Checkbox } from '@mui/material';
import { RestartAlt, CheckCircle, Undo, Edit, Delete, ThumbUpAlt, ThumbDownAlt } from '@mui/icons-material';
import WidgetTitle from '../../../headers/WidgetTitle';
import TableWidget from '../../../widgets/TableWidget';
//import Autocomplete from '@mui/material/Autocomplete';
//import TextField from '@mui/material/TextField';
import DropdownMenu from '../../../menus/DropdownMenu';
import functions from '../../../../functions/functions.js';
import CustomDialog from '../../../popup/CustomDialog';
import api from '../../../../apis/api';
import Highcharts from 'highcharts';
import {
  getSummaryReport,
  getImprovementsReport,
  fetchLoopActionItems,
  fetchSites,
  fetchDeviceTypes,
  fetchApplications,
  fetchLocations,
  fetchImportances,
  fetchEvents,
  fetchIssueTypes,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  updateSort,
  updateFilter,
  updateDateRange,
  deleteLoops,
  deleteDevices,
  setModule,
  prioritizeSetActiveLoop,
  clearFilter,
  approveImprovement,
} from '../../../../actions/index';
import ReportChartWidget from '../../../widgets/ReportChartWidget';

const headerArr = [
  { id: 'improvementFormatted', label: 'Action Item' },
  //combined as deviceFormatted
  //{ id: 'name', label: 'Device' },
  //{ id: 'type', label: 'Device Type' },
  { id: 'deviceFormatted', label: 'Device' },
  { id: 'issueFormatted', label: 'Issue' },
  //{ id: 'improvementType', label: 'Action Item Type' },
  { id: 'comment', label: 'Comment' },
  { id: 'importanceFormatted', label: 'Importance' },
  //combined as statusFormatted
  //{ id: 'status', label: 'Status' },
  //{ id: 'statusDate', label: 'Status Date' },
  { id: 'statusFormatted', label: 'Status' },
  { id: 'contact', label: 'Contact' },
  { id: 'reportLink', label: 'View' },
  { id: 'menu', label: 'Update Status' },
];
/*const data = [
  {
    id: 1,
    action: 'Review Data',
    name: 'C9_AO1_1530',
    type: 'Level',
    issue: 'Loop Error',
    comment:
      'This device appears stuck. Repair or replace at the next turnaround.',
    status: 'Approved',
    statusDate: '5/1/2021',
    contact: 'John Engineer',
    reportLink: '',
  },
];*/

class ActionReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: '',
      orderBy: '',
      status: [],
      loading: true,
      changes: false,
      gridHeight: null,
      issueTypeZoom: false,
      actionTypeZoom: false,
      issueProgressZoom: false,
      issueTypeWidth: 4,
      actionTypeWidth: 4,
      issueProgressWidth: 4,
      approvalOpen: false,
      approvalName: '',
      approvalEmail: '',
      approvalLoop: -1,
      approvalId: null,
      approvalType: '',
      deleteId: null,
      deleteOpen: false,
      filter: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleApproval = this.handleApproval.bind(this);
    this.openApproval = this.openApproval.bind(this);
    this.closeApproval = this.closeApproval.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.openDelete = this.openDelete.bind(this);
    this.closeDelete = this.closeDelete.bind(this);
    this.processStatus = this.processStatus.bind(this);
  }
  async resetFilters() {
    this.setState({ changes: true });
    await this.props.clearFilter();
    this.multiSelectOnClose();
  }
  async componentDidMount() {
    if (this.state.currentModule !== 'Prioritize') {
      this.props.setModule('Prioritize');
      this.props.updateSort('');
    }
    this.props.prioritizeSetActiveLoop('');
    this.props.deleteLoops();
    this.props.deleteDevices();
    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      //Disruption: this.props.sort.filter.Disruption,
      //startDate: this.props.sort.filter.Range.startDate,
      //endDate: this.props.sort.filter.Range.endDate,
      Devicetypes: this.props.sort.filter['Device Types'],
      //Issuetypes: this.props.sort.filter['Issue Types'],
      Importance: this.props.sort.filter.Importance,
      //SpecificDevice: this.props.sort.filter.SpecificDevice,
      //Concerns: JSON.stringify(this.props.sort.concern),
    };
    /*

    this.props
      .fetchLoops(
        this.state.page,
        this.props.table.pageSize,
        this.props.sort.sort,
        filters
      )
      .then(() => {
        this.setState({
          //page,
          //rowsPerPage,
          loading: false,
        });
      });*/
    this.setState({
      loading: true,
    });
    this.props.fetchSites();
    this.props.fetchApplications();
    this.props.fetchLocations();
    this.props.fetchDeviceTypes();
    this.props.fetchImportances();
    this.props.fetchIssueTypes();
    await this.props.fetchLoopActionItems(null, filters);
    this.setState({
      loading: false,
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  multiSelectOnClose = async (event, label, changesOverride) => {
    if (this.state.changes || changesOverride) {
      //99.9% SAME AS SETDEVICESPECIFIC
      //console.log('Closed');
      //console.log(this.props.sort);

      //FIX FOR ACTION TYPES FILTER
      let obj = { ...this.props.sort.filter };
      this.setState({ filter: obj });

      let filters = {
        Application: this.props.sort.filter.Application,
        Site: this.props.sort.filter.Site,
        Location: this.props.sort.filter.Location,
        //Disruption: this.props.sort.filter.Disruption,
        //startDate: this.props.sort.filter.Range.startDate,
        //endDate: this.props.sort.filter.Range.endDate,
        Devicetypes: this.props.sort.filter['Device Types'],
        //Issuetypes: this.props.sort.filter['Issue Types'],
        Importance: this.props.sort.filter.Importance,
        //SpecificDevice: this.props.sort.filter.SpecificDevice,
        //Concerns: JSON.stringify(this.props.sort.concern),
      };
      if (
        !(
          !filters.Application &&
          //!filters.Disruption &&
          !filters.Devicetypes &&
          !filters.Issuetypes &&
          !filters.Importance &&
          !filters.Location &&
          !filters.Site &&
          //!filters.Concerns &&
          this.props.auth.require_filter
        )
      ) {
        this.props.deleteDevices();
        this.props.deleteLoops();
        //TODO Finish with update of page

        //console.log(filters);
        //console.log(this.props.sort.filter);
        this.setState({ loading: true });
        this.props.setTablePage(0);
        //if (!this.props.locations || Object.entries(this.props.locations).length === 0) {
        // if (label !== 'Location') {
        this.props.fetchLocations(null, filters);
        // }
        //}

        //if (!this.props.applications || Object.entries(this.props.applications).length === 0) {
        // if (label !== 'Application') {
        this.props.fetchApplications(null, filters);
        //}
        // }

        //// if (label !== 'Importance') {
        this.props.fetchImportances(null, filters);
        //}
        //}

        //if (!this.props.sites || Object.entries(this.props.sites).length === 0) {
        // if (label !== 'Site') {
        this.props.fetchSites(null, filters);
        // }
        //}
        // if (label !== 'Device Types') {
        this.props.fetchDeviceTypes(null, filters);
        // }
        // if (label !== 'Issue Types') {
        this.props.fetchIssueTypes(null, filters);
        // }
        this.props.fetchLoopActionItems(null, filters).then(() => {
          this.setState({
            //page,
            //rowsPerPage,
            loading: false,
            changes: false,
          });
        });
        //await this.props.fetchDevicesSummary();
        //console.log(filters);
        /* this.props
          .fetchLoops(
            0,
            this.props.table.pageSize,
            this.props.sort.sort,
            filters
          )
          .then(() => {
            this.setState({
              //page,
              //rowsPerPage,
              loading: false,
              changes: false,
            });
          });*/
      }
    }
  };
  multiSelectOnChange = async (event, value, type) => {
    let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    obj[name] = value;
    this.props.updateFilter(obj);
    this.setState({ changes: true });
  };
  updateDateRange = async (value, type) => {
    if (functions.isValidDate(value)) {
      //do nothing if the date isn't valid

      if (type === 'start') {
        this.props.updateDateRange({
          startDate: Math.floor(value.getTime() / 1000),
          endDate: this.props.sort.filter.Range.endDate,
        });
      } else {
        this.props.updateDateRange({
          startDate: this.props.sort.filter.Range.startDate,
          endDate: Math.floor(value.getTime() / 1000),
        });
      }
      let filters = {
        Application: this.props.sort.filter.Application,
        Site: this.props.sort.filter.Site,
        Location: this.props.sort.filter.Location,
        //Disruption: this.props.sort.filter.Disruption,
        Devicetypes: this.props.sort.filter['Device Types'],
        Issuetypes: this.props.sort.filter['Issue Types'],
        Importance: this.props.sort.filter.Importance,
      };
      if (type === 'start') {
        filters.startDate = Math.floor(value.getTime() / 1000);
        filters.endDate = this.props.sort.filter.Range.endDate;
      } else {
        filters.startDate = this.props.sort.filter.Range.startDate;
        filters.endDate = Math.floor(value.getTime() / 1000);
      }
      if (!(!filters.Application && !filters.Disruption && !filters.Importance && !filters.Location && !filters.Site && this.props.auth.require_filter)) {
        this.props.deleteDevices();
        this.props.deleteLoops();

        //TODO Finish with update of page

        //console.log(filters);
        //console.log(this.props.sort.filter);
        this.setState({
          loading: true,
        });
        this.props.fetchLoopActionItems(null, filters).then(() => {
          this.setState({
            //page,
            //rowsPerPage,
            loading: false,
            changes: false,
          });
        });
      }
    }
  };

  processStatus(data) {
    if (data.notApproved === 1) {
      return {
        status: 'Not Approved',
        contact: data.notApprovedBy,
        user: data.notApprovedByUser,
        date: data.notApprovedByDate,
      };
    } else if (data.completed === 1) {
      return {
        status: 'Completed',
        contact: data.completedBy,
        user: data.completedByUser,
        date: data.completedByDate,
      };
    } else if (data.approved === 1) {
      return {
        status: 'Approved',
        contact: data.approvedBy,
        user: data.approvedByUser,
        date: data.approvedByDate,
      };
    } else {
      return {
        status: 'Pending',
        contact: '-',
        user: data.user,
        date: data.date,
      };
    }
  }
  getUniqueValues = (data, prop) => {
    let uniqueValues = [];
    for (let i = 0; i < data.length; i++) {
      if (uniqueValues.indexOf(data[i][prop]) === -1 && !!data[i][prop]) {
        uniqueValues.push(data[i][prop].toString());
      } else if (!data[i][prop]) {
        console.log(`Error: Undefined in getUniqueValues() for ${prop}`);
      }
    }
    return uniqueValues;
  };

  processData = () => {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    const loops = this.props.loops;
    const loopKeys = Object.keys(loops);
    let data = [];
    let statusSeries = [];
    let statusObj = {};
    let actionTypeSeries = [];
    let actionTypeObj = {};
    let issueTypeSeries = [];
    let issueTypeObj = {};
    let importanceSeries = [];
    let importanceObj = {};
    for (let i = 0; i < loopKeys.length; i++) {
      if (!!loops[loopKeys[i]].improvements) {
        let improvements = loops[loopKeys[i]].improvements;
        for (let j = 0; j < improvements.length; j++) {
          let improvement = improvements[j];
          let status = this.processStatus(improvement);
          improvement.importance = improvement.level;
          improvement.importanceFormatted = functions.importanceToString(improvement.level);
          let statusCheck = !this.state.filter['Status'] || this.state.filter['Status'].includes(status.status) || this.state.filter['Status'].length === 0;
          let issue = improvement.issue_id.replace(improvement.loopId, '').replace('null', 'No Issue');
          let issueCheck = !this.state.filter['Issue Types'] || this.state.filter['Issue Types'].includes(issue) || this.state.filter['Issue Types'].length === 0;
          let actionTypeCheck =
            !this.state.filter['Action Item Types'] || this.state.filter['Action Item Types'].includes(improvement.improvementType) || this.state.filter['Action Item Types'].length === 0;
          let importanceStr = functions.importanceToString(improvement.level);
          if (statusCheck && issueCheck && actionTypeCheck) {
            if (!statusObj[status.status]) {
              statusObj[status.status] = 1;
            } else {
              statusObj[status.status]++;
            }
            if (!actionTypeObj[improvement.improvementType]) {
              actionTypeObj[improvement.improvementType] = 1;
            } else {
              actionTypeObj[improvement.improvementType]++;
            }
            if (!importanceObj[importanceStr]) {
              importanceObj[importanceStr] = 1;
            } else {
              importanceObj[importanceStr]++;
            }
            improvement.issueFormatted = improvement.issue_id.replace(improvement.loopId, '').replace('null', '');
            let tempIssue = improvement.issueFormatted === '' ? 'No Issue' : improvement.issueFormatted;
            if (!issueTypeObj[tempIssue]) {
              issueTypeObj[tempIssue] = 1;
            } else {
              issueTypeObj[tempIssue]++;
            }
          }
          if (
            improvement.Datetime >= this.props.sort.filter.Range.startDate &&
            improvement.Datetime <= this.props.sort.filter.Range.endDate &&
            (!this.state.filter['Issue Types'] || this.state.filter['Issue Types'].length === 0 || this.state.filter['Issue Types'].indexOf(improvement.issueFormatted) !== -1)
          ) {
            improvement.deviceFormatted = (
              <React.Fragment>
                <Typography variant='subtitle2'>{improvement.deviceName}</Typography>
                <Typography variant='subtitle2' style={{ marginTop: 4 }}>
                  {improvement.deviceType}
                </Typography>
              </React.Fragment>
            );
            improvement.improvementFormatted = (
              <React.Fragment>
                <Typography variant='subtitle2'>{improvement.improvement}</Typography>
                <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                  {improvement.improvementType}
                </Typography>
              </React.Fragment>
            );
            improvement.device = improvement.deviceType;
            improvement.statusFormatted = (
              <React.Fragment>
                <Typography
                  variant='subtitle2'
                  className={
                    status.status === 'Not Approved'
                      ? classes.redText
                      : status.status === 'Approved'
                      ? classes.blueText
                      : status.status === 'Completed'
                      ? classes.greenText
                      : status.status === 'Pending'
                      ? classes.grayText
                      : null
                  }
                >
                  {status.status}
                </Typography>
                <Typography variant='subtitle2'>{functions.dateToString(status.date * 1000, 'date')}</Typography>
              </React.Fragment>
            );
            improvement.contact = status.contact;
            improvement.status = status.status;
            improvement.menu = this.createMenu(improvement);
            const href =
              window.location.host === 'localhost:3000'
                ? `http://${window.location.host}/prioritize/${improvement.loopId}/Actions`
                : `https://${window.location.host}/prioritize/${improvement.loopId}/Actions`;
            improvement.reportLink = (
              <Button variant='outlined' size='medium' color='primary' className={classes.button} href={href}>
                <Link className={classes.buttonIcon}></Link>Link
              </Button>
            );
            data.push(improvement);
            // console.log(improvement);
          }
        }
      }
    }
    //let im;
    let statusObjKeys = Object.keys(statusObj);
    for (let k = 0; k < statusObjKeys.length; k++) {
      statusSeries.push({
        name: statusObjKeys[k],
        y: statusObj[statusObjKeys[k]],
        color:
          statusObjKeys[k] === 'Approved'
            ? theme.palette.blue.color
            : statusObjKeys[k] === 'Not Approved'
            ? theme.palette.red.color
            : statusObjKeys[k] === '-'
            ? theme.palette.gray.light
            : statusObjKeys[k] === 'Completed'
            ? theme.palette.green.color
            : statusObjKeys[k] === 'Pending'
            ? theme.palette.gray.dark
            : theme.palette.gray.dark,
      });
    }
    let actionTypeObjKeys = Object.keys(actionTypeObj);
    for (let k = 0; k < actionTypeObjKeys.length; k++) {
      actionTypeSeries.push({ name: actionTypeObjKeys[k], y: actionTypeObj[actionTypeObjKeys[k]] });
    }
    let issueTypeObjKeys = Object.keys(issueTypeObj);
    for (let k = 0; k < issueTypeObjKeys.length; k++) {
      issueTypeSeries.push({ name: issueTypeObjKeys[k], y: issueTypeObj[issueTypeObjKeys[k]] });
    }
    let importanceObjKeys = Object.keys(importanceObj);
    for (let k = 0; k < importanceObjKeys.length; k++) {
      importanceSeries.push({ name: importanceObjKeys[k], y: importanceObj[importanceObjKeys[k]] });
    }
    issueTypeSeries.sort(function (a, b) {
      return b.y - a.y;
    });
    actionTypeSeries.sort(function (a, b) {
      return b.y - a.y;
    });
    statusSeries.sort(function (a, b) {
      return b.y - a.y;
    });
    importanceSeries.sort(function (a, b) {
      return b.y - a.y;
    });
    /*console.log(statusObj);
    console.log(actionTypeObj);
    console.log(issueTypeObj);
    console.log(this.state.status);*/
    data = data.filter((row) => {
      let statusCheck = !this.state.filter['Status'] || this.state.filter['Status'].includes(row.status) || this.state.filter['Status'].length === 0;
      let issue = row.issue_id.replace(row.loopId, '').replace('null', 'No Issue');
      let issueCheck = !this.state.filter['Issue Types'] || this.state.filter['Issue Types'].includes(issue) || this.state.filter['Issue Types'].length === 0;
      let actionTypeCheck = !this.state.filter['Action Item Types'] || this.state.filter['Action Item Types'].includes(row.improvementType) || this.state.filter['Action Item Types'].length === 0;

      return statusCheck && issueCheck && actionTypeCheck;
    });
    return { data, statusSeries, actionTypeSeries, issueTypeSeries, importanceSeries };
  };
  editButton(id, user, userId) {
    //console.log(id, approval);
    //console.log(approval === null);
    const { classes } = this.props;
    //const action = approval === null;
    const button =
      userId === user ? (
        <Button
          id={id}
          color={userId === user ? `primary` : `secondary`}
          className={classes.button}
          variant='outlined'
          onClick={(e) => {
            //console.log(e);
            //console.log(id);

            //console.log('edit');
            this.processEdit(id);
            //this.handleApproval(id, action);
          }}
        >
          <Edit className={classes.buttonIcon}></Edit>
        </Button>
      ) : null;
    return button;
  }
  openApproval() {
    this.setState({ approvalOpen: true, status: '', statusMessage: '' });
  }
  closeApproval() {
    this.setState({
      approvalOpen: false,
      approvalName: '',
      approvalEmail: '',
      approvalId: null,
      approvalType: '',
    });
  }
  approvalDialog() {
    const { classes } = this.props;
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    return (
      <CustomDialog
        center
        open={this.state.approvalOpen}
        onClose={this.closeApproval}
        title='Contact'
        content={
          <React.Fragment>
            <DialogContentText style={{ marginTop: 20 }}>Please enter the name of the contact person.</DialogContentText>
            <form>
              <TextField
                autoFocus
                id='approvalName'
                label='Name'
                name='approvalName'
                fullWidth
                //multiline
                //rows={4}
                //defaultValue=''
                //variant="outlined"
                value={this.state.approvalName}
                onChange={this.handleInputChange}
                className={classes.textField}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
              <TextField
                autoFocus
                id='approvalEmail'
                label='Email'
                name='approvalEmail'
                fullWidth
                type='email'
                //multiline
                //rows={4}
                //defaultValue=''
                //variant="outlined"
                value={this.state.approvalEmail}
                onChange={this.handleInputChange}
                className={classes.textField}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </form>
            {this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginRight: 20 }}>{`${this.state.statusMessage}`}</Typography> : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.closeApproval} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.handleApproval(true, false, false, this.state.approvalLoop);
              }}
              color='primary'
            >
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  openDelete() {
    this.setState({ deleteOpen: true, status: '', statusMessage: '' });
  }
  closeDelete() {
    this.setState({
      deleteOpen: false,
      deleteId: null,
    });
  }
  async handleDelete(action, rowId) {
    try {
      const id = !rowId ? this.state.deleteId : rowId;
      const url = `/${this.props.currentModule}/loops/${this.props.activeLoop}/improvements/delete`; /*this.props.fetchDeviceImprovements(
      this.props.activeDevice
    );
    this.setState({ open: false });*/
      //console.log({ rowId: id });
      await api.post(url, {
        rowId: id,
      }); //console.log(response);
      this.props.deleteImprovement(id);
      this.setState({
        deleteId: null,
        deleteOpen: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
        //loading: false,
      });
    }
    /*const response = */
  }
  deleteDialog() {
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    //const { classes } = this.props;
    return (
      <CustomDialog
        center
        open={this.state.deleteOpen}
        onClose={this.closeDelete}
        title='Delete Improvement'
        content={
          <React.Fragment>
            <Typography style={{ marginTop: 20 }}>Are you sure you want to delete this improvement?</Typography>
            {this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginRight: 20 }}>{`${this.state.statusMessage}`}</Typography> : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.closeDelete} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.handleDelete(true);
              }}
              color='primary'
            >
              Delete
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  async handleApproval(action, rowId, type, loop) {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    let validEmail = true;
    if (!regex.test(this.state.approvalEmail.replace(/\s/g, '')) && this.state.approvalEmail !== '') {
      validEmail = false;
    }
    if (validEmail) {
      try {
        const id = !rowId ? this.state.approvalId : rowId;
        const url = `/prioritize/loops/${loop}/improvements/approve`; /*this.props.fetchDeviceImprovements(
      this.props.activeDevice
    );
        
    this.setState({ open: false });*/

        const finalType = !type ? this.state.approvalType : type;
        //console.log(type, finalType);

        await api.post(url, {
          rowId: id,
          action,
          name: this.state.approvalName,
          email: this.state.approvalEmail,
          type: finalType,
        }); //console.log(response);
        this.props.approveImprovement(id, action, this.state.approvalName, this.state.approvalEmail, finalType, null, loop);
        this.setState({
          approvalId: null,
          approvalOpen: false,
          approvalName: '',
          approvalEmail: '',
          approvalType: '',
          status: '',
          statusMessage: '',
        });
        //console.log(action, url);
      } catch (e) {
        console.log(e);
        this.setState({
          status: 'Failure',
          statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
          //loading: false,
        });
      }
    } else {
      this.setState({
        status: 'Failure',
        statusMessage: `Invalid Email Format`,
      });
    }
    /*const response = */
  }
  createMenu(row) {
    let menuOptions = [];
    const approved = row.approved === 1;
    const notApproved = row.notApproved === 1;
    const completed = row.completed === 1;
    const base = !approved && !notApproved && !completed;
    if ((base || approved) && !completed && !notApproved) {
      if (row.approved === 0 || row.approved === null) {
        //Approve if unapproved, unapprove if approved
        menuOptions.push({
          id: row.id,
          title: 'Approve',
          icon: ThumbUpAlt,
          onClick: () => {
            this.setState({
              approvalId: row.id,
              approvalType: `approved`,
              approvalLoop: row.loopId,
            });
            this.openApproval();
          },
        });
      } else {
        menuOptions.push({
          id: row.id,
          title: 'Undo Approval',
          icon: Undo,
          onClick: (id) => {
            //console.log('Time to Unapprove! ' + row.id);
            //this.setState({ approvalType: 'approve' });
            this.handleApproval(false, row.id, `approved`, row.loopId);
          },
        });
      }
    }
    if ((base || approved || notApproved) && !completed) {
      if (row.notApproved === 0 || row.notApproved === null) {
        menuOptions.push({
          id: row.id,
          title: 'Not Approved',
          icon: ThumbDownAlt,
          onClick: () => {
            //console.log('Time to cancel ' + row.id);
            this.setState({
              approvalId: row.id,
              approvalType: `notApproved`,
              approvalLoop: row.loopId,
            });
            this.openApproval();
            //this.setState({ approvalId: row.id });
            //this.openApproval();
          },
        });
      } else {
        menuOptions.push({
          id: row.id,
          title: 'Undo Not Approved',
          icon: Undo,
          onClick: (id) => {
            //console.log('Time to Unapprove! ' + row.id);
            //this.setState({ approvalType: 'approve' });
            this.handleApproval(false, row.id, `notApproved`, row.loopId);
          },
        });
      }
    }
    if (!notApproved) {
      if (row.completed === 0 || row.completed === null) {
        menuOptions.push({
          id: row.id,
          title: 'Complete',
          icon: CheckCircle,
          onClick: () => {
            //console.log('Time to complete ' + row.id);
            this.setState({
              approvalId: row.id,
              approvalType: `completed`,
              approvalLoop: row.loopId,
            });
            this.openApproval();
            //this.setState({ approvalId: row.id });
            //this.openApproval();
          },
        });
      } else {
        menuOptions.push({
          id: row.id,
          title: 'Undo Completed',
          icon: Undo,
          onClick: (id) => {
            //console.log('Time to Unapprove! ' + row.id);
            //this.setState({ approvalType: 'approve' });
            this.handleApproval(false, row.id, `completed`, row.loopId);
          },
        });
      }
    }
    if (this.props.userId === row.user) {
      //permissioning for edit capability, logged in user is the same as the writer. // TODO needs same check on backend
      menuOptions.push({
        id: row.id,
        title: 'Edit',
        icon: Edit,
        onClick: (e) => {
          this.processEdit(row.id);
        },
      });
      menuOptions.push({
        id: row.id,
        title: 'Delete',
        icon: Delete,
        onClick: (e) => {
          this.setState({ deleteId: row.id });
          this.openDelete();
          //this.processDelete(row.id);
        },
      });
    }
    return <DropdownMenu text='Update Status' tooltip='Update Status' helperText='Select to update status' options={menuOptions} />;
  }
  render() {
    const { classes } = this.props;
    //const theme = initializeTheme(this.props.colorMode);
    let { data, /*statusSeries,*/ actionTypeSeries, issueTypeSeries, importanceSeries } = this.processData();
    let actionItemTypes = this.getUniqueValues(data, 'improvementType');
    const monoColors = Highcharts.getOptions().colors.map((c, i) =>
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      Highcharts.color(Highcharts.getOptions().colors[0])
        .brighten((i - 6) / 16)
        .get()
    );
    const monoColors2 = Highcharts.getOptions().colors.map((c, i) =>
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      Highcharts.color(Highcharts.getOptions().colors[7])
        .brighten((i - 3) / 12)
        .get()
    );
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    //console.log(new Date(this.props.sort.filter.Range.startDate * 1000));
    return (
      <React.Fragment>
        <form className={classes.form} autoComplete='off'>
          <Grid container>
            <DatePicker
              label='Start Date'
              disabled={this.state.loading}
              date={new Date(this.props.sort.filter.Range.startDate * 1000)}
              value={new Date(this.props.sort.filter.Range.startDate * 1000)}
              dateFunction={(startDate) => {
                this.updateDateRange(startDate, 'start');
              }}
            ></DatePicker>
            <DatePicker
              label='End Date'
              disabled={this.state.loading}
              date={new Date(this.props.sort.filter.Range.endDate * 1000)}
              value={new Date(this.props.sort.filter.Range.endDate * 1000)}
              dateFunction={(endDate) => {
                this.updateDateRange(endDate, 'end');
              }}
            ></DatePicker>
          </Grid>
          <CustomSelect
            id={null}
            label='Site'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter.Site ? [] : this.props.sort.filter.Site}
            options={!this.props.sites || !this.props.sites.list ? [] : this.props.sites.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Location'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter.Location ? [] : this.props.sort.filter.Location}
            options={!this.props.locations || !this.props.locations.list ? [] : this.props.locations.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Application'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter.Application ? [] : this.props.sort.filter.Application}
            options={!this.props.applications || !this.props.applications.list ? [] : this.props.applications.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Importance'
            disabled={this.state.loading}
            values={!this.props.sort.filter.Importance ? [] : this.props.sort.filter.Importance}
            options={!this.props.importances || !this.props.importances.list ? [] : this.props.importances.list.sort(functions.sortImportance)}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            value={null}
            helperText={null}
          />
          {/* TODO Prio_1.0<CustomSelect
            id={null}
            label='Action Item Type'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //values={!this.props.sort.filter['Issue Types'] ? [] : this.props.sort.filter['Issue Types']}
            options={['Investigate', 'On-line Improvement', 'Outage Required', 'Application Shutdown', 'Plant Shutdown Required', 'Note']}
            value={null}
            helperText={null}
            />*/}
          <CustomSelect
            id={null}
            label='Issue Types'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter['Issue Types'] ? [] : this.props.sort.filter['Issue Types']}
            options={!this.props.issuetypes || !this.props.issuetypes.list ? [] : this.props.issuetypes.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Device Types'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter['Device Types'] ? [] : this.props.sort.filter['Device Types']}
            options={!this.props.devicetypes || !this.props.devicetypes.list ? [] : this.props.devicetypes.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Status'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            values={!this.props.sort.filter['Status'] ? [] : this.props.sort.filter['Status']}
            /*onChangeFunction={(event, value, type) => {
              this.setState({ status: value });
            }}*/
            //type='unit'
            //values={!this.state.status ? [] : this.state.status}

            options={['Pending', 'Approved', 'Not Approved', 'Completed']}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Action Item Types'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            values={!this.props.sort.filter['Action Item Types'] ? [] : this.props.sort.filter['Action Item Types']}
            /*onChangeFunction={(event, value, type) => {
              this.setState({ status: value });
            }}*/
            //type='unit'
            //values={!this.state.status ? [] : this.state.status}

            options={actionItemTypes}
            value={null}
            helperText={null}
          />
          <div style={{ float: 'right' }}>
            <Button className={`${classes.buttonFilterRight} ${classes.printHide}`} variant='outlined' onClick={this.resetFilters} style={{ float: 'right' }}>
              <RestartAlt className={classes.buttonIcon}></RestartAlt>Clear All Filters
            </Button>
          </div>
        </form>
        <Grid container spacing={1}>
          <ReportChartWidget name='Importance' data={importanceSeries} type='pie' colors={monoColors} initialWidth={printStyles ? 6 : 4} loading={this.state.loading}></ReportChartWidget>

          <ReportChartWidget name='Action Item Types' data={actionTypeSeries} type='pie' colors={monoColors2} initialWidth={printStyles ? 6 : 4} loading={this.state.loading}></ReportChartWidget>
          <ReportChartWidget name='Issue Types' data={issueTypeSeries} type='pie' colors={monoColors} initialWidth={printStyles ? 12 : 4} loading={this.state.loading}></ReportChartWidget>
          {/*<ReportChartWidget
            name='Issue Progress'
            data={statusSeries}
            type='pie'
            colors={[theme.palette.gray.dark, theme.palette.green.color, theme.palette.secondary.main, theme.palette.red.color]}
            initialWidth={printStyles ? 6 : 4}
            loading={this.state.loading}
          ></ReportChartWidget>*/}
        </Grid>
        <div className={classes.avoidBreak}>
          <WidgetTitle
            title={`Action Items for ${functions.dateToString(this.props.sort.filter.Range.startDate * 1000, 'date')} - ${functions.dateToString(this.props.sort.filter.Range.endDate * 1000, 'date')}`}
          />
          <TableWidget
            header={headerArr}
            data={data}
            orderBy={'importance' /*['importance', 'issue'] /* DOES NOT WORK WITH TIEBREAKERS, SEE Table.js LINE 16 */}
            order={'desc'}
            defaultRows={25}
            loading={this.state.loading}
          ></TableWidget>
        </div>
        {this.approvalDialog()}
        {this.deleteDialog()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //reports: state.reports.report_data,
    auth: state.auth,
    applications: state.applications,
    importances: state.importances,
    issuetypes: state.issuetypes,
    locations: state.locations,
    devicetypes: state.devicetypes,
    sites: state.sites,
    loops: state.loops.loops,
    sort: state.sort,
    colorMode: state.colorMode.colorMode,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {
  getImprovementsReport,
  getSummaryReport,
  fetchLoopActionItems,
  fetchSites,
  fetchDeviceTypes,
  fetchApplications,
  fetchLocations,
  fetchImportances,
  fetchEvents,
  fetchIssueTypes,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  updateSort,
  updateFilter,
  deleteLoops,
  deleteDevices,
  setModule,
  updateDateRange,
  prioritizeSetActiveLoop,
  clearFilter,
  approveImprovement,
})(withStyles(styles)(ActionReport));
