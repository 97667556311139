const diagnosticsArray = [
  {
    title: 'General Testing',
    checks: [
      { subtitle: 'Data Validity', nickname: 'Data Validity', check: 'ValidityCheck' },
      { subtitle: 'Calibration', nickname: 'Calibration', check: 'CalibrationCheck' },
      { subtitle: 'Signal Span Incorrect', nickname: 'Signal Span', check: 'SignalSourceCheck' },
      { subtitle: 'Nominal Travel', nickname: 'Nominal Travel', check: 'TravelCheck' },
      { subtitle: 'Air Action Incorrect', nickname: 'Air Action', check: 'AirDirCheck' },
      { subtitle: 'Fail Action Incorrect', nickname: 'Fail Action', check: 'FailDirCheck' },
    ],
  },
  {
    title: 'I/P Testing',
    checks: [
      { subtitle: 'I/P Calibration', nickname: 'I/P Calibration', check: 'IPCheck' },
      { subtitle: 'I/P Inaccurate', nickname: 'I/P Inaccurate', check: 'IPNoise' },
    ],
  },
  {
    title: 'Positioner Testing',
    checks: [
      { subtitle: 'Positioner Cutoff', nickname: 'Positioner Cutoff', check: 'CutoffCheck' },
      { subtitle: 'Incorrect Flow Characteristic', nickname: 'Flow Characteristic', check: 'CamCheck' },
      //{ subtitle: 'Inconsistent Positioner Feedback', nickname: 'Poisitioner Feedback', check: 'LinkageCheck' },
      { subtitle: 'Poor Positioner Performance', nickname: 'Positioner Perf.', check: 'LagCheck' },
    ],
  },
  {
    title: 'Air Supply Testing',
    checks: [
      { subtitle: 'Air Supply Pressure', nickname: 'Air Supply', check: 'AirLevelCheck' },
      { subtitle: 'Inconsistent Air Supply Pressure', nickname: 'Inconsistent Air Sup', check: 'AirNoiseCheck' },
      { subtitle: 'Insufficient Air Flow', nickname: 'Insufficient Air Flow', check: 'AirDroopCheck' },
    ],
  },
  {
    title: 'Actuator Testing',
    checks: [
      { subtitle: 'Actuator Supply Pressure', nickname: 'Actuator Pressure', check: 'ActPressLevel' },
      //{ subtitle: 'Actuator Volume', nickname: 'Actuator Volume', check: 'ActVolume' },
      { subtitle: 'Air Leak Detected', nickname: 'Air Leak', check: 'AirDroopLong' },
      { subtitle: 'Actuator Leak Warning', nickname: 'Actuator Leak', check: 'ActLeak' },
      { subtitle: 'Air Blockage Detected', nickname: 'Air Blockage', check: 'ActBlockage' },
      { subtitle: 'Balance Pressure', nickname: 'Balance Pressure', check: 'ActBalanceCheck' },
      { subtitle: 'Weak or Incorrect Spring', nickname: 'Spring Check', check: 'BenchCheck' },
      { subtitle: 'Actuator Saturation', nickname: 'Actuator Saturation', check: 'ActSaturation' },
      { subtitle: 'Poor Actuator Performance', nickname: 'Actuator Performance', check: 'ActTravel' },
      //{ subtitle: 'Poor Actuator Air Flow', nickname: 'Actuator Air Flow', check: 'ActFlow' },
    ],
  },
  {
    title: 'Valve Body and Packing',
    checks: [
      { subtitle: 'Delayed Seat Exit', nickname: 'Seat Exit', check: 'SeatExit' },
      { subtitle: 'Incorrect Seat Entry', nickname: 'Seat Entry', check: 'SeatStarts' },
      { subtitle: 'No Seat Loading', nickname: 'Seat Loading', check: 'Loading' },
      { subtitle: 'Very Low Packing Friction', nickname: 'Low Friction', check: 'FrictionLow' },
      { subtitle: 'High Friction Forces', nickname: 'High Friction', check: 'FrictionMedian' },
      //{ subtitle: 'High Static Friction', nickname: 'High Static Friction', check: 'FrictionStatic' },
      { subtitle: 'Inconsistent Friction Forces', nickname: 'Inconsistent Friction', check: 'FrictionShape' },
      { subtitle: 'Stair-Stepping Warning', nickname: 'Stair-Stepping', check: 'GallingCheck' },
    ],
  },
];

export default diagnosticsArray;
