import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Grid, Typography, List, Button, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { styles } from '../styles';
import issuesArray from '../../arrays/prioritize/issues';
import ChecklistItemSelectable from '../filters/ChecklistItemSelectable';
import { prioritizeToggleActiveVariable, fetchLoopDiagnostics, updateAnalysisRange } from '../../actions/index';
import functions from '../../functions/functions';
import { Info } from '@mui/icons-material';

class PrioritizeIssuesWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeVariables: [],
    };
  }
  addOrRemove = (array, item) => {
    const exists = array.includes(item);

    if (exists) {
      return array.filter((c) => {
        return c !== item;
      });
    } else {
      const result = array;
      result.push(item);
      return result;
    }
  };
  update = (varName) => {
    let changeVariables = this.addOrRemove(this.state.changeVariables, varName);
    this.setState({ changeVariables });
  };
  refresh = () => {
    let changeVars = this.state.changeVariables;
    for (let i = 0; i < changeVars.length; i++) {
      this.props.prioritizeToggleActiveVariable(changeVars[i]);
    }
    this.setState({ changeVariables: [] });
  };
  renderPrintIssues(issueArray) {
    //const { classes } = this.props;
    const diagnostics =
      !this.props.loops || !this.props.loops[this.props.activeLoop] || !this.props.loops[this.props.activeLoop].diagnostics ? {} : this.props.loops[this.props.activeLoop].diagnostics;
    let issues = issuesArray.map((issueSet) => {
      return (
        (<div key={issueSet.title}>
          <Typography variant='subtitle2'>{issueSet.title}</Typography>
          <List style={{ lineHeight: '1 !important' }}>
            <Grid container>
              {issueSet.issues.map((issue) => {
                const labelId = `checkbox-list-label-${issue.varTitle.replace(/\s/g, '')}`;
                let stateVal = -1;
                if (diagnostics[issue.issue] === null) {
                  stateVal = -2;
                } else if (diagnostics[issue.issue] <= -10) {
                  stateVal = -3;
                } else if (diagnostics[issue.issue] < 3) {
                  stateVal = 0;
                } else if (diagnostics[issue.issue] < 6) {
                  stateVal = 1;
                } else if (diagnostics[issue.issue] <= 10) {
                  stateVal = 2;
                }
                return (
                  (<Grid item style={{ width: 240 }}>
                    <ChecklistItemSelectable
                      key={`key_${issue.subtitle.replace(/\s/g, '')}`}
                      onClick={(e) => {
                        const varName = issue.subtitle;
                        this.update(varName);
                      }}
                      issue={issue}
                      labelId={labelId}
                      state={stateVal}
                    ></ChecklistItemSelectable>
                  </Grid>)
                );
              })}
            </Grid>
          </List>
        </div>)
      );
    });
    return (
      <Grid container style={{ marginTop: -8 }}>
        <Grid item xs={3}>
          {issues[0]}
          {issues[1]}
        </Grid>
        <Grid item xs={9}>
          {issues[2]}
        </Grid>
      </Grid>
    );
  }
  renderIssues(issuesArray) {
    const { classes } = this.props;
    const diagnostics =
      !this.props.loops || !this.props.loops[this.props.activeLoop] || !this.props.loops[this.props.activeLoop].diagnostics ? {} : this.props.loops[this.props.activeLoop].diagnostics;
    let issues = issuesArray.map((issueSet) => {
      return (
        (<div key={issueSet.title} className={classes.issueTitle}>
          <Typography variant='subtitle2'>{issueSet.title}</Typography>
          <List style={{ lineHeight: '1 !important' }}>
            {issueSet.issues.map((issue) => {
              const labelId = `checkbox-list-label-${issue.varTitle.replace(/\s/g, '')}`;
              let stateVal = -1;
              if (diagnostics[issue.issue] === null) {
                stateVal = -2;
              } else if (diagnostics[issue.issue] <= -10) {
                stateVal = -3;
              } else if (diagnostics[issue.issue] < 3) {
                stateVal = 0;
              } else if (diagnostics[issue.issue] < 6) {
                stateVal = 1;
              } else if (diagnostics[issue.issue] <= 10) {
                stateVal = 2;
              }
              return (
                (<ChecklistItemSelectable
                                                                    key={`key_${issue.subtitle.replace(/\s/g, '')}`}
                                                                    onClick={(e) => {
                                                                      const varName = issue.subtitle;
                                                                      this.update(varName);
                                                                    }}
                                                                    issue={issue}
                                                                    labelId={labelId}
                                                                    state={stateVal}
                                                                  ></ChecklistItemSelectable>)
              );
            })}
          </List>
        </div>)
      );
    });
    return issues;
  }
  render() {
    const { classes } = this.props;
    //let content = <div>Could not find data for this device!</div>;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    let content = (
      <Grid item>
        <Grid container justifyContent='space-between'>
          {printStyles ? null : (
            <React.Fragment>
              <Typography className={`${classes.printHide} ${classes.infoHelper}`} style={{ width: '100%' }}>
                Click {<Info className={classes.checklistInfoIcon} style={{ marginBottom: -2 }} />} to learn more about each issue
              </Typography>
              <Typography className={`${classes.printHide} ${classes.infoHelper}`} style={{ width: '100%' }}>
                Select any number of issues, then click submit at the bottom to view data.
              </Typography>
            </React.Fragment>
          )}
          <Grid item xs={12}>
            {printStyles ? <div style={{ padding: '8px 8px 0px 8px' }}>{this.renderPrintIssues(issuesArray)}</div> : this.renderIssues(issuesArray)}
          </Grid>
          <Divider className={`${classes.printHide} ${printStyles ? classes.printIssueSubmitDivider : classes.issueSubmitDivider}`}></Divider>
          <Grid container justifyContent='space-between'>
            <Grid item></Grid>
            <Grid item>
              <Button variant='text' color='secondary' onClick={this.refresh} className={`${classes.printHide} ${printStyles ? classes.printIssueSubmit : classes.issueSubmit}`}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
    return <React.Fragment>{content}</React.Fragment>;
  }
}

PrioritizeIssuesWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeLoop: state.prioritize.activeLoop,
    //activeVariables: state.prioritize.activeVariables,
    loops: state.loops.loops,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {
  prioritizeToggleActiveVariable,
  fetchLoopDiagnostics,
  updateAnalysisRange,
  //prioritizeRemoveActiveVariable,
})(withStyles(styles)(PrioritizeIssuesWidget));
