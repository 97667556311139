import React from 'react';
//import Grid from '@mui/material/Grid';
//import WidgetTitle from '../../headers/WidgetTitle';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
//import CustomSelect from '../../filters/CustomSelect';
import { Link } from 'react-router-dom';
//import { Button } from '@mui/material';
//import { Save } from '@mui/icons-material';
//import ToggleButton from '../../filters/ToggleButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
//import TableWidgetExpandable from '../../widgets/TableWidgetExpandable';
import { fetchLoop, fetchLoopEvents, reportsSetActiveReport } from '../../../actions/index';
import DeviceConfig from './prioritize/DeviceConfig';
import LoopConfig from './prioritize/LoopConfig';
import DatasetConfig from './prioritize/DatasetConfig';

class PrioritizeWidgets extends React.Component {
  componentDidMount() {
    let url = window.location.href.toLowerCase();
    url.includes('loops') ? this.props.reportsSetActiveReport('loops') : url.includes('datasets') ? this.props.reportsSetActiveReport('datasets') : this.props.reportsSetActiveReport('devices');
  }
  handleReport(event, value) {
    this.props.reportsSetActiveReport(value);
    if (!value) {
      this.props.reportsSetActiveReport('devices');
    }
  }
  //TODO fix wrapper buttons onclick
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ToggleButtonGroup
          value={!this.props.activeReport ? 'devices' : this.props.activeReport}
          exclusive
          onChange={(event, value) => this.handleReport(event, value)}
          aria-label='text alignment'
          className={classes.toggleButtons}
        >
          <ToggleButton value='devices' text='devices' component={Link} to={`/configure/Prioritize/devices`}>
            Devices
          </ToggleButton>
          <ToggleButton value='loops' text='loops' component={Link} to={`/configure/Prioritize/loops`}>
            Loops
          </ToggleButton>
          <ToggleButton value='datasets' text='datasets' component={Link} to={`/configure/Prioritize/datasets`}>
            Datasets
          </ToggleButton>
        </ToggleButtonGroup>
        {this.props.activeReport === 'devices' || this.props.activeReport === null ? (
          <DeviceConfig />
        ) : this.props.activeReport === 'loops' ? (
          <LoopConfig />
        ) : this.props.activeReport === 'datasets' ? (
          <DatasetConfig />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reports: state.reports.report_data,
    activeReport: state.reports.activeReport,
    view: state.auth.view,
  };
};

export default connect(mapStateToProps, {
  fetchLoop,
  fetchLoopEvents,
  reportsSetActiveReport,
})(withStyles(styles)(PrioritizeWidgets));
