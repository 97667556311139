//import _ from 'lodash';
import { API_ERROR, CLEAR_ERROR } from '../actions/types';

const INITIAL_STATE = { status: null, errorMessage: null, errorStatus: null };

const errorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case API_ERROR:
      const error = action.payload;
      //let errorDetails = '-1';

      // if (error.response) {
      //   // The request was made and the server responded with a status code
      //   // that falls out of the range of 2xx
      //   // console.log(error.response.data);
      //   // console.log(error.response.status);
      //   // console.log(error.response.headers);
      //   errorDetails = error.response;
      // } else if (error.request) {
      //   // The request was made but no response was received
      //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      //   // http.ClientRequest in node.js
      //   //console.log(error.request);
      //   errorDetails = error.request;
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   // console.log('Error', error.message);
      //   errorDetails = error.message;
      // }
      return {
        ...state,
        status: action.type,
        errorMessage: error.message,
        errorStatus: error.response ? error.response.status : null,
      };
    case CLEAR_ERROR:
      return { errorMesssage: null };
    default:
      return state;
  }
};

export default errorReducer;
