import React from 'react';
//import investigationDetails from '../../../arrays/optimize/investigationDetails';
//import InvestigateDetails from '../../../components/helpers/InvestigateDetails';
//import recommendationDetails from '../../../arrays/optimize/recommendationDetails';
import validateClientPermission from '../filters/functions/validateClientPermission';
import TextDisplay from '../filters/TextDisplay';
import api from '../../apis/api';
//import StepTimeWidget from './StepTimeWidget';
//import Grid from '@mui/material/Grid';
import CustomDialog from '../popup/CustomDialog';
import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
  Collapse,
  ListItem,
  ListItemText,
  Checkbox,
  MenuItem,
  Popper,
  Paper,
  Divider,
  ClickAwayListener,
  CircularProgress,
  TextField,
  DialogContentText,
} from '@mui/material';
import WidgetTitle from '../headers/WidgetTitle';
import InfoPopover from '../popup/InfoPopover';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import CustomSelect from '../filters/CustomSelect';
import DropdownMenu from '../menus/DropdownMenu';
import {
  fetchDeviceImprovements,
  fetchDeviceIssues,
  fetchDevices,
  fetchActions,
  fetchInvestigations,
  fetchIssueDetails,
  approveImprovement,
  deleteImprovement,
  fetchDeviceSpecs,
  fetchDiagnostics,
  setTestStatus,
  fetchDeviceIssuesByName,
  //addImprovement,
} from '../../actions/index';
import { Subscriptions, ExpandMore, MoreVert, Search, KeyboardArrowUp, KeyboardArrowDown, Edit, Delete, ThumbDownAlt, Undo, CheckCircle, ThumbUpAlt } from '@mui/icons-material';
import ChecklistIndicator from '../filters/ChecklistIndicator';
import ValveChartWidget from './ValveChartWidget';
import functions from '../../functions/functions';
//import _ from 'lodash';

let requestedInvestigations = [];
let requestedActions = [];
//let requestedIssues = [];

class AccordionWidgetOptimize extends React.Component {
  //const [open, setOpen] = React.useState(false);

  constructor(props) {
    super(props);
    this.state = {
      status: '',
      statusMessage: '',
      open: false,
      formBool: false,
      improvement: '',
      comment: '',
      level: 1,
      improvementType: '',
      test: null,
      testDate: null,
      stateDate: null,
      editId: null,
      menus: {},
      approvalOpen: false,
      approvalName: '',
      approvalEmail: '',
      approvalId: null,
      approvalType: '',
      deleteId: null,
      deleteOpen: false,
      statusMenu: false,
      statusMenuAnchor: null,
      type: !this.props.alignment ? 'Technician' : 'Control',
      inactiveState: {},
      dialogOpen: false,
      moreRecommendations: false,
      copyText: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeLevel = this.handleInputChangeLevel.bind(this);
    this.handleInputChangeImprovementType = this.handleInputChangeImprovementType.bind(this);
    this.handleInputChangeDate = this.handleInputChangeDate.bind(this);
    this.handleApproval = this.handleApproval.bind(this);
    this.openApproval = this.openApproval.bind(this);
    this.closeApproval = this.closeApproval.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.openDelete = this.openDelete.bind(this);
    this.closeDelete = this.closeDelete.bind(this);
    this.processStatus = this.processStatus.bind(this);
    //let issueLoad = false;
  }
  recommendationsCurrent = {};
  setInactiveState(issueId) {
    let inactiveState = this.state.inactiveState;
    if (!inactiveState[issueId]) {
      inactiveState[issueId] = true;
    } else {
      inactiveState[issueId] = !inactiveState[issueId];
    }
    this.setState({ inactiveState });
  }
  convertChartTypeNum(num) {
    switch (num) {
      case '1':
        return { attribute: 'Travel', idType: 'Profile' };
      case '2':
        return { attribute: 'Air', idType: 'Profile' };
      case '3':
        return { attribute: 'Actuator', idType: 'Profile' };
      case '4':
        return { attribute: 'Friction', idType: 'Profile' };
      case '5':
        return { attribute: 'I/P', idType: 'Profile' };
      case '6':
        return { attribute: 'Seat', idType: 'Profile' };
      case '7':
        return { attribute: 'Resolution', idType: 'Resolution' };
      case '8':
        return { attribute: 'Sensitivity', idType: 'Sensitivity' };
      case '9':
        return { attribute: 'Stroke', idType: 'Stroke' };
      case '10':
        return { attribute: 'Alignment', idType: 'Alignment' };
      default:
        return { attribute: 'Error', idType: 'Error' };
    }
  }
  processSupplementalType(infoType) {
    let device = this.props.devices[this.props.activeDevice];
    let testId = !device ? null : device.activeProfileId;
    let specs = !this.props.tests || !this.props.tests.diagnosticsSpecs ? null : this.props.tests.diagnosticsSpecs[testId];
    if (!device || !specs || !this.props.tests || !this.props.tests.diagnosticsSpecs) {
      return { title: 'Loading', value: 'N/A' };
    }
    /*
    console.log('device');
    console.log(device);
    console.log('specs');
    console.log(specs);
*/
    switch (infoType) {
      case 'seatContact':
        return { title: 'Seat Contact', value: specs.seatContact };
      case 'benchSetMin':
        return { title: 'Bench Set Min', value: specs.benchSetMin };
      case 'benchSetMax':
        return { title: 'Bench Set Max', value: specs.benchSetMax };
      case 'fullOpenIP':
        return { title: 'Full Open IP', value: specs.fullOpenIP };
      case 'seatContactIP':
        return { title: 'Seat Contact IP', value: specs.seatContactIP };
      case 'Seat Load':
        return { title: 'Seat Load', value: specs.seatLoad };
      case 'Travel':
        return { title: 'Travel', value: specs.travel };
      case 'fullOpen':
        return { title: 'Full Open', value: specs.fullOpen };
      case 'SignalSource':
        return { title: 'Signal Source', value: device.signalSource };
      case 'signalSource':
        return { title: 'Signal Source', value: device.signalSource };
      case 'actuatorType':
        return { title: 'Actuator Type', value: device.actuatorType };
      case 'airAction':
        return { title: 'Air Action', value: device.airAction };
      case 'failMode':
        return { title: 'Fail Mode', value: device.failMode };
      case 'flowCharacteristic':
        return {
          title: 'Flow Characteristic',
          value: device.flowCharacteristic,
        };
      case 'nomStroke':
        return { title: 'Nominal Stroke', value: device.nomStroke };
      case 'spring':
        return { title: 'Spring', value: device.spring };
      case 'springRange':
        return { title: 'Spring Range', value: device.springRange };
      case 'holdTime':
        return { title: 'Hold Time', value: device.holdTime };
      case 'rampTime':
        return { title: 'Ramp Time', value: device.rampTime };
      case 'stopRamp':
        return { title: 'Stop Ramp', value: device.stopRamp };
      case 'startRamp':
        return { title: 'Start Ramp', value: device.startRamp };

      default:
        return {
          title: this.convertCamelCase(infoType),
          value: !device[infoType] ? specs[infoType] : device[infoType],
        };
    }
  }
  convertCamelCase(text) {
    //const text = 'helloThereMister';
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
  renderIssueSupplemental(chartSupplemental) {
    const { classes } = this.props;
    return (
      <Grid container spacing={2} className={classes.textDisplayGroup}>
        {chartSupplemental.map((infoType, index) => {
          let data = this.processSupplementalType(infoType);
          return (
            <Grid item key={`supplemental_${infoType}_grid_${index}`}>
              <TextDisplay
                key={`supplemental_${infoType}_${index}`}
                id={null}
                label={data.title}
                text={data.value === null ? '-' : !data.value && data.value !== 0 && data.value !== '' ? 'Error' : data.value}
                type='large'
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
  renderIssueDynamic(issueDynamicHeaders, issueDynamicValues) {
    return (
      <Grid container spacing={2}>
        {issueDynamicHeaders.map((header, index) => {
          //let data = this.processSupplementalType(infoType);
          return (
            <Grid item key={`supplemental_grid_${header}_${index}`}>
              <TextDisplay
                key={`supplemental_${header}_${index}`}
                id={null}
                label={header}
                text={!issueDynamicValues[index] ? 'Error' : issueDynamicValues[index]}
                type='large'
                hasBottomMargin
                style={{ marginTop: 4 }}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
  renderIssueCharts(chartDetails) {
    const { classes } = this.props;
    let chartCount = chartDetails.length;
    return (
      <Grid container style={chartCount < 1 ? null : { marginBottom: 10 }}>
        {chartDetails.map((chartTypeId) => {
          let chartType = this.convertChartTypeNum(chartTypeId.toString());
          return (
            <ValveChartWidget
              key={`chartType_key_${chartTypeId}`}
              className={classes.widgetSpacing}
              initialWidth={chartCount > 2 && window.innerWidth > 1000 ? 4 : chartCount > 1 ? 6 : 6}
              type='valve'
              valveColumns={1}
              progress={false}
              testId={!this.props.devices || !this.props.devices[this.props.activeDevice] ? null : this.props.devices[this.props.activeDevice][`active${chartType.idType}Id`]}
              attribute={chartType.attribute}
              loadingValidation={'single'}
              embed
              chartType={chartType}
            ></ValveChartWidget>
          );
        })}
      </Grid>
    );
  }
  getProbabilityColor(probability) {
    const { classes } = this.props;
    switch (probability) {
      case 'Very Likely':
        return classes.greenText;
      case 'Likely':
        return classes.greenText;
      case 'Possible':
        return classes.yellowText;
      case 'Unlikely':
        return classes.redText;
      default:
        return classes.grayText;
    }
  }
  handleRecommendationClick(e, issue, id, inactive = false) {
    if (inactive) {
      this.recommendationsCurrent[issue][id].checked = this.recommendationsCurrent[issue][id].checked === -1 ? false : -1;
    } else {
      this.recommendationsCurrent[issue][id].checked = !this.recommendationsCurrent[issue][id].checked;
    }

    this.setState({ state: this.state });
  }
  renderRecommendations(issueId, recommendations, actionMatch, inactive = false) {
    const { classes } = this.props;
    let recommendationDetails = this.props.recommendations.actions;
    //console.log(issueId, recommendations);
    //console.log(recommendationDetails);
    return (
      <React.Fragment>
        {recommendations.map((recommendation) => {
          let rowActionId = recommendation;
          let actionRec = !recommendationDetails[recommendation] ? '' : recommendationDetails[recommendation].action;
          let actionInUse = false;
          if (!!actionMatch) {
            for (let i = 0; i < actionMatch.length; i++) {
              if (actionRec === actionMatch[i].improvement) {
                actionInUse = true;
              }
            }
          }
          if (!recommendationDetails[recommendation]) {
            return (
              <Typography key={`recommendation_row_id${rowActionId}`}>
                <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                Loading Recommendation...
              </Typography>
            );
          }
          if (typeof this.recommendationsCurrent[issueId] === 'undefined') {
            this.recommendationsCurrent[issueId] = {};
          }
          if (typeof this.recommendationsCurrent[issueId][rowActionId] === 'undefined') {
            this.recommendationsCurrent[issueId][rowActionId] = {
              checked: false,
              level: recommendationDetails[recommendation].actionType,
            };
          }

          if (inactive === true) {
            return (
              <div key={`${recommendation}_recommendation_item`} className={classes.inactiveRec}>
                <ListItem role={undefined} dense style={{ cursor: 'pointer', paddingLeft: 0 }}>
                  <Checkbox
                    checked={this.recommendationsCurrent[issueId][rowActionId].checked || actionInUse}
                    disabled={actionInUse}
                    onClick={(e) => {
                      this.handleRecommendationClick(e, issueId, rowActionId);
                    }}
                    className={`${classes.checkbox} ${classes.inactiveRec}`}
                    style={{ marginRight: 8 }}
                    color='secondary'
                  ></Checkbox>
                  <ListItemText
                    onClick={(e) => {
                      this.handleRecommendationClick(e, issueId, rowActionId, true);
                    }}
                    primary={
                      <Typography variant='subtitle2' className={classes.listTextSelectable}>
                        <Grid container>
                          <Grid item style={{ marginRight: 40 }}>
                            {!recommendationDetails[recommendation].action ? 'Recommendation not found!' : recommendationDetails[recommendation].action}
                          </Grid>
                          <Grid item>
                            Likelihood - <span className={classes.redText}>Unlikely</span>
                          </Grid>
                        </Grid>
                      </Typography>
                    }
                    classes={this.props.format ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }}
                  />
                </ListItem>
                <Collapse in={this.recommendationsCurrent[issueId][rowActionId].checked === -1} style={{ marginLeft: 26, marginTop: 8 }}>
                  <div style={{ marginBottom: 24 }}>
                    <Typography
                      variant='body2'
                      style={{
                        marginBottom: 16,
                      }}
                      className={classes.body}
                    >
                      {!recommendationDetails[recommendation].comment ? 'Recommendation description not found!' : recommendationDetails[recommendation].comment}
                    </Typography>
                    <div
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      <CustomSelect
                        single
                        id={null}
                        label={`Repair Level`}
                        onChangeFunction={(event, value, type) => {
                          this.recommendationsCurrent[issueId][rowActionId].level = value;
                          this.setState({ state: this.state });
                        }}
                        values={this.recommendationsCurrent[issueId][rowActionId].level}
                        options={['Field Adjustment', 'Parts Replacement', 'Body Repair', 'Note']}
                        helperText={null}
                      />
                    </div>
                  </div>
                </Collapse>
              </div>
            );
          } else if (!this.props.disableSubmit) {
            return (
              <React.Fragment key={`${recommendation}_recommendation_item`}>
                <ListItem role={undefined} dense style={{ cursor: 'pointer', paddingLeft: 0 }}>
                  <Checkbox
                    checked={this.recommendationsCurrent[issueId][rowActionId].checked || actionInUse}
                    disabled={actionInUse}
                    onClick={(e) => {
                      this.handleRecommendationClick(e, issueId, rowActionId);
                    }}
                    className={classes.checkbox}
                    style={{ marginRight: 8 }}
                    color='secondary'
                  ></Checkbox>
                  <ListItemText
                    onClick={(e) => {
                      this.handleRecommendationClick(e, issueId, rowActionId);
                    }}
                    primary={
                      <Typography variant='subtitle2' className={classes.listTextSelectable}>
                        <Grid container>
                          <Grid item style={{ marginRight: 40 }}>
                            {!recommendationDetails[recommendation].action ? 'Recommendation not found!' : recommendationDetails[recommendation].action}
                          </Grid>
                          <Grid item>
                            Likelihood -{' '}
                            <span className={this.getProbabilityColor(recommendationDetails[recommendation].probability)}>
                              {!recommendationDetails[recommendation].probability ? 'Likelihood not found!' : recommendationDetails[recommendation].probability}
                            </span>
                          </Grid>
                        </Grid>
                      </Typography>
                    }
                    classes={this.props.format ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }}
                  />
                </ListItem>
                <Collapse in={this.recommendationsCurrent[issueId][rowActionId].checked} style={{ marginLeft: 26, marginTop: 8 }}>
                  <div style={{ marginBottom: 24 }}>
                    <Typography
                      variant='body2'
                      style={{
                        marginBottom: 16,
                      }}
                      className={classes.body}
                    >
                      {!recommendationDetails[recommendation].comment ? 'Recommendation description not found!' : recommendationDetails[recommendation].comment}
                    </Typography>
                    <div
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      <CustomSelect
                        single
                        id={null}
                        label={`Repair Level`}
                        onChangeFunction={(event, value, type) => {
                          this.recommendationsCurrent[issueId][rowActionId].level = value;
                          this.setState({ state: this.state });
                        }}
                        values={this.recommendationsCurrent[issueId][rowActionId].level}
                        options={['Field Adjustment', 'Parts Replacement', 'Body Repair', 'Note']}
                        helperText={null}
                      />
                    </div>
                  </div>
                </Collapse>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={`${recommendation}_recommendation_item`}>
                <ListItem role={undefined} dense style={{ paddingLeft: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant='subtitle2'>
                        <Grid container>
                          <Grid item style={{ marginRight: 40 }}>
                            {!recommendationDetails[recommendation].action ? 'Recommendation not found!' : recommendationDetails[recommendation].action}
                          </Grid>
                          <Grid item style={{ marginRight: 40 }}>
                            Likelihood -{' '}
                            <span className={this.getProbabilityColor(recommendationDetails[recommendation].probability)}>
                              {!recommendationDetails[recommendation].probability ? 'Likelihood not found!' : recommendationDetails[recommendation].probability}
                            </span>
                          </Grid>
                          <Grid item>Repair Level - {this.recommendationsCurrent[issueId][rowActionId].level}</Grid>
                        </Grid>
                      </Typography>
                    }
                    classes={this.props.format ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }}
                  />
                </ListItem>
                <div style={{ marginBottom: 24, marginTop: 8 }}>
                  <Typography
                    variant='body2'
                    style={{
                      marginBottom: 16,
                    }}
                    className={classes.body}
                  >
                    {!recommendationDetails[recommendation].comment ? 'Recommendation description not found!' : recommendationDetails[recommendation].comment}
                  </Typography>
                </div>
              </React.Fragment>
            );
          }
        })}
      </React.Fragment>
    );
  }
  handleInfoClick = (event) => {
    event.stopPropagation();
  };
  handleInvestigateClick = (event) => {
    event.stopPropagation();
  };
  handleMenuClick = (event, issueId, issueName) => {
    event.stopPropagation();
    this.setState({
      statusMenu: !this.state.statusMenu,
      statusMenuAnchor: event.currentTarget,
      statusIssue: issueId,
      statusIssueName: issueName,
    });
  };
  statusUpdateChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let device = this.props.devices[this.props.activeDevice];
    this.updateIssueStatus(this.state.statusIssue, device.activeTestId, e.target.innerText, device.deviceId, 'Technician', this.state.statusIssueName);
    this.handleStatusClose(e);
  };
  updateIssueStatus = async (issueId, testId, status, deviceId, type, issueName) => {
    const statusUrl = `/optimize/devices/${deviceId}/issues/${testId}/${type}/${issueId}`;
    const statusData = { status: status };
    /*let response = */ await api.post(statusUrl, statusData);
    //console.log(response);
    this.props.fetchDeviceIssuesByName(deviceId, testId, 'Technician', issueName.replace('/', '_'));
  };
  handleStatusClose = () => {
    this.setState({ statusMenu: false });
  };
  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };
  handleClose = () => {
    this.setState({ dialogOpen: false });
  };
  handleMoreRecommendations = () => {
    this.setState({ moreRecommendations: !this.state.moreRecommendations });
  };
  matchActionsToIssues = (improvements, issue_name) => {
    const improvementTypes = !improvements ? [] : Object.keys(improvements);
    let matchArr = [];
    for (let i = 0; i < improvementTypes.length; i++) {
      for (let j = 0; j < improvements[improvementTypes[i]].length; j++) {
        if (issue_name === improvements[improvementTypes[i]][j].origin_issue) {
          matchArr.push(improvements[improvementTypes[i]][j]);
        }
      }
    }
    return matchArr.length === 0 ? null : matchArr;
  };

  renderStatus(status, actionMatch) {
    const { classes } = this.props;
    if (!status) {
      return null;
    } else if (!!actionMatch) {
      return (
        <Typography variant='subtitle2'>
          {actionMatch.length} Improvement Path
          {actionMatch.length === 1 ? null : 's'}
        </Typography>
      );
    } else if (status === 'Acknowledge') {
      return (
        <Typography variant='subtitle2' className={classes.purpleText}>
          Issue Acknowledged
        </Typography>
      );
    }
  }
  async handleApproval(action, rowId, type) {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    let validEmail = true;
    if (!regex.test(this.state.approvalEmail.replace(/\s/g, '')) && this.state.approvalEmail !== '') {
      validEmail = false;
    }
    if (validEmail) {
      try {
        const id = !rowId ? this.state.approvalId : rowId;
        const url = `/${this.props.currentModule}/devices/${this.props.activeDevice}/improvements/approve`; /*this.props.fetchDeviceImprovements(
      this.props.activeDevice
    );
        
    this.setState({ open: false });*/

        const finalType = !type ? this.state.approvalType : type;
        //console.log(type, finalType);

        await api.post(url, {
          rowId: id,
          action,
          name: this.state.approvalName,
          email: this.state.approvalEmail,
          type: finalType,
        }); //console.log(response);
        this.props.approveImprovement(id, action, this.state.approvalName, this.state.approvalEmail, finalType);
        this.setState({
          approvalId: null,
          approvalOpen: false,
          approvalName: '',
          approvalEmail: '',
          approvalType: '',
          status: '',
          statusMessage: '',
        });
      } catch (e) {
        console.log(e);
        this.setState({
          status: 'Failure',
          statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
          //loading: false,
        });
      }
    } else {
      this.setState({
        status: 'Failure',
        statusMessage: `Invalid Email Format`,
      });
    }
    /*const response = */
  }

  approvalButton(id, approval) {
    //console.log(id, approval);
    //console.log(approval === null);
    const { classes } = this.props;
    const action = approval === null;
    return (
      <Button
        id={id}
        color={approval === null ? `primary` : `secondary`}
        variant='outlined'
        onClick={() => {
          this.handleApproval(id, action);
        }}
      >
        {approval === null ? (
          <React.Fragment>
            <CheckCircle className={classes.buttonIcon}></CheckCircle>
            <React.Fragment>Approve</React.Fragment>
          </React.Fragment>
        ) : (
          <React.Fragment>Unapprove</React.Fragment>
        )}
      </Button>
    );
  }

  processEdit(id) {
    let data =
      !this.props.devices[this.props.activeDevice] ||
      !this.props.devices[this.props.activeDevice].improvements ||
      !this.props.devices[this.props.activeDevice].improvements[!this.props.alignment ? 'Technician' : 'Control']
        ? []
        : this.props.devices[this.props.activeDevice].improvements[!this.props.alignment ? 'Technician' : 'Control'];
    let improvement = data.filter((e) => e.id === id)[0];
    const device = this.props.devices[this.props.activeDevice];
    const testIndex = device.testIdHistory.indexOf(improvement.testID.toString());
    //console.log(improvement);
    this.setState({
      open: true,
      status: '',
      statusMessage: '',
      formBool: true,
      editId: id,
      improvement: improvement.improvement,
      comment: improvement.comment,
      level: improvement.level2,
      improvementType: improvement.improvementType,
      testDate: device.datetimeHistory[testIndex],
      stateDate: device.stateHistory[testIndex],
    });
  }
  processDelete(id) {
    let data = !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].improvements ? [] : this.props.devices[this.props.activeDevice].improvements;
    let improvement = data.filter((e) => e.id === id)[0];
    const device = this.props.devices[this.props.activeDevice];

    const testIndex = device.testIdHistory.indexOf(improvement.testId.toString());
    //console.log(improvement);
    this.setState({
      open: true,
      formBool: true,
      editId: id,
      improvement: improvement.improvement,
      comment: improvement.comment,
      level: improvement.level2,
      improvementType: improvement.improvementType,
      testDate: device.datetimeHistory[testIndex],
      stateDate: device.stateHistory[testIndex],
    });
  }
  editButton(id, user, userId) {
    //console.log(id, approval);
    //console.log(approval === null);
    const { classes } = this.props;
    //const action = approval === null;
    const button =
      userId === user ? (
        <Button
          id={id}
          color={userId === user ? `primary` : `secondary`}
          className={classes.button}
          variant='outlined'
          onClick={(e) => {
            //console.log(e);
            //console.log(id);

            //console.log('edit');
            this.processEdit(id);
            //this.handleApproval(id, action);
          }}
        >
          <Edit className={classes.buttonIcon}></Edit>
        </Button>
      ) : null;
    return button;
  }
  openApproval() {
    this.setState({ approvalOpen: true, status: '', statusMessage: '' });
  }
  closeApproval() {
    this.setState({
      approvalOpen: false,
      approvalName: '',
      approvalEmail: '',
      approvalId: null,
      approvalType: '',
    });
  }
  approvalDialog() {
    const { classes } = this.props;
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    return (
      <CustomDialog
        center
        open={this.state.approvalOpen}
        onClose={this.closeApproval}
        title='Contact'
        content={
          <React.Fragment>
            <DialogContentText style={{ marginTop: 20, marginBottom: 20 }}>Please enter the name of the contact person.</DialogContentText>
            <form>
              <TextField
                autoFocus
                id='approvalName'
                label='Name'
                name='approvalName'
                fullWidth
                //multiline
                //rows={4}
                //defaultValue=''
                //variant="outlined"
                value={this.state.approvalName}
                onChange={this.handleInputChange}
                className={classes.textField}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
              <TextField
                autoFocus
                id='approvalEmail'
                label='Email'
                name='approvalEmail'
                fullWidth
                type='email'
                //multiline
                //rows={4}
                //defaultValue=''
                //variant="outlined"
                value={this.state.approvalEmail}
                onChange={this.handleInputChange}
                className={classes.textField}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </form>
            {this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginRight: 20 }}>{`${this.state.statusMessage}`}</Typography> : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.closeApproval} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.handleApproval(true);
              }}
              color='primary'
            >
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  openDelete() {
    this.setState({ deleteOpen: true, status: '', statusMessage: '' });
  }
  closeDelete() {
    this.setState({
      deleteOpen: false,
      deleteId: null,
    });
  }
  async handleDelete(action, rowId) {
    try {
      const id = !rowId ? this.state.deleteId : rowId;
      const url = `/${this.props.currentModule}/devices/${this.props.devices[this.props.activeDevice]}/improvements/delete`; /*this.props.fetchDeviceImprovements(
      this.props.activeDevice
    );
    this.setState({ open: false });*/

      await api.post(url, {
        rowId: id,
      }); //console.log(response);
      this.props.deleteImprovement(id);
      this.setState({
        deleteId: null,
        deleteOpen: false,
      });
      this.props.fetchDeviceImprovements(this.props.activeDevice, 'Technician');
    } catch (e) {
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
        //loading: false,
      });
    }
    /*const response = */
  }
  deleteDialog() {
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    //const { classes } = this.props;
    return (
      <CustomDialog
        center
        open={this.state.deleteOpen}
        onClose={this.closeDelete}
        title='Delete Improvement'
        content={
          <React.Fragment>
            <DialogContentText style={{ marginTop: 20 }}>Are you sure you want to delete this improvement?</DialogContentText>
            {this.state.status !== '' ? (
              <Typography variant='subtitle1' color={color} style={{ marginRight: 20 }}>
                {`${this.state.statusMessage}`}
              </Typography>
            ) : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.closeDelete} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.handleDelete(true);
              }}
              color='primary'
            >
              Delete
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  processStatus(data) {
    if (data.notApproved === 1) {
      return {
        status: 'Not Approved',
        contact: data.notApprovedBy,
        user: data.notApprovedByUser,
        date: data.notApprovedByDate,
        email: data.notApprovedByEmail,
      };
    } else if (data.completed === 1) {
      return {
        status: 'Completed',
        contact: data.completedBy,
        user: data.completedByUser,
        date: data.completedByDate,
        email: data.completedByEmail,
      };
    } else if (data.approved === 1) {
      return {
        status: 'Approved',
        contact: data.approvedBy,
        user: data.approvedByUser,
        date: data.approvedByDate,
        email: data.approvedByEmail,
      };
    } else {
      return {
        status: 'Pending',
        contact: '-',
        user: data.user,
        date: data.date,
      };
    }
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleInputChangeLevel(event) {
    //console.log(event);
    //console.log(event.target);
    let value = -2;
    switch (event) {
      case 'Note':
        value = 0;
        break;
      case 'Field Adjustment':
        value = 1;
        break;
      case 'Parts Replacement':
        value = 2;
        break;
      case 'Body Repair':
        value = 3;
        break;
      default:
        value = -1;
    }
    const name = 'level';

    this.setState({
      [name]: value,
      levelName: event,
    });
    return value;
    /* const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;
    console.log(event);
    console.log(event.target);
    this.setState({
      [name]: value,
    });*/
  }
  handleInputChangeImprovementType(event) {
    let value = event;
    let name = 'improvementType';
    this.setState({
      [name]: value,
    });
  }
  handleInputChangeDate(event) {
    //console.log(event);
    //console.log(this.selectActiveTest(event));
    //const target = event.target;
    //const value = target.name === 'isGoing' ? target.checked : target.value;
    const value = event;
    const name = 'test';

    this.setState({
      [name]: value,
    });
  }
  renderIssue(issue) {
    //console.log(issue);
    const { classes } = this.props;
    const issueRecommendations = issue.recommendation_list_active;
    const issueRecommendationsInactive = issue.recommendation_list_inactive;
    const issueInvestigations = issue.investigation_list_active;
    const issueInvestigationsInactive = issue.investigation_list_inactive;
    const combinedRecommendations = issueRecommendations.concat(issueRecommendationsInactive);
    const issueDynamicHeaders = issue.dynamic_headers;
    const issueDynamicValues = issue.dynamic_values;

    //only ask for things we do not have yet
    let missingRecommendations = [];
    for (let i = 0; i < combinedRecommendations.length; i++) {
      if (!this.props.recommendations.actions[combinedRecommendations[i]] && !requestedActions.includes(combinedRecommendations[i])) {
        requestedActions.push(combinedRecommendations[i]);
        missingRecommendations.push(combinedRecommendations[i]);
      }
    }
    const combinedInvestigations = issueInvestigations.concat(issueInvestigationsInactive);
    let missingInvestigations = [];
    for (let i = 0; i < combinedInvestigations.length; i++) {
      if (!this.props.recommendations.investigations[combinedInvestigations[i]] && !requestedInvestigations.includes(combinedInvestigations[i])) {
        requestedInvestigations.push(combinedInvestigations[i]);
        missingInvestigations.push(combinedInvestigations[i]);
      }
    }
    /*if (
      !this.props.recommendations.issues[issue.issue] &&
      !requestedIssues.includes(issue.issue)
    ) {
      requestedIssues.push(issue.issue);
      this.props.fetchIssueDetails([issue.issue]);
    }*/
    if (missingInvestigations.length !== 0) {
      this.props.fetchInvestigations(missingInvestigations);
    }
    if (missingRecommendations.length !== 0) {
      this.props.fetchActions(missingRecommendations);
    }
    const issueDetails = issue; /*!this.props.recommendations.issues[issue.issue]
      ? {}
      : this.props.recommendations.issues[issue.issue]*/
    const issueCharts = !issueDetails.chart_tuple ? [] : issueDetails.chart_tuple;
    const chartSupplemental = !issueDetails.profilesecondary_tuple && !issueDetails.valveinfo_tuple ? [] : issueDetails.profilesecondary_tuple.concat(issueDetails.valveinfo_tuple);
    const device = this.props.devices[this.props.activeDevice];
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    const videoList = ['CalibrationCheck', 'AirLevelCheck'];
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    const improvements = device.improvements;
    const issue_name = issue.issue_name;
    const actionMatch = this.matchActionsToIssues(improvements, issue_name); //null on no action items, array of action items if match
    let issueVideo;
    if (videoList.indexOf(issue.issue_check) > -1) {
      issueVideo = true;
    } else {
      issueVideo = false;
    }
    let videoUrl;
    if (issue.issue_check === 'CalibrationCheck') {
      videoUrl = '807960120?h=b716e709fa';
    } else if (issue.issue_check === 'AirLevelCheck') {
      videoUrl = '814268216?h=61812612a9';
    }

    if (issueDetails.priority < -1000) {
      return (
        <React.Fragment key={`issue_key_${issue.issue}`}>
          <Typography>Inactive</Typography>
        </React.Fragment>
      );
    } else {
      return (
        <div className={classes.avoidBreak} key={`issue_key_${issue.issue}`}>
          <Accordion TransitionProps={{ unmountOnExit: true }} square={false} className={printStyles ? classes.printIssuePaper : classes.issuePaper} disableGutters>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={
                <Tooltip arrow placement='top' title='Expand/Collapse' enterDelay={300}>
                  <ExpandMore className={classes.iconGray} />
                </Tooltip>
              }
              aria-controls='test'
              id='test'
              onClick={() => {
                this.setState({
                  status: '',
                  statusMessage: '',
                  //loading: false,
                });
              }}
            >
              <Grid container justifyContent='space-between'>
                <Grid item className={classes.issueItem}>
                  <Grid container>
                    {printStyles ? null : (
                      <Grid item onClick={this.handleInfoClick}>
                        <InfoPopover
                          resize
                          title={`${!issueDetails.issue_name ? 'Issue Name Not Found' : issueDetails.issue_name} - ${device.name}`}
                          useOptimizeTabs
                          id={issueDetails.issue_check}
                          diagnosticIssue={{
                            device: issueDetails.deviceId,
                            checkValue: issueDetails.priority,
                            name: issueDetails.issue_name,
                            id: issueDetails.issue_name,
                            title: issueDetails.issue_name,
                          }}
                          type='diagnostics'
                          alignment={this.props.alignment}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <div style={{ marginTop: 6 }}>
                        <ChecklistIndicator
                          color={functions.numberToColor(issueDetails.status === 'Acknowledged' ? -3 : issueDetails.priority)}
                          tooltip={functions.numberToOptimizeTooltip(issueDetails.priority)}
                        ></ChecklistIndicator>
                      </div>
                    </Grid>
                    <Grid item>
                      {!issueDetails.issue_name ? (
                        <Typography variant='subtitle2' className={classes.issueName}>
                          Loading Issue Name...
                        </Typography>
                      ) : (
                        <Typography variant='subtitle2' className={classes.issueName}>
                          {issueDetails.issue_name}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.issueItem} style={{ width: 180 }}>
                  <Typography variant='subtitle2' className={classes.issueText}>
                    {issueRecommendations.length} Recommendation
                    {issueRecommendations.length === 1 ? null : 's'}
                  </Typography>
                </Grid>
                <Grid item className={classes.issueItem} style={{ width: 160 }}>
                  <div className={classes.issueText}>{!issue.status ? null : this.renderStatus(issue.status, actionMatch)}</div>
                  {/*<Typography variant='subtitle2' className={classes.issueText}>
                  {!issue.status ? 'Undefined' : issue.priority === -1 ? 'Indeterminate' : issue.status}
                  </Typography>*/}
                </Grid>
                <Grid item className={classes.printHide}>
                  <Grid container style={{ minWidth: 60 }} justifyContent='space-between'>
                    <Grid item></Grid>
                    {/*issueInvestigations < 1 ? null : (
                    <span onClick={this.handleInvestigateClick}>
                      <InfoPopover
                        center
                        tooltip='Investigate this Issue'
                        title={`Investigate - ${!device || !device.name ? 'Device Name Not Found' : device.name}`}
                        titleIcon='Investigate'
                        resize
                        type='investigate'
                        useInvestigateTabs
                        icon={<Search></Search>}
                        issue={issue}
                        investigations={issueInvestigations}
                      />
                    </span>
                  )*/}
                    <Tooltip arrow placement='top' title='Update Issue Status' enterDelay={300}>
                      <IconButton
                        color='inherit'
                        aria-label='more'
                        onClick={(e) => {
                          this.handleMenuClick(e, issue.issue, issue.issue_name);
                        }}
                        size='large'
                      >
                        <MoreVert />
                      </IconButton>
                    </Tooltip>
                    <Popper open={!this.state.statusMenu ? false : this.state.statusMenu} anchorEl={this.state.statusMenuAnchor} onClose={this.handleStatusClose} placement='bottom-start'>
                      <ClickAwayListener onClickAway={this.handleStatusClose}>
                        <Paper variant='outlined'>
                          <Typography
                            variant='caption'
                            style={{
                              padding: '10px 14px 6px 14px',
                              display: 'block',
                            }}
                          >
                            Select to update issue status
                          </Typography>
                          {/*<MenuItem onClick={this.statusUpdateChange}>Detected</MenuItem>*/}
                          <MenuItem onClick={this.statusUpdateChange}>Acknowledge</MenuItem>
                          <MenuItem onClick={this.statusUpdateChange}>Undo Acknowledge</MenuItem>
                          {/*<MenuItem onClick={this.statusUpdateChange}>In Progress</MenuItem>*/}
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <div key={`div_key`} className={!printStyles ? classes.helperSection : null}>
                <Grid container>
                  <Grid item>
                    <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5 }}>
                      Description
                    </Typography>
                  </Grid>
                  {!issueVideo ? null : (
                    <Grid item>
                      <Tooltip arrow placement='top' title='Video Description - click to learn more about this issue' enterDelay={300}>
                        <Button aria-label='Play Video' variant='text' style={{ marginLeft: 10, marginTop: -3, minWidth: 0 }} onClick={this.handleClickOpen}>
                          <Subscriptions style={{ width: 20, height: 20 }} /> <Typography variant='caption'></Typography>
                        </Button>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
                <CustomDialog
                  center
                  maxWidth='lg'
                  open={this.state.dialogOpen}
                  onClose={this.handleClose}
                  title={`Exploring the Issues - ${issueDetails.issue_name}`}
                  titleIcon={<Subscriptions></Subscriptions>}
                  content={
                    <React.Fragment>
                      <div className={classes.videoContainer}>
                        <iframe
                          title={`Exploring the Issues - ${issueDetails.issue_name}`}
                          src={`https://player.vimeo.com/video/${videoUrl}`}
                          allowFullScreen
                          frameBorder='0'
                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        ></iframe>
                      </div>
                    </React.Fragment>
                  }
                  actions={
                    <React.Fragment>
                      <Button onClick={this.handleClose} color='secondary'>
                        Close
                      </Button>
                    </React.Fragment>
                  }
                ></CustomDialog>
                <Typography variant='body1' className={classes.body} style={{ marginBottom: 10 }}>
                  {issueDetails.description}
                </Typography>
                {this.renderIssueCharts(issueCharts)}
                {/*<StepTimeWidget embed initialWidth={12} testId={this.props.devices[this.props.activeDevice].activeTestId} attribute={'Alignment'}></StepTimeWidget>*/}
                <Grid container key={`issueContainer_${issue.issue}`}>
                  <Grid item key={`issueSupplemental_${issue.issue}`}>
                    {this.renderIssueSupplemental(chartSupplemental)}
                  </Grid>
                  <Grid item key={`issueDynamic_${issue.issue}`}>
                    {this.renderIssueDynamic(issueDynamicHeaders, issueDynamicValues)}
                  </Grid>
                </Grid>
                {/* //! ACTION ITEMS IF ACTIVE (use check above) GO HERE */}
                {!actionMatch ? null : (
                  <React.Fragment>
                    <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5, marginTop: 20 }}>
                      Improvement Paths
                    </Typography>
                    {actionMatch.map((action, index) => {
                      let menuOptions = [];
                      const approved = action.approved === 1;
                      const notApproved = action.notApproved === 1;
                      const completed = action.completed === 1;
                      const base = !approved && !notApproved && !completed;
                      if ((base || approved) && !completed && !notApproved) {
                        if (action.approved === 0 || action.approved === null) {
                          //Approve if unapproved, unapprove if approved
                          menuOptions.push({
                            id: action.id,
                            title: 'Approve',
                            icon: ThumbUpAlt,
                            onClick: () => {
                              this.setState({
                                approvalId: action.id,
                                approvalType: `approved`,
                              });
                              this.openApproval();
                            },
                          });
                        } else {
                          menuOptions.push({
                            id: action.id,
                            title: 'Undo Approval',
                            icon: Undo,
                            onClick: (id) => {
                              //console.log('Time to Unapprove! ' + action.id);
                              //this.setState({ approvalType: 'approve' });
                              this.handleApproval(false, action.id, `approved`);
                            },
                          });
                        }
                      }
                      if ((base || approved || notApproved) && !completed) {
                        if (action.notApproved === 0 || action.notApproved === null) {
                          menuOptions.push({
                            id: action.id,
                            title: 'Not Approved',
                            icon: ThumbDownAlt,
                            onClick: () => {
                              //console.log('Time to cancel ' + action.id);
                              this.setState({
                                approvalId: action.id,
                                approvalType: `notApproved`,
                              });
                              this.openApproval();
                              //this.setState({ approvalId: action.id });
                              //this.openApproval();
                            },
                          });
                        } else {
                          menuOptions.push({
                            id: action.id,
                            title: 'Undo Not Approved',
                            icon: Undo,
                            onClick: (id) => {
                              //console.log('Time to Unapprove! ' + action.id);
                              //this.setState({ approvalType: 'approve' });
                              this.handleApproval(false, action.id, `notApproved`);
                            },
                          });
                        }
                      }
                      if (!notApproved) {
                        if (action.completed === 0 || action.completed === null) {
                          menuOptions.push({
                            id: action.id,
                            title: 'Complete',
                            icon: CheckCircle,
                            onClick: () => {
                              //console.log('Time to complete ' + action.id);
                              this.setState({
                                approvalId: action.id,
                                approvalType: `completed`,
                              });
                              this.openApproval();
                              //this.setState({ approvalId: action.id });
                              //this.openApproval();
                            },
                          });
                        } else {
                          menuOptions.push({
                            id: action.id,
                            title: 'Undo Completed',
                            icon: Undo,
                            onClick: (id) => {
                              //console.log('Time to Unapprove! ' + action.id);
                              //this.setState({ approvalType: 'approve' });
                              this.handleApproval(false, action.id, `completed`);
                            },
                          });
                        }
                      }
                      if (this.props.userId === action.user) {
                        //permissioning for edit capability, logged in user is the same as the writer. // TODO needs same check on backend
                        menuOptions.push({
                          id: action.id,
                          title: 'Edit',
                          icon: Edit,
                          onClick: (e) => {
                            this.processEdit(action.id);
                          },
                        });
                        menuOptions.push({
                          id: action.id,
                          title: 'Delete',
                          icon: Delete,
                          onClick: (e) => {
                            this.setState({ deleteId: action.id });
                            this.openDelete();
                            //this.processDelete(action.id);
                          },
                        });
                      }
                      return (
                        <div style={{ marginBottom: 24 }} key={`improvement_div_key`}>
                          <Grid container spacing={2}>
                            <Grid item key={`improvement_subtitle_${index}`} style={{ width: 300 }}>
                              <Typography variant='subtitle1'>{action.improvement}</Typography>
                            </Grid>
                            <Grid item key={`dropdown_menu_${index}`}>
                              <DropdownMenu tooltip='Update Progress' helperText='Select to update progress' options={menuOptions} />
                            </Grid>
                            <Grid item key={`status_${index}`}>
                              <Typography variant='subtitle2' style={{ marginTop: 4 }}>
                                Status:{' '}
                                <span
                                  className={
                                    action.status === 'Not Approved' ? classes.redText : action.status === 'Approved' ? classes.blueText : action.status === 'Completed' ? classes.greenText : null
                                  }
                                >
                                  {action.status}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid key={`date_${index}`} item>
                              <Typography variant='subtitle2' style={{ marginTop: 4 }}>
                                {action.date2}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid key={`repair_${index}`} item>
                              <Typography variant='subtitle2'>Repair Level: {action.level2}</Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item key={`comment_${index}`} style={{ marginTop: 4 }}>
                              <Typography>{action.comment}</Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item key={`contact_${index}`}>
                              <Typography variant='subtitle2'>Contact: {action.contact}</Typography>
                            </Grid>
                            {!action.approvedByEmail ? null : (
                              <Grid item key={`email_${index}`}>
                                <Typography variant='subtitle2'>Email: {action.approvedByEmail}</Typography>
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      );
                    })}
                  </React.Fragment>
                )}

                {/** //! FOR WILL - SELECT, DISABLE CHECK BOXES FOR ISSUES IN USE */}
              </div>
              {/** //!Trigger hide show based on check above - "actionMatch" is an array when something is found, and null when nothing is found, can use !actionMatch ? [nothing found] : [array of issues found]*/}
              {issueInvestigations < 1 || printStyles || !!actionMatch ? null : (
                <div className={!printStyles ? classes.helperSection : null}>
                  <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5 }}>
                    Investigation
                  </Typography>
                  <Typography style={{ marginBottom: 10 }}>Unsure of next steps? Click below to investigate the issue and view examples.</Typography>
                  <InfoPopover
                    button
                    icon={<Search></Search>}
                    tooltip='Investigate this Issue'
                    title={`${!issueDetails.issue_name ? 'Issue Name Not Found' : issueDetails.issue_name} - ${device.name}`}
                    resize
                    useOptimizeTabs
                    id={issueDetails.issue_check}
                    diagnosticIssue={{ device: issueDetails.deviceId, checkValue: issueDetails.priority, name: issueDetails.issue_name, id: issueDetails.issue_name, title: issueDetails.issue_name }}
                    type='diagnostics'
                    defaultTab='investigate'
                    alignment={this.props.alignment}
                  />
                </div>
              )}
              {printStyles ? null : !actionMatch || issueRecommendations.length === 0 ? (
                <React.Fragment>
                  <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5 }}>
                    Recommendations
                  </Typography>
                  {issueRecommendations.length === 0 ? 'No recommendations for this Issue' : this.renderRecommendations(issue.issue, issueRecommendations, actionMatch)}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div
                    onClick={() => {
                      this.handleMoreRecommendations();
                    }}
                    style={{ marginBottom: 10, marginTop: 20 }}
                    className={classes.bodyHeaderSelect}
                  >
                    <Typography variant='subtitle1' color='secondary'>
                      Recommendations
                    </Typography>
                    <Tooltip arrow placement='top' title={this.state.inactiveState[issue.issue] ? 'Collapse' : 'Expand'} aria-label={this.state.moreRecommendations ? 'Collapse' : 'Expand'}>
                      <IconButton style={{ marginTop: -1 }} size='large'>
                        {this.state.moreRecommendations ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown className={classes.inactiveArrow}></KeyboardArrowDown>}
                      </IconButton>
                    </Tooltip>
                    <Typography variant='caption'>Click to view additional recommendations</Typography>
                  </div>
                  <Collapse style={{ marginLeft: 8 }} in={this.state.moreRecommendations}>
                    {this.renderRecommendations(issue.issue, issueRecommendations, actionMatch)}
                  </Collapse>
                </React.Fragment>
              )}
              {issueRecommendationsInactive.length === 0 || printStyles ? null : (
                <React.Fragment>
                  <div
                    onClick={() => {
                      this.setInactiveState(issue.issue);
                    }}
                    style={{ marginBottom: 10, marginTop: 20 }}
                    className={classes.bodyHeaderSelectInactive}
                  >
                    <Typography variant='subtitle1'>Inactive Recommendations</Typography>
                    <Tooltip arrow placement='top' title={this.state.inactiveState[issue.issue] ? 'Collapse' : 'Expand'} aria-label={this.state.inactiveState[issue.issue] ? 'Collapse' : 'Expand'}>
                      <IconButton style={{ marginTop: -1 }} size='large'>
                        {this.state.inactiveState[issue.issue] ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown className={classes.inactiveArrow}></KeyboardArrowDown>}
                      </IconButton>
                    </Tooltip>
                    <Typography variant='caption'>Click to view recommendations that are unlikely to be relevant to this issue</Typography>
                  </div>
                  <Collapse style={{ marginLeft: 8 }} in={this.state.inactiveState[issue.issue]}>
                    {this.renderRecommendations(issue.issue, issueRecommendationsInactive, actionMatch, true)}
                  </Collapse>
                </React.Fragment>
              )}
              {this.props.alignment || printStyles ? null : (
                <Typography style={{ marginBottom: 24, marginTop: 30 }}>Don't see the improvement path you need? Create a custom improvement path below.</Typography>
              )}
              {this.props.disableSubmit || printStyles ? null : (
                <React.Fragment>
                  <Divider className={`${classes.dialogDivider} ${classes.accordionDialogDivider}`}></Divider>
                  <Grid container justifyContent='space-between'>
                    <Grid item>{this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginRight: 20 }}>{`${this.state.statusMessage}`}</Typography> : null}</Grid>
                    <Grid item>
                      <Button
                        type='text'
                        onClick={(e) => {
                          this.handleRecommendationSubmit(issue.issue, this.recommendationsCurrent[issue.issue]);
                        }}
                        color='secondary'
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    }
  }
  async handleRecommendationSubmit(issueId, recommendations) {
    let recKeys = Object.keys(recommendations);
    for (let i = 0; i < recKeys.length; i++) {
      if (recommendations[recKeys[i]].checked === true) {
        let recommendation = this.props.recommendations.actions[recKeys[i]];
        let selectedTest = this.props.devices[this.props.activeDevice].activeTestId;

        const historyId = this.props.devices[this.props.activeDevice].testIdHistory.indexOf(selectedTest.toString());

        const selectedTestValve = this.props.devices[this.props.activeDevice].valveTestIdHistory[historyId];
        let improvement = {
          improvement: recommendation.action,
          level: this.convertIssueLevel(recommendations[recKeys[i]].level),
          improvementType: recommendation.issueName,
          comment: recommendation.comment,
          type: !this.props.alignment ? 'Technician' : 'Control',
        };
        const url = this.state.formBool
          ? `/${this.props.currentModule}/devices/${this.props.activeDevice}/improvements/update/${this.state.editId}`
          : `/${this.props.currentModule}/devices/${this.props.activeDevice}/improvements`;
        try {
          if (validateClientPermission(this.props.clientPermission)) {
            /*const response = */ await api.post(url, [improvement, selectedTest, selectedTestValve]);
            this.recommendationsCurrent[issueId][recKeys[i]].checked = false;
            //console.log(!this.props.alignment ? 'Technician' : 'Control');
          } else {
            //add local state addition here
            //TODO redux persistance
            // https://stackoverflow.com/questions/52161128/react-redux-state-is-lost-at-page-refresh
            // https://github.com/rt2zz/redux-persist
            console.log([improvement, selectedTest, selectedTestValve]);
            console.log(this.props.devices[this.props.activeDevice].improvements);
          }
          this.setState({
            status: 'Success',
            statusMessage: 'Improvement Paths Submitted',
            //loading: false,
          });
        } catch (e) {
          this.setState({
            status: 'Failure',
            statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
            //loading: false,
          });
        }
        this.props.fetchDeviceImprovements(this.props.activeDevice, !this.props.alignment ? 'Technician' : 'Control');
      }
    }
  }
  convertIssueLevel(level) {
    //console.log(event);
    //console.log(event.target);
    let value = -2;
    switch (level) {
      case 'Note':
        value = 0;
        break;
      case 'Field Adjustment':
        value = 1;
        break;
      case 'Parts Replacement':
        value = 2;
        break;
      case 'Body Repair':
        value = 3;
        break;
      default:
        value = -1;
    }

    return value;
    /* const target = event.target;
      const value = target.name === 'isGoing' ? target.checked : target.value;
      const name = target.name;
      console.log(event);
      console.log(event.target);
      this.setState({
        [name]: value,
      });*/
  }

  handleSubmit = async () => {
    let test = this.state.test;
    if (test === null) {
      test =
        !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime
          ? null
          : `${new Date(this.props.devices[this.props.activeDevice].dateTime * 1000).toLocaleString()}${this.processState(this.props.devices[this.props.activeDevice].state)}`;
      this.setState({
        test,
      });
    }
    const selectedTest = this.selectActiveTest(test);
    const selectedTestValve = this.selectActiveTestValve(test);
    //console.log(selectedTestValve);
    //console.log(selectedTest);
    //console.log(this.state);
    //this.props.addImprovement(this.state, this.selectActiveTest(test),this.props.devices[this.props.activeDevice] );
    //console.log(this.props.activeDevice);
    //console.log(this.props.devices[this.props.activeDevice]);
    const url = this.state.formBool
      ? `/${this.props.currentModule}/devices/${this.props.activeDevice}/improvements/update/${this.state.editId}`
      : `/${this.props.currentModule}/devices/${this.props.activeDevice}/improvements`;
    //console.log(url);
    //console.log(this.state);
    //console.log(test);
    //console.log(selectedTest);
    //console.log(

    //);
    //console.log(response);
    let currentState = this.state;
    if (isNaN(currentState.level)) {
      currentState.level = this.handleInputChangeLevel(currentState.level);
    }
    try {
      if (validateClientPermission(this.props.clientPermission)) {
        /*const response = */ await api.post(url, [currentState, selectedTest, selectedTestValve]);

        this.props.fetchDeviceImprovements(this.props.activeDevice, !this.props.alignment ? 'Technician' : 'Control');
      } else {
        //add local state addition here
        //TODO redux persistance
        // https://stackoverflow.com/questions/52161128/react-redux-state-is-lost-at-page-refresh
        // https://github.com/rt2zz/redux-persist
        //console.log([currentState, selectedTest, selectedTestValve]);
        //console.log(this.props.devices[this.props.activeDevice].improvements);
      }

      this.setState({
        status: '',
        statusMessage: '',
        open: false,
        formBool: false,
        improvement: '',
        comment: '',
        level: 1,
        improvementType: '',
        test: null,
        testDate: null,
        stateDate: null,
        editId: null,
        menus: {},
        approvalOpen: false,
        approvalName: '',
        approvalEmail: '',
        approvalId: null,
        approvalType: '',
        deleteId: null,
        deleteOpen: false,
        type: !this.props.alignment ? 'Technician' : 'Control',
      });
    } catch (e) {
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
        //loading: false,
      });
    }
  };
  componentDidMount() {
    if (
      //true
      !this.props.devices[this.props.activeDevice]
    ) {
      this.props.fetchDevices(this.props.activeDevice);
      this.props.fetchDeviceImprovements(this.props.activeDevice, !this.props.alignment ? 'Technician' : 'Control');
    }
    if (
      !!this.props.devices[this.props.activeDevice] &&
      (!this.props.devices[this.props.activeDevice].improvements || !this.props.devices[this.props.activeDevice].improvements[!this.props.alignment ? 'Technician' : 'Control'])
    ) {
      //this.props.fetchDeviceByTest(this.props.activeDevice, this.props.devices[this.props.activeDevice].activeTestId);
      this.props.fetchDeviceImprovements(this.props.activeDevice, !this.props.alignment ? 'Technician' : 'Control');
      //this.props.fetchLoopEvents(this.props.activeLoop);
    }
  }
  componentDidUpdate() {
    let device = this.props.devices[this.props.activeDevice];
    let testId = !device ? null : device.activeProfileId;
    //console.log(this.issueLoad);
    //console.log(this.props.devices[this.props.activeDevice].issues);

    if (
      !!device &&
      !!this.props.devices[this.props.activeDevice] &&
      !!this.props.devices[this.props.activeDevice].activeTestId &&
      (!this.props.devices[this.props.activeDevice].issues || !this.props.devices[this.props.activeDevice].issues[!this.props.alignment ? 'Technician' : 'Control']) &&
      !this.issueLoad
    ) {
      this.issueLoad = true;
      this.props.fetchDeviceIssues(this.props.activeDevice, this.props.devices[this.props.activeDevice].activeTestId, !this.props.alignment ? 'Technician' : 'Control');
      //this.props.fetchIssueDetails([1, 2, 3]);
      // this.props.fetchActions([1, 2, 3]);
      //this.props.fetchInvestigations([1, 2, 3]);
      //this.props.fetchLoopEvents(this.props.activeLoop);
    }
    if (
      (!this.props.tests_status ||
        !this.props.tests_status['diagnostics'] ||
        !(this.props.tests_status['diagnostics'][testId] === 'loading' || this.props.tests_status['diagnostics'][testId] === 'loaded')) &&
      !!testId
    ) {
      this.props.fetchDiagnostics(testId);
      this.props.fetchDeviceSpecs(this.props.activeDevice);
      this.props.setTestStatus('diagnostics', testId, 'loading');
    }
  }
  render() {
    const { classes } = this.props;
    const device = this.props.devices[this.props.activeDevice];
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    let orderedIssues =
      !device || !device.issues || !device.issues[!this.props.alignment ? 'Technician' : 'Control']
        ? []
        : device.issues[!this.props.alignment ? 'Technician' : 'Control'].sort((a, b) => a.issue - b.issue); // b - a for reverse numeric sort
    let indeterminateIssues = orderedIssues.map((issue) => {
      if (issue.priority !== -1 || issue.priority === 0) {
        return null;
      } else {
        return this.renderIssue(issue);
      }
    });
    let isIndetermined;
    if (orderedIssues.find((element) => !!element.priority && element.priority === -1)) {
      isIndetermined = true;
    } else {
      isIndetermined = false;
    }
    let filteredOrderedIssues = orderedIssues.filter((issue) => issue.priority >= 0);
    return (
      <React.Fragment>
        {!device || !device.issues || !device.issues[!this.props.alignment ? 'Technician' : 'Control'] ? (
          <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
            <Typography variant='subtitle2' className={classes.issueName}>
              <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
              Loading Issue...
            </Typography>
          </Paper>
        ) : filteredOrderedIssues.length === 0 ? (
          <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
            No Issues Found.
          </Paper>
        ) : (
          filteredOrderedIssues.map((issue) => {
            if (issue.priority <= 0) {
              return null;
            } else {
              return this.renderIssue(issue);
            }
          })
        )}
        <div className={classes.printMarginSm}>
          {isIndetermined === false ? null : <WidgetTitle title={`Indeterminate Issues - ${!device || !device.name ? 'Device Name Not Found' : device.name}`} />}
          {indeterminateIssues}
        </div>
        {this.approvalDialog()}
        {this.deleteDialog()}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    devices: state.devices.devices,
    currentModule: state.currentModule.currentModule,
    userId: state.auth.userId,
    clientPermission: state.auth.clientPermission,
    recommendations: state.recommendations,
    tests: state.tests.tests,
    tests_status: state.tests.status,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, {
  fetchDeviceImprovements,
  fetchDeviceIssues,
  fetchActions,
  fetchInvestigations,
  fetchIssueDetails,
  fetchDevices,
  approveImprovement,
  fetchDeviceSpecs,
  fetchDiagnostics,
  setTestStatus,
  deleteImprovement,
  fetchDeviceIssuesByName,
  //addImprovement /*, fetchDeviceEvents */,
})(withStyles(styles)(AccordionWidgetOptimize));
