import React from 'react';
import { Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';

class PageTitle extends React.Component {
  render() {
    const path = this.props.location.pathname.slice(1);
    if (!path) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Index
        </Typography>
      );
    } else if (path.indexOf('optimize') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Optimize
        </Typography>
      );
    } else if (path.toLowerCase().indexOf('iba') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Installed Base Analysis
        </Typography>
      );
    } else if (path.indexOf('reliability') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Reliability
        </Typography>
      );
    } else if (path.indexOf('stabilize') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Stabilize
        </Typography>
      );
    } else if (path.indexOf('prioritize') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Prioritize
        </Typography>
      );
    } else if (path.indexOf('reports') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Reports
        </Typography>
      );
    } else if (path.indexOf('configure') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Configure
        </Typography>
      );
    } else if (path.indexOf('upload') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Upload
        </Typography>
      );
    } else if (path.indexOf('roadmap') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Roadmap
        </Typography>
      );
    } else if (path.indexOf('support') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Support
        </Typography>
      );
    } else if (path.indexOf('signup') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Sign Up
        </Typography>
      );
    } else if (path.indexOf('signin') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Sign In
        </Typography>
      );
    } else if (path.indexOf('fsr') !== -1) {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          Field Service Reports
        </Typography>
      );
    } else {
      return (
        <Typography variant='h6' color='inherit' noWrap>
          {path}
        </Typography>
      );
    }
  }
}

export default withRouter(PageTitle);
