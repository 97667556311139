import React from 'react';
import { styles } from '../styles';
import withStyles from '@mui/styles/withStyles';
import { Grid, Typography, Paper, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import Draggable from 'react-draggable';
import DiagnosticIcon from '../helpers/DiagnosticIcon';

function PaperComponent(props) {
  if (window.innerWidth > 960) {
    return (
      <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  } else {
    return <Paper {...props} />;
  }
}

const CustomDialog = (props) => {
  const { classes } = props;
  return (
    <Dialog
      open={!props.open ? false : props.open}
      onClose={props.onClose}
      scroll='paper'
      PaperComponent={PaperComponent}
      PaperProps={{ classes: { root: props.center ? classes.centerDialog : classes.dialog } }}
      maxWidth={!props.maxWidth ? 'sm' : props.maxWidth}
      aria-labelledby='draggable-dialog-title'
      sx={
        props.type === 'image'
          ? {
              maxWidth: '720px',
              margin: '2% auto 4% auto',
            }
          : null
      }
    >
      <DialogTitle id='draggable-dialog-title' className={classes.dialogTitle}>
        <Grid container justifyContent='space-between' spacing={2}>
          <Grid item>
            <Grid container>
              {!props.titleIcon ? null : (
                <Grid item>
                  <DiagnosticIcon icon={props.titleIcon}></DiagnosticIcon>
                </Grid>
              )}
              <Grid item>
                <Typography variant='subtitle1' style={{ marginTop: 3 }}>
                  {!props.title ? 'Title Not Found' : props.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!props.controls ? null : props.controls}
        </Grid>
      </DialogTitle>
      <DialogContent sx={props.type === 'image' ? { padding: 0 } : null}>{props.content}</DialogContent>
      {!props.actions ? null : (
        <React.Fragment>
          <Divider className={classes.dialogDivider}></Divider>
          <DialogActions>{props.actions}</DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(CustomDialog);
