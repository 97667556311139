import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import { supportSetActiveTab } from '../../../actions';
import { supportSetActiveOptimize } from '../../../actions';
import { Typography, Grid, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import UploadDetails from '../../helpers/UploadDetails';
import config1 from '../../../images/screenshots_FAQ/config1_ss.PNG';
import alignment1 from '../../../images/screenshots_FAQ/alignment_ss.PNG';
import alignment2 from '../../../images/screenshots_FAQ/alignment2_ss.PNG';
import diagnostics1 from '../../../images/screenshots_FAQ/diag_ss.PNG';
import improvements1 from '../../../images/screenshots_FAQ/improvement_ss.PNG';
import improvements2 from '../../../images/screenshots_FAQ/improvements_ss.PNG';
import improvements3 from '../../../images/screenshots_FAQ/improvementsReport_ss.PNG';
import improvements4 from '../../../images/screenshots_FAQ/improvementsReport2_ss.png';
import compare1 from '../../../images/screenshots_FAQ/compare1_ss.PNG';
import compare2 from '../../../images/screenshots_FAQ/compare2_ss.PNG';
import helper1 from '../../../images/screenshots_FAQ/helper1_ss.PNG';
import perfHistory1 from '../../../images/screenshots_FAQ/performanceHistory_ss.PNG';
//import search from '../../../images/screenshots_FAQ/search_ss.PNG';
import tiles from '../../../images/screenshots_FAQ/tiles_ss.PNG';
import tile from '../../../images/screenshots_FAQ/tile_ss.PNG';
import filters from '../../../images/screenshots_FAQ/filters_ss.PNG';
import tabs from '../../../images/screenshots_FAQ/tabs_ss.PNG';
import performance from '../../../images/screenshots_FAQ/alignment_ss.PNG';
import compare from '../../../images/screenshots_FAQ/compare_ss.PNG';
import diagPaths from '../../../images/screenshots_FAQ/diagPaths_ss.PNG';
import diag from '../../../images/screenshots_FAQ/diag_ss.PNG';
//import history from '../../../images/screenshots_FAQ/history_ss.PNG';
import improvement from '../../../images/screenshots_FAQ/improvement_ss.PNG';
import improvements from '../../../images/screenshots_FAQ/improvements_ss.PNG';
import info from '../../../images/screenshots_FAQ/info_ss.png';
import recommend from '../../../images/screenshots_FAQ/recommend_ss.PNG';
import database from '../../../images/screenshots_FAQ/database_ss.PNG';
import infoTileButton from '../../../images/screenshots_FAQ/infoTileButton.png';
import concernShowMore from '../../../images/screenshots_FAQ/concernShowMore.png';
import toolbar from '../../../images/screenshots_FAQ/toolbar.png';
import showMore from '../../../images/screenshots_FAQ/showMore.png';
import portfolio from '../../../images/screenshots_FAQ/portfolioReport.png';
import issues from '../../../images/screenshots_FAQ/issues.png';
import issue from '../../../images/screenshots_FAQ/issue.png';
import indeterminateIssue from '../../../images/screenshots_FAQ/indeterminateIssue.png';
import improvementPath from '../../../images/screenshots_FAQ/improvementPath.png';
import alignment from '../../../images/screenshots_FAQ/alignment.png';
import alignmentCharts from '../../../images/screenshots_FAQ/alignmentCharts.png';
import { Link } from 'react-router-dom';
//import VideoContainer from '../../widgets/VideoContainer';

class Start extends React.Component {
  renderSection1() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Optimize Process is broken down into 5 parts, Establish, Identify, Improve, Align, and Report, all of which we will go over briefly here.
        </Typography>
        <Grid
          container
          spacing={2}
          //justifyContent='center'
          style={{ marginTop: 10, marginBottom: 20 }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <Paper elevation={0} className={`${classes.infographicTile} ${classes.infographicTile1}`}>
              <Typography variant='h3' style={{ marginBottom: 20 }}>
                01
              </Typography>
              <Typography variant='h5' style={{ marginBottom: 10 }}>
                Establish
              </Typography>
              <Typography variant='subtitle2'>Performance Targets</Typography>
              <Typography variant='subtitle2'>Control System Parameters</Typography>
              <Typography variant='subtitle2'>Upload Data</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Paper elevation={0} className={`${classes.infographicTile} ${classes.infographicTile2}`}>
              <Typography variant='h3' style={{ marginBottom: 20 }}>
                02
              </Typography>
              <Typography variant='h5' style={{ marginBottom: 10 }}>
                Identify
              </Typography>
              <Typography variant='subtitle2'>Performance Assessment</Typography>
              <Typography variant='subtitle2'>Reliability Concerns</Typography>
              <Typography variant='subtitle2'>Improvement Paths</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Paper elevation={0} className={`${classes.infographicTile} ${classes.infographicTile3}`}>
              <Typography variant='h3' style={{ marginBottom: 20 }}>
                03
              </Typography>
              <Typography variant='h5' style={{ marginBottom: 10 }}>
                Improve
              </Typography>

              <Typography variant='subtitle2'>Approve Proposed Actions</Typography>
              <Typography variant='subtitle2'>Carry Out Recommended Improvements</Typography>
              <Typography variant='subtitle2'>Assess Impact of Completed Actions</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Paper elevation={0} className={`${classes.infographicTile} ${classes.infographicTile4}`}>
              <Typography variant='h3' style={{ marginBottom: 20 }}>
                04
              </Typography>
              <Typography variant='h5' style={{ marginBottom: 10 }}>
                Align
              </Typography>
              <Typography variant='subtitle2'>Positioner Signals</Typography>
              <Typography variant='subtitle2'>Overall Loop</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Paper elevation={0} className={`${classes.infographicTile} ${classes.infographicTile5}`}>
              <Typography variant='h3' style={{ marginBottom: 20 }}>
                05
              </Typography>
              <Typography variant='h5' style={{ marginBottom: 10 }}>
                Report
              </Typography>
              <Typography variant='subtitle2'>Portfolio Summary</Typography>
              <Typography variant='subtitle2'>Performance Comparisons</Typography>
              <Typography variant='subtitle2'>Recommended Next Steps</Typography>
            </Paper>
          </Grid>
        </Grid>
        <Typography variant='caption'>Step 1</Typography>
        <Typography variant='subtitle2' color='secondary'>
          Establish
        </Typography>
        <Typography variant='body1' style={{ marginTop: 4, marginBottom: 16 }}>
          First we need to Establish application specific Performance and Reliability Targets. It needs to be clear what the expectations are of a given application, and what the importance is. If a
          valve does not have great importance, there is no need to spend a lot of time and money trying to optimize its performance.
        </Typography>
        <Typography variant='caption'>Step 2</Typography>
        <Typography variant='subtitle2' color='secondary'>
          Identify
        </Typography>
        <Typography variant='body1' style={{ marginTop: 4, marginBottom: 16 }}>
          Next we want to Identify where a valve does not meet those targets to see if the performance and reliability is inadequate for the task. The way we do this is by utilizing 3 key tests. To
          check for accuracy we use an Alternating Step Test, which we call Resolution, as well as a Same Direction Step Test, which we call Sensitivity. For checking Speed, we use a Bi- Directional
          speed test, which we call Stroke. Once we have seen whether or not a valve is performing adequately, we move on to the Improvement Paths tab where the Optimize Analysis Engine has produced a
          list of valve issues and recommendations that are likely to resolve them.
        </Typography>
        <Typography variant='caption'>Step 3</Typography>
        <Typography variant='subtitle2' color='secondary'>
          Improve
        </Typography>
        <Typography variant='body1' style={{ marginTop: 4 }}>
          After we have Identified which issues need to be resolved, we want to Improve the valve by selecting or creating Improvement Paths. At this step there should be engagement with the key
          stakeholders on what was found. Sometimes this can be difficult to do, especially when there is bad news. It is extremely important to have an effective conversation with the stakeholders,
          because without an agreement that certain maintenance or improvements are necessary, the plant gets minimal value.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 4, marginBottom: 16 }}>
          Once there is agreement from the stakeholder, the various improvements are carried out by technicians in order to match performance with application targets. Follow up valve tests should be
          made in order to determine the effectiveness of the improvements.
        </Typography>
        <Typography variant='caption'>Step 4</Typography>
        <Typography variant='subtitle2' color='secondary'>
          Align
        </Typography>
        <Typography variant='body1' style={{ marginTop: 4, marginBottom: 16 }}>
          After the valves have been Improved we move on to the Align step. This is when the Alignment tab of Optimized is used by the control room operators to make sure that the commands sent by
          them to the valves are producing the optimal actions.
        </Typography>
        <Typography variant='caption'>Step 5</Typography>
        <Typography variant='subtitle2' color='secondary'>
          Report
        </Typography>
        <Typography variant='body1' style={{ marginTop: 4, marginBottom: 16 }}>
          Lastly, the most important step is Report. The first 4 steps can be done, but if there is no evidence that an improvement is made, the stakeholder may not understand the full value they have
          gained from using the Optimize process. You can show this improvement via one of the reports that exist on Optimize, such as the Valve Portfolio summary. Or, if you wanted to show the
          improvement of an individual valve, you could do a comparison of an “as found” and “as left” test. Depending on the results, this would also be the time to discuss next steps to improve the
          valve, should they be necessary.
        </Typography>
      </React.Fragment>
    );
  }
  renderSection2() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginBottom: 10 }}>
          The Valve Summary Page
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The first page you'll see upon clicking on the Optimize tab is the Valve Summary Page. You’ve already noticed that there are boxes on your screen filled with information. In Optimize, these
          are Valve Summaries. Each represents a specific device and displays key information from the most recent test performed. The 3 bars that you see are representations of the target performance
          for the tests listed. A bar will be green if the valve has performed better than the target accuracy, yellow if it is within 25% of exceeding target accuracy, and red if it exceeds target
          accuracy.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={tiles} alt='tiles screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1'>
          You’ll also notice each bar shows what the target was, as well as the actual test result. Below the bars are counts of Open Issues, Field Adjustments, Parts Replacements, and Body Repairs.
          Open Issues are the potential issues that Optimize has found with a valve as a result of testing. The other three are counts of types of Improvement Paths, which will be explained later.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          A more thorough explanation of each test can be found by clicking the information icon next to each test name. This will open up a Helper. In the first tab of this window you will see an
          Introduction with a description of the test and the current concern level. You can see descriptions of the other concern levels by clicking “Show More”. The Investigate tab gives you a
          sequence of steps to help you understand why there may be a deficit in valve performance. The examples tab gives you an idea of what a good or bad test result looks like. These Helpers will
          be extremely valuable to you when you analyze valve tests, as you’ll see later on.
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <img src={infoTileButton} alt='tile screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 400 }}></img>
          </Grid>
          <Grid item>
            <img src={concernShowMore} alt='tile screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 410 }}></img>
          </Grid>
        </Grid>
        <Typography variant='h6' style={{ marginTop: 32, marginBottom: 10 }}>
          Search and Sort
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          You’ll notice that there is a “Search” bar at the top of the page. Using this is the quickest and easiest way to find a specific test. If you know your Valve ID, type it in there, and then
          click on it from the drop down menu. This will take you to the Valve page.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Use the various “sort” options at the top of the page below the search bar. These will allow you to sort and filter your results so that you can see the valves you want, in the order you
          want. As an example, you could choose to see your valves that were tested most recently, but in a specific location and application. To the right of the screen there are 2 buttons. The first
          button will open a new browser tab to print your current page. The second button toggles Dark mode on and off.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={filters} alt='filters screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='h6' style={{ marginTop: 32, marginBottom: 10 }}>
          Navigation
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Valve Summary also acts as a button, which when clicked will take you to the Performance tab of a device page. For experienced users, if you would like to go to a specific tab of a valve
          page, you can click on the Quick Navigation Menu. We’ll see more menus like this as we continue the tour.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={tile} alt='tile screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 600 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          You’ll notice at the top of the page there are buttons (Performance, Improvement Paths, Diagnostics, and Alignment) which will display certain information when clicked. Let’s go through each
          one so you’ll know how to find the data you want.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={tabs} alt='tabs screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='h6' style={{ marginTop: 32, marginBottom: 10 }}>
          Performance
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Performance tab can be used as a way to see your basic device data and test results.You’ll see in the top section that all the information is displayed about your valve. This includes
          physical attributes as well as parts of the valve test results.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={info} alt='valve info screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Below, you will see a row of 3 options. The first option is to choose which test you want to see, which can be selected by date. Next to that you’ll see “Comparison Device” and “Compare to”.
          These options exist to allow you to compare your currently displayed test results to other tests. “Comparison Device” defaults to the device you are currently looking at. The reason for this
          is that “Compare to” is a drop down menu of tests by date. So, say you want to compare your most recent test results for this device to previous test results. You would leave “Comparison
          Device” as i’s default, and then select which test you want from “Compare to”. If you want to compare your current devices’ tests to a different device, you would simply change “Comparison
          Device” to whichever other device you would like. Either way, when you compare 2 tests, you will see one superimposed over the other in the following.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={compare} alt='compare screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 600 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Below, you will see 3 graphs. You’ll recognize that these graphs have the same names and bars as the tests listed on a Valve. The graphs are your actual test results, visualized. If you want
          to explore these tests in more detail, you can expand them by clicking the “expand” button in the top right corner of each graph. Once expanded, you can zoom in to a specific area by
          clicking and dragging your mouse over that area. You will see a blue highlight to know that it is working. To reset your zoom, simply click the “reset zoom” button. To set your graph back to
          its original size, click the “expand” button again.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={performance} alt='performance screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Below the basic tests, you will see a button that says “Show More”. We’ve left the option open to you as to how you’d like to view all of your data. If you would like to see it all in a
          single tab to scroll up and down on, you can. If you would like to just see more specific information and not have everything all at once, you can click on the tabs at the top.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={showMore} alt='show more menu screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 200 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Back at the top of the Performance tab to the right side there are 4 buttons.The first allows you to create a Performance Certificate. The Performance Certificate is a printable version of
          all the information associated with a given valve test.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={toolbar} alt='toolbar screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 300 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The second allows you to set your test “as found” or “as left”. You can also set this when tests are uploaded but throughout your work flow you may not know how many tests will be necessary
          for a given valve, and therefore don’t know how to assign each test from the beginning.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The next button opens up your valve’s configuration information. Again, this is information you will likely set up when uploading your tests, but if adjustments need to be made, you can do
          it here.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The last button is also a “show more” option. This is here in case you want to view all of your information in one tab.
        </Typography>
        <Typography variant='h6' style={{ marginTop: 32, marginBottom: 10 }}>
          Improvement Paths
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Alright, Let’s now click on the Improvement Paths tab and get your first look at how Optimize helps you diagnose your valve issues. You’ll see a header that says Technician Issues and
          Recommendations. Optimize differentiates between Issues that can be resolved by a valve technician and those that would be handled by operators and in a plant’s control room. In the
          Improvement Paths tab you will only ever see Issues directed at technicians. You’ll see later the Control Room related Valve Issues are found in the Alignment tab.
          <Typography variant='body1' style={{ marginTop: 10 }}></Typography>
          Looking below you’ll see a familiar Test Selection drop down menu and, underneath that, a list of issues. Changing your test will also change which issues are shown. This is useful as a
          historical reference when you test the same valve multiple times.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={issues} alt='issues screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Each row shows the Issue name, Number of Recommendations, how many Improvement Paths for that issue have been created, and a button Acknowledgment Status. To the left of the Issue name
          you’ll notice the familiar Information Icon. You can expand the issue by clicking anywhere on the row.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Once the issue has been expanded, the first thing you’ll see is a description of the issue. This is the same description you would find in the helper. Below that you’ll see the relevant
          diagnostic tests that allowed Optimize to determine this issue exists. Continuing down there is a button to “Investigate this issue”. Clicking that will take you directly to the Investigate
          tab of the helper.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={issue} alt='issue screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Below that you will find your Recommendations. A recommendation will initially tell you the title as well as the “Likelihood” that following through with the Recommendation as an Improvement
          Path will resolve the Issue. Clicking on the checkbox of the Recommendation will expand it, showing a detailed description as well as the default Repair Level.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Inactive Recommendations are Recommendations that Optimize has designated as “Unlikely”. These are here should the more likely Recommendations not solve the Issue. Keep in mind that
          “likely” has a much higher probability than “possible” and so forth.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={indeterminateIssue} alt='indeterminate issue screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Once you have selected the Recommendations that you want, you can click the “Submit” button at the bottom right of the expanded Issue. This will change the appearance of the expanded Issue.
          You will now see the Improvement Paths and the Recommendations will be minimized.. When presenting Improvement Paths, it is useful to be able to show the Issue information in order to get
          approval to proceed.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={improvementPath} alt='improvement path screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 400 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Update Progress button will allow you to change the approval status of an Improvement Path. When changing that status, you will be able to add the name and email address of the
          responsible party, which will show up in the “contact” column in the Improvement Paths table below. This menu will also allow you to open a box to edit the fields of the Improvement Path,
          should they need to be changed.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Improvement Paths are also added to the Improvement Paths table. This table shows the Improvement Path name, the Issue it’s associated with, the description, date it was added, its
          approval status, and if there is any Contact information. The Update Progress button is also available here to change the approval status and edit if necessary.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={improvements} alt='improvements screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          If you want to make an Improvement Path from scratch, you can click the “Add Improvement Path” button below the table.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={improvement} alt='improvement screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 400 }}></img>
          </Grid>
        </Grid>
        <Typography variant='h6' style={{ marginTop: 32, marginBottom: 10 }}>
          Diagnostics
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Diagnostics tab is where an analyst will go if the Improvement Paths tab does not provide enough information, or appropriate recommendations, to create the necessary Improvement Paths.
          All of the results from a valve test will be available to see here, as opposed to the curated view in Improvement Paths.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          On the top you’ll see the same valve information as on the Performance tab, with the familiar test selection and comparison menus below.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          On the left, you’ll see a list of “Issues”. Each one of these issues has a color associated with it based on the results of your test. You’ll notice that this is a complete list of possible
          issues, including ones that are not triggered by the test. To learn more about each issue, you can click the Information Icon.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          To the right, you will see your diagnostic test results in graphical form. The graphs are expandable and zoomable like those in the Performance tab. You can use the graphs in conjunction
          with the Issues list to determine appropriate Improvement Paths.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={diag} alt='diagnostics screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Below, you will see sections called “Critical Diagnostics” and “Advisory Diagnostics”. These sections function similarly to the Improvement Paths tab in that it will populate based on
          whatever issues are triggered as red or yellow from above, respectively. They have the same recommendations you would see in Improvement Paths as well.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={diagPaths} alt='improvement paths screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='h6' style={{ marginTop: 32, marginBottom: 10 }}>
          Alignment
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Alignment Tab exists for control room operators. There will be similar information presented here as in Performance. At the top will be valve information.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Directly under are valve performance metrics indicating whether or not the valve is meeting the designating targets, and by how much it is above or below target.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={alignment} alt='alignment screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Below is the Alignment Issues and Recommendations section. Like the Improvement Paths tab, you’ll see Issue rows that can be expanded to show Recommendations. More information about each
          Issue can be found by clicking the Information Icon to open a helper. Once you have chosen your recommendation, you can submit it to create an Improvement Path. As a note, these Improvement
          Paths will only appear in the Alignment tab.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Next are the Performance graphs, which are expandable just like in the Performance tab.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={alignmentCharts} alt='alignment charts screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Lastly, there is a table showing Resolution and Sensitivity Signal, Step Time, and Change from Previous Step. The Signal is what percent move was asked for during the test. The Step Time is
          how long it took for 63% of the step to occur (or T63 for short). Change from Previous Step shows whether the Response time increased or decreased from the Previous Step.
        </Typography>
        <Typography variant='h6' style={{ marginTop: 32, marginBottom: 10 }}>
          Reports
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Let's now go over to the Reports tab to find out how we can view more summary information
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Portfolio Report shows an overall summary of your tested valves, which you can filter down to be more specific. You can see a comparison of how your overall performance has changed, as
          well as a percentage of which valves have issues and to what criticality those issues are.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={portfolio} alt='portfolio report screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Improvement Paths Report will show you a summary checklist of all the improvement paths entered into the system. At the top there are 3 pie graphs. The first shows a distribution of
          Issue types that need improvement. The second is a distribution of repair types. The Third shows a distribution of progress on your Improvement Paths.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={improvements3} alt='improvements report screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          In the table below you’ll see some key information as well as links to the tests themselves if you need to reference something about them more closely.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={improvements4} alt='improvements report table screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Valve Comparison Report allows you to compare devices to each other. First you need to select which devices you’d like to check. Once selected, you’ll be able to see 3 graphs,
          Resolution, Sensitivity, and Stroke Speed, much like you would in the Alignment tab of the Device Page. Below the graphs you will see the same issues list that you would find in the
          Diagnostic tab of the Device Page. This report provides a quick way for you to look at multiple devices on one screen.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={compare1} alt='comparison report screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <img src={compare2} alt='comparison report screenshot 2' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Performance History Report will show you a device’s change in performance over time. When you select a device, you will see 3 graphs showing changes in Resolution, Sensitivity, and
          Stroke Speed. In each graph, there is a target line to show how your test results compare to the desired performance of a given device.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={perfHistory1} alt='performance history screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  renderSection3() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Let's now look at the Configuration page. This page allows you to set certain identification information in Optimize before or after a valve test is complete and uploaded.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={database} alt='valve database screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          If you are starting with a new valve, click the “Add Valve” button. This will display a window that allows you to add all of your valve information.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          To give you a better idea of what this would look like filled in, let’s look at an existing valve in Optimize.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          By clicking on a valve from the table you'll see that it expands. There is information regarding the valves location, size, and type. You'll also notice the section that says recommendations
          with four questions. These questions, along with the identification information above, is how Optimize determines target performance for a valve. If you already have targets you wish to
          input, you do not need to fill out these questions and can add them manually below.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={config1} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          As always, to get further information about a question, click the information "i". Here you will get an explanation as to how you should fill out the questions. You can see these questions
          use a 1-5 scale of importance. If you do not feel comfortable answering these questions yourself, you should speak to a stakeholder who would know the answers, usually a process engineer on
          site.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Below the questions you’ll see fields for Resolution Target, Sensitivity Target, Stroke Speed Target, and Change Effective. These fields should be familiar as they are reflected in our 3
          main Performance tests.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          In this instance, you’ll also notice that under each field it says “System Default”. These default values are determined when a test is uploaded without previous configuration. Optimize will
          default each question to a “Standard” importance and, along with the use of your valve identification information, make target recommendations.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          To see your target recommendations, either generated by default or based on your own answers, click the ‘View Recommendations’ button.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={recommend} alt='recommendations screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 400 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          You can choose to “Confirm” the recommended targets or, if you want to specify them yourself, you can enter them manually. Notice that if a change is made, the “System Default” text
          disappears.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Lastly, the “Change Effective” field allows you to decide from which date your targets are reflected in your valve tests, if for some reason your performance needs change. If you do decide
          to make changes manually, you will simply click the “Submit” button when ready. If you do not hit “Submit” your changes will not be executed.
        </Typography>
      </React.Fragment>
    );
  }
  renderSection4() {
    return (
      <React.Fragment>
        <UploadDetails hideTitle></UploadDetails>
      </React.Fragment>
    );
  }
  renderSection5() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6'>Let’s review the steps of the Optimize Process. Each step is accompanied by a real world example using Optimize.</Typography>
        <Typography variant='h6' style={{ marginTop: 20 }}>
          1. Establish
        </Typography>
        <div style={{ marginLeft: 20 }}>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Using the configuration page, work with the stakeholder to define performance criteria for what you will be testing. Use the recommendation questions and their tooltips to get
            recommendations on what your desired performance criteria should be.
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <img src={config1} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
            </Grid>
          </Grid>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Perform an as-found test. Ensure any important field observations are recorded.
          </Typography>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Upload the as-found test on the upload page.
          </Typography>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Review the performance against the established targets on the alignment page. Does the valve meet the required performance criteria? Review the tooltips on the alignment page to get
            ideas about what might need to be adjusted to bring the valve into alignment.
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <img src={alignment1} alt='alignment screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
            </Grid>
          </Grid>
        </div>
        <Typography variant='h6' style={{ marginTop: 20 }}>
          2. Diagnose
        </Typography>
        <div style={{ marginLeft: 20 }}>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Review the reliability and parts performance in the diagnostics page. Going top to bottom, review each grouping of the checklist and rule-in or rule-out possibilities. Remember to use
            the tooltips to help you understand the possibilities, follow proven diagnostic paths, and to understand conclusions. Pictures are available in each tooltip to provide examples.
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <img src={diagnostics1} alt='diagnostics screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
            </Grid>
          </Grid>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • If the likely culprits are clear, proceed to write improvement paths. If there is conflicting evidence or you are unsure, consult with your subject matter experts.
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <img src={improvements2} alt='improvements screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
            </Grid>
          </Grid>
        </div>
        <Typography variant='h6' style={{ marginTop: 20 }}>
          3. Engage
        </Typography>
        <div style={{ marginLeft: 20 }}>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Write down all potential improvement paths that could be undertaken to improve the performance of the valve or improve reliability. Split each recommendation into its own improvement
            path, put justifications into full sentences in the comments, and add separate observations as ‘notes’. If in the field, always recommend to send the valve to a shop for repairs if
            degenerative valve-body conditions are detected.
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <img src={improvements1} alt='improvements screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 400 }}></img>
            </Grid>
          </Grid>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Be sure to consider the possibility that valve engineering may be insufficient to achieve desired performance and different components, metallurgy, sizes, etc. may be required.
          </Typography>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Prepare information about the logistics of completing these recommendations (how many days to acquire parts, costs, etc.)
          </Typography>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Present the findings via the Optimize website or printout to any parties that need to provide approval. Use the website to show them the data and why it impacts needed
            performance/reliability. Always present solutions to any problems you propose.
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <img src={improvements3} alt='improvements screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
            </Grid>
          </Grid>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Mark approved improvement paths in the improvement paths tab. As needed, adjust the improvement paths to capture any critical new information.
          </Typography>
        </div>
        <Typography variant='h6' style={{ marginTop: 20 }}>
          4. Align
        </Typography>
        <div style={{ marginLeft: 20 }}>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Make all approved changes to the valve.
          </Typography>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Calibrate and tune the valve after all approved changes are made
          </Typography>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Record an as-left test using the sequencer tool, and write down any observations about the valve.
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <img src={alignment2} alt='compare screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
            </Grid>
          </Grid>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • If improvements are insufficient, repeat the process from the ‘engage’ step. If there are still potential improvements left to recommend, pursue those recommendations. Alternatively, if
            no further improvement is obvious, consult with a subject matter expert. Be sure to consider the possibility that valve engineering may be insufficient and different components,
            metallurgy, sizes, etc. may be required.
          </Typography>
        </div>
        <Typography variant='h6' style={{ marginTop: 20 }}>
          5. Prove
        </Typography>
        <div style={{ marginLeft: 20 }}>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Review the data to capture the value provided. What valves were brought into alignment? What valves were likely to fail before the next time they were checked? What consequences were
            likely if action was not taken?
          </Typography>
          <Typography variant='body1' style={{ marginTop: 10 }}>
            • Create a summary report via the Reports section of Optimize that includes:
          </Typography>
          <div style={{ marginLeft: 20 }}>
            <Typography variant='subtitle2' style={{ marginTop: 5 }}>
              A summary of why they should pay attention / value provided
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={helper1} alt='helper screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='subtitle2' style={{ marginTop: 5 }}>
              Every as-found/as-left test, comparisons showing improvement. You can print reports as PDFs by checking out our tips to print here
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={compare1} alt='comparison report screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='subtitle2' style={{ marginTop: 5 }}>
              Use the Improvement Paths Report to provide summary information of improvements recommended and completed
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={improvements3} alt='improvements screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='subtitle2' style={{ marginTop: 5 }}>
              Use the Performance History, Valve Comparison, and Portfolio Reports to provide information about any important trends you wish to communicate – about valve engineering, process wear,
              etc.
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <img src={perfHistory1} alt='performance history screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
              </Grid>
            </Grid>
            <Typography variant='subtitle2' style={{ marginTop: 5 }}>
              Any other conclusions that would improve performance or reduce costs of future projects
            </Typography>
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderSection6() {
    return <React.Fragment></React.Fragment>;
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginTop: 20, marginBottom: 10 }}>
          Hello and welcome to Optimize by Ten One! This sequence of videos will give you the knowledge you need to start using Optimize effectively.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          If you prefer, you may scroll down to read through the various topics covered in these the various topics covered in these videos instead.
        </Typography>
        <Typography variant='subtitle2' style={{ marginTop: 10 }}>
          • The Optimize Process
        </Typography>
        <Typography variant='subtitle2' style={{ marginTop: 5 }}>
          • A Brief Tour of the Website
        </Typography>
        <Typography variant='subtitle2' style={{ marginTop: 5 }}>
          • Configure your Valves
        </Typography>
        <Typography variant='subtitle2' style={{ marginTop: 5 }}>
          • Uploading your Tests to Optimize
        </Typography>
        <Typography variant='subtitle2' style={{ marginTop: 5 }}>
          • The Optimize Process: Step-by-Step Review
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          If you have any questions after that, you should review our{' '}
          <Link
            onClick={() => {
              this.props.supportSetActiveOptimize('faq');
            }}
            to='/support/Optimize/FAQ'
            className={classes.textLink}
          >
            Frequently Asked Questions(FAQ)
          </Link>
          . Don't see the information you need there?{' '}
          <Link
            onClick={() => {
              this.props.supportSetActiveOptimize('contact');
            }}
            to='/support/Optimize/Contact'
            className={classes.textLink}
          >
            Contact us
          </Link>{' '}
          and we'll be happy to help.
        </Typography>
        {/*<Grid container spacing={1} style={{ marginTop: 10, marginBottom: 20 }}>
          <VideoContainer url='https://vimeo.com/showcase/8925543/embed'></VideoContainer>
          </Grid>*/}
        <Accordion style={{ marginTop: 20 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>The Optimize Process</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection1()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>A Brief Tour of the Website</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection2()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Configure Your Valves</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection3()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Uploading your Tests to Optimize</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection4()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>The Optimize Process: Step-by-Step Review</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection5()}</AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.support.activeTab,
    activeOptimize: state.support.activeOptimize,
  };
};

export default connect(mapStateToProps, {
  supportSetActiveTab,
  supportSetActiveOptimize,
})(withStyles(styles, { withTheme: true })(Start));
