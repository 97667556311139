import React from 'react';
import WidgetTitle from '../../headers/WidgetTitle';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import OptimizeContent from './content/OptimizeContent';
import CustomDialog from '../../popup/CustomDialog';
import DialogContentText from '@mui/material/DialogContentText';
import CustomSelect from '../../filters/CustomSelect';
import { Link } from 'react-router-dom';
import { AddBox } from '@mui/icons-material';
import { Button, Grid, ToggleButton, ToggleButtonGroup, Typography, TextField, CircularProgress } from '@mui/material';
import TableWidgetExpandable from '../../widgets/TableWidgetExpandable';
import { fetchDevicesSummaryVerbose, setDevicesSummaryStatus, reportsSetActiveReport, clearState } from '../../../actions/index';
import { CompareArrows } from '@mui/icons-material';
import api from '../../../apis/api';
//require('string_score'); // USE REQUIRE TO AVOID 'no use of declared item'. // TODO replace with better search package
import matchFilter from '../../filters/functions/searchFilter';
import SetDefaultsContent from './content/SetDefaultsContent';

class OptimizeWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transferOpen: false,
      transferRealmId: -1,
      order: 'asc',
      orderBy: 'concern',
      selected: [],
      //data: this.props.data,
      page: 0,
      rowsPerPage: this.props.rows,
      rowOpen: false,
      clickedRow: null,
      rowBorder: null,
      rowChange: {},
      recConfirm: false,
      senRec: null,
      resRec: null,
      strokeRec: null,
      filterStr: '',
      valveDialogOpen: false,
    };
    //this.recommendTargets = this.recommendTargets.bind(this);
    //this.onSearchChange = this.onSearchChange.bind(this);
    //this.onSearchSelect = this.onSearchSelect.bind(this);
  }
  componentDidMount() {
    //console.log(this.props.currentModule);
    //console.log(this.props.devices.summary);
    //console.log(this.props.configModule);
    let url = window.location.href.toLowerCase();
    url.includes('defaults') ? this.props.reportsSetActiveReport('defaults') : this.props.reportsSetActiveReport('valveDatabase');
    if (
      this.props.currentModule === 'Optimize' ||
      this.props.reportModule === 'Optimize' ||
      this.props.configModule === 'Optimize' ||
      this.props.currentModule === null /* &&
      this.props.deviceSummary === false*/
    ) {
      this.props.clearState();
      this.props.setDevicesSummaryStatus(true);
      this.props.fetchDevicesSummaryVerbose();
      //
      //this.props.fetchDevices();
    }
  }
  handleReport(event, value) {
    this.props.reportsSetActiveReport(value);
    if (!value) {
      this.props.reportsSetActiveReport('valveDatabase');
    }
  }
  getOptions = () => {
    let options = [];
    const deviceKeys = Object.keys(this.props.devices);

    options = deviceKeys.map((key) => {
      //console.log(key);
      const device = this.props.devices[key];
      return {
        id: device.deviceId,
        serial: device.serial,
        name: device.name,
        site: device.site,
        client: device.client,
        application: device.application,
        deviceId: device.deviceId,
        bodySize: device.bodySize,
        bodyType: device.bodyType,
        description: device.description,
        importance: device.importance,
        accuracyImportance: device.accuracyImportance,
        speedImportance: device.speedImportance,
        performanceSatisfaction: device.performanceSatisfaction,
        resApp: device.appRes,
        senApp: device.appSen,
        strokeApp: device.appStroke,
        currentSerial: device.serial,
      };
    });
    const setKeys = ['serial', 'name', 'site', 'application', 'bodyType', 'description', 'importance'];
    const filteredOptions = matchFilter(options, this.state.filterStr, setKeys);
    return filteredOptions;
    //return options.filter((x) => x.name === str);
  };

  handleRowClick = (row, e) => {
    //const device = this.props.devices[row];
    //console.log(device);
    //console.log(row,e,f,g);
    if (e.target.type !== 'checkbox') {
      this.setState((state) => ({
        clickedRow: row,
      }));
      if (this.state.rowOpen && row === this.state.clickedRow) {
        this.setState((state) => ({ rowOpen: !state.rowOpen }));
      } else {
        this.setState((state) => ({ rowOpen: false }));
        this.setState((state) => ({ rowOpen: !state.rowOpen }));
      }
    } else {
      const index = this.state.selected.indexOf(row);
      let selections = this.state.selected;
      if (index === -1) {
        selections.push(row);
      } else {
        selections.splice(index, 1);
      }
      this.setState({ selected: selections });
    }
  };
  //TODO NEED TO CHANGE IN CASE OF ALPHANUMERIC IDS
  handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const rowIds = Object.keys(this.props.devices);
      let selections = this.state.selected;
      for (let i = 0; i < rowIds.length; i++) {
        const index = selections.indexOf(parseInt(rowIds[i]));
        if (index === -1) {
          selections.push(parseInt(rowIds[i]));
        } else {
          //this.state.selected.splice(index, 1);
        }
      }
      this.setState({ selected: selections });
      /*const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;*/
    } else {
      this.setState({ selected: [] });
    }
    //setSelected([]);
  };

  handleAddValve = () => {};

  transferDialog() {
    //const { classes } = this.props;
    let current_realm = this.props.realms.find((o) => parseInt(o.id) === parseInt(this.props.current_realm));
    let availableTransferRealms = this.props.realms
      .filter((realm) => {
        return current_realm.name !== realm.name;
      })
      .map((realm) => realm.name);
    return (
      <CustomDialog
        center
        open={this.state.transferOpen}
        onClose={() => {
          this.setState({ transferOpen: false });
        }}
        title='Copy Devices'
        content={
          <React.Fragment>
            <DialogContentText style={{ paddingTop: 20 }}>Select select a realm to copy these devices to.</DialogContentText>
            <form>
              <CustomSelect
                single
                id={null}
                label='Select Realm'
                type='realm'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value, type) => {
                  let new_realm = this.props.realms.find((o) => o.name === value);
                  //this.props.setRealm(new_realm.id);
                  if (!!new_realm) {
                    this.setState({ transferRealmId: new_realm.id });
                  }
                }}
                values={
                  !this.props.realms
                    ? 'Loading'
                    : this.state.transferRealmId === -1
                    ? 'Select Realm'
                    : this.props.realms
                        .filter((realm) => {
                          return realm.id === this.state.transferRealmId;
                        })
                        .map((realm) => realm.name)[0]
                }
                options={!this.props.realms ? ['Loading...'] : this.state.transferRealmId === -1 ? availableTransferRealms.concat(['Select Realm']) : availableTransferRealms}
                //value={null}
                helperText={null}
              />

              {/*<SingleSelect
                label={`Select Realm`}
                selectedOption={'Select Realm'}
                options={this.props.realms
                  .filter((realm) => {
                    return current_realm.name !== realm.name;
                  })
                  .map((realm) => realm.name)}
                handleChange={(e) => {
                  let new_realm = this.props.realms.find((o) => o.name === e);
                  //this.props.setRealm(new_realm.id);
                  //console.log(new_realm.id);
                  this.setState({ transferRealmId: new_realm.id });
                }}
                minWidth
              ></SingleSelect>*/}
            </form>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.closeApproval} color='primary'>
              Cancel
            </Button>
            <Button onClick={this.handleTransfer} color='primary'>
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }

  handleTransfer = async () => {
    const url = `/optimize/devices/transfer`;
    const response = await api.post(url, {
      devices: this.state.selected,
      targetRealm: this.state.transferRealmId,
    });
    console.log(response);
    this.setState({
      transferRealmId: -1,
      transferOpen: false,
    });
  };

  renderChildRowContent = (row) => {
    return (
      <React.Fragment>
        <OptimizeContent
          padding
          row={row}
          clickedRow={this.state.clickedRow}
          rowOpen={this.state.rowOpen}
          bubbleRowOpen={() => {
            this.setState((state) => ({ rowOpen: false }));
          }}
        />
      </React.Fragment>
    );
  };
  handleClose = () => {
    this.setState({
      valveDialogOpen: false,
    });
  };
  showValveForm(row) {
    //const { classes } = this.props;
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.valveDialogOpen}
        onClose={this.handleValveDialogClose}
        title='Add Valve'
        titleIcon='Add'
        content={
          <OptimizeContent
            row={row}
            clickedRow={null}
            rowOpen={true}
            bubbleRowOpen={() => {
              this.setState((state) => ({ rowOpen: false }));
            }}
            closeFunction={this.handleClose}
            addPadding
          />
        }
      ></CustomDialog>
    );
  }
  handleValveDialogOpen = () => {
    this.setState({ valveDialogOpen: true });
  };
  handleValveDialogClose = () => {
    this.setState({ valveDialogOpen: false });
  };
  render() {
    const { classes } = this.props;
    const headerArr = [
      { id: 'name', label: 'Tag #' },
      { id: 'serial', label: 'Serial #' },
      { id: 'site', label: 'Site' },
      { id: 'application', label: 'Application' },
      //{ id: 'role', label: 'Role' },
      { id: 'description', label: 'Description' },
      { id: 'bodySize', label: 'Size' },
      { id: 'bodyType', label: 'Type' },
      { id: 'importance', label: 'Importance' },
    ];
    const data = this.getOptions();
    const menuOptions = [];
    const row = { id: -1 };
    menuOptions.push({
      id: 'transfer',
      title: 'Transfer Devices',
      icon: CompareArrows,
      onClick: () => {
        this.setState({ transferOpen: true });
      },
    });
    return (
      <React.Fragment>
        {this.transferDialog()}
        {this.showValveForm(row)}
        <ToggleButtonGroup value={this.props.activeReport} exclusive onChange={(event, value) => this.handleReport(event, value)} aria-label='text alignment' className={classes.toggleButtons}>
          <ToggleButton value='valveDatabase' component={Link} to={`/configure/Optimize/valvedatabase`}>
            Valve Database
          </ToggleButton>
          <ToggleButton value='defaults' component={Link} to={`/configure/Optimize/defaults`}>
            Set Defaults
          </ToggleButton>
        </ToggleButtonGroup>
        {this.props.activeReport === 'defaults' ? (
          <React.Fragment>
            <WidgetTitle title='Set Defaults'></WidgetTitle>
            <SetDefaultsContent />
          </React.Fragment>
        ) : this.props.activeReport === 'valveDatabase' ? (
          <React.Fragment>
            <WidgetTitle title='Valve Database'></WidgetTitle>
            <Grid container justifyContent='space-between' spacing={0} className={classes.printHide}>
              <Grid item>
                <TextField
                  variant='outlined'
                  InputProps={{ classes: { root: classes.inlineInput } }}
                  className={classes.searchField}
                  placeholder='Search'
                  onChange={(event) => {
                    //console.log(event.target.value);
                    this.setState({ filterStr: event.target.value });
                  }}
                  value={this.state.filterStr}
                />
              </Grid>
              <Grid item>
                <Button variant='outlined' size='medium' color='primary' onClick={this.handleValveDialogOpen} className={classes.buttonFilterRight} style={{ marginBottom: 8 }}>
                  <AddBox className={classes.buttonIcon}></AddBox>Add Valve
                </Button>
              </Grid>
            </Grid>
            {data.length === 0 ? (
              <Typography variant='subtitle2'>
                <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                Loading...
              </Typography>
            ) : (
              <React.Fragment>
                <Typography variant='body2' style={{ marginBottom: 4 }} className={classes.printHide}>
                  Click any row to expand
                </Typography>
                <TableWidgetExpandable
                  data={data}
                  //selectable={true}
                  selectable={false}
                  onSelectAllClick={this.handleSelectAllClick}
                  selections={this.state.selected}
                  selectionActions={menuOptions}
                  orderBy={'name'}
                  header={headerArr}
                  rows={25}
                  type='valveConfig'
                  handleClick={this.handleRowClick}
                  renderChildRowContent={this.renderChildRowContent}
                  rowOpen={this.state.rowOpen}
                ></TableWidgetExpandable>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    devices: state.devices.devices,
    currentModule: state.currentModule.currentModule,
    realms: state.auth.realms,
    current_realm: state.auth.current_realm,
    activeReport: state.reports.activeReport,
  };
};

export default connect(mapStateToProps, {
  fetchDevicesSummaryVerbose,
  setDevicesSummaryStatus,
  reportsSetActiveReport,
  clearState,
})(withStyles(styles)(OptimizeWidgets));
