import React from 'react';
import { Grid, Button } from '@mui/material';
import TableWidget from '../../../widgets/TableWidget';
import WidgetTitle from '../../../headers/WidgetTitle';
import CustomSelect from '../../../filters/CustomSelect';
import { RestartAlt } from '@mui/icons-material';
import { Typography } from '@mui/material';
import DatePicker from '../../../filters/DatePicker';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import PrintHeader from '../../../headers/PrintHeader';
import { getPortfolioReport, fetchLocations, fetchApplications, fetchSites, fetchClients, updateFilter, clearFilter, updateDateRange } from '../../../../actions/index';
import initializeTheme from '../../../theme';
import ReportChartWidget from '../../../widgets/ReportChartWidget';

/*const headerArrFound = [
  { id: 'importance', label: 'Importance' },
  { id: 'percentAffected', label: 'Percent Affected' },
  { id: 'resolutionAvg', label: 'Resolution Avg' },
  { id: 'sensitivityAvg', label: 'Sensitivity Avg' },
  { id: 'strokeAvg', label: 'Stroke Speed Avg' },
];
const headerArr = [
  { id: 'importance', label: 'Importance' },
  { id: 'percentAffected', label: 'Percent Affected' },
  { id: 'resolutionFormatted', label: 'Resolution Avg (Change)' },
  { id: 'sensitivityFormatted', label: 'Sensitivity Avg (Change)' },
  { id: 'strokeFormatted', label: 'Stroke Speed Avg (Change)' },
];*/
const headerArr = [
  { id: 'state', label: 'State' },
  { id: 'sensitivityFormatted', label: 'Sensitivity' },
  { id: 'resolutionFormatted', label: 'Resolution' },
  { id: 'strokeFormatted', label: 'Stroke' },
];
const tableHeader = [
  { id: 'importance', label: 'Importance' },
  { id: 'Okay', label: 'Good' },
  { id: 'Advisory', label: 'Advisory' },
  { id: 'Warning', label: 'Warning' },
  { id: 'Critical', label: 'Critical' },
  { id: 'Unknown', label: 'Unknown' },
  { id: 'overall', label: 'Overall' },
];

class PortfolioReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      importance: 'Overall',
      type: 'Oldest',
      loading: false,
    };
    this.resetFilters = this.resetFilters.bind(this);
  }

  async componentDidMount() {
    let filters = {
      application: [],
      site: [],
      location: [],
      client: [],
    };
    let times = {
      start: this.props.sort.filter.Range.startDate,
      end: this.props.sort.filter.Range.endDate,
    };

    let obj = { times, filters };
    await this.props.clearFilter();
    this.setState({ loading: true });
    this.props.fetchSites();
    this.props.fetchLocations();
    this.props.fetchApplications();
    this.props.fetchClients();
    await this.props.getPortfolioReport(obj);
    this.setState({ loading: false });
  }
  calculateArrayAverage(array) {
    if (array.length === 0) {
      return null;
    } else {
      return array.reduce((a, b) => a + b) / array.length;
    }
  }
  arrayCount = (objArr, key) => {
    const counts = {};
    for (const num of objArr) {
      let value = num[key];
      if (typeof value === 'string') {
        value = Number.parseFloat(value);
      }
      let level = this.getCriticality(value);
      counts[level] = counts[level] ? counts[level] + 1 : 1;
    }
    return counts;
  };
  getCriticality(value) {
    let level = -1;
    //num[key]
    if (value < 0) {
      level = 'Unknown';
    } else if (value < 10) {
      level = 'Okay';
    } else if (value < 20) {
      level = 'Warning';
    } else if (value < 30) {
      level = 'Advisory';
    } else {
      level = 'Critical';
    }
    return level;
  }
  getCriticalityColor(value) {
    const theme = initializeTheme(this.props.colorMode);
    let color = -1;
    //num[key]
    if (value === 'Unknown') {
      color = theme.palette.gray.color;
    } else if (value === 'Okay') {
      color = theme.palette.green.color;
    } else if (value === 'Advisory') {
      color = theme.palette.purple.color;
    } else if (value === 'Warning') {
      color = theme.palette.yellow.color;
    } else if (value === 'Critical') {
      color = theme.palette.red.color;
    } else {
      color = '#333';
    }
    return color;
  }
  processData(data) {
    let tableObjRecent = {
      Okay: 0,
      Warning: 0,
      Advisory: 0,
      Critical: 0,
      Unknown: 0,
    };
    let tableObjCompare = {
      Okay: 0,
      Warning: 0,
      Advisory: 0,
      Critical: 0,
      Unknown: 0,
    };
    let seriesObjRecent = {
      Okay: 0,
      Advisory: 0,
      Warning: 0,
      Critical: 0,
      Unknown: 0,
    };
    let seriesObjCompare = {
      Okay: 0,
      Advisory: 0,
      Warning: 0,
      Critical: 0,
      Unknown: 0,
    };

    for (let i = 0; i < data.length; i++) {
      let valveData = data[i];
      let reliabilityCompare = Number.parseFloat(valveData['reliabilityScore2']);
      let reliabilityRecent = Number.parseFloat(valveData['reliabilityScore1']);

      let criticalityRecent = this.getCriticality(reliabilityRecent);
      let criticalityCompare = this.getCriticality(reliabilityCompare);

      tableObjRecent[criticalityRecent]++;
      tableObjCompare[criticalityCompare]++;
      seriesObjRecent[criticalityRecent]++;
      seriesObjCompare[criticalityCompare]++;
    }

    let seriesOldest = [];
    let seriesRecent = [];
    let criticalities = Object.keys(seriesObjRecent);
    for (let i = 0; i < criticalities.length; i++) {
      let criticality = criticalities[i];
      let color = this.getCriticalityColor(criticalities[i]);

      //dataRecent.push(seriesObjRecent[criticality]);
      //dataOldest.push(seriesObjCompare[criticality]);
      if (seriesObjCompare[criticality] > 0) {
        seriesOldest.push({
          //linkedTo: criticality,
          name: criticality,
          y: seriesObjCompare[criticality],
          color: color,
          //stack: 'Oldest',
        });
      }
      if (seriesObjRecent[criticality] > 0) {
        seriesRecent.push({
          //id: criticality,
          name: criticality,
          y: seriesObjRecent[criticality],
          color: color,
          //stack: 'Recent',
        });
      }
    }

    let tableCriticality = [];
    //let tableCompare = [];

    let rowCompare = { id: 1, importance: this.state.type, overall: 0 };
    let rowRecent = { id: 0, importance: 'Recent', overall: 0 };

    for (let j = 0; j < criticalities.length; j++) {
      rowRecent[criticalities[j]] = tableObjRecent[criticalities[j]];
      rowRecent.overall = rowRecent.overall + tableObjRecent[criticalities[j]];
      rowCompare[criticalities[j]] = tableObjCompare[criticalities[j]];
      rowCompare.overall = rowCompare.overall + tableObjCompare[criticalities[j]];
    }
    if (rowRecent.overall !== 0) {
      tableCriticality.push(rowCompare);
      tableCriticality.push(rowRecent);
    }

    return { seriesOldest, seriesRecent, tableCriticality };
  }
  createTable(data, id, status, state) {
    const { classes } = this.props;
    //also make a overall row
    /*
    id: x
state: 'AF/AL/Change' // 3 rows
sensitivity: 
resolution:
stroke:
*/
    let varKey = status;
    let table = [];

    let states = [this.state.type, 'Recent'];
    let types = ['resolution', 'sensitivity', 'stroke'];
    let filteredData =
      status.toLowerCase() === 'overall'
        ? data
        : this.filterKeyByCriteria(
            data,
            'importance',
            //state === 'As Left' ? 'reliabilityScore1' : 'reliabilityScore2',
            varKey
            //this.getCriticality
          );

    if (filteredData.length > 0) {
      for (let i = 0; i < states.length; i++) {
        let row = {};
        row.id = i;
        row.state = states[i];
        let modifier = states[i] === 'Recent' ? 'Recent' : 'Compare';
        for (let j = 0; j < types.length; j++) {
          row[types[j]] =
            Math.round(
              this.calculateArrayAverage(
                this.mapAndFilter(
                  filteredData,
                  types[j] + 'Result' + modifier,
                  types[j] + 'Delta' //make sure a delta is available
                )
              ) * 100
            ) / 100;
        }
        table.push(row);
      }
      table.push({
        id: table.length,
        state: 'Change',
        resolutionFormatted: (
          <Typography variant='subtitle2' className={table[1].resolution - table[0].resolution < 0 ? classes.greenText : table[1].resolution - table[0].resolution === 0 ? null : classes.redText}>
            {Math.round((table[1].resolution - table[0].resolution) * 100) / 100}%
          </Typography>
        ),
        sensitivityFormatted: (
          <Typography variant='subtitle2' className={table[1].sensitivity - table[0].sensitivity < 0 ? classes.greenText : table[1].sensitivity - table[0].sensitivity === 0 ? null : classes.redText}>
            {Math.round((table[1].sensitivity - table[0].sensitivity) * 100) / 100}%
          </Typography>
        ),
        strokeFormatted: (
          <Typography variant='subtitle2' className={table[1].stroke - table[0].stroke < 0 ? classes.greenText : table[1].stroke - table[0].stroke === 0 ? null : classes.redText}>
            {Math.round((table[1].stroke - table[0].stroke) * 100) / 100}s
          </Typography>
        ),
        resolution: Math.round((table[1].resolution - table[0].resolution) * 100) / 100,
        sensitivity: Math.round((table[1].sensitivity - table[0].sensitivity) * 100) / 100,
        stroke: Math.round((table[1].stroke - table[0].stroke) * 100) / 100,
      });
      table[0].resolutionFormatted = <Typography variant='subtitle2'>{table[0].resolution}%</Typography>;
      table[0].sensitivityFormatted = <Typography variant='subtitle2'>{table[0].sensitivity}%</Typography>;
      table[0].strokeFormatted = <Typography variant='subtitle2'>{table[0].stroke}s</Typography>;
      table[1].resolutionFormatted = <Typography variant='subtitle2'>{table[1].resolution}%</Typography>;
      table[1].sensitivityFormatted = <Typography variant='subtitle2'>{table[1].sensitivity}%</Typography>;
      table[1].strokeFormatted = <Typography variant='subtitle2'>{table[1].stroke}s</Typography>;
      //console.log(table);
      return table;
    } else {
      return null;
    }
  }

  createRow(data, id, status, state) {
    let varKey = status;
    let row = { id };
    let filteredData =
      varKey === 'Overall'
        ? data
        : this.filterKeyByCriteria(
            data,
            'importance',
            //state === 'As Left' ? 'reliabilityScore1' : 'reliabilityScore2',
            varKey
            //this.getCriticality
          );
    let modifier = state === 'As Left' ? 'Recent' : 'Compare';

    if (filteredData.length > 0) {
      row.importance = varKey;
      row.percentAffected = Math.round((filteredData.length / data.length) * 1000) / 10;

      row.resolutionAvg =
        Math.round(
          this.calculateArrayAverage(
            this.mapAndFilter(
              filteredData,
              'resolutionResult' + modifier,
              'resolutionDelta' //make sure a delta is available
            )
          ) * 100
        ) / 100;
      row.sensitivityAvg =
        Math.round(
          this.calculateArrayAverage(
            this.mapAndFilter(
              filteredData,
              'sensitivityResult' + modifier,
              'sensitivityDelta' //make sure a delta is available
            )
          ) * 100
        ) / 100;
      row.strokeAvg =
        Math.round(
          this.calculateArrayAverage(
            this.mapAndFilter(
              filteredData,
              'strokeResult' + modifier,
              'strokeDelta' //make sure a delta is available
            )
          ) * 100
        ) / 100;
      if (state === 'As Left') {
        row.sensitivityChange =
          Math.round(
            this.calculateArrayAverage(
              this.mapAndFilter(
                filteredData,
                'sensitivityDelta',
                'sensitivityDelta' //make sure a delta is available
              )
            ) * 100
          ) / 100;
        row.resolutionChange =
          Math.round(
            this.calculateArrayAverage(
              this.mapAndFilter(
                filteredData,
                'resolutionDelta',
                'resolutionDelta' //make sure a delta is available
              )
            ) * 100
          ) / 100;
        row.strokeChange =
          Math.round(
            this.calculateArrayAverage(
              this.mapAndFilter(
                filteredData,
                'strokeDelta',
                'strokeDelta' //make sure a delta is available
              )
            ) * 100
          ) / 100;
      }
      return row;
    } else {
      return null;
    }
  }
  filterKeyByCriteria = (arr, index, criteria, processFn) => {
    if (!processFn) {
      return arr.filter(function (obj) {
        return obj[index] === criteria;
      });
    } else {
      return arr.filter(function (obj) {
        return processFn(obj[index]) === criteria;
      });
    }
  };

  mapAndFilter = (objArr, key, deltaKey) => {
    return objArr
      .map((val) => {
        if (!!deltaKey) {
          //only look for comparisons where a test is available to compare
          return val[deltaKey] !== null ? val[key] : null;
        } else {
          return val[key];
        }
      })
      .filter(this.filterNullFunction);
  };
  filterNullFunction = (el) => {
    return el !== null;
  };

  refreshReport = (event, label) => {
    let filters = {
      application: this.props.sort.filter.Application,
      site: this.props.sort.filter.Site,
      location: this.props.sort.filter.Location,
      client: this.props.sort.filter.Client,
    };
    let times = {
      start: this.props.sort.filter.Range.startDate,
      end: this.props.sort.filter.Range.endDate,
    };

    //if (label !== 'Client') {
    this.props.fetchClients(null, filters);
    //}
    //if (label !== 'Location') {
    this.props.fetchLocations(null, filters);
    //}
    //}

    //if (!this.props.applications || Object.entries(this.props.applications).length === 0) {
    //if (label !== 'Application') {
    this.props.fetchApplications(null, filters);
    //}
    //}

    //if (!this.props.sites || Object.entries(this.props.sites).length === 0) {
    // if (label !== 'Site') {
    this.props.fetchSites(null, filters);
    // }
    //}

    let obj = { times, filters, type: this.state.type };
    this.props.getPortfolioReport(obj);
  };
  multiSelectOnChange = (event, value) => {
    let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    obj[name] = value;
    this.props.updateFilter(obj);
  };
  isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  updateDateRange = async (value, type) => {
    if (this.isValidDate(value)) {
      //do nothing if the date isn't valid
      if (type === 'start') {
        this.props.updateDateRange({
          startDate: Math.floor(value.getTime() / 1000),
          endDate: this.props.sort.filter.Range.endDate,
        });
      } else {
        this.props.updateDateRange({
          startDate: this.props.sort.filter.Range.startDate,
          endDate: Math.floor(value.getTime() / 1000),
        });
      }
    }
  };

  processImprovementSeries(oldestData, recentData) {
    return [
      {
        index: 1,
        color: '#E19878',
        data: oldestData,
        id: this.state.type,
        name: this.state.type,
        stack: this.state.type,
      },
      {
        index: 2,
        color: '#598FD6',
        data: recentData,
        id: 'Recent',
        name: 'Recent',
        stack: 'Recent',
      },
    ];
  }
  async resetFilters() {
    await this.props.clearFilter();
    await this.setState({
      importance: 'Overall',
      type: 'Oldest',
    });
    this.refreshReport();
  }
  render() {
    const { classes } = this.props;
    const data = this.props.reportData;
    const importance = this.state.importance;
    const processedData = this.processData(data.filter((device) => device.importance === importance || importance === 'Overall'));

    const newDataOldest = [{ name: 'Vulnerability', data: processedData.seriesOldest }];

    const newDataRecent = [{ name: 'Vulnerability', data: processedData.seriesRecent }];

    const tableDataCriticality = processedData.tableCriticality;
    //const tableDataAsLeft = processedData.tableRecent;

    const dataAsLeft = [];
    const dataAsFound = [];

    const dataStrokeRecent = [];
    const dataStrokeOldest = [];
    const dataSenRecent = [];
    const dataSenOldest = [];
    const dataResRecent = [];
    const dataResOldest = [];

    const tableData = {};
    if (!!data && data.length > 0) {
      tableData[importance] = this.createTable(data, 0, importance);
      //console.log(tableData);

      let localFound = this.createRow(data, 0, importance);

      let localLeft = this.createRow(data, 1, importance, 'As Left');

      dataResOldest.push(!localFound ? null : localFound.resolutionAvg);
      dataResRecent.push(!localLeft ? null : localLeft.resolutionAvg);
      dataSenOldest.push(!localFound ? null : localFound.sensitivityAvg);
      dataSenRecent.push(!localLeft ? null : localLeft.sensitivityAvg);
      dataStrokeOldest.push(!localFound ? null : localFound.strokeAvg);
      dataStrokeRecent.push(!localLeft ? null : localLeft.strokeAvg);
      if (localFound !== null) {
        dataAsFound.push(localFound);
        dataAsLeft.push(localLeft);
      }
    }

    const newDataStroke = this.processImprovementSeries(dataStrokeOldest, dataStrokeRecent);
    const newDataRes = this.processImprovementSeries(dataResOldest, dataResRecent);
    const newDataSen = this.processImprovementSeries(dataSenOldest, dataSenRecent);

    return (
      <React.Fragment>
        <form className={classes.form} autoComplete='off'>
          <Grid container>
            <DatePicker
              label='Start Date'
              disabled={this.state.loading}
              //value={new Date(this.props.sort.filter.Range.startDate * 1000)}
              //date={`${new Date().getFullYear() - 2}-01-01T12:00:00`}
              date={new Date(this.props.sort.filter.Range.startDate * 1000)}
              dateFunction={(startDate) => {
                this.updateDateRange(startDate, 'start');
              }}
            ></DatePicker>
            <DatePicker
              label='End Date'
              value={new Date(this.props.sort.filter.Range.endDate * 1000)}
              disabled={this.state.loading}
              dateFunction={(endDate) => {
                this.updateDateRange(endDate, 'end');
              }}
            ></DatePicker>
          </Grid>
          <CustomSelect
            //id={null}
            label='Client'
            variant='standard'
            //type='site'
            options={!this.props.clients || !this.props.clients.list ? [] : this.props.clients.list.sort()}
            onCloseFunction={this.refreshReport}
            onChangeFunction={this.multiSelectOnChange}
            //selectedOption={'Resolution'}
            values={!this.props.sort.filter.Client ? [] : this.props.sort.filter.Client}
            value={null}
            helperText={null}
          />
          <CustomSelect
            //id={null}
            label='Site'
            //type='site'
            options={!this.props.sites || !this.props.sites.list ? [] : this.props.sites.list.sort()}
            onCloseFunction={this.refreshReport}
            onChangeFunction={this.multiSelectOnChange}
            //selectedOption={'Resolution'}
            values={!this.props.sort.filter.Site ? [] : this.props.sort.filter.Site}
            value={null}
            helperText={null}
          />
          <CustomSelect
            //id={null}
            label='Location'
            //type='site'
            options={!this.props.locations || !this.props.locations.list ? [] : this.props.locations.list.sort()}
            onCloseFunction={this.refreshReport}
            onChangeFunction={this.multiSelectOnChange}
            //selectedOption={'Resolution'}
            values={!this.props.sort.filter.Location ? [] : this.props.sort.filter.Location}
            value={null}
            helperText={null}
          />
          <CustomSelect
            //id={null}
            label='Application'
            //type='site'
            options={!this.props.applications || !this.props.applications.list ? [] : this.props.applications.list.sort()}
            onCloseFunction={this.refreshReport}
            onChangeFunction={this.multiSelectOnChange}
            //selectedOption={'Resolution'}
            values={!this.props.sort.filter.Application ? [] : this.props.sort.filter.Application}
            value={null}
            helperText={null}
          />
          <CustomSelect
            single
            //id={null}
            label='Importance'
            //type='site'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={(event, value) => {
              this.setState({ importance: value });
            }}
            //type='unit'
            values={this.state.importance}
            options={['Overall', 'Extremely Important', 'Very Important', 'Important', 'Standard', 'Unimportant', 'Unknown']}
            //value={null}
            helperText={null}
          />
          <CustomSelect
            single
            //id={null}
            label='Test Type'
            //type='site'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={async (e, v) => {
              await this.setState({ type: v });
              this.refreshReport();
            }}
            //type='unit'
            values={this.state.type}
            options={['Oldest', 'Previous']}
            //value={null}
            helperText={null}
          />
          <div style={{ float: 'right' }}>
            <Button variant='outlined' color='primary' aria-label='submit' className={`${classes.buttonFilterInline} ${classes.printHide}`} onClick={this.refreshReport}>
              Submit
            </Button>
            <Button className={`${classes.buttonFilterInline} ${classes.printHide}`} variant='outlined' onClick={this.resetFilters}>
              <RestartAlt className={classes.buttonIcon}></RestartAlt>Clear All Filters
            </Button>
          </div>
        </form>
        <div className={classes.printWidgetSpacer}></div>
        <WidgetTitle title='Valve Portfolio Summary' />
        <Grid container spacing={1}>
          <ReportChartWidget title='Resolution' suffix='%' titleY='Signal (%)' type='bar' initialWidth={4} seriesData={newDataRes} loading={this.state.loading}></ReportChartWidget>
          <ReportChartWidget title='Sensitivity' suffix='%' titleY='Signal (%)' type='bar' initialWidth={4} seriesData={newDataSen} loading={this.state.loading}></ReportChartWidget>
          <ReportChartWidget title='Stroke Speed' suffix='s' titleY='Time (s)' type='bar' initialWidth={4} seriesData={newDataStroke} loading={this.state.loading}></ReportChartWidget>
          {/*<Grid item sm={4} xs={12}>
            <Paper className={`${printStyles ? classes.printPaper : classes.paper} `} elevation={3}>
              <Chart type='bar' title='Resolution' suffix='%' titleY='Signal (%)' options={newDataRes} />
            </Paper>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Paper className={`${printStyles ? classes.printPaper : classes.paper} `} elevation={3}>
              <Chart type='bar' title='Sensitivity' suffix='%' titleY='Signal (%)' options={newDataSen} />
            </Paper>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Paper className={`${printStyles ? classes.printPaper : classes.paper} `} elevation={3}>
              <Chart type='bar' title='Stroke Speed' suffix='s' titleY='Time (s)' options={newDataStroke} />
            </Paper>
          </Grid>*/}
        </Grid>
        <Grid container spacing={1} style={{ marginTop: 0 }} className={classes.pageBreakAfter}>
          <ReportChartWidget title={`${this.state.type}  Vulnerability`} suffix='%' type='pie' initialWidth={6} seriesData={newDataOldest} loading={this.state.loading}></ReportChartWidget>
          <ReportChartWidget title='Recent Vulnerability' suffix='%' type='pie' initialWidth={6} seriesData={newDataRecent} loading={this.state.loading}></ReportChartWidget>
        </Grid>
        <PrintHeader></PrintHeader>
        <WidgetTitle
          //key={`table-title-${importance}`}
          title='Performance Summary'
        />
        <TableWidget
          //key={`table-${importance}`}
          defaultRows={10}
          data={tableData[importance]}
          header={headerArr}
          loading={this.state.loading}
        ></TableWidget>

        <WidgetTitle
          //key={`table-title-${importance}`}
          title='Valve Portfolio Criticality'
        />
        <TableWidget
          //key={`table-${importance}`}
          defaultRows={10}
          data={tableDataCriticality}
          header={tableHeader}
          loading={this.state.loading}
        ></TableWidget>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    reportData: state.reports.report_data.portfolio,
    currentModule: state.currentModule.currentModule,
    optimize: state.optimize,
    sort: state.sort,
    reportModule: state.reports.activeTab,
    applications: state.applications,
    locations: state.locations,
    sites: state.sites,
    clients: state.clients,
    format: state.format.format,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {
  getPortfolioReport,
  fetchLocations,
  fetchApplications,
  fetchSites,
  fetchClients,
  updateFilter,
  clearFilter,
  updateDateRange,
})(withStyles(styles)(PortfolioReport));
