import React from 'react';
import SupportTabs from '../menus/SupportTabs';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { supportSetActiveTab, supportSetActiveOptimize, supportSetActivePrioritize, setActiveNav, updatePreset, setModule } from '../../actions';
import { Link } from 'react-router-dom';
//import Introduction from '../supportContent/optimize/Introduction';
import Start from '../supportContent/optimize/Start';
import FAQ from '../supportContent/optimize/FAQ';
import Contact from '../supportContent/optimize/Contact';
import Roadmaps from '../supportContent/prioritize/Roadmaps';
//import IntroductionPrioritize from '../supportContent/prioritize/IntroductionPrioritize';
import StartPrioritize from '../supportContent/prioritize/StartPrioritize';
import ContactPrioritize from '../supportContent/prioritize/ContactPrioritize';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import functions from '../../functions/functions';
import Resources from '../supportContent/optimize/Resources';

class Support extends React.Component {
  componentDidMount() {
    //console.log(this.props.match.params);
    if (this.props.match.params.tab === 'null' || this.props.match.params.tab === null || !this.props.match.params.tab || this.props.match.params.tab === 'User') {
      this.props.supportSetActiveTab('Optimize');
      this.props.setModule('Optimize');
    } else {
      this.props.supportSetActiveTab(this.props.match.params.tab);
      this.props.setModule(this.props.match.params.tab);
    }
    if (this.props.match.params.supportTab !== 'null' && !!this.props.match.params.supportTab) {
      this.props.supportSetActiveOptimize(this.props.match.params.supportTab.toLowerCase());
      this.props.supportSetActivePrioritize(this.props.match.params.supportTab.toLowerCase());
    }
  }
  handleOptimize(event, value) {
    this.props.supportSetActiveOptimize(value);
    if (!value) {
      this.props.supportSetActiveOptimize('gettingstarted');
    }
    //console.log(value);
  }
  handlePrioritize(event, value) {
    this.props.supportSetActivePrioritize(value);
    if (!value) {
      this.props.supportSetActivePrioritize('gettingstarted');
    }
    //console.log(value);
  }
  handleMenuClick = (e) => {
    e.preventDefault();
  };
  renderPrioritizeSupport() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ToggleButtonGroup
          value={!this.props.activePrioritize ? 'gettingstarted' : this.props.activePrioritize}
          exclusive
          onChange={(event, value) => this.handlePrioritize(event, value)}
          aria-label='text alignment'
          className={classes.toggleButtons}
        >
          <ToggleButton value='gettingstarted' component={Link} to={`/support/Prioritize/GettingStarted`}>
            Getting Started
          </ToggleButton>
          {/*<ToggleButton value='roadmaps' component={Link} to={`/support/Prioritize/Roadmaps`}>
            Roadmaps
    </ToggleButton>*/}
          <ToggleButton value='contact' component={Link} to={`/support/Prioritize/Contact`}>
            Contact Us
          </ToggleButton>
        </ToggleButtonGroup>
        {this.props.activePrioritize === 'gettingstarted' ? (
          <StartPrioritize />
        ) : this.props.activePrioritize === 'roadmaps' ? (
          <Roadmaps />
        ) : this.props.activePrioritize === 'contact' ? (
          <ContactPrioritize />
        ) : null}
      </React.Fragment>
    );
  }
  renderOptimizeSupport() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ToggleButtonGroup
          value={!this.props.activeOptimize ? 'introduction' : this.props.activeOptimize}
          exclusive
          onChange={(event, value) => this.handleOptimize(event, value)}
          aria-label='text alignment'
          className={classes.toggleButtons}
        >
          <ToggleButton value='gettingstarted' component={Link} to={`/support/Optimize/GettingStarted`}>
            Getting Started
          </ToggleButton>
          <ToggleButton value='resources' component={Link} to={`/support/Optimize/Resources`}>
            Resources
          </ToggleButton>
          <ToggleButton value='faq' component={Link} to={`/support/Optimize/FAQ`}>
            FAQ
          </ToggleButton>
          <ToggleButton value='contact' component={Link} to={`/support/Optimize/Contact`}>
            Contact Us
          </ToggleButton>
        </ToggleButtonGroup>
        {this.props.activeOptimize === 'gettingstarted' ? (
          <Start />
        ) : this.props.activeOptimize === 'faq' ? (
          <FAQ />
        ) : this.props.activeOptimize === 'contact' ? (
          <Contact />
        ) : this.props.activeOptimize === 'resources' ? (
          <Resources />
        ) : null}
      </React.Fragment>
    );
  }
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        {this.props.format ? null : <SupportTabs />}
        <div className={printStyles ? null : classes.detailsMin}>
          {this.props.activeTab === 'Prioritize' && this.renderPrioritizeSupport()}
          {this.props.activeTab === 'Optimize' && this.renderOptimizeSupport()}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.support.activeTab,
    activePrioritize: state.support.activePrioritize,
    activeOptimize: state.support.activeOptimize,
    activeNav: state.nav.activeNav,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {
  supportSetActiveTab,
  supportSetActiveOptimize,
  supportSetActivePrioritize,
  setActiveNav,
  updatePreset,
  setModule,
})(withStyles(styles, { withTheme: true })(Support));
