import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { connect } from 'react-redux';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import '../calendar.css';
//import { Redirect } from 'react-router-dom';

//GET RID OF WHEN USING REDUX
var eventGuid = 0;
var todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
var tomorrowStr = new Date().setDate(new Date().getDate() + 1); // YYYY-MM-DD of today

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weekendsVisible: true,
      currentEvents: [],
    };
  }

  createEventId = () => {
    return String(eventGuid++);
  };

  //GET RID OF USING REDUX??
  INITIAL_EVENTS = [
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: todayStr,
    },
    {
      id: this.createEventId(),
      title: 'Timed event',
      start: todayStr + 'T12:00:00',
    },
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: todayStr,
    },
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: todayStr,
    },
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: todayStr,
    },
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: todayStr,
    },
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: todayStr,
      color: '#D3001B',
    },
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: todayStr,
    },
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: todayStr,
      color: '#308d3b',
    },
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: todayStr,
    },
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: tomorrowStr,
    },
    {
      id: this.createEventId(),
      title: 'All-day event',
      start: todayStr,
    },
  ];

  componentDidMount() {
    /*if (this.props.match.params.tab === 'null' || this.props.match.params.tab === null || !this.props.match.params.tab) {
      this.props.uploadSetActiveTab('Optimize');
    } else {
      this.props.uploadSetActiveTab(this.props.match.params.tab);
    }*/
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  handleDateSelect = (selectInfo) => {
    //console.log('t');
    let title = prompt('Please enter a new title for your event');
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: this.createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  handleEventClick = (clickInfo) => {
    //console.log('t');
    if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    });
  };

  renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };
  render() {
    //const { classes } = this.props;
    return (
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView='timeGridWeek'
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={this.state.weekendsVisible}
        initialEvents={this.INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
        select={this.handleDateSelect}
        eventContent={this.renderEventContent} // custom render function
        eventClick={this.handleEventClick}
        eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.upload.activeTab,
  };
};

export default connect(mapStateToProps, {
  //uploadSetActiveTab,
})(withStyles(styles, { withTheme: true })(Calendar));
