//import _ from 'lodash';
import {
  CLEAR_STATE,
  DELETE_LOOPS,
  DELETE_LOOPS_ARCHIVE,
  FETCH_ACTION_ITEMS,
  FETCH_LOOP,
  FETCH_LOOP_ARCHIVE,
  FETCH_LOOPS,
  FETCH_LOOPS_ARCHIVE,
  FETCH_PRIORITIZE_DIAGNOSTICS,
  UPDATE_LOOP_COUNT,
  SET_LOOP_SUMMARY_STATUS /*,
  FETCH_LOCATIONS,
  CREATE_LOCATION,
  DELETE_LOCATION,
  EDIT_LOCATION*/,
} from '../actions/types';

const INITIAL_STATE = { loops: {}, archives: {}, count: 0, summary: false };

const loopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return {
        ...state,
        loops: {},
        archives: {},
      };
    }
    case DELETE_LOOPS:
      return {
        ...state,
        loops: {},
      };
    case DELETE_LOOPS_ARCHIVE:
      return {
        ...state,
        archives: {},
      };
    /*case FETCH_LOOP: {
      let loops = Object.assign({}, state.loops);
      let loop = action.payload;
      console.log(loop);
      if (!state.loops[loop].loopId) {
        //test doesn't exist yet, add
        //console.log('add');
        loops[loop.loopId] = loop;
      } else {
        //console.log('merge');
        //test exists, merge data
        const mergedLoop = Object.assign(state.loops[loop.loopId], loop);
        loops[loop.loopId] = mergedLoop;
      }
      return {
        ...state,
        loops,
      };
    }*/
    case FETCH_LOOP:
    case FETCH_LOOPS: {
      let loops = Object.assign({}, state.loops);
      for (let i = 0; i < action.payload.length; i++) {
        let loop = action.payload[i];
        if (!state.loops[loop.loopId]) {
          //test doesn't exist yet, add
          //console.log('add');
          loops[loop.loopId] = loop;
        } else if (!!state.loops[loop.loopId].rowId) {
          //console.log('t');
          //do nothing, the detailed request data already exists
        } else {
          //console.log('merge');
          //test exists, merge data
          const mergedLoop = Object.assign(state.loops[loop.loopId], loop);
          loops[loop.loopId] = mergedLoop;
        }
      }
      return {
        ...state,
        loops,
      };
    }
    case FETCH_LOOP_ARCHIVE:
    case FETCH_LOOPS_ARCHIVE: {
      let archives = Object.assign({}, state.archives);
      for (let i = 0; i < action.payload.length; i++) {
        let loop = action.payload[i];
        if (!state.archives[loop.loopId]) {
          //test doesn't exist yet, add
          //console.log('add');
          archives[loop.loopId] = loop;
        } else if (!!state.archives[loop.loopId].rowId) {
          //console.log('t');
          //do nothing, the detailed request data already exists
        } else {
          //console.log('merge');
          //test exists, merge data
          const mergedLoop = Object.assign(state.archives[loop.loopId], loop);
          archives[loop.loopId] = mergedLoop;
        }
      }
      return {
        ...state,
        archives,
      };
    }
    case FETCH_PRIORITIZE_DIAGNOSTICS: {
      if (Object.keys(action.payload).length === 0) {
        return state;
      }
      let loopId = action.payload[0].loopId;

      /*console.log(loopId);
      console.log(state.loops);
      console.log(action.payload);*/
      // console.log(deviceId);
      let loops = Object.assign([], state.loops);
      if (!state.loops[loopId]) {
        //console.log('add');
        //test doesn't exist yet, add
        //console.log('add');
        loops[loopId] = { diagnostics: action.payload[0] };
      } else {
        //console.log('merge');
        //test exists, merge data
        loops[loopId].diagnostics = action.payload[0];
      }
      /*for (let i = 0; i < action.payload.length; i++) {
        let device = action.payload[i];
        if (!state.devices[device.deviceId]) {
          //test doesn't exist yet, add
          devices[device.deviceId] = device;
        } else {
          //test exists, merge data
          devices[device.deviceId] = Object.assign(
            state.devices[device.deviceId],
            device
          );
        }
      }
*/
      //console.log(loops);
      return {
        ...state,
        loops,
      };
    }
    case FETCH_ACTION_ITEMS: {
      if (action.payload.length === 0) {
        return state;
      }

      //TODO RESTRUCTURE TO USE ITS OWN FILE SYSTEM AND REDO ACTION ITEMS PAGE TO READ
      let tempLoops = {};
      for (let i = 0; i < action.payload.length; i++) {
        let loopId = action.payload[i].loopId;
        if (!tempLoops[loopId]) {
          //console.log('add');
          //test doesn't exist yet, add
          //console.log('add');
          tempLoops[loopId] = [action.payload[i]];
        } else {
          tempLoops[loopId].push(action.payload[i]);
        }
      }
      /*console.log(loopId);
      console.log(state.loops);
      console.log(action.payload);*/
      // console.log(deviceId);
      let tempKeys = Object.keys(tempLoops);
      let loops = Object.assign([], state.loops);
      for (let i = 0; i < tempKeys.length; i++) {
        let loopId = tempKeys[i];
        if (!state.loops[loopId]) {
          //console.log('add');
          //test doesn't exist yet, add
          //console.log('add');
          loops[loopId] = { improvements: tempLoops[loopId] };
        } else {
          //console.log('merge');
          //test exists, merge data
          loops[loopId].improvements = tempLoops[loopId];
        }
      }
      // console.log(loops);
      /*for (let i = 0; i < action.payload.length; i++) {
        let device = action.payload[i];
        if (!state.devices[device.deviceId]) {
          //test doesn't exist yet, add
          devices[device.deviceId] = device;
        } else {
          //test exists, merge data
          devices[device.deviceId] = Object.assign(
            state.devices[device.deviceId],
            device
          );
        }
      }
*/
      //console.log(loops);
      return {
        ...state,
        loops,
      };
    }
    //return { ...state, ..._.mapKeys(action.payload, 'id') };
    case UPDATE_LOOP_COUNT:
      return { ...state, count: action.payload };
    case SET_LOOP_SUMMARY_STATUS:
      return { ...state, summary: action.payload };
    /*case EDIT_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_LOCATION:
      return _.omit(state, action.payload);*/
    default:
      return state;
  }
};

export default loopReducer;
