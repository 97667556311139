import { SET_COLOR_MODE } from '../actions/types';

const INITIAL_STATE = {
  colorMode: 'light',
};

const colorModeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COLOR_MODE:
      return { ...state, colorMode: action.payload };
    default:
      return state;
  }
};

export default colorModeReducer;
