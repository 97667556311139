import React, { useState } from 'react';
import { Typography, Grid, List, Button } from '@mui/material';
import ChecklistIndicator from '../filters/ChecklistIndicator';
import alignmentDetails from '../../arrays/optimize/performanceDetails';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from '../styles';
import imagesArray from '../../arrays/optimize/imagesArray';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import performanceDetails from '../../arrays/optimize/performanceDetails';

const useStyles = makeStyles(styles);

function PerformanceDetails(props) {
  const classes = useStyles();
  let id = props.id;
  const [showMore, setShowMore] = useState(false);
  if (!alignmentDetails[id]) {
    return <Typography style={{ marginRight: 40 }}>Loading...</Typography>;
  }
  function handleShowMore(e) {
    e.preventDefault();
    setShowMore(!showMore);
  }
  let content = (
    <Grid container spacing={0} justifyContent='space-between'>
      <Grid item>
        {props.activeTab === 'intro' ? (
          <React.Fragment>
            <div className={classes.helperSection}>
              <Typography variant='subtitle1' className={classes.bodyHeader}>
                Description
              </Typography>
              <Typography variant='body1' className={classes.body}>
                {!alignmentDetails[id].description ? `Description Not Found!` : alignmentDetails[id].description}
              </Typography>
            </div>
            {alignmentDetails[id].title === 'Step Times' ? null : (
              <div className={classes.helperSection}>
                <Typography variant='subtitle1' className={classes.bodyHeader}>
                  Concern Level
                </Typography>
                {!alignmentDetails[id].alarms
                  ? `Concern not listed for this item!`
                  : Object.keys(alignmentDetails[id].alarms).map((alarm) => {
                      const labelId = `checkbox-list-label-${alarm.replace(/\s/g, '')}`;
                      if (props.colorValue === alarm || showMore === true) {
                        return (
                          (<div key={`${alarm.replace(/\s/g, '')}_item`} style={{ marginBottom: 15 }}>
                            <ChecklistIndicator color={alarm.toLowerCase()} bodyCheckbox></ChecklistIndicator>
                            <Typography style={{ width: '100%' }} id={labelId} className={classes.body}>
                              <span style={{ fontWeight: 500 }}>{alarm}</span>
                              <br></br>
                              {alignmentDetails[id].alarms[alarm]}
                            </Typography>
                          </div>)
                        );
                      } else return null;
                    })}
                <Button onClick={handleShowMore}>
                  {showMore ? (
                    <React.Fragment>
                      <ArrowDropUp className={classes.buttonIcon}></ArrowDropUp>Show Less
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ArrowDropDown className={classes.buttonIcon}></ArrowDropDown>Show More
                    </React.Fragment>
                  )}
                </Button>
              </div>
            )}
          </React.Fragment>
        ) : null}
        {props.activeTab === 'investigate' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Step by Step Investigation
            </Typography>
            <List>
              {!alignmentDetails[id].investigate
                ? `Investigation name not found!`
                : Object.keys(alignmentDetails[id].investigate).map((recommendation, index) => {
                    const labelId = `checkbox-list-label-${recommendation.replace(/\s/g, '')}`;
                    var recommendationLabel = recommendation.slice(5, 6);
                    if (labelId.includes('Indent')) {
                      return (
                        (<Typography key={`${recommendation.replace(/\s/g, '')}_item`} role={undefined} className={classes.bodyList} style={{ paddingLeft: 10, marginTop: -10 }} id={labelId}>
                          {alignmentDetails[id].investigate[recommendation]}
                        </Typography>)
                      );
                    } else if (labelId.includes('Title')) {
                      return (
                        <React.Fragment>
                          <Typography variant='subtitle2'>Step {recommendationLabel}</Typography>
                          <Typography variant='subtitle2' color='secondary'>
                            {alignmentDetails[id].investigate[recommendation]}
                          </Typography>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        (<Typography key={`${recommendation.replace(/\s/g, '')}_item`} role={undefined} className={classes.bodyList} id={labelId}>
                          {alignmentDetails[id].investigate[recommendation]}
                        </Typography>)
                      );
                    }
                  })}
            </List>
          </div>
        ) : null}
        {props.activeTab === 'examples' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Examples
            </Typography>
            <List>
              {!alignmentDetails[id].examples
                ? null
                : Object.keys(alignmentDetails[id].examples).map((example, index) => {
                    let size = performanceDetails[id].examples[example].size;
                    return (
                      <Grid key={example} container style={{ marginTop: 10, marginBottom: 30 }}>
                        <Grid item xs={12}>
                          <Typography variant='subtitle2' className={classes.body} color='secondary' style={{ width: '100%' }}>
                            <span style={{ fontWeight: 500 }}>Example {index + 1}</span> - {alignmentDetails[id].examples[example].title}
                          </Typography>
                          <Typography variant='body1' className={classes.body}>
                            {alignmentDetails[id].examples[example].caption}
                          </Typography>
                        </Grid>
                        <Grid container>
                          <img
                            key={`img-${alignmentDetails[id].examples[example].src}`}
                            src={imagesArray[alignmentDetails[id].examples[example].src]}
                            alt={alignmentDetails[id].examples[example].caption}
                            className={classes.helperScreenshot}
                            style={{
                              maxWidth: !!props.resize ? 1000 : size === 'xs' ? 340 : size === 'sm' ? 400 : size === 'md' ? 500 : 600,
                            }}
                          />
                        </Grid>
                        <Grid
                          container
                          justifyContent='space-between'
                          style={{
                            maxWidth: !!props.resize ? 1000 : size === 'xs' ? 340 : size === 'sm' ? 400 : size === 'md' ? 500 : 600,
                          }}
                        >
                          <Grid item></Grid>
                          <Grid item>
                            <Typography variant='caption' style={{ fontStyle: 'italic' }}>
                              Example {index + 1} image
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
            </List>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
  return content;
}

export default PerformanceDetails;
