import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { TextField, Typography, Button, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { MailOutline, Link } from '@mui/icons-material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WidgetTitle from '../../../headers/WidgetTitle';
import TableWidget from '../../../widgets/TableWidget';
import InfoPopover from '../../../popup/InfoPopover.js';
import { getSummaryReport, getImprovementsReport, updateFilter } from '../../../../actions/index';
import matchFilter from '../../../filters/functions/searchFilter';
import initializeTheme from '../../../theme.js';
import Highcharts from 'highcharts';
import ReportChartWidget from '../../../widgets/ReportChartWidget';
import functions from '../../../../functions/functions';
import CustomSelect from '../../../filters/CustomSelect.js';

const headerArr = [
  //{ id: 'visualId', label: 'Name' },
  //{ id: 'applicationType', label: 'Application' },
  { id: 'visualIdFormatted', label: 'Name' },
  { id: 'convertedTestTime', label: 'Date As Left' },
  //{ id: 'resolutionResultRoundedFormatted', label: 'Performance' },
  { id: 'resolutionResultRounded', label: 'Resolution' },
  { id: 'sensitivityResultRounded', label: 'Sensitivity' },
  { id: 'strokeResultRounded', label: 'Stroke Speed' },
  { id: 'improvement', label: 'Proposed Improvement Path' },
  { id: 'level2', label: 'Repair Level' },
  { id: 'statusFormatted', label: 'Progress' },
  { id: 'contactFormatted', label: 'Contact' },
  { id: 'reportLink', label: 'Test' },
];

class ImprovementsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterStr: '',
      filter: {},
      copy: false,
      copyText: '',
      loading: false,
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    await this.props.getImprovementsReport();
    this.setState({ loading: false });
  }

  multiSelectOnClose = async (event, label, changesOverride) => {
    let obj = { ...this.props.sort.filter };
    this.setState({ filter: obj });
  };

  multiSelectOnChange = async (event, value, type) => {
    let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    obj[name] = value;
    this.props.updateFilter(obj);
  };
  approvalButton(id, approval) {
    //console.log(id, approval);
    //console.log(approval === null);
    //const action = approval === null;
    return <CheckCircleRoundedIcon color={approval === null || approval === 0 ? `disabled` : `primary`} />;
  }
  processTroubleshootingLevel(level) {
    switch (level) {
      case 0:
        return 'Note';
      case 1:
        return 'Field Adjustment';
      case 2:
        return 'Parts Replacement';
      case 3:
        return 'Body Repair';
      default:
        return 'Unknown';
    }
  }
  processStatus(data) {
    if (data.notApproved === 1) {
      return {
        status: 'Not Approved',
        contact: data.notApprovedBy,
        user: data.notApprovedByUser,
        date: data.notApprovedByDate,
      };
    } else if (data.completed === 1) {
      return {
        status: 'Completed',
        contact: data.completedBy,
        user: data.completedByUser,
        date: data.completedByDate,
      };
    } else if (data.approved === 1) {
      return {
        status: 'Approved',
        contact: data.approvedBy,
        user: data.approvedByUser,
        date: data.approvedByDate,
      };
    } else {
      return {
        status: '-',
        contact: '-',
        user: data.user,
        date: data.date,
      };
    }
  }

  processData = (rawData) => {
    /*let data = _.map(this.props.loops, (loop) => {
      let loopAdj = loop;
      loopAdj.meterCheck = 'TRUE';
      loopAdj.actionCheck = 'TRUE';
      //return loopAdj;
    });*/
    const filters = this.state.filter;
    //const report_link_function = this.getFastFieldReport;
    let filterList = ['Tag', 'Repair Level', 'Application', 'Unit', 'Site', 'Importance', 'Improvement Type', 'Issue Type'];
    let filterListRow = ['visualId', 'level', 'applicationType', 'location', 'site', 'importance', 'improvementType', 'issue_type'];
    //console.log(filters);
    let outerThis = this;
    let data = _.reduce(
      rawData,
      function (acc, obj) {
        //acc stands for accumlation, [] is the accumulator
        let match = true;
        for (let i = 0; i < filterList.length; i++) {
          if (
            (!filters[filterList[i]] ||
              filters[filterList[i]].length === 0 ||
              filters[filterList[i]].indexOf(obj[filterListRow[i]]) !== -1 ||
              //FIX FOR REPAIR LEVEL PROCESSING
              (filterList[i] === 'Repair Level' && filters[filterList[i]].indexOf(outerThis.processTroubleshootingLevel(obj[filterListRow[i]])) !== -1)) &&
            match
          ) {
          } else {
            match = false;
          } /*&& true*/
        }
        if (match) {
          acc.push(obj);
        }
        return acc;
      },
      []
    );
    return data;
  };

  getUniqueValues = (data, prop) => {
    let uniqueValues = [];
    for (let i = 0; i < data.length; i++) {
      if (uniqueValues.indexOf(data[i][prop]) === -1 && !!data[i][prop]) {
        uniqueValues.push(data[i][prop].toString());
      } else if (!data[i][prop]) {
        //console.log(`Error: Undefined in getUniqueValues() for ${prop}`);
      }
    }
    return uniqueValues;
  };

  customSelectFactory = (label, values) => {
    return (
      <CustomSelect
        id={null}
        label={label}
        disabled={this.state.loading}
        onCloseFunction={this.multiSelectOnClose}
        onChangeFunction={this.multiSelectOnChange}
        //type='unit'
        values={!this.props.sort.filter[label] ? [] : this.props.sort.filter[label]}
        options={values /*!this.props.sites || !this.props.sites.list ? [] : this.props.sites.list.sort()*/}
        value={null}
        helperText={null}
      />
    );
  };

  //todo Add Mouseover Popup for Comment or more data.
  render() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    let dataArr = !this.props.reports.improvements ? [] : matchFilter(this.props.reports.improvements, this.state.filterStr);
    dataArr = this.processData(dataArr); //let the filters work
    let data = [];
    const monoColors = Highcharts.getOptions().colors.map((c, i) =>
      Highcharts.color(Highcharts.getOptions().colors[0])
        .brighten((i - 6) / 16)
        .get()
    );
    const monoColors2 = Highcharts.getOptions().colors.map((c, i) =>
      Highcharts.color(Highcharts.getOptions().colors[7])
        .brighten((i - 3) / 12)
        .get()
    );
    let improvementTypeObj = {};
    let improvementTypeSeries = [];
    let repairLevelObj = {};
    let repairLevelSeries = [];
    let statusObj = {};
    let statusSeries = [];
    for (let i = 0; i < dataArr.length; i++) {
      //Handler for local development using http
      data.push(dataArr[i]);
      const href =
        window.location.host === 'localhost:3000'
          ? `http://${window.location.host}/optimize/${dataArr[i].deviceId}/Performance`
          : `https://${window.location.host}/optimize/${dataArr[i].deviceId}/Performance`;
      data[i].approved2 = this.approvalButton(dataArr[i].id, dataArr[i].approved);
      data[i].level2 = this.processTroubleshootingLevel(data[i].level);
      let status = this.processStatus(data[i]);
      if (!improvementTypeObj[data[i].improvementType]) {
        improvementTypeObj[data[i].improvementType] = 1;
      } else {
        improvementTypeObj[data[i].improvementType]++;
      }
      if (!repairLevelObj[data[i].level2]) {
        repairLevelObj[data[i].level2] = 1;
      } else {
        repairLevelObj[data[i].level2]++;
      }
      if (!statusObj[status.status]) {
        statusObj[status.status] = 1;
      } else {
        statusObj[status.status]++;
      }
      //data[i].status = status.status;
      data[i].statusFormatted = (
        <Typography
          variant='subtitle2'
          className={
            status.status === 'Not Approved'
              ? classes.redText
              : status.status === 'Approved'
              ? classes.blueText
              : status.status === 'Completed'
              ? classes.greenText
              : status.status === 'Pending'
              ? classes.grayText
              : null
          }
        >
          {status.status}
        </Typography>
      );
      data[i].status = status.status;
      data[i].contact = status.contact;
      data[i].contactFormatted = (
        <React.Fragment>
          <Typography variant='subtitle2'>{status.contact}</Typography>
          {!!status.user && status.user !== '' ? (
            <React.Fragment>
              <InfoPopover
                tooltip='Copy Email'
                title='Copy Email'
                resize
                onClick={() => {
                  //console.log('t');
                  navigator.clipboard.writeText(status.user);
                  this.setState({ copy: true, copyText: status.user });
                }}
                style={{ marginBottom: -15 }}
                icon={<MailOutline />}
                content={
                  <Typography style={{ marginRight: 40 }} variant='subtitle2' className={classes.printHide}>
                    '{this.state.copyText}' Copied to Clipboard
                  </Typography>
                }
              />
            </React.Fragment>
          ) : null}
        </React.Fragment>
      );
      data[i].visualIdFormatted = (
        <React.Fragment>
          <Typography variant='subtitle2'>{data[i].visualId}</Typography>
          <Typography variant='subtitle2'>{data[i].applicationType}</Typography>
        </React.Fragment>
      );
      data[i].reportLink = (
        <Button variant='outlined' size='medium' color='primary' className={`${classes.buttonFilterInline} ${classes.button}`} href={href}>
          <Link className={classes.buttonIcon}></Link>Link
        </Button>
      );
      data[i].resolutionResultRounded = Math.round(data[i].resolutionResult * 100) / 100 + '%';
      data[i].sensitivityResultRounded = Math.round(data[i].sensitivityResult * 100) / 100 + '%';
      data[i].strokeResultRounded = Math.round(data[i].strokeResult * 100) / 100 + 's';
      /*data[i].resolutionResultRoundedFormatted = (
        <React.Fragment>
          <Typography variant='subtitle2'>Resolution: {data[i].resolutionResultRounded}</Typography>
          <Typography variant='subtitle2'>Sensitivity: {data[i].sensitivityResultRounded}</Typography>
          <Typography variant='subtitle2'>Stroke Speed: {data[i].strokeResultRounded}</Typography>
        </React.Fragment>
      );*/
      data[i].id2 = data[i].id;
      data[i].id = data[i].rowNum;
    }
    const improvementTypeObjKeys = Object.keys(improvementTypeObj);
    for (let k = 0; k < improvementTypeObjKeys.length; k++) {
      improvementTypeSeries.push({ name: improvementTypeObjKeys[k], y: improvementTypeObj[improvementTypeObjKeys[k]] });
    }
    const repairLevelObjKeys = Object.keys(repairLevelObj);
    for (let k = 0; k < repairLevelObjKeys.length; k++) {
      repairLevelSeries.push({ name: repairLevelObjKeys[k], y: repairLevelObj[repairLevelObjKeys[k]] });
    }
    const statusObjKeys = Object.keys(statusObj);
    for (let k = 0; k < statusObjKeys.length; k++) {
      statusSeries.push({
        name: statusObjKeys[k] === '-' ? 'No Status' : statusObjKeys[k],
        y: statusObj[statusObjKeys[k]],
        color:
          statusObjKeys[k] === 'Approved'
            ? theme.palette.blue.color
            : statusObjKeys[k] === 'Not Approved'
            ? theme.palette.red.color
            : statusObjKeys[k] === '-'
            ? theme.palette.gray.light
            : statusObjKeys[k] === 'Completed'
            ? theme.palette.green.color
            : statusObjKeys[k] === 'Pending'
            ? theme.palette.gray.dark
            : theme.palette.gray.dark,
      });
    }
    const printStyles = functions.setPrintStyles(this.props.activeTab);

    let tag_names = this.getUniqueValues(data, 'visualId');
    let application_names = this.getUniqueValues(data, 'applicationType');
    let unit_names = this.getUniqueValues(data, 'location');
    let site_names = this.getUniqueValues(data, 'site');
    let repair_names = this.getUniqueValues(data, 'level2');
    let importance_names = this.getUniqueValues(data, 'importance');
    let improvement_type_names = this.getUniqueValues(data, 'improvementType');
    let issue_type_names = this.getUniqueValues(data, 'issue_type');

    return (
      <React.Fragment>
        {this.customSelectFactory('Site', site_names)}
        {this.customSelectFactory('Unit', unit_names)}
        {this.customSelectFactory('Application', application_names)}
        {this.customSelectFactory('Importance', importance_names)}
        {this.customSelectFactory('Improvement Type', improvement_type_names)}
        {this.customSelectFactory('Repair Level', repair_names)}
        {this.customSelectFactory('Tag', tag_names)}
        {this.customSelectFactory('Issue Type', issue_type_names)}
        <div className={classes.printWidgetSpacer}></div>
        <Grid container spacing={1}>
          <ReportChartWidget name='Improvement Types' data={improvementTypeSeries} type='pie' colors={monoColors} initialWidth={printStyles ? 12 : 4} loading={this.state.loading}></ReportChartWidget>
          <ReportChartWidget
            name='Repair Level Distribution'
            data={repairLevelSeries}
            type='pie'
            colors={monoColors2}
            initialWidth={printStyles ? 6 : 4}
            loading={this.state.loading}
          ></ReportChartWidget>
          <ReportChartWidget
            name='Improvement Progress'
            data={statusSeries}
            type='pie'
            colors={[theme.palette.green.color, theme.palette.gray.dark, theme.palette.secondary.main, theme.palette.red.color]}
            initialWidth={printStyles ? 6 : 4}
            loading={this.state.loading}
          ></ReportChartWidget>
        </Grid>
        <WidgetTitle title='Improvement Paths' />
        <TextField
          variant='outlined'
          InputProps={{ classes: { root: classes.inlineInput } }}
          className={`${classes.searchField} ${classes.printHide}`}
          placeholder='Search'
          hiddenLabel
          //defaultValue='Small'
          onChange={(event) => {
            this.setState({ filterStr: event.target.value });
          }}
          value={this.state.filterStr}
        />

        <TableWidget header={headerArr} data={data} defaultRows={25} loading={this.state.loading}></TableWidget>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reports: state.reports.report_data,
    colorMode: state.colorMode.colorMode,
    sort: state.sort,
  };
};

export default connect(mapStateToProps, {
  getImprovementsReport,
  getSummaryReport,
  updateFilter,
})(withStyles(styles)(ImprovementsReport));
