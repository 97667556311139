import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Grid, Tooltip, TableSortLabel, TableRow, TablePagination, TableHead, TableCell, TableBody, Table, Typography } from '@mui/material';
import { styles } from '../styles';
//import InfoPopover from '../popup/InfoPopover';
//import diagnosticsDetails from '../../arrays/optimize/diagnosticsDetails';

/*let counter = 0;
function createData(name, concern, type) {
  counter += 1;
  return { id: counter, name, concern, type };
}*/

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = !array ? [] : array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class TableComponentHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;
    return (
      <TableHead>
        <TableRow>
          {!this.props.header
            ? null
            : this.props.header.map(
                (row) => (
                  <TableCell
                    style={{
                      paddingTop: '12px',
                      paddingBottom: '12px',
                      borderBottom: '1px solid rgba(224, 224, 224, .3)',
                    }}
                    key={row.id}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    <Tooltip arrow placement='top' title='Sort' enterDelay={300}>
                      <TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ),
                this
              )}
        </TableRow>
      </TableHead>
    );
  }
}

TableComponentHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

class TableComponent extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'concern',
    selected: [],
    //data: this.props.data,
    page: 0,
    rowsPerPage: this.props.rows,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  /*getStripedStyle(index, row) {
    if (row.check) {
      return { backgroundColor: index % 2 ? '#f5f5f5' : null };
    }
  }*/
  render() {
    const data = this.props.data;
    const { classes } = this.props;
    const { /*data,*/ order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = !data ? rowsPerPage : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const rowCount = !data ? 0 : data.length;
    if (!data || data.length === 0) {
      return <Grid>No data found for this table.</Grid>;
    } else {
      //console.log(data);
      return (
        <React.Fragment>
          <Table aria-labelledby='tableTitle'>
            <TableComponentHead order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} rowCount={rowCount} header={this.props.header} />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index, check) => {
                  //console.log(n);
                  return (
                    <TableRow
                      style={{
                        height: row.check ? 34 : 60,
                        //backgroundColor: row.check && index % 2 === 1 ? '#f5f5f5' : null,
                      }}
                      hover
                      tabIndex={-1}
                      key={`${row.id}_${index}`}
                    >
                      {this.props.header.map((header) => {
                        return (
                          <TableCell component='th' key={`${header.id}`} scope='row' className={classes.checklistCell}>
                            {row[header.id] !== null ? (
                              header.id === 'diagnostic' ? (
                                !row.check ? (
                                  <Typography variant='subtitle2' color='secondary'>
                                    {row[header.id]}
                                  </Typography>
                                ) : (
                                  <React.Fragment>
                                    <Grid container>
                                      <Grid item>
                                        {/*<InfoPopover
                                          resize
                                          useOptimizeTabs
                                          showAll
                                          title={diagnosticsDetails[row.check].title}
                                          titleIcon={diagnosticsDetails[row.check].title}
                                          type='diagnostics'
                                          id={row.check}
                                        />*/}
                                      </Grid>
                                      <Grid item style={{ marginTop: 3 }}>
                                        {row[header.id]}
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                )
                              ) : (
                                row[header.id]
                              )
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && null}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component='div'
            count={rowCount}
            rowsPerPage={99}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            onClick={(e) => e.preventDefault()}
          />
        </React.Fragment>
      );
    }
  }
}

TableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableComponent);
