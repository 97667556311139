import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import { supportSetActiveTab } from '../../../actions';
import { supportSetActivePrioritize } from '../../../actions';
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import actionItem from '../../../images/screenshots_Prioritize/actionItem.jpg';
import actionItemsTable from '../../../images/screenshots_Prioritize/actionItemsTable.jpg';
import addActionItem from '../../../images/screenshots_Prioritize/addActionItem.jpg';
import helperIntro from '../../../images/screenshots_Prioritize/helperIntro.jpg';
import helperEx from '../../../images/screenshots_Prioritize/helperEx.jpg';
import helperRec from '../../../images/screenshots_Prioritize/helperRec.jpg';
import helperRecSpecific from '../../../images/screenshots_Prioritize/helperRecSpecific.jpg';
import issueOpen from '../../../images/screenshots_Prioritize/issueOpen.jpg';
import issues from '../../../images/screenshots_Prioritize/issues.jpg';
import issuesChecklist from '../../../images/screenshots_Prioritize/issuesChecklist.jpg';
import issuesChecklistSelected from '../../../images/screenshots_Prioritize/issuesChecklistSelected.jpg';
import loopIndex from '../../../images/screenshots_Prioritize/loopIndex.jpg';
import loopIndexFilters from '../../../images/screenshots_Prioritize/loopIndexFilters.jpg';
import loopIndexSelected from '../../../images/screenshots_Prioritize/loopIndexSelected.jpg';
import loopNav from '../../../images/screenshots_Prioritize/loopNav.jpg';
import searchBar from '../../../images/screenshots_Prioritize/searchBar.jpg';
import workbench from '../../../images/screenshots_Prioritize/workbench.jpg';
import actionItemsTableEdit from '../../../images/screenshots_Prioritize/actionItemsTableEdit.jpg';
//import VideoContainer from '../../widgets/VideoContainer';

class StartPrioritize extends React.Component {
  renderSection1() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginBottom: 10 }}>
          The Loop Index
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The first page you’ll see upon clicking the Prioritize tab is the Loop Index. Here you’ll notice there are boxes on your screen filled with information. Each represents a specific loop.
          Starting from the left side, you’ll see the location, name, number and type of devices that compose the loop. To the right of that is the loops Concern Score section. Here you will see the
          current Concern Score from your most recent Prioritize run, as well as the Concern Score Shift and Concern Score Max from a given analysis range. To learn more about Concern Score click on
          the information “i” to open a Helper. You will see the information “i” throughout Prioritize to help explain the various aspects of the software. Next to the Concern Score section shows you
          the Importance of this loop, which was defined during the configuration process. Next to that you’ll see your Open Issues and Action Items. You have probably also noticed as you move your
          mouse of a given Loop that it becomes highlighted. This is because this box also acts as a button to take you into the Workbench.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={loopIndex} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          In addition to what you see in the Loops, you’ll also notice there are various filters and sorting options at the top of the page. These may be helpful to you if you have a large amount of
          loops in different locations, or if you want to focus on a specific issue type, among other reasons. One particularly important filter is the Analysis Range. Changing the range will affect
          the concern scores of the Loops, because different metrics in Prioritize are time dependent. This can be valuable if you are looking for more immediate issues versus longer term trends.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={loopIndexFilters} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          If you ever want to search for a specific Loop, you can do so by utilizing the Search bar at the top of the page. In the right corner you will see 2 Icons. The first is the Print icon.
          Clicking it will open a new tab in your browser, with all of the information on the page correctly formatted for printing. The second Icon switches between “Light” and “Dark” mode on
          Prioritize, depending on your preference. Both of these Icons are consistent throughout Prioritize should you wish to use them.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={searchBar} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          You have probably also noticed as you move your mouse of a given Loop that it becomes highlighted. This is because this box also acts as a button to take you into the Workbench.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={loopIndexSelected} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='h6' style={{ marginTop: 32, marginBottom: 10 }}>
          Workbench
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Workbench is the main area where analysis will be undertaken on a given Loop.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={workbench} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          As we look at the Workbench, from the top of the page you’ll see additional tabs for Address Issues and Action Items. We’ll return to these later. Below that you’ll see some of the same
          information from the Loop Index, as well as the individual devices that make up the Loop. This provides additional details on the concern scores of each device. Underneath this box and to
          the left, you’ll notice the Analysis Range is carried over from the Loop index. This directly affects the Issues List you see below that.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={loopNav} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Issues List shows you which Issues are affecting the Loop. The color coding of red, yellow, and green indicate the level of concern for a given issue. You can learn more about each Issue
          by clicking the information “i”.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={issuesChecklist} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 300 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Let’s take a moment now to look at the Issues helper, as there is a bit more information to go through. We’ll look at the Stuck Reading Issue as an example. You’ll see there are tabs on the
          top of the Helper. The first tab, Introduction, gives a description of the Issue, as well the current concern level of that Issue for this Loop. If you click on “Show More” you can see
          explanations of all the concern levels for this Issue.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={helperIntro} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 600 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Next, on the General Recommendations tab, there are baseline actions that an analyst should take when reviewing the Issues. In many cases, there will be multiple Issues affected if these
          General Recommendations come into play. These will become second nature after repeated usage of Prioritize, but it is good to have them as a reminder early on.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={helperRec} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 600 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The Stuck Reading Recommendations shows recommendations that are specific to this Issue. All other Issues have specific recommendations as well. These exist for the analyst to start thinking
          about what the potential causes are, and possible resolutions, to this issue. The recommendations will become useful later when we get to the Address Issues tab.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={helperRecSpecific} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 600 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Lastly in the Issues Helper there is the Examples tab. Here you will get a description and visualization of different ways this issue can manifest in your data.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={helperEx} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 600 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Let’s now go back to the Issues List. You’ll notice next to each Issue there is a check box. Clicking a checkbox and then pressing “Submit” will reload graphs to the right of the Issues
          List.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={issuesChecklistSelected} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The graphs initially show the primary variables of the devices of the Loop in a time series. The time range of the graphs defaults to the Analysis Range, but you have complete control to
          zoom in on or expand out to whatever time period you want. As previously mentioned, you can add graphs of the various Issues from the Issues List. This allows you to see graphically when
          Issues are of higher or lower concern over time, and how they compare with the primary variable data.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Now that we’ve covered the Workbench, let’s return to discussing the additional tabs, Address Issues and Action Items. While it is possible to click on either of these tabs at the top of the
          page, you can also click the “Show More” button at the bottom of the page. This will allow you to keep the Workbench accessible while also interacting with the other tabs.
        </Typography>
        <Typography variant='h6' style={{ marginTop: 32, marginBottom: 10 }}>
          Address Issues
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          The top of the Address Issues tab shows you a list of Issues based on the checklist from the Workbench. Clicking on an Issue will expand it. You’ll first see graphs showing the Events when
          the Issue occurred.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={issues} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <img src={issueOpen} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Below that you’ll see a header that says Auto-Generated Action Items. Prioritize will give you the option to select Action Items that are likely relevant to a given Issue. This can save you
          time by reducing the need to write out each Action Item you require. You can select as many Action Items as you need for your Issues. Each has a space for a comment which is prefilled, but
          can be edited by you. The type of Action and and Priority are also preset, but you can change these as well. Once your Action Item is set the way you want it to be, hit the “Submit” button.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={addActionItem} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Scrolling down, you’ll see that your Action Item has been added to the Action Items list. Here you can see the information about the Action Item, when it was added, its current Status,
          Contact, and a button to update the Status.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={actionItemsTable} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1200 }}></img>
          </Grid>
        </Grid>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Updating the Status lets you know if a certain Action Item has been approved, not approved, or completed.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={actionItemsTableEdit} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1200 }}></img>
          </Grid>
        </Grid>
        <Typography variant='h6' style={{ marginTop: 32, marginBottom: 10 }}>
          Action Items
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          Moving on to the Action Items tab, you’ll see a report for this Loop that shows you the Loop Concern information as seen in the Loop Index and Workbench, as well as the set of Action Items
          you created in the Address Issues tab. This page is useful for presenting your findings to the relevant stakeholders of this loop. From this tab you can also update the Status of a given
          Action Item which allows for easy workflow with the stakeholder.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={actionItem} alt='config screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 1000 }}></img>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginTop: 20, marginBottom: 10 }}>
          Hello and welcome to Prioritize by Ten One!
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10, marginBottom: 10 }}>
          Prioritize is a multifunctional tool that allows its user to view and analyze their data.
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10, marginBottom: 20 }}>
          If you have any further questions, you can{' '}
          <Link
            onClick={() => {
              this.props.supportSetActivePrioritize('contact');
            }}
            to='/support/Prioritize/Contact'
            className={classes.textLink}
          >
            contact us
          </Link>{' '}
          and we'll be happy to assist you.
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>A Brief Tour of the Website</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection1()}</AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.support.activeTab,
    activePrioritize: state.support.activePrioritize,
  };
};

export default connect(mapStateToProps, {
  supportSetActiveTab,
  supportSetActivePrioritize,
})(withStyles(styles, { withTheme: true })(StartPrioritize));
